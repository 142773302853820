import { Link } from 'react-router-dom';

import { useStyle } from './Layout.styles';

export function Logo(): JSX.Element {
  const { classes } = useStyle({});
  return (
    <Link to="/home" className={classes.logo}>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.82422 3.93555C9.14361 10.6759 6.60458 11.3478 6.52406 13.8662C6.37555 18.5122 9.92375 20.3077 10.1921 20.4867C10.1921 20.4867 8.80454 17.0413 9.83428 12.6137C10.8372 8.30236 7.72469 5.32137 4.82422 3.93555Z"
          fill="url(#paint0_linear_5555_74075)"
        />
        <path
          d="M27.9046 11.541C31.1254 13.8671 33.6304 19.1456 32.5013 22.0631C31.4367 15.0561 16.4369 11.3934 21.3736 0C6.4329 9.8412 12.0531 24.4375 14.7067 25.5853C6.32644 24.3095 4.6257 13.9253 4.6257 13.9253C2.88023 21.3921 1.43536 28.7587 9.29579 32.1181C5.62771 32.0286 2.5 28.8642 2.5 28.8642C9.38079 39.9168 25.5168 44.7148 34.7478 33.9656C40.4951 27.677 37.8353 13.1514 27.9046 11.541ZM17.6062 22.3306C22.1788 26.0344 24.5944 29.9709 27.3821 30.9067C27.3821 30.9067 21.8415 31.0042 19.5208 28.4482C17.1992 25.8931 17.6062 22.3306 17.6062 22.3306ZM10.9956 30.4182C13.3217 30.8656 14.9321 29.792 14.9321 29.792C14.9321 29.792 21.1446 36.3954 31.7722 32.5082C22.984 40.6173 11.9798 31.5813 10.9956 30.4182ZM34.2584 28.985C32.8655 30.7332 29.2833 30.9023 29.2833 30.9023C31.0359 30.2393 32.6463 27.5554 35.239 24.9528C35.239 24.9528 35.6514 27.2369 34.2584 28.985Z"
          fill="url(#paint1_linear_5555_74075)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_5555_74075"
            x1="4.82422"
            y1="12.2111"
            x2="10.1921"
            y2="12.2111"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#00C6A0" />
            <stop offset="0.24" stopColor="#00C6A3" />
            <stop offset="0.44" stopColor="#01C6AD" />
            <stop offset="0.62" stopColor="#04C7BE" />
            <stop offset="0.79" stopColor="#07C9D6" />
            <stop offset="0.95" stopColor="#0BCBF5" />
            <stop offset="0.99" stopColor="#0DCCFF" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_5555_74075"
            x1="2.5"
            y1="19.9996"
            x2="37.7416"
            y2="19.9996"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#00C6A0" />
            <stop offset="0.24" stopColor="#00C6A3" />
            <stop offset="0.44" stopColor="#01C6AD" />
            <stop offset="0.62" stopColor="#04C7BE" />
            <stop offset="0.79" stopColor="#07C9D6" />
            <stop offset="0.95" stopColor="#0BCBF5" />
            <stop offset="0.99" stopColor="#0DCCFF" />
          </linearGradient>
        </defs>
      </svg>
    </Link>
  );
}
