import { FC, useEffect, useRef, useState } from 'react';

import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { mutate } from 'swr';

import { requestBetaTestReward } from 'api';
import { useClickOutside } from 'hooks';
import { CloseIcon, HrefIcon, InfoIcon } from 'icons';
import { Input, Button, Typography } from 'ui-kit';

import { Modal } from '../Modal';
import { useStyle } from './ModalRewards.styles';

interface IModalRewardProps {
  isOpen: boolean;
  onClose: () => void;
  selectedStep: number;
}

export const ModalReward: FC<IModalRewardProps> = ({
  isOpen,
  onClose,
  selectedStep,
}) => {
  const [rewardsError, setRewardsError] = useState('');
  const handleRequestSteamReward = async (
    tradeLink: string
  ): Promise<Response> => {
    try {
      const response = await requestBetaTestReward(selectedStep, tradeLink);
      if (response.ok) {
        mutate('api/rewards');
      }
      return response;
    } catch (err) {
      console.log(err);
      setRewardsError(err);
    }
  };
  const ref = useRef(null);
  useClickOutside(ref, onClose);
  const [tradeLink, setTradeLink] = useState('');
  const {
    classes,
    theme: { palette },
  } = useStyle();
  const onTradeSubmit = async (tradeLink: string): Promise<void> => {
    const response = await handleRequestSteamReward(tradeLink);
    if (response.ok) {
      onClose();
    }
  };

  const { t: translation } = useTranslation();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setRewardsError('');
    }, 3000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [rewardsError]);

  const { Text, Headline } = Typography;

  return (
    <Modal isOpen={isOpen}>
      <div ref={ref} className={classes.modalContainer}>
        <button onClick={onClose} className={classes.closeIcon}>
          <CloseIcon />
        </button>
        <Headline className={classes.title}>
          {translation('home.modalReward.title')}
        </Headline>
        <div className={classes.list}>
          <Text className={classes.line}>
            {translation('home.modalReward.friendRequest')}
            <div
              className={classes.link}
              onClick={() =>
                window.open(
                  'https://steamcommunity.com/profiles/76561199404873624',
                  '_blank'
                )
              }
            >
              {translation('home.modalReward.account')}
              <HrefIcon />
            </div>
          </Text>
          <Text variant="b3" className={clsx(classes.line, classes.hint)}>
            <InfoIcon color={palette.typo.warning} width={32} />
            {translation('home.modalReward.hint')}
          </Text>
          <Text className={classes.line}>
            {translation('home.modalReward.steamUrl')}
            <div
              className={classes.link}
              onClick={() =>
                window.open(
                  'https://www.youtube.com/watch?v=pUVxLn-znoU&ab_channel=GameGreed',
                  '_blank'
                )
              }
            >
              {translation('home.modalReward.tradeUrl')}
              <HrefIcon />
            </div>
          </Text>
        </div>
        <Input
          className={classes.input}
          value={tradeLink}
          placeholder={translation('home.modalReward.placeholder')}
          onChange={(event) => setTradeLink(event.target.value)}
        />
        <div className={classes.buttonContainer}>
          <Button
            onClick={() => onTradeSubmit(tradeLink)}
            className={classes.button}
            disabled={!tradeLink.length}
          >
            {translation('home.modalReward.button')}
          </Button>
          {!!rewardsError && (
            <span className={classes.error}>
              {translation('challengeView.errors.server')}
            </span>
          )}
        </div>
        <Text variant="b4" className={classes.discordDescription}>
          {translation('home.modalReward.advise')}{' '}
          <a
            href="https://discord.gg/7fKEWhnh4x"
            className={classes.discordLink}
          >
            {translation('home.modalReward.discordLink')}
          </a>
        </Text>
      </div>
    </Modal>
  );
};
