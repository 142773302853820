import { FC, useContext, useEffect, useRef, useState } from 'react';

import { Controller, FormProvider, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useSWR, { mutate } from 'swr';

import { getBalance, isErrorResponse, sendWithdrawData } from 'api';
import { useClickOutside } from 'hooks';
import { Button, Dividers, Input, InputNumber, Typography } from 'ui-kit';

import { ModalContext } from '../../ModalProvider';
import { Modal } from '../Modal';
import { useStyle } from './Withdraw.styles';

interface IWithdrawProps {
  isOpen: boolean;
  onClose: () => void;
}

const defaultValues = {
  address: '',
  amount: null,
};

type WithdrawTypes = typeof defaultValues;

export const Withdraw: FC<IWithdrawProps> = ({ isOpen, onClose }) => {
  const ref = useRef(null);
  const { classes } = useStyle();
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);

  useClickOutside(ref, onClose);
  const { Text, Headline } = Typography;
  const { VerticalDivider, HorizontalDivider } = Dividers;
  const { data: balanceData } = useSWR('api/balance');
  const methods = useForm<WithdrawTypes>({ defaultValues });
  const {
    formState: { errors },
    control,
    register,
    getValues,
    trigger,
    setError,
    clearErrors,
    reset,
  } = methods;
  const address = useWatch<WithdrawTypes>({ name: 'address', control });
  const amount = useWatch<WithdrawTypes>({ name: 'amount', control });

  const {
    walletSuccess: { openWalletSuccess },
    walletError: { openWalletError },
  } = useContext(ModalContext);

  const onWithdraw = async (): Promise<void> => {
    if (errors['amount']) {
      return;
    }
    const isValid = await trigger(['address', 'amount']);
    if (!isValid) {
      return;
    }
    const { address, amount } = getValues();
    setIsButtonDisabled(true);
    const response = await sendWithdrawData(address, amount);
    if (response.status === 200) {
      onClose();
      openWalletSuccess();
      reset(defaultValues);
      setIsButtonDisabled(false);
      mutate('api/balance', getBalance);
    } else {
      onClose();
      reset(defaultValues);
      setIsButtonDisabled(false);
      openWalletError();
    }
  };

  useEffect(() => {
    const balance = balanceData?.balance || 0;
    if (amount > balance) {
      setError('amount', {
        type: 'custom',
        message: translation('profile.withdraw.amountInsuffError'),
      });
    } else {
      clearErrors('amount');
    }
  }, [amount]);

  const { t: translation } = useTranslation();

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        reset(defaultValues);
        onClose();
      }}
    >
      <div ref={ref} className={classes.modal}>
        <Headline variant="h3" className={classes.title}>
          {translation('profile.withdraw.title')}
        </Headline>
        <div className={classes.infoContainer}>
          <Text variant="b5" className={classes.text}>
            {translation('profile.withdraw.description')}{' '}
            <a
              href="https://discord.gg/7fKEWhnh4x"
              target="_blank"
              rel="noreferrer"
            >
              <Text variant="b5" color="link">
                Discord
              </Text>
            </a>
          </Text>
          <Text variant="b6" className={classes.text} color="warning">
            {translation('profile.withdraw.warning')}
          </Text>
        </div>
        <div className={classes.currencyContainer}>
          <div className={classes.currencyItem}>
            <Text className={classes.text} color="typoLabel" variant="b6">
              {translation('profile.withdraw.currency')}
            </Text>
            <Text className={classes.text} variant="b5">
              Teather (USDT)
            </Text>
          </div>
          <VerticalDivider />
          <div className={classes.currencyItem}>
            <Text className={classes.text} color="typoLabel" variant="b6">
              {translation('profile.withdraw.network')}
            </Text>
            <Text className={classes.text} variant="b5">
              TRC20
            </Text>
          </div>
          <VerticalDivider />
          <div className={classes.currencyItem}>
            <Text className={classes.text} color="typoLabel" variant="b6">
              {translation('profile.withdraw.exchange')}
            </Text>
            <Text className={classes.text} variant="b5">
              1 USDT = 1000 GC
            </Text>
          </div>
          <VerticalDivider />
          <div className={classes.currencyItem}>
            <Text className={classes.text} color="typoLabel" variant="b6">
              {translation('profile.withdraw.fee')}
            </Text>
            <Text className={classes.text} variant="b5">
              3% + 0.8 USDT
            </Text>
          </div>
        </div>
        <HorizontalDivider />
        <FormProvider {...methods}>
          <Input
            placeholder={translation('profile.withdraw.walletHolder')}
            title={translation('profile.withdraw.walletTitle')}
            className={classes.addressInput}
            error={errors['address']?.message}
            {...register('address', {
              required: {
                value: true,
                message: translation('profile.withdraw.walletError'),
              },
              minLength: {
                value: 34,
                message: translation('profile.withdraw.walletError'),
              },
              maxLength: {
                value: 34,
                message: translation('profile.withdraw.walletError'),
              },
            })}
          />
          <div className={classes.amountContainer}>
            <Controller
              name="amount"
              rules={{
                required: {
                  value: true,
                  message: translation('profile.withdraw.minError'),
                },
                min: {
                  value: 20000,
                  message: translation('profile.withdraw.minError'),
                },
              }}
              render={({ field: { onChange, ...rest } }) => (
                <InputNumber
                  error={errors['amount']?.message as string}
                  placeholder="20 000 GC"
                  title={translation('profile.withdraw.amountTitle')}
                  className={classes.amountInput}
                  suffix={' GC'}
                  disabled={!address}
                  onValueChange={({ value }) => onChange(value)}
                  {...rest}
                />
              )}
            />
          </div>
          <div className={classes.footer}>
            <div className={classes.currencyItem}>
              <Text className={classes.text} color="typoLabel" variant="b6">
                {translation('profile.withdraw.total')}
              </Text>
              <Text className={classes.text} variant="b5">
                {amount >= 20000
                  ? ((amount * 0.97) / 1000 - 0.8).toFixed(2)
                  : 0}{' '}
                USDT
              </Text>
            </div>
            <Button
              color="secondary"
              className={classes.cancelBtn}
              onClick={() => {
                reset(defaultValues);
                onClose();
              }}
            >
              {translation('profile.withdraw.cancelBtn')}
            </Button>
            <Button onClick={onWithdraw} disabled={isButtonDisabled}>
              {translation('profile.withdraw.withdrawBtn')}
            </Button>
          </div>
        </FormProvider>
      </div>
    </Modal>
  );
};
