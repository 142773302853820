import { FC, InputHTMLAttributes } from 'react';

import clsx from 'clsx';

import { UICheck20Icon } from 'icons';
import { Typography } from 'ui-kit';

import { useStyle } from './Checkbox.styles';

export interface ICheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
}

export const Checkbox: FC<ICheckboxProps> = ({
  onChange,
  checked,
  label,
  className,
  ...rest
}) => {
  const { classes } = useStyle({ checked });

  const { Text } = Typography;

  return (
    <label className={clsx(classes.checkboxContainer, className)}>
      <input
        type="checkbox"
        checked={checked}
        onChange={onChange}
        className={classes.hideInput}
        {...rest}
      />
      <div className={classes.checkbox}>
        <UICheck20Icon className={classes.check} />
      </div>
      <Text variant="b4">{label}</Text>
    </label>
  );
};
