import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { Typography } from 'ui-kit';

import { Money } from '../Assets';
import { useStyle } from './MoneyBanner.styles';

export const MoneyBanner: FC = () => {
  const { classes } = useStyle();
  const { Headline, Text } = Typography;
  const { t: translation } = useTranslation();
  return (
    <div className={classes.bannerContainer}>
      <Money className={classes.money} />
      <div className={classes.textContainer}>
        <Headline block>{translation('profile.moneyBanner.title')}</Headline>
        <Text block>{translation('profile.moneyBanner.description')}</Text>
      </div>
    </div>
  );
};
