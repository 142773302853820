import React, { FC } from 'react';

import clsx from 'clsx';
import { ButtonProps } from 'primereact/button';
import { useTranslation } from 'react-i18next';

import { Typography } from 'ui-kit';

import { useStyle } from './RoadHowTo.styles';

interface IRoadStep extends ButtonProps {
  titleKey: string;
  descriptionKey: string;
}

const getGoldGC: FC<{ input: string; gcSubstring: string }> = ({
  input,
  gcSubstring,
}) => {
  const substrings = input.split(gcSubstring);
  const { Text } = Typography;
  return (
    <>
      {substrings[0]}
      <Text color="yellow" variant="b5">
        {gcSubstring}
      </Text>
      {substrings[1]}
    </>
  );
};

export const RoadStep: FC<IRoadStep> = ({
  titleKey,
  descriptionKey,
  className,
}) => {
  const { Text } = Typography;
  const { classes } = useStyle();
  const stepRegExp = /\d+[\d ]+GC/;
  const { t: translation } = useTranslation();
  const title = translation(titleKey);
  const description = translation(descriptionKey);
  const gcSubstring = description.match(stepRegExp);
  const hasGC = !!gcSubstring;

  const finishDescription = hasGC
    ? getGoldGC({ input: description, gcSubstring: gcSubstring[0] })
    : description;
  return (
    <div className={clsx(classes.step, className)}>
      <div className={classes.stepTitle}>
        <Text>{title}</Text>
      </div>
      <Text variant="b5">{finishDescription}</Text>
    </div>
  );
};
