import { Profile } from './Profile';
import { Referral } from './Referral';

export const PROFILE = {
  name: 'profile',
  routes: [
    {
      title: 'header.tabs.statistic',
      path: '/profile',
      element: <Profile />,
    },
    {
      title: 'header.tabs.referral',
      path: '/profile/referral',
      element: <Referral />,
    },
  ],
};
