import { useRef } from 'react';

import { useTranslation } from 'react-i18next';

import { useClickOutside } from 'hooks';
import { Button, Typography } from 'ui-kit';

import { Modal } from '../Modal';
import { useStyle } from './DeleteModal.styles';

interface IDeleteModalProps {
  title: string;
  text: string;
  isOpen: boolean;
  onClose: () => void;
  isSubmitting: boolean;
  onDeleteChallenge: () => void;
}

export const DeleteModal: React.FC<IDeleteModalProps> = ({
  title,
  text,
  isOpen,
  onClose,
  isSubmitting,
  onDeleteChallenge,
}): JSX.Element => {
  const { t: translation } = useTranslation();
  const { classes } = useStyle();
  const ref = useRef(null);

  useClickOutside(ref, onClose);

  const { Text, Headline } = Typography;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className={classes.modal} ref={ref}>
        <Headline variant="h3">{title}</Headline>
        <Text variant="b4" className={classes.container}>
          {text}
        </Text>

        <div className={classes.buttonContainer}>
          <Button onClick={onClose} color="secondary">
            {translation('challenges-list.delete-modal.btnText')}
          </Button>

          <Button
            className={classes.btn}
            onClick={onDeleteChallenge}
            disabled={isSubmitting}
          >
            {isSubmitting
              ? translation('challenges-list.delete-modal.btnTextSubmitting')
              : translation('challenges-list.delete-modal.btnTextSubmit')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
