import { useEffect, useState } from 'react';

import useSWR, { mutate } from 'swr';

import {
  getActiveChallengesMy,
  getHistoryChallenges,
  getDraftChallenges,
  ICreatedChallenge,
} from 'api';
import { convertObjValuesToString } from 'helpers';
import { prevState } from 'middleware/prevState';

import { EChallengeRole, EChallengeViewMode } from '../Challenges.model';

export const updateDraftChallenges = (
  title: string,
  currentPage: number,
  itemPerPage: number
): void => {
  const params = new URLSearchParams(
    convertObjValuesToString({
      title,
      offset: (currentPage - 1) * itemPerPage,
      limit: itemPerPage,
    })
  );
  mutate(`api/challenges/draft?${params.toString()}`);
};

interface IParams {
  activeMode: EChallengeViewMode;
  debouncedFilters: {
    title: string;
    roles: EChallengeRole;
  };
  itemPerPage: number;
  currentPage: number;
}

interface IUseGetChallengeListResult {
  challenges: {
    total: number;
    data: ICreatedChallenge[];
  };
  isLoading: boolean;
}

export function useGetChallengesList({
  debouncedFilters,
  activeMode,
  itemPerPage,
  currentPage,
}: IParams): IUseGetChallengeListResult {
  const [challenges, setChallenges] = useState<{
    total: number;
    data: ICreatedChallenge[];
  }>({ total: 0, data: [] });

  // EChallengeViewMode.MyActive
  const { data: activeChallenges, isValidating: isValidatingMyActive } = useSWR(
    () => {
      const params = new URLSearchParams(
        convertObjValuesToString({
          asOwner:
            debouncedFilters.roles === EChallengeRole.Both
              ? undefined
              : [EChallengeRole.Both, EChallengeRole.Owner].includes(
                  debouncedFilters.roles
                ),
          title: debouncedFilters.title,
          offset: 0,
          limit: 8,
        })
      );

      return activeMode === EChallengeViewMode.MyActive
        ? `api/challenges/active?${params.toString()}`
        : null;
    },
    () =>
      getActiveChallengesMy({
        title: debouncedFilters.title,
        asOwner:
          debouncedFilters.roles === EChallengeRole.Both
            ? undefined
            : [EChallengeRole.Both, EChallengeRole.Owner].includes(
                debouncedFilters.roles
              ),
        offset: 0,
        limit: 8,
      }),
    { use: [prevState] }
  );

  // EChallengeViewMode.Draft
  const { data: draftChallenges, isValidating: isValidatingDraft } = useSWR(
    () => {
      const params = new URLSearchParams(
        convertObjValuesToString({
          title: debouncedFilters.title,
          offset: (currentPage - 1) * itemPerPage,
          limit: itemPerPage,
        })
      );
      return activeMode === EChallengeViewMode.Draft
        ? `api/challenges/draft?${params.toString()}`
        : null;
    },
    () =>
      getDraftChallenges({
        title: debouncedFilters.title,
        offset: (currentPage - 1) * itemPerPage,
        limit: itemPerPage,
      }),
    { use: [prevState] }
  );

  // EChallengeViewMode.History
  const { data: historyChallenges, isValidating: isValidatingHistory } = useSWR(
    () => {
      const params = new URLSearchParams(
        convertObjValuesToString({
          title: debouncedFilters.title,
          asOwner:
            debouncedFilters.roles === EChallengeRole.Both
              ? undefined
              : [EChallengeRole.Both, EChallengeRole.Owner].includes(
                  debouncedFilters.roles
                ),
          offset: (currentPage - 1) * itemPerPage,
          limit: itemPerPage,
        })
      );
      return activeMode === EChallengeViewMode.History
        ? `api/challenges/history?${params.toString()}`
        : null;
    },
    () =>
      getHistoryChallenges({
        title: debouncedFilters.title,
        asOwner:
          debouncedFilters.roles === EChallengeRole.Both
            ? undefined
            : [EChallengeRole.Both, EChallengeRole.Owner].includes(
                debouncedFilters.roles
              ),
        offset: (currentPage - 1) * itemPerPage,
        limit: itemPerPage,
      }),
    { use: [prevState] }
  );

  useEffect(() => {
    if (activeChallenges && activeMode === EChallengeViewMode.MyActive) {
      setChallenges({
        data: activeChallenges.result,
        total: activeChallenges.total,
      });
    } else if (draftChallenges && activeMode === EChallengeViewMode.Draft) {
      setChallenges({
        data: draftChallenges.result,
        total: draftChallenges.total,
      });
    } else if (historyChallenges && activeMode === EChallengeViewMode.History) {
      setChallenges({
        data: historyChallenges.result,
        total: historyChallenges.total,
      });
    } else {
      setChallenges({
        data: [],
        total: 0,
      });
    }
  }, [activeMode, activeChallenges, draftChallenges, historyChallenges]);

  return {
    challenges,
    isLoading:
      isValidatingHistory ||
      isValidatingMyActive ||
      isValidatingDraft ||
      !challenges,
  };
}
