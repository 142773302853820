import type { FC } from 'react';

import type { ICommonIconProps } from './types';

export const PhotoIcon: FC<ICommonIconProps> = ({
  color = '#8289A3',
  width = 74,
  height = 60,
  viewBox = '0 0 74 60',
  className,
  style,
}) => (
  <svg
    color={color}
    width={width}
    height={height}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M67.0007 0H7.00065C3.66732 0 0.333984 3.33333 0.333984 6.66667V53.3333C0.333984 57 3.33398 60 7.00065 60H67.0007C70.334 60 73.6673 56.6667 73.6673 53.3333V6.66667C73.6673 3.33333 70.334 0 67.0007 0ZM67.0007 53.0667C66.934 53.1667 66.8007 53.2667 66.734 53.3333H7.00065V6.93333L7.26732 6.66667H66.7007C66.8007 6.73333 66.9007 6.86667 66.9673 6.93333V53.0667H67.0007ZM33.6673 41.7L25.334 31.6667L13.6673 46.6667H60.334L45.334 26.6667L33.6673 41.7Z"
      fill="currentColor"
    />
  </svg>
);
