import { makeStyles } from 'theme';

export const useStyle = makeStyles()(() => ({
  description: {
    maxWidth: 600,
    marginBottom: 40,
    display: 'inline-block',
  },
  section: {
    paddingBottom: 300,
  },
  title: {
    display: 'inline-block',
    width: '100%',
    marginBottom: '1.75rem',
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 480,
  },
  counterContainer: {
    display: 'flex',
    marginLeft: '3rem',
  },
  condition: {
    width: 182,
  },
  additionalTitle: {
    width: 160,
  },
  inputTitle: {
    marginBottom: '2rem',
  },
  flexContainer: {
    display: 'flex',
    marginTop: 32,
    ':nth-of-type(2)': {
      marginTop: 0,
    },
  },
  counter: {
    width: 170,
  },
  checkBox: {
    marginTop: '1rem',
    marginBottom: '2rem',
  },
  quantityContainer: {
    marginLeft: '2rem',
  },
  additionalCounter: {
    marginLeft: 52,
    width: 174,
  },
  addButton: {
    marginBottom: '4rem',
    marginTop: '2rem',
  },
  fullWidth: {
    width: '100%',
  },
  conditionContainer: {
    maxWidth: 'min-content',
  },
}));
