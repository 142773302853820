import { makeStyles } from 'theme';

export const useStyle = makeStyles()((theme) => {
  return {
    root: {
      display: 'flex',
      paddingTop: '56px',
      justifyContent: 'space-between',
      '@media (max-width: 1400px)': {
        maxWidth: '990px',
        minWidth: '920px',
        paddingRight: '8px',
      },
    },
    title: {
      fontFamily: 'Ubuntu',
      fontWeight: 700,
      color: theme.palette.typo.primary,
      fontSize: '2rem',
      textAlign: 'left',
    },
    rewardContainer: {
      display: 'flex',
      gap: '1rem',
      marginTop: '32px',
      '@media (max-width: 1400px)': {
        flexWrap: 'wrap',
      },
    },
    reward: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    },
    rewardBorder: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      backdropFilter: 'blur(4px)',
      border: '1px solid rgba(68, 78, 97, 0.4)',
      borderRadius: '6px',
      paddingTop: 12,
      paddingBottom: 21,
      paddingLeft: 8,
      paddingRight: 8,
      width: '112px',
      height: '168px',
      cursor: 'pointer',
    },
    preloginBorder: {
      background:
        'linear-gradient(88.89deg, rgba(3, 215, 189, 0.4) 0.73%, rgba(0, 209, 255, 0.4) 102.93%)',
    },
    rewardButton: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '6px',
      backgroundColor: '#15191E',
      width: '110px',
      height: '40px',
      color: theme.palette.typo.primary,
      marginTop: 16,
    },
    loginButton: {
      width: 110,
      marginTop: 16,
    },
    rewardTitle: {
      textAlign: 'center',
      minHeight: 40,
    },
    rewFirst: {
      minHeight: 22,
    },

    rewardUSDT: {
      opacity: '0.8',
    },

    rewardImg: {
      width: '100%',
    },
    completed: {
      position: 'absolute',
      top: 0,
      backgroundColor: theme.palette.control.default.bg,
      opacity: '0.9',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      width: '100%',
      borderRadius: '6px',
      gap: '18px',
      cursor: 'default',
    },
    lich: {
      objectFit: 'contain',
      width: '260px',
      height: 'auto',
    },
    list: {
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
      padding: '32px 16px 16px 32px',
      listStyleType: 'disc',
    },
    condition: {
      opacity: 0.6,
      marginTop: '16px',
    },
    betaContainer: {
      borderRadius: '6px',
      marginTop: 80,
      width: 232,
      height: 240,
      border: '2px solid rgba(130, 137, 163, 0.32)',
      backdropFilter: 'blur(6px)',
      background:
        'linear-gradient(38.92deg, rgba(10, 109, 224, 0.03) 0%, rgba(10, 109, 224, 0.01) 134.39%)',
      boxShadow:
        '0px 0px 8px 1px rgba(0, 16, 23, 0.56), 0px 0px 24px 4px rgba(0, 16, 23, 0.24)',
    },
    titleContainer: {
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'space-between',
    },
    readyToClaim: {
      width: '80%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },

    claimBtn: {
      marginTop: 16,
      width: '100%',
    },
    error: {
      ...theme.fontStyles.text14,
      display: 'inline-block',
      textAlign: 'center',
      fontWeight: '500',
      height: '15px',
      color: theme.palette.typo.alert,
      position: 'absolute',
      bottom: '-20px',
      left: '0',
      right: '0',
    },
  };
});
