import TagManager from 'react-gtm-module';
import useSWR from 'swr';

import { getDiscordUrl, getSteamUrl } from 'api';

interface IUseLoginServices {
  steamUrlError: Error;
  discordUrlError: Error;
  onSteamClick: () => void;
  onDiscordClick: () => void;
  steamUrl: string;
}

export const useLoginServices: (referral: string) => IUseLoginServices = (
  referral
) => {
  const { data: steamUrl, error: steamUrlError } = useSWR(
    ['api/steam', referral],
    () => getSteamUrl(referral)
  );
  const { data: discordUrl, error: discordUrlError } = useSWR(
    ['api/discord', referral],
    () => getDiscordUrl(referral)
  );

  const onSteamClick: () => void = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'login',
        method: 'steam',
      },
    });
    window.location.href = steamUrl.url;
  };

  const onDiscordClick: () => void = () => {
    window.location.href = discordUrl.url;
    TagManager.dataLayer({
      dataLayer: {
        event: 'login',
        method: 'discord',
      },
    });
  };
  return {
    steamUrlError,
    discordUrlError,
    onSteamClick,
    onDiscordClick,
    steamUrl: steamUrl?.url,
  };
};
