import { FC, useContext, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';

import { getParriesCount, getDota2Heroes, getDota2HeroImage } from 'api';
import { ModalContext } from 'components';
import { PARRY_FORM_DATA_KEY } from 'consts';
import {
  Button,
  Input,
  InputNumber,
  Checkbox,
  Typography,
  Dropdown,
} from 'ui-kit';

import { EParryViewMode, SELECT_FILTER_ROLES } from '../../Parries.model';
import { useStyle } from './Filters.styles';

interface IFiltersProps {
  changeFilter: (name: string, value: string | boolean) => void;
  filters: Record<string, string | boolean | number>;
  mode: EParryViewMode;
}

export const Filters: FC<IFiltersProps> = ({
  changeFilter,
  filters,
  mode,
}): JSX.Element | null => {
  const { t: translation } = useTranslation();
  const { data: user } = useSWR('api/user');
  const isUserExist = !!user?.id;
  const hasSteam = !!user?.steamId;
  const navigate = useNavigate();
  const {
    loginModal: { openLogin },
    steamModal: { openSteam },
  } = useContext(ModalContext);
  const onCreateParryClick = (): void => {
    if (isUserExist && hasSteam) {
      navigate('/parry');
      localStorage.removeItem(PARRY_FORM_DATA_KEY);
    } else if (isUserExist && !hasSteam) {
      openSteam();
    } else {
      openLogin();
    }
  };
  const { data: dota2Heroes } = useSWR(
    () => (mode === EParryViewMode.AllActive ? 'api/dota2-heroes' : null),
    getDota2Heroes
  );

  const { data: parriesCount } = useSWR(
    () => (mode === EParryViewMode.MyActive ? 'api/parries-count' : null),
    getParriesCount
  );
  const { classes } = useStyle();
  const { Text } = Typography;

  const selectHeroes = useMemo(() => {
    return dota2Heroes?.map((hero) => ({
      imgUrl: getDota2HeroImage(hero?.name.replace('npc_dota_hero_', '')),
      label: hero?.localized_name,
      value: hero?.id,
    }));
  }, [dota2Heroes]);

  return (
    <div>
      <div className={classes.container}>
        <Input
          placeholder={translation('parries-list.filters.search')}
          className={classes.filterInput}
          onChange={(e) => {
            changeFilter('title', e.target.value);
          }}
          value={String(filters.title)}
          iconType="search"
        />
        <Button onClick={onCreateParryClick} className={classes.createButton}>
          {translation('parries-list.create-new-parry')}
        </Button>
      </div>
      {[EParryViewMode.MyActive, EParryViewMode.History].includes(mode) && (
        <div className={classes.roleContainer}>
          <div>
            <div className={classes.roleTitle}>
              {translation('parries-list.filters.listing')}
            </div>
            <Dropdown
              options={SELECT_FILTER_ROLES(translation)}
              onChange={(value) => changeFilter('roles', value)}
              defaultValue={SELECT_FILTER_ROLES(translation)[0]}
              className={classes.roleSelect}
            />
          </div>
          {mode === EParryViewMode.MyActive && (
            <div className={classes.parryNumberContainer}>
              <Text className={classes.parryInfo} variant={'b2'}>
                {translation('parries-list.max-as-player')}{' '}
                <span>{parriesCount?.asParticipant ?? 0}/3</span>
              </Text>
              <div className={classes.line} />
              <Text className={classes.parryInfo} variant={'b2'}>
                {translation('parries-list.max-as-owner')}{' '}
                <span>{parriesCount?.asOwner ?? 0}/5</span>
              </Text>
            </div>
          )}
        </div>
      )}

      {mode === EParryViewMode.AllActive && (
        <div className={classes.additionalFilterContainer}>
          <div className={classes.addSubContainer}>
            <div className={classes.settingContainer}>
              <Text className={classes.subTitle} variant="b6" color="menu2">
                {translation('parries-list.filters.entryFee')}
              </Text>
              <div className={classes.addSubContainer}>
                <div className={classes.rangeContainer}>
                  <Text variant="b6" color="menu2">
                    {translation('parries-list.filters.from')}{' '}
                  </Text>
                  <InputNumber
                    className={classes.numberInput}
                    placeholder="0.00"
                    value={String(filters.entryCostFrom)}
                    decimalScale={2}
                    min={0}
                    withError={false}
                    allowNegative={false}
                    isAllowed={({ floatValue }) =>
                      floatValue < 100000 || floatValue === undefined
                    }
                    onChange={(e) => {
                      changeFilter('entryCostFrom', e.target.value);
                    }}
                  />
                </div>
                <div className={classes.rangeContainer}>
                  <Text variant="b6" color="menu2">
                    {translation('parries-list.filters.to')}{' '}
                  </Text>
                  <InputNumber
                    className={classes.numberInput}
                    placeholder="0.00"
                    decimalScale={2}
                    min={0}
                    withError={false}
                    allowNegative={false}
                    isAllowed={({ floatValue }) =>
                      floatValue < 100000 || floatValue === undefined
                    }
                    value={String(filters.entryCostTo)}
                    onChange={(e) => {
                      changeFilter('entryCostTo', e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={classes.rewardContainer}>
            <div className={classes.settingContainer}>
              <Text variant="b6" color="menu2">
                {translation('parries-list.filters.reward')}
              </Text>
              <div className={classes.rewardSubContainer}>
                <div className={classes.rewardInputContainer}>
                  <Text variant="b6" color="menu2">
                    {translation('parries-list.filters.from')}{' '}
                  </Text>
                  <InputNumber
                    withError={false}
                    className={classes.numberInput}
                    placeholder="0.00"
                    value={String(filters.rewardFrom)}
                    decimalScale={2}
                    allowNegative={false}
                    isAllowed={({ floatValue }) =>
                      floatValue < 100000 || floatValue === undefined
                    }
                    min={0}
                    onChange={(e) => {
                      changeFilter('rewardFrom', e.target.value);
                    }}
                  />
                </div>
                <div className={classes.rangeContainer}>
                  <Text variant="b6" color="menu2">
                    {translation('parries-list.filters.to')}{' '}
                  </Text>
                  <InputNumber
                    withError={false}
                    className={classes.numberInput}
                    placeholder="0.00"
                    value={String(filters.rewardTo)}
                    decimalScale={2}
                    isAllowed={({ floatValue }) =>
                      floatValue < 100000 || floatValue === undefined
                    }
                    min={0}
                    allowNegative={false}
                    onChange={(e) => {
                      changeFilter('rewardTo', e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <Dropdown
            title={translation('parries-list.filters.hero')}
            placeholder={translation('parries-list.filters.chooseHero')}
            className={classes.heroSelect}
            cross
            filter
            options={selectHeroes}
            onChange={(value) => changeFilter('hero', value)}
          />
          <div className={classes.checkboxContainer}>
            <Checkbox
              label={translation('parries-list.filters.password')}
              checked={!!filters.password}
              onChange={() => {
                changeFilter('password', !filters.password);
              }}
            />
            <Checkbox
              label={translation('parries-list.filters.free-slots')}
              className={classes.checkBox}
              checked={!!filters.freeSlots}
              onChange={() => {
                changeFilter('freeSlots', !filters.freeSlots);
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};
