import { useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { useClickOutside } from 'hooks';
import { Button, Typography } from 'ui-kit';

import { Modal } from '../Modal';
import { useStyle } from './MakeHistoryModal.styles';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  onClick: () => void;
  isLoading: boolean;
}

export function ModalMakeHistory({
  isOpen,
  onClose,
  onClick,
  isLoading,
}: IProps): JSX.Element {
  const [renderOnce, setRenderOnce] = useState(false);
  const ref = useRef(null);

  useClickOutside(ref, onClose);

  const { t: translation } = useTranslation();
  const { classes } = useStyle();

  let buttonText = translation('modal.history.readyButton');
  let title = translation('modal.history.title');

  if (isLoading) {
    buttonText = translation('modal.history.checking');
    title = translation('modal.history.titleChecking');
  } else if (!isLoading && renderOnce) {
    buttonText = translation('modal.history.again');
    title = translation('modal.history.titleHidden');
  }

  const { Headline, Text } = Typography;

  return (
    <Modal isOpen={isOpen}>
      <div className={classes.modal} ref={ref}>
        <div className={classes.videoContainer} />
        <div className={classes.content}>
          <Headline className={classes.modalTitle}>{title}</Headline>
          <Text variant="b4" className={classes.modalDescr}>
            {translation('modal.history.description')}
          </Text>
          <video
            src="/videos/dota_2_public.mp4"
            className={classes.video}
            autoPlay
            muted
            loop
          />
          <div className={classes.buttonContainer}>
            <Button
              className={classes.modalBtn}
              onClick={() => {
                setRenderOnce(true);
                onClick();
              }}
            >
              {buttonText}
            </Button>
            <Button
              className={classes.backBtn}
              onClick={onClose}
              color="secondary"
            >
              {translation('modal.history.back')}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
