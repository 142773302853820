import { FC, useContext, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { createParry, ICreatedParry, isErrorResponse, updateParry } from 'api';
import { ModalContext } from 'components';
import { PARRY_FORM_DATA_KEY } from 'consts';
import { BackgroundContext } from 'Layout';
import { Button } from 'ui-kit';

import { prepareChallangeData } from '../../ParryView.model';
import { useStyle } from './BottomButtons.styles';

interface IDraftButton {
  parry: ICreatedParry;
}

export const DraftButton: FC<IDraftButton> = ({ parry }) => {
  const [isDrafting, setIsDrafting] = useState(false);
  const navigate = useNavigate();
  const { t: translation } = useTranslation();
  const { classes } = useStyle();
  const { setBackgroundState } = useContext(BackgroundContext);
  const {
    galleryModal: { resetImage },
  } = useContext(ModalContext);
  const onCreateDraftClick = async (): Promise<void> => {
    try {
      setIsDrafting(true);
      const preparedData = prepareChallangeData(parry);
      const parryResponse = parry?.isFetched
        ? await updateParry({ ...preparedData, id: parry?.id })
        : await createParry(preparedData);
      if (!isErrorResponse(parryResponse)) {
        navigate('/parries/my/draft');
        localStorage.removeItem(PARRY_FORM_DATA_KEY);
        setBackgroundState(0);
        resetImage();
      }
    } catch (err) {
      console.error('Failed to create draft', err);
    } finally {
      setIsDrafting(false);
    }
  };
  return (
    <Button
      className={classes.draftBtn}
      onClick={onCreateDraftClick}
      color="secondary"
      buttonSize="l"
      disabled={isDrafting}
    >
      {isDrafting
        ? translation('parryView.creating')
        : translation('parryView.draft')}
    </Button>
  );
};
