import { FC } from 'react';

import { ProgressSpinner } from 'primereact/progressspinner';
import { useTranslation } from 'react-i18next';

import type { IStatisticResponse } from 'api';
import { Title, Typography } from 'ui-kit';

import { useStyle } from './Statistic.styles';
interface IStatisticCardProps {
  statistic: IStatisticResponse;
}

export const StatisticCard: FC<IStatisticCardProps> = ({ statistic }) => {
  const { classes } = useStyle();
  const { t: translation } = useTranslation();

  const { Text } = Typography;
  return (
    <div>
      {!statistic ? (
        <ProgressSpinner className={classes.spinner} />
      ) : (
        <>
          <Title className={classes.title}>
            {translation('predictions.gameStat.title')}
          </Title>
          {statistic?.heroes?.length > 0 ? (
            statistic?.heroes?.map((hero) => (
              <div className={classes.heroName} key={hero.name}>
                <img
                  src={hero.url}
                  alt="hero-dota-2"
                  className={classes.heroIcon}
                />
                <div className={classes.heroDescr}>
                  <Text variant="b4">{hero.name}</Text>
                  <div className={classes.flex}>
                    <Text variant="b4">
                      {hero.totalGames}{' '}
                      <Text variant="b4" className={classes.heroGames}>
                        {translation('predictions.gameStat.games')}
                      </Text>
                    </Text>
                    <Text variant="b4" className={classes.heroWins}>
                      {hero.wins}{' '}
                      <Text variant="b4" className={classes.heroGames}>
                        {translation('predictions.gameStat.wins')}
                      </Text>
                    </Text>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className={classes.noStatisticBlock}>
              <Text variant="b4" className={classes.noStatisticTitle}>
                {translation('predictions.noStatistic.title')}
              </Text>
              <Text variant="b4" className={classes.noStatisticDescr}>
                {translation('predictions.noStatistic.description')}
              </Text>
            </div>
          )}
        </>
      )}
    </div>
  );
};
