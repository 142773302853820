import { makeStyles } from 'theme';

export const useStyle = makeStyles()(() => ({
  cardContainer: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    marginBottom: 100,
    marginTop: 131,
  },
  hero: {
    position: 'absolute',
    left: '60%',
    top: '-20%',
    width: 230,
  },
  itemDot: {
    display: 'inline-block',
    content: "''",
    minWidth: 10,
    height: 10,
    width: 10,
    minHeight: 10,
    borderRadius: 10,
    backgroundColor: '#ECF6FFCC',
    marginRight: 20,
  },
  item: {
    marginBottom: 21,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  card: {
    background:
      'linear-gradient(38.92deg, rgba(22, 28, 40, 0.4) 0%, rgba(22, 28, 40, 0.1) 134.39%)',
    backdropFilter: 'blur(8px)',
    borderRadius: 6,
    padding: 40,
    width: 'calc((100% - 24px)/2)',
    border: '2px solid rgba(43, 44, 46, 0.64)',
    '&:first-of-type': {
      marginRight: 24,
    },
  },
  title: {
    display: 'inline-block',
    marginBottom: 24,
  },
  button: {
    marginTop: 40,
    width: 400,
  },
  img: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    height: '100%',
  },
  stepDescription: {
    display: 'inline-block',
  },
}));
