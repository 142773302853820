import { FC, useState } from 'react';

import { DateTime } from 'luxon';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import { getMatches, IMatch, isErrorResponse } from 'api';
import { prevState } from 'middleware/prevState';
import { Paginator, Typography } from 'ui-kit';

import { useCheckHistory } from '../../../../../hooks';
import { stats } from '../../Prediction.model';
import { useStyle } from './PredictTable.styles';
import { TableEarned } from './TableEarned';
import { TableResult } from './TableResult';

const ITEM_PER_PAGE = 5;

export const Table: FC = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const { t: translation } = useTranslation();
  const { classes } = useStyle();
  const { data: user } = useSWR('api/user');
  const { isHistoryFailed } = useCheckHistory();
  const isPrelogin = !user || !user?.steamId || isHistoryFailed;

  const { data: tableMatches } = useSWR(
    () =>
      `api/matches?offset=${
        (currentPage - 1) * ITEM_PER_PAGE
      }&limit=${ITEM_PER_PAGE}`,
    () =>
      getMatches({
        offset: (currentPage - 1) * ITEM_PER_PAGE,
        limit: ITEM_PER_PAGE,
        mode: 'ranked',
      }),
    { use: [prevState] }
  );

  const { Text, Headline } = Typography;

  if (isPrelogin) {
    return (
      <div className={classes.preloginContainer}>
        <Headline variant="h2" className={classes.preloginTitle}>
          {translation('predictions.noStatistic.title')}
        </Headline>
        <Text className={classes.preloginDescription}>
          {translation('predictions.noStatistic.description')}
        </Text>
      </div>
    );
  }

  if (!tableMatches || isErrorResponse(tableMatches)) {
    return <ProgressSpinner className={classes.spinner} />;
  }

  return (
    <>
      <DataTable
        value={tableMatches?.matches}
        responsiveLayout="scroll"
        className={classes.table}
        emptyMessage={
          <Text variant="b3">{translation('predictions.table.noMatches')}</Text>
        }
      >
        <Column
          header={translation('predictions.table.date')}
          body={(match: IMatch) => (
            <Text variant="b3">
              {DateTime.fromSeconds(match.date / 1000).toFormat('yyyy-MM-dd')}
            </Text>
          )}
        />
        <Column
          header={translation('predictions.table.hero')}
          body={(match) => (
            <img
              src={match.img}
              alt="dota 2 hero"
              className={classes.heroIcon}
            />
          )}
        />
        <Column
          header={translation('predictions.table.kda')}
          body={(match) => <Text variant="b3">{stats(match)}</Text>}
        />
        <Column
          header={translation('predictions.table.earned')}
          body={(match) => <TableEarned match={match} />}
        />
        <Column
          header={translation('predictions.table.result')}
          body={(match) => <TableResult match={match} />}
        />
      </DataTable>
      {tableMatches?.total > ITEM_PER_PAGE && (
        <Paginator
          itemsPerPage={ITEM_PER_PAGE}
          itemsTotal={tableMatches.total}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          className={classes.paginator}
        />
      )}
    </>
  );
};
