import { FC, useContext, useEffect, useState } from 'react';

import clsx from 'clsx';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useNavigate, useParams, generatePath } from 'react-router-dom';

import { ParryCard, ModalContext } from 'components';
import { useDebouncedValue } from 'hooks';
import { Paginator } from 'ui-kit';

import { Filters, ParriesNotFound } from '../components';
import {
  EParryViewMode,
  LIMIT_OPTIONS,
  EParryRole,
  isParryViewMode,
} from '../Parries.model';
import { ParryNavigation } from './components';
import { updateDraftParries, useGetParriesList } from './Workshop.model';
import { useStyle } from './Workshop.styles';

export const Workshop: FC = () => {
  const navigate = useNavigate();
  const { mode } = useParams();
  const [itemPerPage, setItemPerPage] = useState(LIMIT_OPTIONS[0]);
  const [currentPage, setCurrentPage] = useState(1);
  const [activeMode, setActiveMode] = useState<EParryViewMode>(
    isParryViewMode(mode) ? mode : EParryViewMode.MyActive
  );
  const [filters, setFilters] = useState({
    title: '',
    roles: EParryRole.Both,
  });

  const debouncedFilters = useDebouncedValue(filters, 1000);

  const { parries, isLoading } = useGetParriesList({
    activeMode,
    itemPerPage,
    currentPage,
    debouncedFilters,
  });

  const onChangeFilter = (key: string, value: string | boolean): void => {
    setFilters((prevState) => ({ ...prevState, [key]: value }));
  };

  const onChangeCategory = (mode: EParryViewMode): void => {
    setFilters({ title: '', roles: EParryRole.Both });
    setActiveMode(mode);
    navigate(generatePath('/parries/my/:mode', { mode }));
  };

  const {
    deleteModal: { cancelUpdate, needUpdateAfterDelete },
  } = useContext(ModalContext);

  useEffect(() => {
    if (needUpdateAfterDelete) {
      updateDraftParries(debouncedFilters?.title, currentPage, itemPerPage);
    }
    cancelUpdate();
  }, [needUpdateAfterDelete]);

  useEffect(() => {
    setCurrentPage(1);
  }, [activeMode]);

  const { classes } = useStyle();
  return (
    <>
      <div className={classes.container}>
        <ParryNavigation
          changeCategory={onChangeCategory}
          activeMode={activeMode}
        />
        <Filters
          changeFilter={onChangeFilter}
          filters={filters}
          mode={activeMode}
        />

        {!parries || isLoading ? (
          <ProgressSpinner className={classes.spinner} />
        ) : (
          <>
            <div
              className={clsx(
                classes.listContainer,
                activeMode !== EParryViewMode.History &&
                  classes.additionalMargin
              )}
            >
              {parries.total > 0 ? (
                parries.data.map((item) => {
                  return (
                    <ParryCard
                      parry={item as any}
                      isShowDuration={activeMode === EParryViewMode.Draft}
                      key={item.id}
                      isWorkshop={true}
                    />
                  );
                })
              ) : (
                <ParriesNotFound title={filters?.title} />
              )}
            </div>
            {[EParryViewMode.History, EParryViewMode.Draft].includes(
              activeMode
            ) && (
              <Paginator
                className={classes.paginator}
                currentPage={currentPage}
                itemsPerPage={itemPerPage}
                itemsTotal={parries.total}
                setCurrentPage={setCurrentPage}
                setItemsPerPage={setItemPerPage}
                limitOptions={LIMIT_OPTIONS}
                isMarkedActive
                showSizeChanger
                hideArrow
              />
            )}
          </>
        )}
      </div>
    </>
  );
};
