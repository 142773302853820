import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { ICreatedParry } from 'api';
import { ECondition, ETerm, PARRY_OPTIONS_TERM } from 'consts';
import {
  AssistIcon,
  DeathIcon,
  DenyIcon,
  KillIcon,
  LashHitIcon,
  TowerIcon,
} from 'icons';
import { Card, Typography } from 'ui-kit';

import { useStyle } from './AdditionalTerms.styles';

interface IAdditionalTermsProps {
  parry: ICreatedParry;
}

enum EAdditionalTerm {
  Kills,
  Deaths,
  Assists,
  LastHit,
  Denies,
  Towers,
}

const iconMap: Record<EAdditionalTerm, FC<{ className: string }>> = {
  [EAdditionalTerm.Kills]: KillIcon,
  [EAdditionalTerm.Deaths]: DeathIcon,
  [EAdditionalTerm.Assists]: AssistIcon,
  [EAdditionalTerm.LastHit]: LashHitIcon,
  [EAdditionalTerm.Denies]: DenyIcon,
  [EAdditionalTerm.Towers]: TowerIcon,
};

const conditionMap: Record<ECondition, string> = {
  [ECondition.LessOrEqual]: '≤',
  [ECondition.Equal]: '',
  [ECondition.MoreOrEqual]: '≥',
};

export const AdditionalTerms: FC<IAdditionalTermsProps> = ({ parry }) => {
  const { t: translation } = useTranslation();
  const allOptions = PARRY_OPTIONS_TERM(translation);
  const { options } = parry;
  const additionalOptions = options.slice(1);
  const { classes } = useStyle();
  const { Text, Headline } = Typography;
  const terms: {
    condition: ECondition;
    quantity: number;
    label: string;
    value: ETerm;
  }[] = allOptions
    .filter(
      (option) =>
        additionalOptions.findIndex(
          (parryOption) => parryOption.term === option.value
        ) !== -1
    )
    .map((term) => {
      const index = additionalOptions.findIndex(
        (option) => option.term === term.value
      );
      return {
        condition: options[index + 1].condition,
        quantity: options[index + 1].quantity,
        ...term,
      };
    });
  return (
    <div className={classes.termContainer}>
      {terms.map((term) => {
        const Icon = iconMap[term.value];
        const symbol = conditionMap[term.condition];
        const condition = symbol ? `${symbol} ${term.quantity}` : term.quantity;
        return (
          <Card
            variant="p12x16"
            className={classes.card}
            color="bgSecondary"
            key={term.value}
          >
            <Icon className={classes.icon} />
            <div className={classes.content}>
              <Headline variant="h2">{condition}</Headline>
              <Text variant="b5" color="system">
                {term.label}
              </Text>
            </div>
          </Card>
        );
      })}
    </div>
  );
};
