import { FC } from 'react';

import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { ICreatedParry } from 'api';
import { removeZeroesAfterComma } from 'helpers';
import {
  EntryCostIcon,
  EstablishedRewardIcon,
  RecycleIcon,
  SmallLockIcon,
  TotalEntryFeeeIcon,
  TotalProfitIcon,
  TrophyIcon,
} from 'icons';
import { Button, Dividers, Typography } from 'ui-kit';

import { calcProfit } from '../../ParryView.model';
import { useStyle } from './ParryHeader.styles';

interface IOwnerHeader {
  parry: ICreatedParry;
  onStopRegistrationClick: () => void;
  onStartParryClick: () => void;
  onStopCycleClick: () => void;
}

export const OwnerHeader: FC<IOwnerHeader> = ({
  parry,
  onStopRegistrationClick,
  onStartParryClick,
  onStopCycleClick,
}) => {
  const {
    entryCost,
    reward,
    password,
    iterations,
    cycleRunning,
    step,
    status,
    activeParticipants,
  } = parry;

  const { classes } = useStyle({ isSmall: false });
  const [searchParams] = useSearchParams();
  const isPreview = searchParams.get('mode') === 'preview';
  const isRecycle = iterations !== 1 && cycleRunning;
  const { Text } = Typography;
  const { t: translation } = useTranslation();
  const { VerticalDivider } = Dividers;
  const { totalProfit, establishedReward, totalEntryFees } = calcProfit(parry);
  const isShowProfit = activeParticipants > 0 && false;
  return (
    <>
      <div className={classes.divider} />
      <div className={classes.controlContainer}>
        <div className={classes.controlDataContainer}>
          <div className={classes.dataContainer}>
            <Text variant="b6" color="system">
              {translation('parryView.entryFee')}
            </Text>
            <div className={classes.iconContainer}>
              <Text color="system">
                <EntryCostIcon
                  className={classes.controlIcon}
                  height={20}
                  width={20}
                />
              </Text>
              <Text variant="b3">
                {removeZeroesAfterComma(String(entryCost))}
                {' GC'}
              </Text>
            </div>
          </div>
          {password && (
            <>
              <VerticalDivider />
              <div className={classes.dataContainer}>
                <Text variant="b6" color="system">
                  {translation('parryView.password')}
                </Text>
                <div className={classes.iconContainer}>
                  <Text color="system">
                    <SmallLockIcon
                      className={classes.controlIcon}
                      height={20}
                      width={20}
                    />
                  </Text>
                  <Text variant="b3">{password}</Text>
                </div>
              </div>
            </>
          )}
          {isShowProfit && (
            <>
              <VerticalDivider />
              <div className={classes.dataContainer}>
                <Text variant="b6" color="system">
                  {translation('parryView.establishedReward')}
                </Text>
                <div className={classes.iconContainer}>
                  <Text color="system">
                    <EstablishedRewardIcon
                      className={classes.controlIcon}
                      height={20}
                      width={20}
                    />
                  </Text>
                  <Text variant="b3">{establishedReward}</Text>
                </div>
              </div>
              <VerticalDivider />
              <div className={classes.dataContainer}>
                <Text variant="b6" color="system">
                  {translation('parryView.totalEntryFee')}
                </Text>
                <div className={classes.iconContainer}>
                  <Text color="system">
                    <TotalEntryFeeeIcon
                      className={classes.controlIcon}
                      height={20}
                      width={20}
                    />
                  </Text>
                  <Text variant="b3">{totalEntryFees}</Text>
                </div>
              </div>
              <VerticalDivider />
              <div className={classes.dataContainer}>
                <Text variant="b6" color="system">
                  {translation('parryView.totalProfit')}
                </Text>
                <div className={classes.iconContainer}>
                  <Text color="system">
                    <TotalProfitIcon
                      className={classes.controlIcon}
                      height={20}
                      width={20}
                    />
                  </Text>
                  <Text variant="b3">
                    {removeZeroesAfterComma(totalProfit)}
                  </Text>
                </div>
              </div>
            </>
          )}
        </div>
        <span>
          {!isPreview && status == 'active' && (
            <Button onClick={onStartParryClick}>
              {translation('parryView.start')}
            </Button>
          )}
          {!isPreview && status == 'active' && (
            <Button
              onClick={onStopRegistrationClick}
              style={{
                marginLeft: '25px',
              }}
            >
              {activeParticipants === 0
                ? translation('parryView.cancelBtn')
                : translation('parryView.stopReg')}
            </Button>
          )}
        </span>
      </div>
    </>
  );
};
