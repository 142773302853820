import { ButtonHTMLAttributes, FC } from 'react';

import clsx from 'clsx';

import { Typography } from '../Typography';
import { useStyle } from './Button.styles';

export type ButtonSizeType = 'sm' | 'md' | 'l' | 'xl';
export type ButtonType = 'button' | 'icon';
export type ColorType =
  | 'primary'
  | 'secondary'
  | 'link'
  | 'transparent'
  | 'brand';

interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  buttonSize?: ButtonSizeType;
  contentType?: ButtonType;
  color?: ColorType;
}

export const Button: FC<IButtonProps> = ({
  buttonSize = 'md',
  contentType = 'button',
  color = 'primary',
  children,
  className,
  type = 'button',
  disabled = false,
  ...rest
}) => {
  const { Text } = Typography;
  const { classes } = useStyle({
    size: buttonSize,
    color,
    type: contentType,
    disabled,
  });
  return (
    <button
      disabled={disabled}
      className={clsx(className, classes.button, disabled && classes.disabled)}
      type={type}
      {...rest}
    >
      <Text
        variant={buttonSize === 'sm' ? 'b3' : 'b1'}
        color={color === 'primary' || color === 'brand' ? 'primary' : 'link'}
      >
        {children}
      </Text>
    </button>
  );
};
