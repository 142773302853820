import { TFunction } from 'react-i18next';

export enum EChallengeViewMode {
  AllActive = 'allActive',
  Draft = 'draft',
  MyActive = 'myActive',
  History = 'history',
}

export function isChallengeViewMode(path: string): path is EChallengeViewMode {
  return Object.values(EChallengeViewMode).includes(path as EChallengeViewMode);
}

export const LIMIT_OPTIONS = [15, 30, 50];

export enum EChallengeRole {
  Both,
  Owner,
  Player,
}

interface ISelectOption {
  value: EChallengeRole;
  label: string;
}

export const SELECT_FILTER_ROLES = (
  translation: TFunction<'translation', undefined>
): ISelectOption[] => [
  {
    value: EChallengeRole.Both,
    label: translation('challenges-list.roles.option-1'),
  },
  {
    value: EChallengeRole.Owner,
    label: translation('challenges-list.roles.option-2'),
  },
  {
    value: EChallengeRole.Player,
    label: translation('challenges-list.roles.option-3'),
  },
];

export const CATEGORIES_CHALLENGE = [
  {
    title: 'challenges-list.category.active',
    mode: EChallengeViewMode.MyActive,
  },
  { title: 'challenges-list.category.draft', mode: EChallengeViewMode.Draft },
  {
    title: 'challenges-list.category.history',
    mode: EChallengeViewMode.History,
  },
];
