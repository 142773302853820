import { FC } from 'react';

import { RoadHowTo } from 'components';

import { challengeConfig, challengeOwnerConfig } from './About.config';
import { useStyle } from './About.style';
import { Footer } from './Footer';
import { Header } from './Header';

export const About: FC = () => {
  const { classes } = useStyle();
  return (
    <>
      <Header />
      <RoadHowTo className={classes.firstRoad} text={challengeConfig} />
      <RoadHowTo className={classes.secondRoad} text={challengeOwnerConfig} />
      <Footer />
    </>
  );
};
