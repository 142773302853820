import { FC } from 'react';

import { ICommonIconProps } from './types';

export const TotalEntryFeeeIcon: FC<ICommonIconProps> = ({
  color = 'currentColor',
  width = 24,
  height = 24,
  viewBox = '0 0 24 24',
  className = '',
  style,
}) => (
  <svg
    fill="none"
    width={width}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M10 2.5C11.9283 2.5 13.7133 2.84 15.0483 3.42417C15.7142 3.71583 16.3058 4.08417 16.7417 4.53583C17.1458 4.9525 17.4458 5.47583 17.4933 6.0825L17.5 6.25V6.87333C16.9467 7.30048 16.3319 7.64155 15.6767 7.885C14.2142 8.42667 12.2067 8.7575 10 8.7575C7.79417 8.7575 5.78667 8.42667 4.32333 7.885C3.75987 7.67599 3.22617 7.39416 2.73583 7.04667L2.5 6.87333V6.25C2.5 5.57167 2.8175 4.99167 3.25833 4.53583C3.69417 4.08417 4.28583 3.71583 4.95167 3.42417C6.28667 2.84083 8.07167 2.5 10 2.5Z"
      fill="#8188A2"
    />
    <path
      d="M15.8195 9.59961H17.5V8.86917C17.1015 9.09587 16.6851 9.28958 16.255 9.44833C16.1134 9.50071 15.9682 9.55115 15.8195 9.59961Z"
      fill="#8188A2"
    />
    <path
      d="M10 18.3333C10.3383 18.3333 10.6722 18.3228 11.0001 18.3024C11.0044 17.8401 11.1688 17.3839 11.4815 17.0192L13.2463 14.9602L13.1041 14.7942C12.1228 14.9335 11.0759 15.0067 10 15.0067C7.64917 15.0067 5.43667 14.6575 3.745 14.0317C3.30167 13.8675 2.8875 13.6725 2.5 13.4525V14.5833L2.50667 14.7508C2.55417 15.3575 2.85417 15.8808 3.25833 16.2975C3.69417 16.7492 4.28583 17.1175 4.95167 17.4092C6.28667 17.9933 8.07167 18.3333 10 18.3333Z"
      fill="#8188A2"
    />
    <path
      d="M11.4143 10.3806C11.3243 10.4978 11.2464 10.626 11.183 10.7639C10.8566 11.4735 10.9732 12.3082 11.4815 12.9012L11.7942 13.2661C11.2167 13.315 10.6161 13.3408 10 13.3408C7.79417 13.3408 5.78667 13.01 4.32333 12.4683C3.66807 12.2249 3.05336 11.8838 2.5 11.4567V8.86917C2.8875 9.08917 3.30167 9.28417 3.745 9.44833C5.43667 10.0742 7.64917 10.4233 10 10.4233C10.4782 10.4233 10.9508 10.4089 11.4143 10.3806Z"
      fill="#8188A2"
    />
    <path
      d="M13.0013 10.8798C12.7201 10.8798 12.4646 11.0435 12.3471 11.299C12.2295 11.5545 12.2715 11.8551 12.4545 12.0686L14.9333 14.9605L12.4545 17.8524C12.2715 18.066 12.2295 18.3665 12.3471 18.622C12.4646 18.8775 12.7201 19.0412 13.0013 19.0412H18.2822C18.6005 19.0412 18.9058 18.9148 19.1309 18.6897C19.356 18.4646 19.4824 18.1593 19.4824 17.841V16.8808C19.4824 16.4831 19.16 16.1607 18.7623 16.1607C18.3646 16.1607 18.0422 16.4831 18.0422 16.8808V17.601H14.567L16.4286 15.4292C16.6597 15.1595 16.6597 14.7615 16.4286 14.4919L14.567 12.3201H18.0422V13.0402C18.0422 13.4379 18.3646 13.7603 18.7623 13.7603C19.16 13.7603 19.4824 13.4379 19.4824 13.0402V12.08C19.4824 11.7617 19.356 11.4564 19.1309 11.2313C18.9058 11.0063 18.6005 10.8798 18.2822 10.8798H13.0013Z"
      fill={color}
    />
  </svg>
);
