import { FC } from 'react';

import { ICommonIconProps } from './types';

export const FlagParticipantIcon: FC<ICommonIconProps> = ({
  color = 'currentColor',
  width = 56,
  height = 56,
  viewBox = '0 0 56 56',
  className = '',
  style,
}) => (
  <svg
    fill="none"
    width={width}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path d="M0 0H56V56L28 45L0 56V0Z" fill="url(#paint0_linear_5629_96449)" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34.001 15.3987C33.5672 15.8428 33.2435 16.085 32.7527 16.3324C32.0812 16.6709 31.4234 16.8329 30.4528 16.8987C29.7651 16.9454 29.071 16.9111 27.4449 16.7503C26.1751 16.6248 24.8884 16.5828 24.2749 16.6468C23.0231 16.7775 22.0382 17.1986 21.2538 17.9385C21.048 18.1326 21.0151 18.1792 21.0447 18.2346C21.064 18.2706 21.6334 20.2826 22.3099 22.7054C22.9865 25.1284 23.548 27.132 23.5578 27.1581C23.5699 27.1904 23.6513 27.1388 23.8127 26.9967C24.9275 26.015 26.1836 25.6362 28.1112 25.7004C28.4883 25.713 29.0342 25.7465 29.3243 25.7748C29.6144 25.8031 30.1999 25.861 30.6253 25.9034C31.7246 26.013 33.1892 26.0142 33.7724 25.9059C34.3805 25.793 34.7648 25.6711 35.2331 25.4425C35.7392 25.1956 36.148 24.901 36.5428 24.4989C36.8369 24.1993 36.9449 24.0368 36.983 23.8364C37.0278 23.6008 36.9932 23.5649 36.4097 23.2413C35.4935 22.7332 34.6185 22.1231 33.8651 21.4671C33.6985 21.3221 33.5479 21.1621 33.5302 21.1116C33.5057 21.0413 33.542 20.843 33.6844 20.2676C34.031 18.8671 34.3109 17.4165 34.515 15.9619C34.6025 15.3387 34.6178 15.1512 34.5869 15.0833C34.5137 14.9227 34.413 14.977 34.001 15.3987ZM19.4965 18.1025C19.2396 18.1843 19.0039 18.5235 19.0039 18.8115C19.0039 18.9508 22.7911 32.495 22.8829 32.6841C22.9742 32.872 23.1734 32.9991 23.3779 33C23.4532 33.0003 23.6227 32.9684 23.7545 32.9292C24.1338 32.8162 24.3135 32.6223 24.3135 32.3263C24.3135 32.2351 23.5677 29.507 22.416 25.3854C21.3723 21.6505 20.5036 18.5558 20.4855 18.5081C20.4306 18.3638 20.2634 18.1906 20.113 18.1223C19.9548 18.0504 19.687 18.0418 19.4965 18.1025Z"
      fill="#171717"
      fillOpacity="0.8"
    />
    <defs>
      <linearGradient
        id="paint0_linear_5629_96449"
        x1="4.59611e-08"
        y1="34.5883"
        x2="99.7069"
        y2="32.8925"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00DAA5" />
        <stop offset="1" stopColor="#08C1D1" />
      </linearGradient>
    </defs>
  </svg>
);
