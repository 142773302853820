import { makeStyles } from 'theme';

export const useStyle = makeStyles()((theme) => ({
  ...theme.layouts,
  infoIcon: {
    color: theme.palette.typo.primary,
    cursor: 'pointer',
    width: '14px',
    height: '14px',
  },
  title: {
    display: 'inline-block',
    marginRight: 10,
  },
}));
