import { FC, useMemo } from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import { getDota2Heroes, getDota2HeroImage } from 'api';
import { Dropdown } from 'ui-kit';

import { text } from '../../../CreateChallenge.model';
import { useStyle } from './Hero.styles';

export const Hero: FC = () => {
  const { data: dota2Heroes } = useSWR('api/dota2-heroes', getDota2Heroes);
  const { control } = useFormContext();

  const { t: translation } = useTranslation();
  const { classes } = useStyle();

  const anyHero = { value: 0, label: 'Anyone' };

  const selectHeroes = useMemo(() => {
    return dota2Heroes?.map((hero) => ({
      imgUrl: getDota2HeroImage(hero?.name.replace('npc_dota_hero_', '')),
      label: hero?.localized_name,
      value: hero?.id,
    }));
  }, [dota2Heroes]);

  return (
    <Controller
      name="hero"
      control={control}
      render={({ field: { onChange, value } }) => {
        const heroOption = selectHeroes?.filter(
          (hero) => hero.value === value
        )[0];
        return (
          <Dropdown
            title={translation('challenge.form.gameOptions.hero')}
            options={selectHeroes}
            filter
            cross={!!heroOption?.value}
            onChange={(value) => onChange(value)}
            tooltip={translation(text.hero)}
            defaultValue={anyHero}
            value={heroOption}
            className={classes.heroWidth}
          />
        );
      }}
    />
  );
};
