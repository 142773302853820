import { FC } from 'react';

import { ICreatedChallenge } from 'api';

import { useStyle } from './BottomButtons.styles';
import { DraftButton } from './DraftButton';
import { PublishButton } from './PublishButton';

interface IBottomActionButtonsProps {
  challenge: ICreatedChallenge;
}

export const BottomActionButtons: FC<IBottomActionButtonsProps> = ({
  challenge,
}) => {
  const { classes } = useStyle();
  return (
    <div className={classes.buttonsContainer}>
      <div className={classes.buttons}>
        <DraftButton challenge={challenge} />
        <PublishButton challenge={challenge} />
      </div>
    </div>
  );
};
