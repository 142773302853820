import { FC } from 'react';

import { ICommonIconProps } from './types';

export const FlagOwnerIcon: FC<ICommonIconProps> = ({
  color = 'currentColor',
  width = 56,
  height = 56,
  viewBox = '0 0 56 56',
  className = '',
  style,
}) => (
  <svg
    fill="none"
    width={width}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path d="M0 0H56V56L28 45L0 56V0Z" fill="url(#paint0_linear_5629_96448)" />
    <path
      d="M20.0486 17.8019L23.8536 21.1499L27.2436 16.9759C27.3386 16.8592 27.4586 16.7654 27.5948 16.7015C27.731 16.6377 27.8798 16.6054 28.0302 16.607C28.1807 16.6086 28.3288 16.6442 28.4635 16.711C28.5983 16.7778 28.7163 16.8742 28.8086 16.9929L32.0436 21.1489L35.9716 17.7529C36.1259 17.6198 36.3171 17.537 36.5197 17.5156C36.7223 17.4941 36.9266 17.5351 37.1053 17.633C37.284 17.7309 37.4285 17.881 37.5196 18.0632C37.6106 18.2455 37.6438 18.4512 37.6146 18.6529L36.1146 28.9999H19.9216L18.3986 18.6999C18.3684 18.4971 18.4012 18.29 18.4926 18.1064C18.584 17.9229 18.7296 17.772 18.9097 17.674C19.0897 17.576 19.2956 17.5357 19.4993 17.5586C19.703 17.5814 19.8948 17.6664 20.0486 17.8019ZM19.9996 29.9999H35.9996V30.9999C35.9996 31.2651 35.8943 31.5194 35.7068 31.707C35.5192 31.8945 35.2649 31.9999 34.9996 31.9999H20.9996C20.7344 31.9999 20.4801 31.8945 20.2925 31.707C20.105 31.5194 19.9996 31.2651 19.9996 30.9999V29.9999Z"
      fill="#171717"
      fillOpacity="0.8"
    />
    <defs>
      <linearGradient
        id="paint0_linear_5629_96448"
        x1="4.17562e-07"
        y1="2.8"
        x2="13.3313"
        y2="66.1489"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#DEBB6C" />
        <stop offset="1" stopColor="#E3CB8E" />
      </linearGradient>
    </defs>
  </svg>
);
