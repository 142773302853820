import { CSSObject } from 'tss-react';

import { makeStyles } from 'theme';

import { BuilderItemSizeType } from './BuilderItemsView';

export const useStyle = makeStyles<{ size: BuilderItemSizeType }>()(
  (theme, { size }) => {
    const builderSizeMap: Record<
      BuilderItemSizeType,
      Record<string, CSSObject>
    > = {
      small: {
        lockIcon: {
          width: 12,
          height: 12,
        },
        item: {
          width: 30,
          height: 20,
          marginRight: 4,
          ':last-of-type': {
            marginRight: 0,
          },
        },
      },
      medium: {
        lockIcon: {
          width: 24,
          height: 24,
        },
        item: {
          width: 60,
          height: 40,
          marginRight: 8,
          ':last-of-type': {
            marginRight: 0,
          },
        },
      },
    };
    return {
      itemContainer: {
        ...theme.layouts.flexAICenter,
        justifyContent: 'space-between',
      },
      img: {
        width: '100%',
        height: '100%',
        borderRadius: 4,
      },
      lockIcon: {
        ...builderSizeMap[size].lockIcon,
      },
      item: {
        color: theme.palette.bg.border,
        border: `1px solid ${theme.palette.bg.border}`,
        borderRadius: 4,
        width: 30,
        height: 20,
        ...builderSizeMap[size].item,
        ...theme.fontStyles.text12,
        ...theme.layouts.flexCenter,
        ':hover': {
          color: theme.palette.typo.system,
          border: `1px solid ${theme.palette.typo.system}`,
          transitionDuration: '300ms',
        },
      },
    };
  }
);
