import type { FC } from 'react';

import type { ICommonIconProps } from './types';

export const LogoutIcon: FC<ICommonIconProps> = ({
  width = 14,
  height = 14,
  viewBox = '0 0 14 14',
  className = '',
  style,
}) => (
  <svg
    fill="none"
    width={width}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.41667 11.6667C6.41667 11.512 6.35521 11.3636 6.24581 11.2542C6.13642 11.1448 5.98804 11.0833 5.83333 11.0833H2.91667V2.91667H5.83333C5.98804 2.91667 6.13642 2.85521 6.24581 2.74581C6.35521 2.63642 6.41667 2.48804 6.41667 2.33333C6.41667 2.17862 6.35521 2.03025 6.24581 1.92085C6.13642 1.81146 5.98804 1.75 5.83333 1.75H2.91667C2.60725 1.75 2.3105 1.87292 2.09171 2.09171C1.87292 2.3105 1.75 2.60725 1.75 2.91667V11.0833C1.75 11.3928 1.87292 11.6895 2.09171 11.9083C2.3105 12.1271 2.60725 12.25 2.91667 12.25H5.83333C5.98804 12.25 6.13642 12.1885 6.24581 12.0791C6.35521 11.9697 6.41667 11.8214 6.41667 11.6667Z"
      fill={'currentcolor'}
    />
    <path
      d="M12.6665 7.40834C12.7732 7.29988 12.8331 7.1539 12.8333 7.00176V6.99826C12.833 6.84413 12.7715 6.69642 12.6624 6.58759L10.3291 4.25426C10.2753 4.19854 10.2109 4.1541 10.1397 4.12353C10.0686 4.09296 9.99201 4.07687 9.91456 4.07619C9.8371 4.07552 9.76029 4.09028 9.6886 4.11961C9.61691 4.14894 9.55178 4.19226 9.49701 4.24703C9.44224 4.3018 9.39892 4.36693 9.36959 4.43862C9.34026 4.51031 9.3255 4.58712 9.32618 4.66457C9.32685 4.74203 9.34294 4.81857 9.37351 4.88974C9.40409 4.96091 9.44852 5.02528 9.50424 5.07909L10.8418 6.41667H5.24999C5.09528 6.41667 4.94691 6.47813 4.83751 6.58753C4.72811 6.69692 4.66666 6.8453 4.66666 7.00001C4.66666 7.15472 4.72811 7.30309 4.83751 7.41249C4.94691 7.52188 5.09528 7.58334 5.24999 7.58334H10.8418L9.50424 8.92092C9.39798 9.03094 9.33918 9.17829 9.34051 9.33124C9.34184 9.48419 9.40319 9.6305 9.51135 9.73865C9.6195 9.84681 9.76581 9.90815 9.91876 9.90948C10.0717 9.91081 10.2191 9.85202 10.3291 9.74576L12.6624 7.41242L12.6665 7.40834Z"
      fill={'currentcolor'}
    />
  </svg>
);
