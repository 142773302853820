import { CSSObject } from 'tss-react';

import { makeStyles } from 'theme';

import { TooltipColorType, TooltipPositionType } from './Tooltip';

export const useStyle = makeStyles<{
  position: TooltipPositionType;
  color: TooltipColorType;
}>()((theme, { color, position }) => {
  const positionTextMap: Record<TooltipPositionType, CSSObject> = {
    right: {
      left: '100%',
      top: '50%',
      marginLeft: '7px',
      transform: 'translateY(-50%)',
    },
    top: {
      bottom: '100%',
      left: '50%',
      marginBottom: '7px',
      transform: 'translateX(-50%)',
    },
    bottom: {
      top: '100%',
      left: '50%',
      marginTop: '7px',
      transform: 'translateX(-50%)',
    },
    left: {
      right: '100%',
      top: '50%',
      marginRight: '7px',
      transform: 'translateY(-50%)',
    },
  };
  const colorTextMap: Record<TooltipColorType, CSSObject> = {
    gray: {
      backgroundColor: theme.palette.bg.stripe,
    },
    blue: {
      backgroundColor: theme.palette.typo.link,
      color: theme.palette.typo.primary,
    },
  };
  const positionArrowMap: Record<TooltipPositionType, CSSObject> = {
    top: {
      bottom: -6,
      left: '50%',
      transform: 'translateX(-50%) rotate(180deg)',
    },
    bottom: {
      top: -6,
      left: '50%',
      transform: 'translateX(-50%)',
    },
    left: {
      right: -9,
      transform: 'translateY(-50%) rotate(90deg)',
      top: '50%',
    },
    right: {
      transform: 'translateY(-50%) rotate(270deg)',
      left: -9,
      top: '50%',
    },
  };
  const colorArrowMap: Record<TooltipColorType, CSSObject> = {
    gray: {
      color: theme.palette.bg.stripe,
    },
    blue: {
      color: theme.palette.typo.link,
    },
  };
  return {
    container: {
      position: 'relative',
    },
    tooltip: {
      position: 'absolute',
      zIndex: '10',
      borderRadius: '8px',
      padding: '1rem',
      textAlign: 'left',
      textTransform: 'none',
      boxShadow:
        '0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)',
      width: '250px',
      ...positionTextMap[position],
      ...colorTextMap[color],
    },
    tooltipArrow: {
      position: 'absolute',
      ...positionArrowMap[position],
      ...colorArrowMap[color],
    },
  };
});
