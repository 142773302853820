import type { FC } from 'react';

import type { ICommonIconProps } from './types';

export const BucketIcon: FC<ICommonIconProps> = ({
  color = '#78828B',
  width = 18,
  height = 20,
  viewBox = '0 0 18 20',
  className = '',
  style,
}) => (
  <svg
    color={color}
    fill="none"
    width={width}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M3 5H2V18C2 18.5304 2.21071 19.0391 2.58579 19.4142C2.96086 19.7893 3.46957 20 4 20H14C14.5304 20 15.0391 19.7893 15.4142 19.4142C15.7893 19.0391 16 18.5304 16 18V5H3ZM13.618 2L12 0H6L4.382 2H0V4H18V2H13.618Z"
      fill="currentColor"
    />
  </svg>
);
