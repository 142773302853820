import { makeStyles } from 'theme';

export const useStyle = makeStyles()(() => ({
  modal: {
    width: 530,
    display: 'flex',
    flexDirection: 'column',
    padding: '16px 20px',
  },
  text: {
    display: 'inline-block',
  },
  cardInfo: {
    display: 'flex',
    gap: 8,
  },
  cardDate: {
    display: 'flex',
    width: '50%',
    gap: 4,
  },
  currencyItem: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
  },
  addressInput: {
    width: '100%',
  },
  amountInput: {
    width: 172,
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
  cancelBtn: {
    marginRight: 20,
    marginLeft: 'auto',
  },
  amountContainer: {
    display: 'flex',
    width: '100%',
    gap: 16,
  },
}));
