export interface ICover {
  id: number;
  src: string;
}

export enum EChallengeCoverTypes {
  Hero,
  Dota2,
  Memes,
  Art,
}

export const challengeCovers: Record<EChallengeCoverTypes, ICover[]> = {
  [EChallengeCoverTypes.Hero]: [
    {
      id: 1,
      src: '/images/modals/gallery/challenge-1.png',
    },
    {
      id: 2,
      src: '/images/modals/gallery/challenge-2.png',
    },
    {
      id: 3,
      src: '/images/modals/gallery/challenge-3.png',
    },
    {
      id: 4,
      src: '/images/modals/gallery/challenge-4.png',
    },
    {
      id: 5,
      src: '/images/modals/gallery/challenge-5.png',
    },
    {
      id: 6,
      src: '/images/modals/gallery/challenge-6.png',
    },
    {
      id: 7,
      src: '/images/modals/gallery/challenge-7.png',
    },
    {
      id: 8,
      src: '/images/modals/gallery/challenge-8.png',
    },
    {
      id: 9,
      src: '/images/modals/gallery/challenge-9.png',
    },
    {
      id: 10,
      src: '/images/modals/gallery/challenge-10.png',
    },
    {
      id: 11,
      src: '/images/modals/gallery/challenge-11.png',
    },
    {
      id: 12,
      src: '/images/modals/gallery/challenge-12.png',
    },
    {
      id: 13,
      src: '/images/modals/gallery/challenge-13.png',
    },
    {
      id: 14,
      src: '/images/modals/gallery/challenge-14.png',
    },
    {
      id: 15,
      src: '/images/modals/gallery/challenge-15.png',
    },
    {
      id: 16,
      src: '/images/modals/gallery/challenge-16.png',
    },
    {
      id: 17,
      src: '/images/modals/gallery/challenge-17.png',
    },
    {
      id: 18,
      src: '/images/modals/gallery/challenge-18.png',
    },
    {
      id: 19,
      src: '/images/modals/gallery/challenge-19.png',
    },
    {
      id: 21,
      src: '/images/modals/gallery/challenge-21.png',
    },
    {
      id: 22,
      src: '/images/modals/gallery/challenge-22.png',
    },
    {
      id: 23,
      src: '/images/modals/gallery/challenge-23.png',
    },
    {
      id: 24,
      src: '/images/modals/gallery/challenge-24.png',
    },
    {
      id: 25,
      src: '/images/modals/gallery/challenge-25.png',
    },
    {
      id: 26,
      src: '/images/modals/gallery/challenge-26.png',
    },
    {
      id: 27,
      src: '/images/modals/gallery/challenge-27.png',
    },
    {
      id: 28,
      src: '/images/modals/gallery/challenge-28.png',
    },
    {
      id: 29,
      src: '/images/modals/gallery/challenge-29.png',
    },
    {
      id: 30,
      src: '/images/modals/gallery/challenge-30.png',
    },
  ],
  [EChallengeCoverTypes.Dota2]: [
    {
      id: 31,
      src: '/images/modals/gallery/challenge-31.png',
    },
    {
      id: 32,
      src: '/images/modals/gallery/challenge-32.png',
    },
    {
      id: 33,
      src: '/images/modals/gallery/challenge-33.png',
    },
    {
      id: 34,
      src: '/images/modals/gallery/challenge-34.png',
    },
    {
      id: 35,
      src: '/images/modals/gallery/challenge-35.png',
    },
    {
      id: 56,
      src: '/images/modals/gallery/challenge-56.png',
    },
    {
      id: 57,
      src: '/images/modals/gallery/challenge-57.png',
    },
    {
      id: 58,
      src: '/images/modals/gallery/challenge-58.png',
    },
    {
      id: 59,
      src: '/images/modals/gallery/challenge-59.png',
    },
    {
      id: 60,
      src: '/images/modals/gallery/challenge-60.png',
    },
  ],
  [EChallengeCoverTypes.Memes]: [
    {
      id: 20,
      src: '/images/modals/gallery/challenge-20.png',
    },
    {
      id: 36,
      src: '/images/modals/gallery/challenge-36.png',
    },
    {
      id: 37,
      src: '/images/modals/gallery/challenge-37.png',
    },
    {
      id: 38,
      src: '/images/modals/gallery/challenge-38.png',
    },
    {
      id: 39,
      src: '/images/modals/gallery/challenge-39.png',
    },
    {
      id: 40,
      src: '/images/modals/gallery/challenge-40.png',
    },
    {
      id: 61,
      src: '/images/modals/gallery/challenge-61.png',
    },
  ],
  [EChallengeCoverTypes.Art]: [
    {
      id: 41,
      src: '/images/modals/gallery/challenge-41.png',
    },
    {
      id: 42,
      src: '/images/modals/gallery/challenge-42.png',
    },
    {
      id: 43,
      src: '/images/modals/gallery/challenge-43.png',
    },
    {
      id: 44,
      src: '/images/modals/gallery/challenge-44.png',
    },
    {
      id: 45,
      src: '/images/modals/gallery/challenge-45.png',
    },
    {
      id: 46,
      src: '/images/modals/gallery/challenge-46.png',
    },
    {
      id: 47,
      src: '/images/modals/gallery/challenge-47.png',
    },
    {
      id: 48,
      src: '/images/modals/gallery/challenge-48.png',
    },
    {
      id: 49,
      src: '/images/modals/gallery/challenge-49.png',
    },
    {
      id: 50,
      src: '/images/modals/gallery/challenge-50.png',
    },
    {
      id: 51,
      src: '/images/modals/gallery/challenge-51.png',
    },
    {
      id: 52,
      src: '/images/modals/gallery/challenge-52.png',
    },
    {
      id: 53,
      src: '/images/modals/gallery/challenge-53.png',
    },
    {
      id: 54,
      src: '/images/modals/gallery/challenge-54.png',
    },
    {
      id: 55,
      src: '/images/modals/gallery/challenge-55.png',
    },
  ],
};
