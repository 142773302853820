import { FC } from 'react';

import { ICreatedParry } from 'api';

import { useStyle } from './BottomButtons.styles';
import { DraftButton } from './DraftButton';
import { PublishButton } from './PublishButton';

interface IBottomActionButtonsProps {
  parry: ICreatedParry;
}

export const BottomActionButtons: FC<IBottomActionButtonsProps> = ({
  parry,
}) => {
  const { classes } = useStyle();
  return (
    <div className={classes.buttonsContainer}>
      <div className={classes.buttons}>
        <DraftButton parry={parry} />
        <PublishButton parry={parry} />
      </div>
    </div>
  );
};
