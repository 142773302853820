import { CreateParry } from './CreateParry';

export const CREATE_PARRY = {
  name: 'parry',
  routes: [
    {
      title: 'CreateParry',
      path: 'parry',
      element: <CreateParry />,
    },
  ],
};
