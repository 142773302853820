import { makeStyles } from 'theme';

export const useStyle = makeStyles<{ isSmall?: boolean }>()(
  (theme, { isSmall = false }) => ({
    headerContainer: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      width: '100%',
      gap: 16,
      position: 'relative',
      marginTop: 140,
      maxWidth: 1170,
    },
    infoContainer: {
      gap: !isSmall ? '0 40px' : '0 20px',
      display: 'flex',
      width: '100%',
      height: 40,
    },
    dataContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: 6,
    },
    participantsContainer: {
      display: 'flex',
      gap: 40,
    },
    titleContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      gap: 16,
      width: '100%',
    },
    copyIcon: {
      cursor: 'pointer',
      display: 'inline',
      marginRight: 10,
    },
    game: {
      position: 'absolute',
      top: 0,
      transform: 'translateY(-43%)',
      left: 0,
      width: 174,
      height: 262,
    },
    ownerAvatar: {
      height: 20,
      width: 20,
      borderRadius: '50%',
      marginRight: 8,
    },
    ownerContainer: {
      display: 'flex',
      width: '100%',
    },
    resultContainer: {
      marginTop: 32,
      marginLeft: -190,
      display: 'flex',
      flexDirection: 'column',
      gap: 4,
      alignItems: 'center',
      marginBottom: -16,
      zIndex: 1,
    },
    resultPicture: {
      position: 'absolute',
      bottom: 0,
      right: 0,
      zIndex: 1,
    },
    resultShadow: {
      position: 'absolute',
      bottom: 0,
      right: 0,
      width: '100%',
      zIndex: '0',
    },
    controlDataContainer: {
      display: 'flex',
      height: 44,
      gap: 40,
      marginLeft: -176,
    },
    controlContainer: {
      ...theme.layouts.flexJCSpaceBetween,
      marginTop: 31,
      marginBottom: -17,
      width: '100%',
      alignItems: 'center',
      zIndex: 2,
    },
    stopCycle: {
      marginRight: 16,
      marginLeft: 'auto',
    },
    divider: {
      height: 1,
      width: '100%',
      position: 'absolute',
      bottom: 76,
      backgroundColor: theme.palette.bg.border,
      left: 0,
    },
    controlIcon: {
      height: 20,
      width: 20,
    },
    iconContainer: {
      display: 'flex',
      gap: 7,
      alignItems: 'center',
    },
    infIcon: {
      display: 'inline',
    },
  })
);
