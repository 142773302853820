import { FC } from 'react';

import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import { ICreatedChallenge, getSteamUsers } from 'api';
import { useDota2Hero } from 'hooks';
import { ProfileSmall, SwordIcon } from 'icons';
import { BuilderItemsView, Card, Typography, Dividers } from 'ui-kit';

import {
  isHeroDefault,
  isItemsDefault,
} from '../../../CreateChallenge/CreateChallenge.model';
import { useStyle } from './GeneralTerms.styles';

interface IGeneralTermsProps {
  challenge: ICreatedChallenge;
}

export const GeneralTerms: FC<IGeneralTermsProps> = ({ challenge }) => {
  const { classes } = useStyle();
  const { t: translation } = useTranslation();

  const { VerticalDivider } = Dividers;
  const { Text, Headline } = Typography;

  const { items, hero, options, winInsensitivity, targetSteamId } = challenge;
  const { data: steamUsers, isValidating } = useSWR(
    ['api/steam-users', targetSteamId],
    () => getSteamUsers(targetSteamId)
  );
  const withoutItems = isItemsDefault(items);
  const withoutHero = isHeroDefault(hero);
  const { localized_name, name } = useDota2Hero(challenge);
  const mainTerm = options[0];
  const heroImg = `https://gamegreed-cdn.ams3.cdn.digitaloceanspaces.com/heroes/${
    withoutHero ? 'any_hero' : name.replace('npc_dota_hero_', '')
  }.png`;

  return (
    <div className={classes.generalContainer}>
      <Card variant="p16" className={classes.mainTermCard} color="bgSecondary">
        <div className={classes.mainTermCard} style={{ height: '100%' }}>
          <SwordIcon className={classes.icon} />
          <div className={classes.mainTermContent}>
            <Text variant="b6" color="system">
              {winInsensitivity
                ? `${translation(
                    'challenge.form.gameOptions.winInsensitivity'
                  )}`
                : mainTerm['term'] == 0
                ? `${translation(
                    'challenge.form.gameOptions.win'
                  )} ${translation('challengeView.games')}`
                : `${translation(
                    'challenge.form.gameOptions.lose'
                  )} ${translation('challengeView.games')}`}
            </Text>
            <VerticalDivider />
            <Headline variant="h3">
              {!mainTerm['shouldBeInRow']
                ? mainTerm['quantity']
                : `${mainTerm['quantity']} ${translation('challengeView.row')}`}
            </Headline>
          </div>
          {targetSteamId && !isValidating && (
            <>
              <VerticalDivider />
              <Text color="system" className={classes.performer}>
                {steamUsers[0]?.avatarUrl ? (
                  <img
                    src={steamUsers[0].avatarUrl}
                    className={classes.ownerAvatar}
                    alt="Performer avatar"
                  />
                ) : (
                  <ProfileSmall
                    isFill
                    className={classes.controlIcon}
                    height={20}
                    width={20}
                  />
                )}
              </Text>
              <div className={classes.mainTermContent}>
                <Text variant="b6" color="system">
                  {translation('challengeView.performer')}
                </Text>
                <VerticalDivider />
                <Text variant="b3">{steamUsers[0]?.username ?? 'unknown'}</Text>
              </div>
            </>
          )}
        </div>
      </Card>
      {!withoutItems && (
        <Card variant="p16" className={classes.subTermCard} color="bgSecondary">
          <div className={classes.cardContent}>
            <Text variant="b6" color="system">
              {translation('challengeView.items')}
            </Text>
            <BuilderItemsView items={items} />
          </div>
        </Card>
      )}

      <Card variant="p16" className={classes.subTermCard} color="bgSecondary">
        <div className={classes.heroContent}>
          <Text variant="b6" color="system">
            {translation('challengeView.hero')}
          </Text>
          <Headline variant="h3">
            {withoutHero
              ? translation('challenges-list.challengeCard.any')
              : localized_name}
          </Headline>
          <img src={heroImg} className={classes.heroImg} alt="Hero img" />
        </div>
      </Card>
    </div>
  );
};
