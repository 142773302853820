import { makeStyles } from 'theme';

export const useStyle = makeStyles()((theme) => ({
  gameCard: {
    position: 'relative',
    width: '200px',
    padding: '0.75rem',
    borderRadius: '0.5rem',
    border: '1px solid rgba(53,57,54,0.3)',
    cursor: 'pointer',
  },
  gameCardInner: {
    position: 'absolute',
    top: '0.75rem',
    left: '0.75rem',
    height: 'calc(100% - 1.5rem)',
    width: 'calc(100% - 1.5rem)',
    backgroundImage: 'url("/images/main-page/dota2.png")',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    borderRadius: '6px',
  },
  activePredictCard: {
    ...theme.layouts.flexCenter,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(24,30,51,0.8)',
  },
  activePredictIcon: {
    ...theme.layouts.flexCenter,
    width: '56px',
    height: '56px',
    backgroundColor: theme.palette.typo.link,
    borderRadius: '50%',
  },
  playIcon: {
    color: theme.palette.typo.primary,
  },
}));
