import type { FC } from 'react';

import { v4 as uuid } from 'uuid';

import type { ICommonIconProps } from './types';

const COLORS_GRADIENT = {
  gold: ['#DEBB6C', '#E3CB8E'],
  silver: ['#C7C8C8', '#DDDEDE'],
  bronze: ['#AE7C3C', '#BB9768'],
};

export type ColorVariantType = keyof typeof COLORS_GRADIENT;

export const TrophyGradeIcon: FC<
  ICommonIconProps & { type?: ColorVariantType }
> = ({
  width = 16,
  height = 16,
  viewBox = '0 0 16 16',
  className = '',
  style,
  type = 'gold',
}) => {
  const id1 = uuid();
  const id2 = uuid();
  const id3 = uuid();
  const id4 = uuid();
  const id5 = uuid();
  const id6 = uuid();

  return (
    <svg
      fill="none"
      width={width}
      height={height}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 0.916667C3 0.80616 3.0439 0.700179 3.12204 0.622039C3.20018 0.543899 3.30616 0.5 3.41667 0.5H12.5833C12.6938 0.5 12.7998 0.543899 12.878 0.622039C12.9561 0.700179 13 0.80616 13 0.916667V1.33333H15.0833C15.1938 1.33333 15.2998 1.37723 15.378 1.45537C15.4561 1.53351 15.5 1.63949 15.5 1.75V4.25C15.5 4.80253 15.2805 5.33244 14.8898 5.72314C14.4991 6.11384 13.9692 6.33333 13.4167 6.33333H12.7154C12.0733 8.15 10.4113 9.485 8.41667 9.64958V12.1667H11.3333C11.4438 12.1667 11.5498 12.2106 11.628 12.2887C11.7061 12.3668 11.75 12.4728 11.75 12.5833V15.0833C11.75 15.1938 11.7061 15.2998 11.628 15.378C11.5498 15.4561 11.4438 15.5 11.3333 15.5H4.66667C4.55616 15.5 4.45018 15.4561 4.37204 15.378C4.2939 15.2998 4.25 15.1938 4.25 15.0833V12.5833C4.25 12.4728 4.2939 12.3668 4.37204 12.2887C4.45018 12.2106 4.55616 12.1667 4.66667 12.1667H7.58333V9.64958C5.58917 9.485 3.92667 8.15 3.28458 6.33333H2.58333C2.0308 6.33333 1.5009 6.11384 1.11019 5.72314C0.719493 5.33244 0.5 4.80253 0.5 4.25V1.75C0.5 1.63949 0.543899 1.53351 0.622039 1.45537C0.700179 1.37723 0.80616 1.33333 0.916667 1.33333H3V0.916667ZM12.1667 4.66667V1.33333H3.83333V4.66667C3.83333 6.96792 5.69875 8.83333 8 8.83333C10.3013 8.83333 12.1667 6.96792 12.1667 4.66667ZM13 2.16667V5.5H13.4167C13.7482 5.5 14.0661 5.3683 14.3005 5.13388C14.535 4.89946 14.6667 4.58152 14.6667 4.25V2.16667H13ZM1.33333 2.16667H3V5.5H2.58333C2.25181 5.5 1.93387 5.3683 1.69945 5.13388C1.46503 4.89946 1.33333 4.58152 1.33333 4.25V2.16667ZM5.08333 13V14.6667H10.9167V13H5.08333Z"
        fill={`url(#${id1})`}
      />
      <path
        d="M12.1667 4.66667V1.33333H3.83333V4.66667C3.83333 6.96792 5.69875 8.83333 8 8.83333C10.3013 8.83333 12.1667 6.96792 12.1667 4.66667Z"
        fill={`url(#${id2})`}
      />
      <path d="M5.08333 13V14.6667H10.9167V13H5.08333Z" fill={`url(#${id3})`} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 0.916667C3 0.80616 3.0439 0.700179 3.12204 0.622039C3.20018 0.543899 3.30616 0.5 3.41667 0.5H12.5833C12.6938 0.5 12.7998 0.543899 12.878 0.622039C12.9561 0.700179 13 0.80616 13 0.916667V1.33333H15.0833C15.1938 1.33333 15.2998 1.37723 15.378 1.45537C15.4561 1.53351 15.5 1.63949 15.5 1.75V4.25C15.5 4.80253 15.2805 5.33244 14.8898 5.72314C14.4991 6.11384 13.9692 6.33333 13.4167 6.33333H12.7154C12.0733 8.15 10.4113 9.485 8.41667 9.64958V12.1667H11.3333C11.4438 12.1667 11.5498 12.2106 11.628 12.2887C11.7061 12.3668 11.75 12.4728 11.75 12.5833V15.0833C11.75 15.1938 11.7061 15.2998 11.628 15.378C11.5498 15.4561 11.4438 15.5 11.3333 15.5H4.66667C4.55616 15.5 4.45018 15.4561 4.37204 15.378C4.2939 15.2998 4.25 15.1938 4.25 15.0833V12.5833C4.25 12.4728 4.2939 12.3668 4.37204 12.2887C4.45018 12.2106 4.55616 12.1667 4.66667 12.1667H7.58333V9.64958C5.58917 9.485 3.92667 8.15 3.28458 6.33333H2.58333C2.0308 6.33333 1.5009 6.11384 1.11019 5.72314C0.719493 5.33244 0.5 4.80253 0.5 4.25V1.75C0.5 1.63949 0.543899 1.53351 0.622039 1.45537C0.700179 1.37723 0.80616 1.33333 0.916667 1.33333H3V0.916667ZM12.1667 4.66667V1.33333H3.83333V4.66667C3.83333 6.96792 5.69875 8.83333 8 8.83333C10.3013 8.83333 12.1667 6.96792 12.1667 4.66667ZM13 2.16667V5.5H13.4167C13.7482 5.5 14.0661 5.3683 14.3005 5.13388C14.535 4.89946 14.6667 4.58152 14.6667 4.25V2.16667H13ZM1.33333 2.16667H3V5.5H2.58333C2.25181 5.5 1.93387 5.3683 1.69945 5.13388C1.46503 4.89946 1.33333 4.58152 1.33333 4.25V2.16667ZM5.08333 13V14.6667H10.9167V13H5.08333Z"
        stroke={`url(#${id4})`}
        strokeWidth="0.5"
      />
      <path
        d="M12.1667 4.66667V1.33333H3.83333V4.66667C3.83333 6.96792 5.69875 8.83333 8 8.83333C10.3013 8.83333 12.1667 6.96792 12.1667 4.66667Z"
        stroke={`url(#${id5})`}
        strokeWidth="0.5"
      />
      <path
        d="M5.08333 13V14.6667H10.9167V13H5.08333Z"
        stroke={`url(#${id6})`}
        strokeWidth="0.5"
      />
      <defs>
        <linearGradient
          id={id1}
          x1="0.5"
          y1="1.25"
          x2="4.0709"
          y2="18.2185"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={COLORS_GRADIENT[type][0]} />
          <stop offset="1" stopColor={COLORS_GRADIENT[type][1]} />
        </linearGradient>
        <linearGradient
          id={id2}
          x1="0.5"
          y1="1.25"
          x2="4.0709"
          y2="18.2185"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={COLORS_GRADIENT[type][0]} />
          <stop offset="1" stopColor={COLORS_GRADIENT[type][1]} />
        </linearGradient>
        <linearGradient
          id={id3}
          x1="0.5"
          y1="1.25"
          x2="4.0709"
          y2="18.2185"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={COLORS_GRADIENT[type][0]} />
          <stop offset="1" stopColor={COLORS_GRADIENT[type][1]} />
        </linearGradient>
        <linearGradient
          id={id4}
          x1="0.5"
          y1="1.25"
          x2="4.0709"
          y2="18.2185"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={COLORS_GRADIENT[type][0]} />
          <stop offset="1" stopColor={COLORS_GRADIENT[type][1]} />
        </linearGradient>
        <linearGradient
          id={id5}
          x1="0.5"
          y1="1.25"
          x2="4.0709"
          y2="18.2185"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={COLORS_GRADIENT[type][0]} />
          <stop offset="1" stopColor={COLORS_GRADIENT[type][1]} />
        </linearGradient>
        <linearGradient
          id={id6}
          x1="0.5"
          y1="1.25"
          x2="4.0709"
          y2="18.2185"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={COLORS_GRADIENT[type][0]} />
          <stop offset="1" stopColor={COLORS_GRADIENT[type][1]} />
        </linearGradient>
      </defs>
    </svg>
  );
};
