import { makeStyles } from 'theme';

export const useStyle = makeStyles()((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: 350,
    height: 300,
    borderRadius: 10,
    padding: '24px 24px 40px 24px',
  },
  titleContainer: {
    display: 'flex',
    gap: 8,
    alignItems: 'center',
  },
  title: {
    width: 106,
    height: 28,
    borderRadius: 2,
    backgroundColor: theme.palette.bg.ghost,
  },
  tooltip: {
    width: 20,
    height: 20,
    borderRadius: 2,
    backgroundColor: theme.palette.bg.ghost,
  },
  selectorContainer: {
    width: '100%',
    ...theme.layouts.flexCenter,
    backgroundColor: theme.palette.bg.ghost,
    borderRadius: 6,
    height: 44,
    marginTop: 22,
  },
  selector: {
    width: 96,
    backgroundColor: theme.palette.bg.ghost,
    borderRadius: 6,
    height: 36,
  },
  input: {
    width: '100%',
    backgroundColor: theme.palette.bg.ghost,
    borderRadius: 6,
    height: 44,
    marginTop: 16,
  },
  button: {
    width: '100%',
    backgroundColor: theme.palette.bg.ghost,
    borderRadius: 6,
    height: 44,
    marginTop: 36,
  },
}));
