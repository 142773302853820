import { makeStyles } from 'theme';

export const useStyle = makeStyles()((theme) => ({
  switchContainer: {
    padding: '12px 16px',
    border: `1px solid ${theme.palette.typo.link}`,
    display: 'flex',
    borderRadius: 8,
    maxHeight: 44,
  },
  item: {
    userSelect: 'none',
    ...theme.layouts.flexCenter,
    width: 'calc(50% - 8px)',
    cursor: 'pointer',
    position: 'relative',
    '&:first-of-type': {
      marginRight: 28,
    },
    zIndex: 1,
    ':before': {
      height: 10,
      transitionDuration: '500ms',
      position: 'absolute',
      width: 'calc(100% + 26px)',
      display: 'block',
      content: "''",
      backgroundColor: theme.palette.typo.link,
      borderRadius: 6,
      zIndex: -1,
      opacity: 0,
    },
  },
  active: {
    position: 'relative',
    ':before': {
      height: 36,
      opacity: 1,
      transitionDuration: '500ms',
    },
  },
}));
