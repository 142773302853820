import { makeStyles } from 'theme';

export const useStyle = makeStyles()((theme) => ({
  modal: {
    color: theme.palette.typo.primary,
    width: '590px',
    padding: '1.5rem 1rem',
  },
  container: {
    marginTop: '2.5rem',
    display: 'inline-block',
    width: '100%',
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    width: '100%',
    marginTop: '2.5rem',
  },
  btn: {
    backgroundColor: theme.palette.typo.link,
    marginLeft: '1rem',
  },
}));
