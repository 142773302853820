import { FC } from 'react';

import { SkeletonLoader } from 'ui-kit';

import { useStyle } from './StatisticSkeleton.styles';

export const StatisticSkeleton: FC = () => {
  const { classes } = useStyle();
  return (
    <SkeletonLoader className={classes.container}>
      <div className={classes.title} />
      <div className={classes.heroContainer}>
        <div className={classes.heroImg} />
        <div className={classes.heroInfoContainer}>
          <div className={classes.heroName} />
          <div className={classes.heroInfoSubContainer}>
            <div className={classes.heroInfo} />
            <div className={classes.heroInfo} />
          </div>
        </div>
      </div>
      <div className={classes.heroContainer}>
        <div className={classes.heroImg} />
        <div className={classes.heroInfoContainer}>
          <div className={classes.heroName} />
          <div className={classes.heroInfoSubContainer}>
            <div className={classes.heroInfo} />
            <div className={classes.heroInfo} />
          </div>
        </div>
      </div>
      <div className={classes.heroContainer}>
        <div className={classes.heroImg} />
        <div className={classes.heroInfoContainer}>
          <div className={classes.heroName} />
          <div className={classes.heroInfoSubContainer}>
            <div className={classes.heroInfo} />
            <div className={classes.heroInfo} />
          </div>
        </div>
      </div>
    </SkeletonLoader>
  );
};
