import type { FC } from 'react';

import type { ICommonIconProps } from './types';

export const CoinIcon: FC<ICommonIconProps> = ({
  width = 24,
  height = 24,
  viewBox = '0 0 24 24',
  className = '',
  style,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
    >
      <path
        d="M20.7926 15.4513V16.7368C20.7926 17.508 20.4203 18.1674 19.9035 18.6856C19.3926 19.1991 18.6989 19.6178 17.9183 19.9494C16.3532 20.6125 14.2605 21 11.9998 21C9.73908 21 7.6464 20.6135 6.08129 19.9494C5.30069 19.6178 4.60703 19.1991 4.09608 18.6856C3.62225 18.2119 3.27053 17.6169 3.21485 16.9273L3.20703 16.7368V15.4513C3.66132 15.7014 4.14688 15.9231 4.66663 16.1097C6.64989 16.8212 9.24375 17.2181 11.9998 17.2181C14.7558 17.2181 17.3497 16.8212 19.333 16.1097C19.7228 15.9695 20.093 15.8103 20.4457 15.6332L20.7926 15.4513ZM3.20703 10.2407C3.66132 10.4908 4.14688 10.7125 4.66663 10.8992C6.64989 11.6106 9.24375 12.0076 11.9998 12.0076C14.7558 12.0076 17.3497 11.6106 19.333 10.8992C19.8372 10.7187 20.3254 10.4985 20.7926 10.2407V13.1823C20.1438 13.6679 19.4231 14.0556 18.6549 14.3324C16.9404 14.9482 14.5868 15.3243 11.9998 15.3243C9.41375 15.3243 7.06022 14.9482 5.34465 14.3324C4.57644 14.0556 3.85577 13.6679 3.20703 13.1823V10.2407ZM11.9998 3C14.2605 3 16.3532 3.38653 17.9183 4.05063C18.6989 4.38221 19.3926 4.80095 19.9035 5.31442C20.3773 5.78811 20.7291 6.38305 20.7847 7.07274L20.7926 7.26316V7.97179C20.1438 8.45739 19.4232 8.84513 18.6549 9.12189C16.9404 9.73768 14.5868 10.1138 11.9998 10.1138C9.41375 10.1138 7.06022 9.73768 5.34465 9.12189C4.68406 8.88429 4.05837 8.56389 3.48351 8.16884L3.20703 7.97179V7.26316C3.20703 6.492 3.57926 5.83263 4.09608 5.31442C4.60703 4.80095 5.30069 4.38221 6.08129 4.05063C7.6464 3.38747 9.73908 3 11.9998 3Z"
        fill={'currentcolor'}
      />
    </svg>
  );
};
