import {
  EChallengeStatus,
  ECondition,
  EItemState,
  EMainTerm,
  ETerm,
} from 'consts';
import { convertObjValuesToString } from 'helpers';

import { BACKEND_URL } from './env';
import { IErrorResponse } from './interface';

export interface IChallenge {
  title: string;
  duration: number;
  password: string;
  entryCost: number;
  reward: number;
  distribution: number;
  participants: number;
  image: string;
  hero: number;
  winnerCount: number;
  iterations: number;
  options: {
    term: EMainTerm | ETerm;
    condition: ECondition;
    quantity: number;
    shouldBeInRow?: boolean;
  }[];
  items: { item: number; place: number; type: EItemState }[];
  winInsensitivity: boolean;
  targetSteamId?: string;
}

export interface IWinner {
  avatarUrl: string;
  username: string;
  id: number;
}

export interface ICreatedChallenge extends IChallenge {
  id: number;
  status: EChallengeStatus;
  avatars: string[];
  activeParticipants: number;
  owner: { username: string; avatarUrl: string };
  createdAt?: string;
  hasPassword: boolean;
  winners: IWinner[];
  isParticipant: boolean;
  isOwner: boolean;
  isFetched?: boolean;
  step?: number;
  cycleRunning: boolean;
  items: { item: number; place: number; type: EItemState }[];
  image: string;
}

export interface IChallengesList {
  result: ICreatedChallenge[];
  total: number;
}

export interface IChallengesCount {
  asOwner: number;
  asParticipant: number;
}

interface IFilterPagination {
  offset: number;
  limit: number;
}

interface IFilterParams extends IFilterPagination {
  title?: string;
  entryCostForm?: string;
  entryCostTo?: string;
  rewardFrom?: string;
  rewardTo?: string;
  playersFrom?: string;
  playersTo?: string;
  asParticipant?: boolean;
  hero?: string;
  password?: boolean;
  freeSlots?: boolean;
  orderBy?: string;
  orderType?: string;
}

interface IFilterMyChallenge extends IFilterPagination {
  title?: string;
  asOwner?: boolean;
}

export function createChallenge(
  challenge: IChallenge
): Promise<ICreatedChallenge | IErrorResponse> {
  return fetch(`${BACKEND_URL}/api/challenge-service/challenges`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify(challenge),
  }).then((data) => data.json());
}

export function updateChallenge(
  challenge: IChallenge & { id: number }
): Promise<IChallenge | IErrorResponse> {
  return fetch(
    `${BACKEND_URL}/api/challenge-service/challenges/${challenge.id}`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(challenge),
    }
  ).then((data) => data.json());
}

export function getActiveChallenges(
  filters: IFilterParams
): Promise<IChallengesList> {
  const params = new URLSearchParams(
    convertObjValuesToString<IFilterParams>(filters)
  );

  return fetch(
    `${BACKEND_URL}/api/challenge-service/challenges/active?orderType=DESC&${params.toString()}`,
    {
      credentials: 'include',
    }
  ).then((data) => data.json());
}

export function getActiveChallengesMy(
  filters: IFilterMyChallenge
): Promise<IChallengesList> {
  const params = new URLSearchParams(
    convertObjValuesToString<IFilterMyChallenge>(filters)
  );
  return fetch(
    `${BACKEND_URL}/api/challenge-service/challenges/active/my?orderType=DESC&${params.toString()}`,
    {
      credentials: 'include',
    }
  ).then((data) => data.json());
}

export function getChallenge(id: number): Promise<ICreatedChallenge> {
  return fetch(`${BACKEND_URL}/api/challenge-service/challenges/${id}`, {
    credentials: 'include',
  }).then((data) => data.json());
}

export function getChallengeHistory(id: number): Promise<ICreatedChallenge> {
  return fetch(
    `${BACKEND_URL}/api/challenge-service/challenges/history/${id}`,
    {
      credentials: 'include',
    }
  ).then((data) => data.json());
}

export function getDraftChallenges(
  filters: IFilterMyChallenge
): Promise<IChallengesList> {
  const params = new URLSearchParams(
    convertObjValuesToString<IFilterMyChallenge>(filters)
  );
  return fetch(
    `${BACKEND_URL}/api/challenge-service/challenges?${params.toString()}&orderBy=createdAt&orderType=DESC`,
    {
      credentials: 'include',
    }
  ).then((data) => data.json());
}

export function deleteDraftChallenge(id: number): Promise<Response> {
  return fetch(`${BACKEND_URL}/api/challenge-service/challenges/${id}`, {
    method: 'DELETE',
    credentials: 'include',
  });
}

export function activateChallenge(
  id: number
): Promise<IChallenge | IErrorResponse> {
  return fetch(
    `${BACKEND_URL}/api/challenge-service/challenges/${id}/activate`,
    {
      method: 'PUT',
      credentials: 'include',
    }
  ).then((data) => data.json());
}

export function disableChallenge(id: number): Promise<Response> {
  return fetch(
    `${BACKEND_URL}/api/challenge-service/challenges/${id}/disable`,
    {
      method: 'PUT',

      credentials: 'include',
    }
  );
}

export function participateInChallenge(
  id: number,
  password?: string
): Promise<IChallenge | IErrorResponse> {
  return fetch(
    `${BACKEND_URL}/api/challenge-service/challenges/${id}/participation`,
    {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify({ password }),
      headers: {
        'Content-Type': 'application/json',
      },
    }
  ).then((data) => data.json());
}

export function deactivateParticipationInChallenge(
  id: number
): Promise<Response | void> {
  return fetch(
    `${BACKEND_URL}/api/challenge-service/challenges/${id}/participation`,
    {
      method: 'DELETE',
      credentials: 'include',
    }
  );
}

export function getHistoryChallenges(
  filters: IFilterMyChallenge
): Promise<IChallengesList> {
  const params = new URLSearchParams(
    convertObjValuesToString<IFilterMyChallenge>(filters)
  );

  return fetch(
    `${BACKEND_URL}/api/challenge-service/challenges/history?${params.toString()}&orderBy=createdAt&orderType=DESC`,
    {
      credentials: 'include',
    }
  ).then((data) => data.json());
}

export function getChallengesCount(): Promise<IChallengesCount> {
  return fetch(`${BACKEND_URL}/api/challenge-service/challenges/count`, {
    credentials: 'include',
  }).then((data) => data.json());
}

export function stopChallengeRestart(challengeId: number): Promise<Response> {
  return fetch(
    `${BACKEND_URL}/api/challenge-service/challenges/${challengeId}/cycle`,
    {
      method: 'DELETE',
      credentials: 'include',
    }
  );
}
