import { FC } from 'react';

import { Headline } from './Headline';
import { IHeadlineProps } from './Headline/Headline';
import { Text } from './Text';
import { IBodyProps } from './Text/Text';

interface ITypography {
  Headline: FC<IHeadlineProps>;
  Text: FC<IBodyProps>;
}

export const Typography: ITypography = {
  Headline: Headline,
  Text: Text,
};
