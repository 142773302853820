import { FC, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import { LOCALE_LOCAL_STORAGE_KEY, PRELOGIN_LOCAL_STORAGE_KEY } from 'consts';
import { Typography } from 'ui-kit';

interface ILanguageSelectorProps {
  className?: string;
}

export const LanguageSelector: FC<ILanguageSelectorProps> = ({ className }) => {
  const { Text } = Typography;
  const { data: user } = useSWR('api/user');
  const {
    i18n: { changeLanguage, language },
  } = useTranslation();

  const onChangeLanguage = (): void => {
    if (language === 'en') {
      if (user?.id) {
        localStorage.setItem(`${user.id}-${LOCALE_LOCAL_STORAGE_KEY}`, 'ru');
      } else {
        localStorage.setItem(PRELOGIN_LOCAL_STORAGE_KEY, 'ru');
      }
      changeLanguage('ru');
    } else {
      if (user?.id) {
        localStorage.setItem(`${user.id}-${LOCALE_LOCAL_STORAGE_KEY}`, 'en');
      } else {
        localStorage.setItem(PRELOGIN_LOCAL_STORAGE_KEY, 'en');
      }
      changeLanguage('en');
    }
  };

  useEffect(() => {
    const savedLocale = localStorage.getItem(
      `${user?.id}-${LOCALE_LOCAL_STORAGE_KEY}`
    );
    if (user?.id && savedLocale) {
      changeLanguage(savedLocale);
    }
  }, [user, language]);

  useEffect(() => {
    const locale = window.navigator.language;
    const preloginLocale = localStorage.getItem(PRELOGIN_LOCAL_STORAGE_KEY);
    if (preloginLocale) {
      changeLanguage(preloginLocale);
      return;
    }
    if (locale.includes('ru')) {
      changeLanguage('ru');
    }
  }, []);

  return (
    <div className={className} onClick={onChangeLanguage}>
      <Text color="typoLabel">{language?.toUpperCase()}</Text>{' '}
    </div>
  );
};
