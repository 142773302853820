import { data } from 'autoprefixer';

import { BACKEND_URL } from './env';

export interface IBalanceResponse {
  balance: number;
}

type ITransactionsResponse = {
  time: number;
  value: number;
  reason: string;
}[];

export function getBalance(): Promise<IBalanceResponse> {
  return fetch(`${BACKEND_URL}/api/finance-service/balance`, {
    credentials: 'include',
  }).then((data) => data.json());
}

export function getTransactions(from: Date): Promise<ITransactionsResponse> {
  return fetch(`${BACKEND_URL}/api/finance-service/transactions?from=${from}`, {
    credentials: 'include',
  }).then((data) => data.json());
}

export function sendDepositHash(hash: string): Promise<Response> {
  return fetch(`${BACKEND_URL}/api/finance-service/balance/deposite`, {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify({ hash }),
  });
}

export function sendWithdrawData(
  wallet: string,
  amount: number
): Promise<Response> {
  return fetch(`${BACKEND_URL}/api/finance-service/balance/withdraw`, {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify({ wallet, amount }),
  });
}
