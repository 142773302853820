import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { ICreatedChallenge } from 'api';
import { calcChallengeDuration } from 'helpers';
import { Typography } from 'ui-kit';

import { EChallengeStatus } from '../../../../consts';
import { InfinityIcon } from '../../../../icons';
import { useStyle } from './ChallengeInfo.styles';

interface IChallengeInfoProps {
  challenge: ICreatedChallenge;
  isShowDuration: boolean;
}

export const ChallengeInfo: FC<IChallengeInfoProps> = ({ challenge }) => {
  const { t: translation } = useTranslation();
  const { classes } = useStyle();
  const { formattedString, isFinished } = calcChallengeDuration(challenge);

  const { Text } = Typography;
  const VerticalDivider: FC = () => <div className={classes.divider} />;
  const HorizontalDivider: FC = () => (
    <div className={classes.horizontalDivider} />
  );

  const isDraft = challenge?.status === EChallengeStatus.Draft;

  return (
    <>
      <div className={classes.infoContainer}>
        <div className={classes.itemContainer}>
          <Text variant="b7" color="system">
            {translation('challenges-list.challengeCard.owner')}
          </Text>
          <Text variant="b6">{challenge.owner.username}</Text>
        </div>

        {!isFinished && (
          <>
            <VerticalDivider />
            <div className={classes.itemContainer}>
              <Text variant="b7" color="system">
                {!isDraft
                  ? translation('challenges-list.challengeCard.finishing')
                  : translation('challenges-list.challengeCard.duration')}
              </Text>
              <Text variant="b6">{formattedString}</Text>
            </div>
          </>
        )}
        <VerticalDivider />
        <div className={classes.itemContainer}>
          <Text variant="b7" color="system">
            {translation('challenges-list.challengeCard.signed')}
          </Text>
          <Text variant="b6">
            {challenge.activeParticipants}
            <Text variant="b6" color="system">
              {' / '}
              {challenge.participants || (
                <InfinityIcon
                  width={16}
                  height={16}
                  className={classes.infIcon}
                />
              )}
            </Text>
          </Text>
        </div>
        <VerticalDivider />
        <div className={classes.itemContainer}>
          <Text variant="b7" color="system">
            {translation('challenges-list.challengeCard.winners')}
          </Text>
          <Text variant="b6">
            {challenge.winners.length}
            <Text variant="b6" color="system">
              {' / '}
              {challenge.winnerCount}
            </Text>
          </Text>
        </div>
      </div>
      <HorizontalDivider />
    </>
  );
};
