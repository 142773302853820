import { makeStyles } from 'theme';

export const useStyle = makeStyles()((theme) => ({
  button: {
    marginLeft: '3rem',
    borderBottom: '3px solid transparent',
    transitionDuration: '300ms',
    paddingBottom: '1rem',
    ':first-of-type': {
      marginLeft: 0,
    },
    ':hover': {
      color: theme.palette.typo.primary,
    },
  },
  activeButton: {
    borderBottom: `3px solid ${theme.palette.typo.link}`,
    transitionDuration: '300ms',
  },
}));
