import { FC } from 'react';

import clsx from 'clsx';

import { EWinnerPlace, removeZeroesAfterComma } from 'helpers';
import { ChallengeRewardIcon } from 'icons';
import { Card, Typography } from 'ui-kit';

import { useStyle } from '../../ChallengeView.styles';
import { colorMap, colorShadowMap, colorTextMap, placeMap } from './utils';

export const WinnerPlaceholder: FC<{
  place: EWinnerPlace;
  reward: string;
}> = ({ place, reward }) => {
  const { classes } = useStyle();
  const { Headline } = Typography;
  return (
    <Card
      className={classes.winnerPlaceholderCard}
      variant="p16"
      color="bgSecondary"
    >
      <div className={classes.winnerPlaceHolderContainer}>
        <div
          className={clsx(
            classes[colorTextMap[place]],
            classes.winnersLeftBar,
            classes[colorShadowMap[place]]
          )}
        />
        <div className={classes.trophyContainer}>
          <ChallengeRewardIcon type={colorMap[place]} />
          <Headline variant="h2">
            <span
              className={clsx(
                classes[colorTextMap[place]],
                classes.gradientTextTrophy
              )}
            >
              {place + 1}
            </span>
            <span className={classes.winnersPlaceholder}>
              {placeMap[place]}
            </span>
          </Headline>
        </div>
        <Headline variant="h2">
          <span className={clsx(classes.winnersReward)}>
            {`${removeZeroesAfterComma(reward)} GC`}
          </span>
        </Headline>
      </div>
    </Card>
  );
};
