import type { FC } from 'react';

import type { ICommonIconProps } from './types';

export const SwordIcon: FC<ICommonIconProps> = ({
  color = 'currentColor',
  width = 16,
  height = 16,
  viewBox = '0 0 16 16',
  className = '',
  style,
}) => (
  <svg
    fill="none"
    width={width}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M3.875 9.17167L6.82 12.1183L5.6425 13.2967L6.82166 14.4758L5.64333 15.6542L3.58083 13.5917L1.22333 15.9492L0.0449982 14.7708L2.4025 12.4125L0.339998 10.3508L1.51833 9.1725L2.69666 10.35L3.87416 9.17167H3.875ZM0.499998 0.5L3.455 0.5025L13.3025 10.3508L14.4817 9.1725L15.66 10.3508L13.5983 12.4133L15.955 14.7708L14.7767 15.9492L12.4192 13.5917L10.3567 15.6542L9.17833 14.4758L10.3567 13.2967L0.502498 3.4425L0.499998 0.5ZM12.5475 0.5L15.5 0.5025L15.5017 3.43833L12.1242 6.815L9.1775 3.86917L12.5475 0.5Z"
      fill={color}
      fillOpacity="0.6"
    />
  </svg>
);
