import { FC } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Footer } from 'Layout/Footer';
import { Button, Typography } from 'ui-kit';

import { useStyle } from './NotFound.styles';

export const NotFound: FC = () => {
  const { classes } = useStyle();
  const { t: translation } = useTranslation();
  const { Headline } = Typography;
  const navigate = useNavigate();
  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <img
          alt="404"
          src="./images/not-found/404.png"
          className={classes.img}
        />
        <Headline variant="h2">{translation('notFound.notFoundText')}</Headline>
        <Button onClick={() => navigate('/home')}>
          {translation('notFound.homeButton')}
        </Button>
      </div>
      <Footer className={classes.footer} />
    </div>
  );
};
