import { FC } from 'react';

import clsx from 'clsx';

import { CheckSmallIcon, MinusIcon } from 'icons';
import { Typography, HeadlineColorType } from 'ui-kit';

import { useStyle } from './Step.styles';

export interface IStep {
  label: string;
  onClick: (stepNumber: number) => void;
  stepIndex: number;
  status: EStepStatus;
}

export enum EStepStatus {
  Skipped = 'skipped',
  Active = 'active',
  Inactive = 'inactive',
  Complete = 'complete',
}

interface IClass {
  numberClassname: string;
  labelClassname: string;
}

const classMap: Record<EStepStatus, IClass> = {
  [EStepStatus.Complete]: {
    numberClassname: 'completeNumber',
    labelClassname: 'completeLabel',
  },
  [EStepStatus.Active]: {
    numberClassname: 'activeNumber',
    labelClassname: 'activeLabel',
  },
  [EStepStatus.Inactive]: {
    numberClassname: 'inactiveNumber',
    labelClassname: 'inactiveLabel',
  },
  [EStepStatus.Skipped]: {
    numberClassname: 'skippedNumber',
    labelClassname: 'skippedLabel',
  },
};

const textColorMap: Record<EStepStatus, HeadlineColorType> = {
  [EStepStatus.Complete]: 'link',
  [EStepStatus.Active]: 'primary',
  [EStepStatus.Inactive]: 'typoPlaceholder',
  [EStepStatus.Skipped]: 'link',
};

export const Step: FC<IStep> = ({
  label,
  onClick,
  stepIndex = 0,
  status = EStepStatus.Active,
}) => {
  const onStepClick = (): void => onClick(stepIndex);
  const { classes } = useStyle();
  const { numberClassname, labelClassname } = classMap[status];
  const isNumber =
    status === EStepStatus.Active || status === EStepStatus.Inactive;
  const { Headline } = Typography;
  return (
    <div onClick={onStepClick} className={classes.stepContainer}>
      <div className={clsx(classes[numberClassname], classes.number)}>
        {isNumber && (
          <Headline variant="h4" color={textColorMap[status]}>
            {' '}
            {stepIndex + 1}{' '}
          </Headline>
        )}
        {status === EStepStatus.Complete && (
          <CheckSmallIcon
            color={'white'}
            height={11}
            width={15}
            viewBox={'0 0 17 11'}
          />
        )}
        {status === EStepStatus.Skipped && (
          <MinusIcon color={'white'} height={2} width={15} viewBox="0 0 15 2" />
        )}
      </div>
      <Headline
        variant="h4"
        color={textColorMap[status]}
        className={clsx(classes[labelClassname])}
      >
        {label}
      </Headline>
    </div>
  );
};
