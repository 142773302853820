import { makeStyles } from 'theme';

export const useStyle = makeStyles()(() => ({
  cardContainer: {
    marginTop: 108,
    display: 'flex',
    marginBottom: 100,
    width: '100%',
    justifyContent: 'center',
  },
  card: {
    fontFamily: 'Ubuntu',
    background:
      'linear-gradient(38.92deg, rgba(22, 28, 40, 0.4) 0%, rgba(22, 28, 40, 0.1) 134.39%)',
    backdropFilter: 'blur(8px)',
    borderRadius: 6,
    paddingTop: 30,
    paddingLeft: 40,
    paddingRight: 40,
    paddingBottom: 42,
    border: '2px solid rgba(43, 44, 46, 0.64)',
    width: 'calc(50% - 16px)',
    '&:first-of-type': {
      marginRight: 32,
    },
  },
  title: {
    fontWeight: 700,
    fontSize: '2rem',
    color: '#ECF6FF',
    marginBottom: 48,
  },
  item: {
    display: 'flex',
    color: '#ECF6FFCC',
    fontSize: 21,
    marginBottom: 21,
    width: '100%',
    alignItems: 'center',
  },
  itemLeaderBoardDots: {
    color: '#ECF6FFCC',
    padding: '0 24px',
    fontSize: 20,
  },
  itemDot: {
    display: 'inline-block',
    content: "''",
    minWidth: 10,
    heigh: 10,
    width: 10,
    minHeight: 10,
    borderRadius: 10,
    backgroundColor: '#ECF6FFCC',
    marginRight: 20,
  },
  blueGreen: {
    backgroundImage:
      'linear-gradient(88.89deg, #03D7BD 0.73%, #00D1FF 102.93%)',
    backgroundClip: 'text',
    color: 'transparent',
    display: 'inline',
  },
  button: {
    width: 400,
    display: 'flex',
    justifyContent: 'center',
  },
  buttonFirst: {
    marginTop: 60,
  },
  buttonSecond: {
    marginTop: 22,
  },
  leaderboardItem: {
    backgroundColor: '#212843',
    color: '#ECF6FF',
    width: '100%',
    marginBottom: 8,
    borderRadius: 6,
    padding: '8px 24px',
    display: 'flex',
    alignItems: 'center',
    '& div': {
      '&:first-of-type': {
        marginRight: 40,
      },
      '&:last-child': {
        marginRight: 0,
        marginLeft: 'auto',
      },
    },
  },
  unicCell: {
    marginRight: '45px !important',
  },
  tableHeader: {
    display: 'flex',
    marginBottom: 8,
    paddingLeft: 14,
    paddingRight: 14,
  },
  tableColumn: {
    color: '#ECF6FF',
    fontSize: 12,
    '&:first-of-type': {
      marginRight: 44,
    },
    '&:last-child': {
      marginRight: 0,
      marginLeft: 'auto',
    },
  },
  avatar: {
    width: '32px',
    borderRadius: 4,
    marginRight: 16,
  },
}));
