import { FC } from 'react';

import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { Button } from 'ui-kit';

import { useStyle } from './AboutFooter.styles';

export const AboutFooter: FC<{
  isLogin: boolean;
  onMakePredictionClick: () => void;
}> = ({ onMakePredictionClick }) => {
  const { classes } = useStyle();
  const { t: translation } = useTranslation();
  return (
    <div className={classes.cardContainer}>
      <Button
        onClick={onMakePredictionClick}
        className={clsx(classes.button, classes.buttonFirst)}
        buttonSize="l"
      >
        {translation('predictionsAbout.footer.leaderBoardDescription.button')}{' '}
      </Button>
    </div>
  );
};
