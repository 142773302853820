import { BACKEND_URL } from './env';

interface ISteamUser {
  steamId: string;
  username: string;
  avatarUrl: string;
}

export function getSteamUrl(referral?: string): Promise<{ url: string }> {
  return fetch(
    `${BACKEND_URL}/api/steam-auth-service/auth/get-url?redirect_url=${BACKEND_URL}${
      referral ? `&referal_code=${referral}` : ''
    }`,
    {
      credentials: 'include',
    }
  ).then((data) => data.json());
}

export function getSteamUsers(param?: string): Promise<ISteamUser[]> {
  return fetch(
    `${BACKEND_URL}/api/steam-auth-service/auth/find?query=${param}`,
    {
      credentials: 'include',
    }
  ).then((data) => data.json());
}
