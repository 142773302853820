import { FC, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import { BACKEND_URL } from 'api/env';
import { LinkIcon } from 'icons';
import { Button } from 'ui-kit';

import { Text } from '../../../../../ui-kit/Typography/Text';
import { useStyle } from './ReferralLink.styles';

export const ReferralLink: FC = () => {
  const { t: translation } = useTranslation();
  const { classes } = useStyle();
  const [isCopied, setIsCopied] = useState(false);
  const { data: { referalCode = '' } = {} } = useSWR('api/user');
  const link = `${BACKEND_URL}/login?referal=${referalCode}`;
  useEffect(() => {
    if (isCopied) {
      setTimeout(() => setIsCopied(false), 2000);
    }
  }, [isCopied]);
  const onClickCopy = async (): Promise<void> => {
    await navigator.clipboard.writeText(link);
    setIsCopied(true);
  };
  return (
    <div className={classes.linkContainer}>
      <div className={classes.codeContainer}>
        <div className={classes.link}>
          <Text variant="b4">{link}</Text>
        </div>
        <Button
          className={classes.button}
          onClick={onClickCopy}
          buttonSize="xl"
        >
          {translation('referral.button')}
        </Button>
      </div>
      {isCopied && (
        <div className={classes.copySuccessfulContainer}>
          <LinkIcon className={classes.copyIcon} />
          <Text variant="b4" color="success">
            {translation('referral.buttonSuc')}
          </Text>
        </div>
      )}
    </div>
  );
};
