import { FC } from 'react';

import { ICommonIconProps } from './types';

export const HrefIcon: FC<ICommonIconProps> = ({
  color = 'currentColor',
  width = 20,
  height = 20,
  viewBox = '0 0 20 20',
  className = '',
  style,
}) => (
  <svg
    fill="none"
    width={width}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.1665 3.33329C3.94549 3.33329 3.73353 3.42109 3.57725 3.57737C3.42097 3.73365 3.33317 3.94561 3.33317 4.16663V15.8333C3.33317 16.0543 3.42097 16.2663 3.57725 16.4225C3.73353 16.5788 3.94549 16.6666 4.1665 16.6666H15.8332C16.0542 16.6666 16.2661 16.5788 16.4224 16.4225C16.5787 16.2663 16.6665 16.0543 16.6665 15.8333V11.6666C16.6665 11.2064 17.0396 10.8333 17.4998 10.8333C17.9601 10.8333 18.3332 11.2064 18.3332 11.6666V15.8333C18.3332 16.4963 18.0698 17.1322 17.6009 17.6011C17.1321 18.0699 16.4962 18.3333 15.8332 18.3333H4.1665C3.50346 18.3333 2.86758 18.0699 2.39874 17.6011C1.9299 17.1322 1.6665 16.4963 1.6665 15.8333V4.16663C1.6665 3.50358 1.9299 2.8677 2.39874 2.39886C2.86758 1.93002 3.50346 1.66663 4.1665 1.66663H8.33317C8.79341 1.66663 9.1665 2.03972 9.1665 2.49996C9.1665 2.9602 8.79341 3.33329 8.33317 3.33329H4.1665ZM13.3332 3.33329C12.8729 3.33329 12.4998 2.9602 12.4998 2.49996C12.4998 2.03972 12.8729 1.66663 13.3332 1.66663H17.4997C17.4997 1.66663 17.4998 1.66663 17.4998 1.66663C17.6128 1.66663 17.7206 1.68911 17.8188 1.72986C17.9171 1.77052 18.0092 1.83081 18.0891 1.9107C18.2416 2.06325 18.3227 2.26023 18.3322 2.45998C18.3329 2.4733 18.3332 2.48663 18.3332 2.49996C18.3332 2.50023 18.3332 2.5005 18.3332 2.50078V6.66663C18.3332 7.12686 17.9601 7.49996 17.4998 7.49996C17.0396 7.49996 16.6665 7.12686 16.6665 6.66663V4.5118L11.8391 9.33922C11.5137 9.66465 10.986 9.66465 10.6606 9.33922C10.3351 9.01378 10.3351 8.48614 10.6606 8.1607L15.488 3.33329H13.3332Z"
      fill="#2D63FF"
    />
  </svg>
);
