import { FC } from 'react';

import { IErrorResponse, isErrorResponse, IUserResponse } from 'api';

import { UserCard } from './components';
import { LoginButtons } from './components/LoginButtons';

interface IUserMenu {
  user: IUserResponse | IErrorResponse;
}

export const UserMenu: FC<IUserMenu> = ({ user }) => {
  const isUserExist = !isErrorResponse(user) && user?.id;
  return <>{isUserExist ? <UserCard user={user} /> : <LoginButtons />}</>;
};
