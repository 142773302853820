import { FC, useState } from 'react';

import { motion } from 'framer-motion';
import { useFormContext, useWatch } from 'react-hook-form';

import { EItemState, IItem } from 'consts';
import { NavigationTabs, NavigationTabType } from 'ui-kit';

import { ParryFormValueType } from '../../../CreateParry.model';
import { BasicItems } from './BasicItems';
import { useStyle } from './ItemTable.styles';
import { UpgradeItems } from './UpgradeItems';

const itemTabs: NavigationTabType[] = [
  {
    labelKey: 'parry.form.heroItemBuild.items.tabs.upgrade',
    id: 1,
  },
  {
    labelKey: 'parry.form.heroItemBuild.items.tabs.basic',
    id: 0,
  },
];

export const ItemTable: FC = () => {
  const { classes } = useStyle();
  const [activeTab, setActiveTab] = useState(itemTabs[0].id);
  const { control, setValue } = useFormContext();
  const items: ParryFormValueType['items'] = useWatch({
    name: 'items',
    control,
  });

  const hasEmptySlot = (items: ParryFormValueType['items']): boolean => {
    return items.some(
      (item) => item.item === null && item.type === EItemState.Free
    );
  };
  const findEmptySlot = (items: ParryFormValueType['items']): number => {
    return items.findIndex(
      (item) => item.item === null && item.type === EItemState.Free
    );
  };

  const onItemClick = (item: IItem): void => {
    if (!hasEmptySlot(items)) {
      return;
    }
    const itemsCopy = [...items];
    const emptyIndex = findEmptySlot(items);
    itemsCopy[emptyIndex] = {
      type: EItemState.Filled,
      item: item.id,
      place: emptyIndex,
      url: `https://media.steampowered.com${item.img}`,
      dname: item.dname,
    };
    setValue('items', itemsCopy);
  };

  return (
    <>
      <NavigationTabs
        tabs={itemTabs}
        onTabClick={setActiveTab}
        selectedTab={activeTab}
      />
      {activeTab === 0 && (
        <motion.div
          className={classes.table}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
        >
          <BasicItems onItemClick={onItemClick} />
        </motion.div>
      )}
      {activeTab === 1 && (
        <motion.div
          className={classes.table}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
        >
          <UpgradeItems onItemClick={onItemClick} />
        </motion.div>
      )}
    </>
  );
};
