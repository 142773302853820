import { makeStyles } from 'theme';

export const useStyle = makeStyles()(() => ({
  modal: {
    padding: '16px 24px 24px 20px',
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    display: 'inline-block',
    marginBottom: 40,
  },
  description: {
    display: 'inline-block',
    marginBottom: 40,
  },
  button: {
    width: 120,
    '&:first-of-type': {
      marginRight: 16,
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));
