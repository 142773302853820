import { FC, useState } from 'react';

import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { AlertIcon, UIClock24Icon } from 'icons';
import { Dividers, TextSwitch, Typography } from 'ui-kit';

import { useStyle } from './BetaTestResult.styles';

const challengeWinners = [
  {
    username: '[🅰]вѕσℓυ𝓽ツ🎮',
    imgUrl: '/images/home/winners/c1.jpg',
    score: 710,
  },
  {
    username: 'Waku Waku',
    score: 603,
    imgUrl: '/images/home/winners/c2.jpg',
  },
  {
    username: 'Miku fan №1',
    score: 379,
    imgUrl: '/images/home/winners/c3.jpg',
  },
  {
    username: 's_cry_3d',
    score: 353,
    imgUrl: '/images/home/winners/c4.jpg',
  },
  {
    username: 'who is she',
    score: 268,
    imgUrl: '/images/home/winners/c5.jpg',
  },
];

const predictionWinners = [
  {
    username: 's_cry_3d',
    imgUrl: '/images/home/winners/p1.jpg',
    score: 275,
  },
  {
    username: 'theGM',
    score: 181,
    imgUrl: '/images/home/winners/p2.jpg',
  },
  {
    username: '[🅰]вѕσℓυ𝓽ツ🎮',
    score: 166,
    imgUrl: '/images/home/winners/p3.jpg',
  },
  {
    username: 'LeChe-KiToy',
    score: 126,
    imgUrl: '/images/home/winners/p4.jpg',
  },
  {
    username: 'Art',
    score: 126,
    imgUrl: '/images/home/winners/p5.jpg',
  },
];

const getColor = (index: number): string => {
  if (index === 0) {
    return 'firstWinner';
  }
  if (index === 1) {
    return 'secondWinner';
  }
  if (index === 2) {
    return 'thirdWinner';
  }
  return 'fourthWinner';
};

export const BetaTestResult: FC = () => {
  const { classes } = useStyle();
  const { Text, Headline } = Typography;
  const { HorizontalDivider } = Dividers;
  const [viewMode, setViewMode] = useState<number>(0);
  const winners = viewMode === 0 ? challengeWinners : predictionWinners;
  const { t: translation } = useTranslation();
  const RewardStepFirst: FC = () => {
    const text = translation('home.betaFinish.stepFirst').split('Reward');
    return (
      <>
        {text[0]}
        <a
          href="https://discord.gg/7fKEWhnh4x"
          target="_blank"
          rel="noreferrer"
        >
          <Text variant="b3" color="link">
            {' '}
            Reward{' '}
          </Text>
        </a>
        {text[1]}
      </>
    );
  };
  const RewardStepSecond: FC = () => {
    const text = translation('home.betaFinish.stepThird').split('GameGreed');
    return (
      <>
        {text[0]}
        <a
          href="https://steamcommunity.com/profiles/76561199404873624"
          target="_blank"
          rel="noreferrer"
        >
          <Text variant="b3" color="link">
            {' '}
            GameGreed{' '}
          </Text>
        </a>
        {text[1]}
      </>
    );
  };
  return (
    <div className={classes.container}>
      <img
        src="/images/home/invoker.png"
        alt="invoker img"
        className={classes.invoker}
      />
      <Headline className={classes.text}>
        {translation('home.betaFinish.title')}
      </Headline>
      <Headline variant="h3" className={classes.text}>
        {translation('home.betaFinish.subTitle')}
      </Headline>
      <Headline
        variant="h3"
        className={clsx(classes.text, classes.resultTitle)}
      >
        {translation('home.betaFinish.during')}
      </Headline>
      <div className={classes.numbersContainer}>
        <Headline variant="h2" className={classes.subContainer}>
          2727
        </Headline>
        <Headline variant="h2" className={classes.subContainer}>
          1 681
        </Headline>
        <Headline variant="h2" className={classes.subContainer}>
          6 600
        </Headline>
        <Headline variant="h2" className={classes.subLongContainer}>
          ~ 5 000 000
        </Headline>
        <Headline variant="h2" className={classes.subContainer}>
          13
        </Headline>
      </div>
      <HorizontalDivider className={classes.divider} />
      <div className={classes.numbersContainer}>
        <Text variant="b3" className={classes.subContainer}>
          {translation('home.betaFinish.first')}
        </Text>
        <Text variant="b3" className={classes.subContainer}>
          {translation('home.betaFinish.second')}
        </Text>
        <Text variant="b3" className={classes.subContainer}>
          {translation('home.betaFinish.third')}
        </Text>
        <Text variant="b3" className={classes.subLongContainer}>
          {translation('home.betaFinish.fourth')}
        </Text>
        <Text variant="b3" className={classes.subLongContainer}>
          {translation('home.betaFinish.fifth')}
        </Text>
      </div>
      <div className={classes.infoContainer}>
        <div className={classes.leadersContainer}>
          <div className={classes.leaderTitleContainer}>
            <Headline className={classes.text} variant="h3">
              {translation('home.betaFinish.leaderTitle')}
            </Headline>
            <TextSwitch
              currentOption={viewMode}
              onClick={setViewMode}
              options={[
                {
                  label: translation('home.betaFinish.switchFirst'),
                  value: 0,
                },
                {
                  label: translation('home.betaFinish.switchSecond'),
                  value: 1,
                },
              ]}
            />
          </div>
          {winners.map((winner, index) => (
            <div className={classes.winnerItem} key={winner?.username}>
              <div
                className={clsx(classes[getColor(index)], classes.winnerLabel)}
              />
              <img
                className={classes.avatar}
                src={winner?.imgUrl}
                alt={winner?.username}
              />
              <Text className={classes.text}>{winner?.username}</Text>
              <Text className={classes.value} variant="b4">
                {winner?.score}
              </Text>
            </div>
          ))}
        </div>
        {/* <div className={classes.howToContainer}>
          <Headline variant="h3">
            {translation('home.betaFinish.howToTitle')}
          </Headline>
          <div className={classes.stepContainer}>
            <Text variant="b3">
              <RewardStepFirst />
            </Text>
            <Text variant="b3">
              {translation('home.betaFinish.stepSecond')}
            </Text>
            <Text variant="b3">
              <RewardStepSecond />
            </Text>
          </div>
          <div className={classes.warningContainer}>
            <div className={classes.warningPoint}>
              <Text color="warning">
                <UIClock24Icon />
              </Text>
              <Text variant="b4">
                {translation('home.betaFinish.warningFirst')}
              </Text>
            </div>
            <div className={classes.warningPoint}>
              <Text color="warning">
                <AlertIcon width={24} height={24} />
              </Text>
              <Text variant="b4">
                {translation('home.betaFinish.warningSecond')}
              </Text>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};
