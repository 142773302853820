import { FC } from 'react';

import useSWR from 'swr';

import { useCheckHistory } from 'hooks';
import { BottomNotification } from 'ui-kit';

import {
  StatisticCard,
  PredictionAd,
  Table,
  WinRateCard,
  GameCard,
  PredictCard,
} from './components';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { PredictCardSkeleton } from './components/PredictCard/PredictCardSkeleton';
import { StatisticSkeleton } from './components/StatisticCard';
import { WinRateSkeleton } from './components/WinRateCard/WinRateSkeleton';
import { usePredictionApi } from './Prediction.model';
import { useStyle } from './Prediction.styles';

export const Prediction: FC = () => {
  const { classes } = useStyle();

  const {
    activePredict,
    statistic,
    balance,
    isLoadingPredict,
    isRefetching,
    refetchGame,
  } = usePredictionApi();
  const { data: user } = useSWR('api/user');
  const { isHistoryFailed } = useCheckHistory();
  const isPrelogin = !user || !user?.steamId || isHistoryFailed;

  return (
    <>
      <div className={classes.predictContainer}>
        <GameCard activePredict={activePredict} />
        {(isLoadingPredict || isRefetching) && <PredictCardSkeleton />}
        {!isLoadingPredict && (
          <div className={classes.gameCard}>
            <PredictCard
              balance={balance?.balance}
              activePredict={activePredict}
              refetchGame={refetchGame}
              isRefetching={isRefetching}
            />
          </div>
        )}
        {!statistic && <WinRateSkeleton />}
        {statistic && (
          <div className={classes.predictBlock}>
            <WinRateCard statistic={statistic} />
          </div>
        )}
        {!statistic && <StatisticSkeleton />}
        {statistic && (
          <div className={classes.winRateBlock}>
            <StatisticCard statistic={statistic} />
          </div>
        )}
      </div>
      <div className={classes.matchesSection}>
        <div className={classes.matchesBlock}>
          <Table />
        </div>

        <div className={classes.predictBanner}>
          <PredictionAd />
        </div>
      </div>
      <BottomNotification />
      {/* {isPrelogin && (
        <img
          src="/images/predictions/prelogin-hero.png"
          alt="Hero"
          className={classes.preloginHero}
        />
      )} */}
    </>
  );
};
