import { makeStyles } from 'theme';

export const useStyle = makeStyles()((theme) => ({
  title: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  stepContainer: {
    marginTop: 72,
    display: 'flex',
    justifyContent: 'center',
  },
  step: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginRight: 64,
  },
  stepImg: {
    width: 200,
  },
  stepTitle: {
    display: 'inline-block',
    maxWidth: 280,
    textAlign: 'center',
  },
  card: {
    marginTop: 48,
    boxShadow:
      '0px 0px 12px 1px #001017, 0px 0px 40px 4px rgba(0, 16, 23, 0.24)',
    borderRadius: 6,
    backdropFilter: 'blur(6px)',
    border: '2px solid rgba(68, 78, 97, 0.4)',
    background:
      'linear-gradient(38.92deg, rgba(22, 28, 40, 0.4) 0%, rgba(22, 28, 40, 0.1) 134.39%)',
    padding: 32,
  },
  articleContainer: {
    display: 'flex',
  },
  cardArticle: {
    marginRight: 44,
    maxWidth: 360,
    '&:last-child': {
      marginRight: 0,
    },
  },
  cardTitle: {
    color: theme.palette.typo.primary,
    fontFamily: 'Ubuntu',
    fontSize: 18,
    fontWeight: 500,
    marginBottom: 16,
  },
  cardDescription: {
    color: theme.palette.typo.system,
    fontSize: 14,
    fontFamily: 'Dm Sans',
  },
  white: {
    color: theme.palette.typo.primary,
    fontWeight: 700,
    display: 'inline',
  },
  gold: {
    color: theme.palette.typo.warning,
    display: 'inline',
  },
  linkContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 40,
    maxHeight: 48,
    boxSizing: 'border-box',
    position: 'relative',
  },
  codeContainer: {
    display: 'flex',
    minWidth: 756,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  link: {
    backgroundColor: theme.palette.control.default.bg,
    borderTop: '1px solid #444E61',
    borderLeft: '1px solid #444E61',
    borderBottom: '1px solid #444E61',
    borderTopLeftRadius: 6,
    borderBottomLeftRadius: 6,
    color: theme.palette.typo.primary,
    width: 'calc(100% - 130px)',
    display: 'flex',
    alignItems: 'center',
    padding: '13px 16px',
    fontSize: 14,
    lineHeight: '20px',
    boxSizing: 'border-box',
  },
  button: {
    width: 130,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  copySuccessful: {
    color: theme.palette.typo.success,
    fontSize: 14,
  },
  copyIcon: {
    color: theme.palette.typo.success,
    marginRight: 10,
  },
  copySuccessfulContainer: {
    display: 'flex',
    position: 'absolute',
    left: 'calc((100% - 756px)/2 + 756px + 18px)',
    top: 12,
    alignItems: 'center',
  },
}));
