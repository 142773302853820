import type { FC } from 'react';

import type { ICommonIconProps } from './types';

export const UserIcon: FC<ICommonIconProps & { isFill?: boolean }> = ({
  width = 24,
  height = 24,
  viewBox = '0 0 24 24',
  className = '',
  style,
}) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M11.8889 2C9.51347 2 7.58781 4.03766 7.58781 6.55123C7.58781 9.06481 9.51347 11.1025 11.8889 11.1025C14.2643 11.1025 16.19 9.06481 16.19 6.55123C16.19 4.03766 14.2643 2 11.8889 2Z"
      fill="currentcolor"
    />
    <path
      d="M7.30107 13.5298C4.92566 13.5298 3 15.5674 3 18.081V19.5232C3 20.4374 3.6261 21.2168 4.47873 21.3641C9.38635 22.212 14.3914 22.212 19.299 21.3641C20.1517 21.2168 20.7778 20.4374 20.7778 19.5232V18.081C20.7778 15.5674 18.8521 13.5298 16.4767 13.5298H16.0857C15.8741 13.5298 15.6639 13.5652 15.4627 13.6347L14.47 13.9777C12.7928 14.5572 10.9849 14.5572 9.30777 13.9777L8.31506 13.6347C8.11391 13.5652 7.90364 13.5298 7.69204 13.5298H7.30107Z"
      fill="currentcolor"
    />
  </svg>
);
