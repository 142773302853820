import type { FC } from 'react';

import type { ICommonIconProps } from './types';

export const PlayIcon: FC<ICommonIconProps> = ({
  color = 'currentColor',
  width = 16,
  height = 16,
  viewBox = '0 0 16 16',
  className = '',
  style,
}) => (
  <svg
    fill="none"
    width={width}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M14.2661 9.51622C15.258 8.74874 15.258 7.25128 14.2661 6.4838C11.2685 4.1644 7.92133 2.33625 4.34979 1.06777L3.69732 0.836032C2.44904 0.392692 1.13053 1.23725 0.961542 2.5258C0.489382 6.12601 0.489382 9.874 0.961541 13.4742C1.13053 14.7628 2.44904 15.6073 3.69731 15.164L4.34979 14.9322C7.92133 13.6638 11.2685 11.8356 14.2661 9.51622Z"
      fill={color}
    />
  </svg>
);
