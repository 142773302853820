import React, { FC, useRef, useState } from 'react';

import clsx from 'clsx';
import { motion } from 'framer-motion';

import { useClickOutside } from 'hooks';
import { ArrowIcon, CloseIcon } from 'icons';
import { Tooltip, Typography } from 'ui-kit';

import { DropdownOptions } from './components';
import { useStyle } from './Dropdown.styles';

export type DropdownSizeType = 'sm' | 'md' | 'l';

export interface IDropdownOption {
  value: number | string;
  label: string;
  imgUrl?: string;
}

export type DropdownPositionType = 'top' | 'bottom';

export interface IDropdownProps {
  tooltip?: string | React.ReactNode;
  title?: string;
  size?: DropdownSizeType;
  placeholder?: string;
  defaultValue?: IDropdownOption;
  options: IDropdownOption[];
  className?: string;
  cross?: boolean;
  filter?: boolean;
  onChange: (number) => void;
  onInputChange?: (param) => void;
  position?: DropdownPositionType;
  value?: IDropdownOption;
  arrow?: boolean;
}

export const Dropdown: FC<IDropdownProps> = ({
  tooltip = '',
  title = '',
  size = 'md',
  placeholder = '',
  options,
  defaultValue = null,
  className,
  cross = false,
  filter = false,
  onChange,
  onInputChange,
  position = 'bottom',
  value = null,
  arrow = true,
}) => {
  const [isOptionsOpen, setIsOptionsOpen] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<IDropdownOption>(
    value || defaultValue
  );
  const ref = useRef(null);

  const { classes } = useStyle({ size, isOpen: isOptionsOpen });
  const { Text } = Typography;

  const onToggleDropdown = (): void =>
    setIsOptionsOpen((prevState) => !prevState);
  const onOptionClick = (option: IDropdownOption): void => {
    onChange(option.value);
    setSelectedOption(option);
  };
  const onOptionClose = (): void => setIsOptionsOpen(false);
  const onClearClick = (e): void => {
    e.stopPropagation();
    onChange('');
    setSelectedOption(defaultValue);
  };

  useClickOutside(ref, onOptionClose);
  return (
    <div className={clsx(className, classes.mainContainer)}>
      {title && (
        <Text variant="b6" color="typoLabel" className={classes.title}>
          {title}
        </Text>
      )}
      <Tooltip
        position={'right'}
        content={tooltip}
        isVisible={isOptionsOpen && !!tooltip}
        type="focus"
        color="blue"
      >
        <div className={classes.dropdown} onClick={onToggleDropdown} ref={ref}>
          {selectedOption?.imgUrl && (
            <img
              src={selectedOption?.imgUrl}
              className={classes.img}
              alt="dota 2 hero"
            />
          )}
          <Text
            variant="b5"
            color={selectedOption ? 'primary' : 'typoPlaceholder'}
          >
            {selectedOption?.label || placeholder}
          </Text>
          {cross && selectedOption && (
            <Text color="typoPlaceholder">
              <button onClick={onClearClick} type="button">
                <CloseIcon className={classes.cross} />
              </button>
            </Text>
          )}
          {arrow && <ArrowIcon className={classes.arrowIcon} />}

          {isOptionsOpen && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.1 }}
            >
              <DropdownOptions
                options={options}
                onOptionClick={onOptionClick}
                onInputChange={onInputChange}
                filter={filter}
                position={position}
              />
            </motion.div>
          )}
        </div>
      </Tooltip>
    </div>
  );
};
