import { FC } from 'react';

import clsx from 'clsx';

import { IWinner } from 'api';
import { EWinnerPlace, removeZeroesAfterComma } from 'helpers';
import { Card, Typography } from 'ui-kit';

import { colorShadowMap, colorTextMap } from '../utils';
import { useStyle } from './WinnerItem.styles';

export const WinnerItem: FC<{
  place: EWinnerPlace;
  winner: IWinner;
  reward: string;
}> = ({ winner, place, reward }) => {
  const { classes } = useStyle();
  const { Headline, Text } = Typography;
  return (
    <Card
      className={classes.winnerItem}
      key={winner.id}
      variant="p16"
      color="bgSecondary"
    >
      <div className={classes.winnerItemContainer}>
        <div
          className={clsx(
            classes[colorTextMap[place]],
            classes.winnersLeftBar,
            classes[colorShadowMap[place]]
          )}
        />
        <Headline variant="h3">
          <span
            className={clsx(
              classes[colorTextMap[place]],
              classes.gradientTextTrophy
            )}
          >
            {place + 1}
          </span>
        </Headline>
        <div className={classes.userInfo}>
          <img
            src={winner.avatarUrl}
            className={classes.winnersIcon}
            alt="user avatar"
          />
          <Text variant="b5"> {winner.username}</Text>
        </div>
        <div
          className={clsx(
            classes[colorTextMap[place]],
            classes.gradientTextTrophy
          )}
        >
          <Headline variant="h3">{`${removeZeroesAfterComma(
            reward
          )} GC`}</Headline>
        </div>
      </div>
    </Card>
  );
};
