import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { ICreatedChallenge } from 'api';
import { EChallengeStatus } from 'consts';
import { Button } from 'ui-kit';

import { EChallengeModals } from '../../ChallengeView.model';
import { useStyle } from './Buttons.styles';

interface IStopRegButton {
  openModal: (EChallengeModals) => void;
  challenge: ICreatedChallenge;
}

export const StopRegButton: FC<IStopRegButton> = ({ openModal, challenge }) => {
  const { t: translation } = useTranslation();
  const { classes } = useStyle();
  const { status } = challenge;
  return (
    <Button
      className={classes.stopRegBtn}
      disabled={status === EChallengeStatus.Disabled}
      onClick={() => {
        openModal(EChallengeModals.Stop);
      }}
    >
      {translation('challengeView.actions.stopRegistration')}
    </Button>
  );
};
