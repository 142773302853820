import { makeStyles } from 'theme';

export const useStyle = makeStyles()((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 32,
    padding: 20,
    width: 540,
  },
  btnContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 20,
  },
}));
