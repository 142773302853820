import { cloneElement } from 'react';

import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import useSWR from 'swr';

import { IUserResponse, getUserGeo } from 'api';
import {
  SwordsIcon,
  UserIcon,
  PredictionIcon,
  TwitterIcon,
  DiscordMiniIcon,
  ParryIcon,
} from 'icons';

import { LanguageSelector } from '../components';
import { Typography } from '../ui-kit';
import { useStyle } from './Layout.styles';

const COMMON_BLOCKS = [
  { icon: <SwordsIcon />, to: '/challenges' },
  { icon: <ParryIcon />, to: '/parries' },
  // { icon: <PredictionIcon />, to: '/predictions' },
];
const AUTH_BLOCKS = [
  { icon: <SwordsIcon />, to: '/challenges' },
  { icon: <PredictionIcon />, to: '/predictions' },
  { icon: <UserIcon />, to: '/profile' },
  { icon: <ParryIcon />, to: '/parries' },
];

export function Navigation(): JSX.Element {
  const { t: translation } = useTranslation();
  const { classes } = useStyle({
    predictionLabel: translation('navigation.prediction'),
    challengeLabel: translation('navigation.challenge'),
    parryLabel: translation('navigation.parry'),
    profileLabel: translation('navigation.profile'),
  });
  const { data: user } = useSWR<IUserResponse>('api/user');
  const { data: geo } = useSWR('api/geo', getUserGeo);
  const isLogin = !!user?.id;
  const BLOCKS = isLogin ? AUTH_BLOCKS : COMMON_BLOCKS;
  const { Text } = Typography;
  return (
    <div className={classes.navigation}>
      {BLOCKS.map((block) => (
        <NavLink
          key={block.to}
          to={block.to}
          className={({ isActive }) =>
            isActive
              ? clsx(
                  isLogin ? classes.navIcon : classes.navIconCommon,
                  classes.navIconActive
                )
              : isLogin
              ? classes.navIcon
              : classes.navIconCommon
          }
        >
          {({ isActive }) => {
            return cloneElement(block.icon, { isFill: isActive });
          }}
        </NavLink>
      ))}
      <div className={classes.iconContainer}>
        {
          /*geo?.country !== 'RU' && (*/
          <a href="https://twitter.com/greed_ltd" className={classes.icon}>
            <TwitterIcon />
          </a>
          /*)*/
        }
        <a href="https://discord.gg/7fKEWhnh4x" className={classes.icon}>
          <DiscordMiniIcon />
        </a>
        {/* <a href="https://t.me/GameGreed" className={classes.icon}>
          <TelegramIcon />
        </a> */}
      </div>
      <div className={classes.earlyAccess}>
        <Text variant="b7">Early</Text>
        <Text variant="b7">Access</Text>
      </div>
      <LanguageSelector className={classes.languageContainer} />
    </div>
  );
}
