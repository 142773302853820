import { makeStyles } from 'theme';

export const useStyle = makeStyles()((theme) => ({
  timer: {
    padding: '10px 12px',
    gap: 8,
    border: `1px solid ${theme.palette.bg.border}`,
    borderRadius: 6,
    width: 'fit-content',
    ...theme.layouts.flexCenter,
  },
}));
