import { Ref } from 'react';

import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';

import { Cross } from 'ui-kit';

import { useStyle } from './Modal.styles';

interface IProps {
  children: React.ReactNode;
  isOpen: boolean;
  onClose?: () => void;
  className?: string;
  ref?: Ref<HTMLDivElement>;
}

export function Modal({
  children,
  onClose,
  isOpen,
  ref,
  className,
}: IProps): JSX.Element {
  const { classes } = useStyle();
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          className={classes.animationContainer}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
        >
          <motion.div
            className={clsx(classes.modalContainer, className)}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            ref={ref}
          >
            {onClose && <Cross className={classes.closeIcon} />}
            {children}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}
