import { FC, useContext } from 'react';

import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';

import { ICreatedParry, IListedParry, IUserResponse } from 'api';
import { ModalContext } from 'components';
import {
  dota2ItemsMap,
  EParryModals,
  EParryStatus,
  PARRY_FORM_DATA_KEY,
} from 'consts';
import { correctAbsoluteUrl } from 'helpers';
import {
  LikeIcon,
  EditIcon,
  FlagOwnerIcon,
  FlagParticipantIcon,
  FlagPasswordIcon,
  TrashIcon,
  DislikeIcon,
} from 'icons';
import { Button, Card, Dividers, Typography } from 'ui-kit';

import { ParryInfo, ParryRules, ParryTags } from './components';
import { useStyle } from './ParryCard.styles';

interface IParryCardProps {
  disabled?: boolean;
  isShowDuration?: boolean;
  parry: IListedParry;
  isWorkshop?: boolean;
}

export const ParryCard: FC<IParryCardProps> = ({
  parry,
  isShowDuration,
  isWorkshop,
}) => {
  const {
    image,
    title,
    status,
    id,
    isOwner,
    isParticipant,
    hasPassword,
    toWin,
    toLose,
  } = parry;
  const isDraft = status === EParryStatus.Draft;
  const isStarted = status === EParryStatus.Started;
  const { classes } = useStyle({
    imageUrl: correctAbsoluteUrl(image),
    isDraft,
  });
  const { Text } = Typography;
  const navigate = useNavigate();
  const { t: translation } = useTranslation();
  const { data: user } = useSWR<IUserResponse>('api/user');
  const isLogin = !!user?.id;

  const {
    deleteParryModal: { openDelete },
    parryConfirmation: {
      setParryModalMode,
      setParryId,
      setParryConfirmationValue,
      setParryWinning,
    },
  } = useContext(ModalContext);
  const onCardClick = (): void => {
    if (status === EParryStatus.Active || status === EParryStatus.Disabled) {
      navigate(`/parries/${id}`);
    }
    if (status === EParryStatus.Done) {
      navigate(`/parries/${id}?mode=history`);
    }
  };

  const onEditClick = (): void => {
    const items = parry?.items.map((item) => ({
      ...item,
      ...dota2ItemsMap[item.item],
      url: `https://media.steampowered.com${dota2ItemsMap[item.item]?.img}`,
    }));
    localStorage.setItem(
      PARRY_FORM_DATA_KEY,
      JSON.stringify({ ...parry, items, isFetched: true })
    );
    navigate('/parry');
  };

  const onDeleteClick = (): void => {
    openDelete(id);
  };

  const onOpenModalClick = (mode: EParryModals): void => {
    setParryModalMode(mode);
    setParryId(parry?.id);
    setParryConfirmationValue(+parry.entryCost);
  };

  const onWinClick = (): void => {
    setParryWinning(true);
    onOpenModalClick(EParryModals.EntryCost);
  };

  const onLoseClick = (): void => {
    setParryWinning(false);
    onOpenModalClick(EParryModals.EntryCost);
  };

  const { VerticalDivider } = Dividers;
  const withDivider = parry?.options?.length > 1;
  return (
    <Card
      className={classes.parryCard}
      color="bgSecondary"
      onClick={onCardClick}
    >
      {isDraft && (
        <div className={classes.draftButtons}>
          <Button onClick={onEditClick} contentType="icon">
            <EditIcon />
          </Button>
          <Button onClick={onDeleteClick} contentType="icon" color="secondary">
            <TrashIcon />
          </Button>
        </div>
      )}
      {hasPassword && <FlagPasswordIcon className={classes.flagIcon} />}
      {isParticipant && <FlagParticipantIcon className={classes.flagIcon} />}
      {isOwner && <FlagOwnerIcon className={classes.flagIcon} />}
      <div className={classes.parryCardContent}>
        <Text variant="b1">{title}</Text>
        <ParryInfo parry={parry} isShowDuration={isShowDuration} />
        <div className={classes.dataAndTagContainer}>
          <ParryRules parry={parry} />
          {withDivider && <VerticalDivider />}
          <ParryTags parry={parry} />
        </div>
      </div>
      {!isWorkshop && (
        <div className={classes.parryFooter}>
          <Text variant="b3">
            {translation('parries-list.parryCard.makeYourChoice')}:
          </Text>
          <div className={classes.parryFooterControls}>
            <div className={classes.parryFooterControl}>
              <Button
                buttonSize="sm"
                color="secondary"
                className={classes.winLoseButton}
                disabled={
                  isParticipant || isOwner || isDraft || !isLogin || isStarted
                }
                onClick={(e) => {
                  onWinClick();
                  e.stopPropagation();
                }}
              >
                <span className={classes.winLoseButtonContent}>
                  <LikeIcon width={12} height={12} viewBox="0 0 12 12" />
                  <Text variant="b5">
                    {translation('parries-list.parryCard.win')}
                  </Text>
                </span>
              </Button>
              <div className={classes.parryFooterBetCount}>
                <Text variant="b7">
                  {toWin} {translation('parries-list.parryCard.bets')}
                </Text>
              </div>
            </div>
            <div>
              <Text variant="b5">OR</Text>
            </div>
            <div className={classes.parryFooterControl}>
              <Button
                buttonSize="sm"
                color="secondary"
                className={classes.winLoseButton}
                disabled={
                  isParticipant || isOwner || isDraft || !isLogin || isStarted
                }
                onClick={(e) => {
                  onLoseClick();
                  e.stopPropagation();
                }}
              >
                <span className={classes.winLoseButtonContent}>
                  <DislikeIcon width={12} height={12} viewBox="0 0 12 12" />
                  <Text variant="b5">
                    {translation('parries-list.parryCard.lose')}
                  </Text>
                </span>
              </Button>
              <div className={classes.parryFooterBetCount}>
                <Text variant="b7">
                  {toLose} {translation('parries-list.parryCard.bets')}
                </Text>
              </div>
            </div>
          </div>
        </div>
      )}
    </Card>
  );
};
