import { FC, useState } from 'react';

import { ChallengeCard } from 'components';
import { BottomNotification, Paginator } from 'ui-kit';

import { EChallengeViewMode, LIMIT_OPTIONS } from '../Challenges.model';
import { Filters, ChallengesNotFound } from '../components';
import { ChallengeCardSkeleton } from './ChallengeCardSkeleton';
import { useChallengeListFilter } from './ChallengeList.model';
import { useStyle } from './ChallengesList.styles';

const ChallengeListSkeleton: FC = () => (
  <>
    <ChallengeCardSkeleton />
    <ChallengeCardSkeleton />
    <ChallengeCardSkeleton />
    <ChallengeCardSkeleton />
    <ChallengeCardSkeleton />
    <ChallengeCardSkeleton />
  </>
);

export const ChallengeList: FC = () => {
  const [itemPerPage, setItemPerPage] = useState(LIMIT_OPTIONS[0]);
  const [currentPage, setCurrentPage] = useState(1);

  const { isLoading, filters, changeFilter, activeChallenges } =
    useChallengeListFilter(currentPage, itemPerPage);
  const { classes } = useStyle();
  return (
    <div className={classes.container}>
      <Filters
        changeFilter={changeFilter}
        filters={filters}
        mode={EChallengeViewMode.AllActive}
      />
      <div className={classes.listContainer}>
        {isLoading && <ChallengeListSkeleton />}
        {!isLoading && activeChallenges?.total > 0 ? (
          <>
            {activeChallenges?.result.map((item) => (
              <ChallengeCard challenge={item} key={item.id} />
            ))}
          </>
        ) : (
          <ChallengesNotFound title={filters.title} />
        )}
      </div>
      {activeChallenges?.total > 0 && (
        <Paginator
          className={classes.paginator}
          currentPage={currentPage}
          itemsPerPage={itemPerPage}
          itemsTotal={activeChallenges.total}
          setCurrentPage={setCurrentPage}
          setItemsPerPage={setItemPerPage}
          limitOptions={LIMIT_OPTIONS}
          isMarkedActive
          showSizeChanger
          hideArrow
        />
      )}
      <BottomNotification />
    </div>
  );
};
