import { makeStyles } from 'theme';

export const useStyle = makeStyles()((theme) => ({
  container: {
    ...theme.layouts.flexJCSpaceBetween,
    marginTop: '2.5rem',
  },
  filterInput: {
    width: '450px',
  },
  createButton: {
    backgroundColor: theme.palette.typo.link,
    marginRight: 70,
  },
  roleContainer: {
    ...theme.layouts.flexJCSpaceBetween,
    marginTop: '1rem',
    height: 73,
  },
  roleTitle: {
    ...theme.fontStyles.text12,
    color: theme.palette.typo.system,
    marginBottom: '0.5rem',
  },
  roleSelect: {
    width: 480,
  },
  parryNumberContainer: {
    borderRadius: '0.375rem',
    padding: '0.5rem 1.25rem 0.5rem 1.5rem',
    width: 220,
    backgroundImage:
      'linear-gradient(to right top, rgba(255,255,255,0.2), rgba(255,255,255,0.1))',
  },
  parryInfo: {
    ...theme.layouts.flexJCSpaceBetween,
  },
  line: {
    height: 1,
    backgroundColor: theme.palette.bg.border,
    width: '100%',
    marginTop: '0.25rem',
    marginBottom: '0.25rem',
  },
  additionalFilterContainer: {
    display: 'flex',
    color: theme.palette.bg.border,
    marginTop: '2rem',
  },
  addSubContainer: {
    display: 'flex',
  },
  settingContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  subTitle: {
    marginBottom: '0.5rem',
    display: 'inline-block',
  },
  rangeContainer: {
    ...theme.layouts.flexAICenter,
    width: 124,
    marginLeft: 20,
    ':first-of-type': {
      marginLeft: 0,
    },
  },
  numberInput: {
    marginLeft: '0.5rem',
    width: 88,
    height: 36,
  },
  rewardContainer: {
    display: 'flex',
    marginLeft: '2.5rem',
  },
  rewardSubContainer: {
    display: 'flex',
    paddingTop: '0.5rem',
  },
  rewardInputContainer: {
    ...theme.layouts.flexAICenter,
    width: 124,
  },
  heroContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '5rem',
  },
  heroSelect: {
    width: 240,
    marginLeft: 90,
  },
  checkboxContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    marginLeft: '2.5rem',
    paddingBottom: '0.5rem',
  },
  checkBox: {
    ':last-of-type': {
      marginLeft: '2.5rem',
    },
  },
}));
