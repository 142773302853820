import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { IMatch } from 'api';
import { Typography } from 'ui-kit';

export const TableEarned: FC<{ match: IMatch }> = ({ match }) => {
  const { Text } = Typography;
  const { t: translation } = useTranslation();
  if (match.earned && match.earned < 0) {
    return (
      <Text variant="b3" color="red">
        {match.earned} GC
      </Text>
    );
  } else if (match.earned && match.earned > 0) {
    return (
      <Text variant="b3" color="yellow">
        +{match.earned} GC
      </Text>
    );
  } else {
    return <Text variant="b3">{translation('predictions.table.na')}</Text>;
  }
};
