import type { FC } from 'react';

import type { ICommonIconProps } from './types';

export const EyeIcon: FC<ICommonIconProps> = ({
  color = 'currentColor',
  width = 20,
  height = 14,
  viewBox = '0 0 20 14',
  className = '',
  style,
}) => (
  <svg
    fill="none"
    width={width}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M10 4.75C8.75736 4.75 7.75 5.75736 7.75 7C7.75 8.24264 8.75736 9.25 10 9.25C11.2426 9.25 12.25 8.24264 12.25 7C12.25 5.75736 11.2426 4.75 10 4.75Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 0.5C7.38223 0.5 5.02801 1.55139 3.33162 2.85335C2.48232 3.50519 1.78544 4.22913 1.29649 4.93368C0.816861 5.62481 0.5 6.35153 0.5 7C0.5 7.64847 0.816861 8.37519 1.29649 9.06632C1.78544 9.77087 2.48232 10.4948 3.33162 11.1466C5.02801 12.4486 7.38223 13.5 10 13.5C12.6178 13.5 14.972 12.4486 16.6684 11.1466C17.5177 10.4948 18.2146 9.77087 18.7035 9.06632C19.1831 8.37519 19.5 7.64847 19.5 7C19.5 6.35153 19.1831 5.62481 18.7035 4.93368C18.2146 4.22913 17.5177 3.50519 16.6684 2.85335C14.972 1.55139 12.6178 0.5 10 0.5ZM6.25 7C6.25 4.92893 7.92893 3.25 10 3.25C12.0711 3.25 13.75 4.92893 13.75 7C13.75 9.07107 12.0711 10.75 10 10.75C7.92893 10.75 6.25 9.07107 6.25 7Z"
      fill={color}
    />
  </svg>
);
