import { makeStyles } from 'theme';

export const useStyle = makeStyles()(() => ({
  title: {
    display: 'inline-block',
    width: '100%',
  },
  description: {
    width: 360,
    marginTop: '1rem',
    marginBottom: 30,
    display: 'inline-block',
  },
  intro: {
    position: 'relative',
    padding: 40,
    background:
      'linear-gradient(38.92deg, rgba(22, 28, 40, 0.4) 0%, rgba(22, 28, 40, 0.1) 134.39%)',
    border: '2px solid #2B2C2EA3',
    backdropFilter: 'blur(6px)',
    borderRadius: 6,
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 64,
    boxShadow:
      '0px 0px 12px 1px #001017, 0px 0px 40px 4px rgba(0, 16, 23, 0.24)',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  predictionsTitle: {
    marginLeft: 0,
    marginRight: 'auto',
    marginBottom: 24,
    display: 'inline-block',
    width: '100%',
  },
  img: {
    position: 'absolute',
    right: 0,
    bottom: 0,
  },
}));
