import type { FC } from 'react';

import type { ICommonIconProps } from './types';

export const TrophyIcon: FC<ICommonIconProps & { isFill?: boolean }> = ({
  color = 'currentColor',
  width = 20,
  height = 20,
  viewBox = '0 0 20 20',
  className = '',
  style,
  isFill,
}) => (
  <svg
    fill="none"
    width={width}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
    color={color}
  >
    {isFill ? (
      <>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 0.916667C3 0.80616 3.0439 0.700179 3.12204 0.622039C3.20018 0.543899 3.30616 0.5 3.41667 0.5H12.5833C12.6938 0.5 12.7998 0.543899 12.878 0.622039C12.9561 0.700179 13 0.80616 13 0.916667V1.33333H15.0833C15.1938 1.33333 15.2998 1.37723 15.378 1.45537C15.4561 1.53351 15.5 1.63949 15.5 1.75V4.25C15.5 4.80253 15.2805 5.33244 14.8898 5.72314C14.4991 6.11384 13.9692 6.33333 13.4167 6.33333H12.7154C12.0733 8.15 10.4113 9.485 8.41667 9.64958V12.1667H11.3333C11.4438 12.1667 11.5498 12.2106 11.628 12.2887C11.7061 12.3668 11.75 12.4728 11.75 12.5833V15.0833C11.75 15.1938 11.7061 15.2998 11.628 15.378C11.5498 15.4561 11.4438 15.5 11.3333 15.5H4.66667C4.55616 15.5 4.45018 15.4561 4.37204 15.378C4.2939 15.2998 4.25 15.1938 4.25 15.0833V12.5833C4.25 12.4728 4.2939 12.3668 4.37204 12.2887C4.45018 12.2106 4.55616 12.1667 4.66667 12.1667H7.58333V9.64958C5.58917 9.485 3.92667 8.15 3.28458 6.33333H2.58333C2.0308 6.33333 1.5009 6.11384 1.11019 5.72314C0.719493 5.33244 0.5 4.80253 0.5 4.25V1.75C0.5 1.63949 0.543899 1.53351 0.622039 1.45537C0.700179 1.37723 0.80616 1.33333 0.916667 1.33333H3V0.916667ZM12.1667 4.66667V1.33333H3.83333V4.66667C3.83333 6.96792 5.69875 8.83333 8 8.83333C10.3013 8.83333 12.1667 6.96792 12.1667 4.66667ZM13 2.16667V5.5H13.4167C13.7482 5.5 14.0661 5.3683 14.3005 5.13388C14.535 4.89946 14.6667 4.58152 14.6667 4.25V2.16667H13ZM1.33333 2.16667H3V5.5H2.58333C2.25181 5.5 1.93387 5.3683 1.69945 5.13388C1.46503 4.89946 1.33333 4.58152 1.33333 4.25V2.16667ZM5.08333 13V14.6667H10.9167V13H5.08333Z"
          fill="currentColor"
        />
        <path
          d="M12.1667 4.66667V1.33333H3.83333V4.66667C3.83333 6.96792 5.69875 8.83333 8 8.83333C10.3013 8.83333 12.1667 6.96792 12.1667 4.66667Z"
          fill="currentColor"
        />
        <path d="M5.08333 13V14.6667H10.9167V13H5.08333Z" fill="currentColor" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 0.916667C3 0.80616 3.0439 0.700179 3.12204 0.622039C3.20018 0.543899 3.30616 0.5 3.41667 0.5H12.5833C12.6938 0.5 12.7998 0.543899 12.878 0.622039C12.9561 0.700179 13 0.80616 13 0.916667V1.33333H15.0833C15.1938 1.33333 15.2998 1.37723 15.378 1.45537C15.4561 1.53351 15.5 1.63949 15.5 1.75V4.25C15.5 4.80253 15.2805 5.33244 14.8898 5.72314C14.4991 6.11384 13.9692 6.33333 13.4167 6.33333H12.7154C12.0733 8.15 10.4113 9.485 8.41667 9.64958V12.1667H11.3333C11.4438 12.1667 11.5498 12.2106 11.628 12.2887C11.7061 12.3668 11.75 12.4728 11.75 12.5833V15.0833C11.75 15.1938 11.7061 15.2998 11.628 15.378C11.5498 15.4561 11.4438 15.5 11.3333 15.5H4.66667C4.55616 15.5 4.45018 15.4561 4.37204 15.378C4.2939 15.2998 4.25 15.1938 4.25 15.0833V12.5833C4.25 12.4728 4.2939 12.3668 4.37204 12.2887C4.45018 12.2106 4.55616 12.1667 4.66667 12.1667H7.58333V9.64958C5.58917 9.485 3.92667 8.15 3.28458 6.33333H2.58333C2.0308 6.33333 1.5009 6.11384 1.11019 5.72314C0.719493 5.33244 0.5 4.80253 0.5 4.25V1.75C0.5 1.63949 0.543899 1.53351 0.622039 1.45537C0.700179 1.37723 0.80616 1.33333 0.916667 1.33333H3V0.916667ZM12.1667 4.66667V1.33333H3.83333V4.66667C3.83333 6.96792 5.69875 8.83333 8 8.83333C10.3013 8.83333 12.1667 6.96792 12.1667 4.66667ZM13 2.16667V5.5H13.4167C13.7482 5.5 14.0661 5.3683 14.3005 5.13388C14.535 4.89946 14.6667 4.58152 14.6667 4.25V2.16667H13ZM1.33333 2.16667H3V5.5H2.58333C2.25181 5.5 1.93387 5.3683 1.69945 5.13388C1.46503 4.89946 1.33333 4.58152 1.33333 4.25V2.16667ZM5.08333 13V14.6667H10.9167V13H5.08333Z"
          stroke="currentColor"
          strokeWidth="0.5"
        />
        <path
          d="M12.1667 4.66667V1.33333H3.83333V4.66667C3.83333 6.96792 5.69875 8.83333 8 8.83333C10.3013 8.83333 12.1667 6.96792 12.1667 4.66667Z"
          stroke="currentColor"
          strokeWidth="0.5"
        />
        <path
          d="M5.08333 13V14.6667H10.9167V13H5.08333Z"
          stroke="currentColor"
          strokeWidth="0.5"
        />
      </>
    ) : (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 1.5C4 1.36739 4.05268 1.24021 4.14645 1.14645C4.24021 1.05268 4.36739 1 4.5 1H15.5C15.6326 1 15.7598 1.05268 15.8536 1.14645C15.9473 1.24021 16 1.36739 16 1.5V2H18.5C18.6326 2 18.7598 2.05268 18.8536 2.14645C18.9473 2.24021 19 2.36739 19 2.5V5.5C19 6.16304 18.7366 6.79893 18.2678 7.26777C17.7989 7.73661 17.163 8 16.5 8H15.6585C14.888 10.18 12.8935 11.782 10.5 11.9795V15H14C14.1326 15 14.2598 15.0527 14.3536 15.1464C14.4473 15.2402 14.5 15.3674 14.5 15.5V18.5C14.5 18.6326 14.4473 18.7598 14.3536 18.8536C14.2598 18.9473 14.1326 19 14 19H6C5.86739 19 5.74021 18.9473 5.64645 18.8536C5.55268 18.7598 5.5 18.6326 5.5 18.5V15.5C5.5 15.3674 5.55268 15.2402 5.64645 15.1464C5.74021 15.0527 5.86739 15 6 15H9.5V11.9795C7.107 11.782 5.112 10.18 4.3415 8H3.5C2.83696 8 2.20107 7.73661 1.73223 7.26777C1.26339 6.79893 1 6.16304 1 5.5V2.5C1 2.36739 1.05268 2.24021 1.14645 2.14645C1.24021 2.05268 1.36739 2 1.5 2H4V1.5ZM15 6V2H5V6C5 8.7615 7.2385 11 10 11C12.7615 11 15 8.7615 15 6ZM16 3V7H16.5C16.8978 7 17.2794 6.84196 17.5607 6.56066C17.842 6.27936 18 5.89782 18 5.5V3H16ZM2 3H4V7H3.5C3.10218 7 2.72064 6.84196 2.43934 6.56066C2.15804 6.27936 2 5.89782 2 5.5V3ZM6.5 16V18H13.5V16H6.5Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.5"
      />
    )}
  </svg>
);
