import { Home } from './Home';
import { Product } from './Product';

export const HOME = {
  name: 'home',
  routes: [
    {
      title: 'header.tabs.home',
      path: '/home',
      element: <Home />,
    },
    // {
    //   title: 'header.tabs.product',
    //   path: '/home/product',
    //   element: <Product />,
    // },
  ],
};
