import { makeStyles } from 'theme';

export const useStyle = makeStyles()((theme) => ({
  ...theme.layouts,
  ...theme.fontStyles,

  steamModal: {
    ...theme.layouts.flexAICenter,
    flexDirection: 'column',
    width: '736px',
    paddingBottom: '40px',
  },

  steamModalBg: {
    backgroundImage: "url('/images/modals/verify-account-modal.png')",
    width: '736px',
    height: '320px',
    marginBottom: '48px',
  },

  steamModalText: {
    width: '400px',
    color: theme.palette.typo.primary,
  },

  steamModalTitle: {
    display: 'inline-block',
    width: '100%',
    marginBottom: '16px',
    textAlign: 'center',
  },

  steamModalDescr: {
    display: 'inline-block',
    marginBottom: '32px',
  },

  steamBtn: {
    width: '100%',
    marginBottom: '16px',
  },

  closeBtn: {
    width: '100%',
  },
}));
