import { makeStyles } from 'theme';

export const useStyle = makeStyles()((theme) => ({
  card: {
    position: 'relative',
    width: 158,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'flex-start',
  },
  icon: {
    position: 'absolute',
    top: 12,
    right: 16,
  },
  termContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 20,
    width: 870,
    marginTop: 20,
    marginBottom: 50,
  },
}));
