import { FC, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';

import { Typography } from 'ui-kit';

import { ReferralCard } from './components';
import { useStyle } from './Referral.styles';

export const Referral: FC = () => {
  const { classes } = useStyle();
  const { t: translation } = useTranslation();
  const { Headline } = Typography;
  const { error } = useSWR('api/user');
  const navigate = useNavigate();
  useEffect(() => {
    if (error?.status === 403) {
      navigate('/home');
    }
  }, [error]);
  return (
    <div>
      <Headline className={classes.title}>
        {translation('referral.title')}
      </Headline>
      <div className={classes.stepContainer}>
        <div className={classes.step}>
          <img
            className={classes.stepImg}
            src="/images/profile/hero-1.png"
            alt="Dota 2 hero"
          />
          <Headline variant="h3" className={classes.stepTitle}>
            {translation('referral.stepOne')}
          </Headline>
        </div>
        <div className={classes.step}>
          <img
            className={classes.stepImg}
            src="/images/profile/hero-2.png"
            alt="Dota 2 hero"
          />
          <Headline variant="h3" className={classes.stepTitle}>
            {translation('referral.stepTwo')}
          </Headline>
        </div>
        <div className={classes.step}>
          <img
            className={classes.stepImg}
            src="/images/profile/hero-3.png"
            alt="Dota 2 hero"
          />
          <Headline variant="h3" className={classes.stepTitle}>
            {translation('referral.stepThree')}
          </Headline>
        </div>
      </div>
      <ReferralCard />
    </div>
  );
};
