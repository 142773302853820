import { EWinnerPlace } from 'helpers';
import { ColorVariantType } from 'icons';

export const colorMap: Record<EWinnerPlace, ColorVariantType> = {
  [EWinnerPlace.FIRST]: 'gold',
  [EWinnerPlace.SECOND]: 'silver',
  [EWinnerPlace.THIRD]: 'bronze',
};
export const colorTextMap = {
  [EWinnerPlace.FIRST]: 'gradient-text-gold',
  [EWinnerPlace.SECOND]: 'gradient-text-silver',
  [EWinnerPlace.THIRD]: 'gradient-text-bronze',
};

export const colorShadowMap = {
  [EWinnerPlace.FIRST]: 'gold-shadow',
  [EWinnerPlace.SECOND]: 'silver-shadow',
  [EWinnerPlace.THIRD]: 'bronze-shadow',
};
export const placeMap = ['st', 'nd', 'rd'];
