import { FC } from 'react';

import { ICreatedParry } from 'api';
import { EDistribution } from 'consts';
import { calcReward } from 'helpers';

import { useStyle } from '../../ParryView.styles';
import { WinnerEqual } from './WinnerEqual';
import { WinnerEqualList } from './WinnerEqualList';
import { WinnerItem } from './WinnerItem/WinnerItem';
import { WinnerPlaceholder } from './WinnerPlaceholder';

interface IWinnersProps {
  parry: ICreatedParry;
}

const winnerTemplate = {
  [EDistribution.EqualReward]: [null],
  [EDistribution.FirstToWin]: [null],
  [EDistribution.ThreeParticipants]: [null, null, null],
};

export const WinnersList: FC<IWinnersProps> = ({
  parry: { reward, distribution, winnerCount, winners },
}) => {
  const winnersWithTemplate = winnerTemplate[distribution];
  winnersWithTemplate.forEach((_, index) => {
    if (winners) {
      winnersWithTemplate[index] = winners[index] ?? null;
    }
  });

  const { classes } = useStyle();
  const rewardList = winnersWithTemplate.map((_, index) =>
    calcReward(distribution, reward, index, winnerCount)
  );
  return (
    <div className={classes.winnersListContainer}>
      {distribution !== EDistribution.EqualReward && (
        <>
          {winnersWithTemplate.map((winner, index) =>
            winner ? (
              <WinnerItem
                place={index}
                winner={winner}
                key={winner.id}
                reward={rewardList[index]}
              />
            ) : (
              <WinnerPlaceholder
                key={index}
                place={index}
                reward={rewardList[index]}
              />
            )
          )}
        </>
      )}

      {distribution === EDistribution.EqualReward && (
        <>
          {winners?.length ? (
            <>
              <WinnerEqual
                reward={reward / winnerCount}
                winnerCount={winnerCount}
                winnersActual={winners.length}
              />
              <WinnerEqualList winners={winners} />
            </>
          ) : (
            <WinnerEqual
              reward={reward / winnerCount}
              winnerCount={winnerCount}
              winnersActual={0}
            />
          )}
        </>
      )}
    </div>
  );
};
