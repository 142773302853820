import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import {
  dota2Items,
  EBasicDota2ItemTypes,
  EDota2ItemTypes,
  IItem,
} from 'consts';

import { useStyle } from './ItemTable.styles';

interface IBasicItemsProps {
  onItemClick: (item: IItem) => void;
}

export const BasicItems: FC<IBasicItemsProps> = ({ onItemClick }) => {
  const { classes } = useStyle();
  const { t: translation } = useTranslation();
  return (
    <>
      <div className={classes.categoryContainer}>
        <div className={classes.categoryTitle}>
          {translation(
            'challenge.form.heroItemBuild.items.table.basic.consumable'
          )}
        </div>
        {dota2Items[EDota2ItemTypes.Basic][EBasicDota2ItemTypes.Consumable].map(
          (item) => (
            <img
              className={classes.item}
              key={item.id}
              title={item.dname}
              src={`https://media.steampowered.com${item.img}`}
              alt={item.dname}
              onClick={() => onItemClick(item)}
            />
          )
        )}
      </div>
      <div className={classes.categoryContainer}>
        <div className={classes.categoryTitle}>
          {translation(
            'challenge.form.heroItemBuild.items.table.basic.attribute'
          )}
        </div>
        {dota2Items[EDota2ItemTypes.Basic][EBasicDota2ItemTypes.Attribute].map(
          (item) => (
            <img
              className={classes.item}
              title={item.dname}
              key={item.id}
              src={`https://media.steampowered.com${item.img}`}
              alt={item.dname}
              onClick={() => onItemClick(item)}
            />
          )
        )}
      </div>
      <div className={classes.categoryContainer}>
        <div className={classes.categoryTitle}>
          {translation(
            'challenge.form.heroItemBuild.items.table.basic.equipment'
          )}
        </div>
        {dota2Items[EDota2ItemTypes.Basic][EBasicDota2ItemTypes.Equipment].map(
          (item) => (
            <img
              className={classes.item}
              key={item.id}
              title={item.dname}
              src={`https://media.steampowered.com${item.img}`}
              alt={item.dname}
              onClick={() => onItemClick(item)}
            />
          )
        )}
      </div>
      <div className={classes.categoryContainer}>
        <div className={classes.categoryTitle}>
          {translation('challenge.form.heroItemBuild.items.table.basic.misc')}
        </div>
        {dota2Items[EDota2ItemTypes.Basic][EBasicDota2ItemTypes.Misc].map(
          (item) => (
            <img
              className={classes.item}
              key={item.id}
              title={item.dname}
              src={`https://media.steampowered.com${item.img}`}
              alt={item.dname}
              onClick={() => onItemClick(item)}
            />
          )
        )}
      </div>
      <div className={classes.categoryContainer}>
        <div className={classes.categoryTitle}>
          {translation('challenge.form.heroItemBuild.items.table.basic.secret')}
        </div>
        {dota2Items[EDota2ItemTypes.Basic][EBasicDota2ItemTypes.Secret].map(
          (item) => (
            <img
              className={classes.item}
              key={item.id}
              title={item.dname}
              src={`https://media.steampowered.com${item.img}`}
              alt={item.dname}
              onClick={() => onItemClick(item)}
            />
          )
        )}
      </div>
    </>
  );
};
