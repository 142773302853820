import { TFunction } from 'react-i18next';

export enum ETerm {
  HeroKills,
  Deaths,
  Assists,
  CreepKills,
  CreepDenies,
  BuildingDamage,
}

export interface IOptionTerm {
  value: ETerm;
  label: string;
}
export const OPTIONS_TERM = (
  translation: TFunction<'translation', undefined>
): IOptionTerm[] => [
  {
    value: ETerm.HeroKills,
    label: translation('challenge.form.gameOptions.kills'),
  },
  {
    value: ETerm.Deaths,
    label: translation('challenge.form.gameOptions.deaths'),
  },
  {
    value: ETerm.Assists,
    label: translation('challenge.form.gameOptions.assists'),
  },
  {
    value: ETerm.CreepKills,
    label: translation('challenge.form.gameOptions.lastHit'),
  },
  {
    value: ETerm.CreepDenies,
    label: translation('challenge.form.gameOptions.denies'),
  },
  {
    value: ETerm.BuildingDamage,
    label: translation('challenge.form.gameOptions.towersDamage'),
  },
];

export const MAX_TERM_VALUES = {
  [ETerm.HeroKills]: 999,
  [ETerm.Deaths]: 999,
  [ETerm.Assists]: 999,
  [ETerm.CreepKills]: 9999,
  [ETerm.CreepDenies]: 9999,
  [ETerm.BuildingDamage]: 99999,
};

export enum EMainTerm {
  Win,
  Lose,
}

interface IOptionMainTerm {
  value: EMainTerm;
  label: string;
}

export const OPTIONS_MAIN_TERM: IOptionMainTerm[] = [
  {
    value: EMainTerm.Win,
    label: 'challenge.form.gameOptions.win',
  },
  {
    value: EMainTerm.Lose,
    label: 'challenge.form.gameOptions.lose',
  },
];

export enum ECondition {
  MoreOrEqual,
  LessOrEqual,
  Equal,
}

interface IOptionCondition {
  value: ECondition;
  label: string;
}

export const OPTIONS_CONDITION = (
  translation: TFunction<'translation', undefined>
): IOptionCondition[] => [
  {
    value: ECondition.MoreOrEqual,
    label: translation('challenge.form.gameOptions.moreOrEqual'),
  },
  {
    value: ECondition.Equal,
    label: translation('challenge.form.gameOptions.equal'),
  },
  {
    value: ECondition.LessOrEqual,
    label: translation('challenge.form.gameOptions.lessOrEqual'),
  },
];

export enum EDistribution {
  FirstToWin,
  ThreeParticipants,
  EqualReward,
}

export enum EChallengeStatus {
  Draft = 'draft',
  Active = 'active',
  Done = 'done',
  Disabled = 'disabled',
}

interface IOptionDistribution {
  value: EDistribution;
  label: string;
}

export const OPTIONS_DISTRIBUTION = (
  translation: TFunction<'translation', undefined>
): IOptionDistribution[] => [
  {
    value: EDistribution.FirstToWin,
    label: translation('challenge.form.costReward.firstToWin'),
  },
  {
    value: EDistribution.ThreeParticipants,
    label: translation('challenge.form.costReward.amongThree'),
  },
  {
    value: EDistribution.EqualReward,
    label: translation('challenge.form.costReward.equalReward'),
  },
];

export const CHALLENGE_FORM_DATA_KEY = 'gg-challenge-form';

export enum EChallengeModals {
  Password = 'password',
  EntryCost = 'entryCost',
  Exit = 'exit',
  Stop = 'stop',
}

export enum EViewChallengeMode {
  Preview = 'preview',
  History = 'history',
}

export enum EItemState {
  Free = 'free',
  Filled = 'item',
  Locked = 'blocked',
}
