import { CSSObject } from 'tss-react';

import { makeStyles } from 'theme';

import { InputNumberSizeType } from './InputNumber';

export const useStyle = makeStyles<{
  size: InputNumberSizeType;
  withError: boolean;
}>()((theme, { size, withError }) => {
  const sizeMap: Record<InputNumberSizeType, CSSObject> = {
    sm: {
      height: 36,
      padding: '8px 16px',
    },
    md: {
      height: 40,
      padding: '10px 16px',
    },
    l: {
      height: 44,
      padding: '12px 16px',
    },
  };

  return {
    input: {
      ...sizeMap[size],
      '::placeholder': {
        color: theme.palette.control.default.typoPlaceholder,
      },
      transitionDuration: '300ms',
      ':hover': {
        border: `1px solid ${theme.palette.typo.link}`,
        outline: `1px solid ${theme.palette.typo.link}`,
      },
      ':focus': {
        boxShadow: '0px 0px 0px 2.8px #94C5FF',
        border: `1px solid ${theme.palette.typo.link}`,
      },
      width: '100%',
      boxSizing: 'border-box',
      outline: '1px solid transparent',
      backgroundColor: theme.palette.bg.default,
      borderRadius: 6,
      border: `1px solid ${theme.palette.bg.border}`,
    },
    errorInput: {
      border: `1px solid ${theme.palette.typo.alert} !important`,
    },
    inputContainer: {
      position: 'relative',
      marginBottom: withError ? 20 : 0,
    },
    errorMessage: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      transform: 'translateY(100%)',
    },
    disabled: {
      opacity: 0.4,
    },
    title: {
      display: 'inline-block',
      marginBottom: 8,
    },
    icon: {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      right: 15,
    },
  };
});
