import { Navigate, Route, Routes } from 'react-router-dom';
import { SWRConfig } from 'swr';

import { RequireAuth } from 'auth';
import { ModalProvider, PipProvider } from 'components';
import { Page } from 'Layout';

import { LoginReferral, ROUTES, AUTH_ROUTES, NotFound } from './pages';

import './index.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/bootstrap4-light-blue/theme.css';
import 'tailwindcss/tailwind.css';
import './i18n';

function App(): JSX.Element {
  return (
    <SWRConfig
      value={{
        revalidateOnFocus: false,
      }}
    >
      <PipProvider>
        <ModalProvider>
          <Routes>
            <Route path="login" element={<LoginReferral />} />
            <Route element={<Page />}>
              {ROUTES.map((subroute) =>
                subroute.routes.map(({ path, element }) => (
                  <Route key={path} path={path} element={element} />
                ))
              )}
              <Route element={<RequireAuth />}>
                {AUTH_ROUTES.map((subroute) =>
                  subroute.routes.map(({ path, element }) => (
                    <Route key={path} path={path} element={element} />
                  ))
                )}
              </Route>
            </Route>
            <Route path="/" element={<Navigate to="/home" replace />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </ModalProvider>
      </PipProvider>
    </SWRConfig>
  );
}

export default App;
