import { makeStyles } from 'theme';

export const useStyle = makeStyles()((theme) => ({
  activeNumber: {
    backgroundColor: theme.palette.typo.link,
    border: '1px solid transparent',
    transitionDuration: '500ms',
  },
  completeNumber: {
    backgroundColor: theme.palette.typo.link,
    transitionDuration: '500ms',
  },
  completeLabel: {
    borderBottom: '1px solid transparent',
    transitionDuration: '500ms',
    ':hover': {
      borderBottom: `1px solid ${theme.palette.control.default.bgBorderHover}`,
    },
  },
  skippedLabel: {
    borderBottom: '1px solid transparent',
    transitionDuration: '500ms',
    ':hover': {
      borderBottom: `1px solid ${theme.palette.control.default.bgBorderHover}`,
    },
  },
  skippedNumber: {
    backgroundColor: theme.palette.typo.link,
    border: '1px solid transparent',
    transitionDuration: '500ms',
  },
  inactiveNumber: {
    backgroundColor: 'transparent',
    border: `1px solid ${theme.palette.typo.system}`,
    transitionDuration: '500ms',
  },
  number: {
    borderRadius: '50%',
    boxSizing: 'border-box',
    width: 24,
    height: 24,
    marginRight: 8,
    ...theme.layouts.flexCenter,
  },
  activeLabel: {
    borderBottom: '1px solid transparent',
    transitionDuration: '500ms',
  },
  inactiveLabel: {
    borderBottom: '1px solid transparent',
    transitionDuration: '500ms',
    ':hover': {
      color: theme.palette.typo.primary,
      borderBottom: `1px solid ${theme.palette.control.default.bgBorderHover}`,
    },
  },
  label: {
    paddingTop: 1,
    boxSizing: 'border-box',
    borderBottom: '1px solid transparent',
    transitionDuration: '500ms',
  },
  stepContainer: {
    cursor: 'pointer',
    ...theme.layouts.flexCenter,
    '&:hover': {
      div: {
        '&:last-child': {
          color: theme.palette.typo.primary,
          borderBottom: `1px solid ${theme.palette.typo.link}`,
          transitionDuration: '500ms',
        },
      },
    },
  },
}));
