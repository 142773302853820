import { FC, useContext, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player';
import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';

import { ModalContext } from 'components';
import { TextLogoIcon } from 'icons';
import { Button, Typography } from 'ui-kit';

import { useStyle } from './VideoBlock.style';

const banners = [
  'url(images/main-page/video-block/banner-invoker.png)',
  'url(images/main-page/video-block/banner-leoric.png)',
];
const titles = [
  'home.videoBlock.innerTitle',
  'home.videoBlock.innerTitleSecondary',
];
const descs = ['home.videoBlock.innerDescription', ''];

export const VideoBlock: FC = () => {
  const { classes, cx } = useStyle();
  const { t: translation } = useTranslation();
  const { data: user } = useSWR('api/user');
  const isLogin = user?.id;

  const navigate = useNavigate();

  const {
    loginModal: { openLogin },
  } = useContext(ModalContext);
  const { Headline, Text } = Typography;

  const [imageIndex, setImageIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(
      () =>
        setImageIndex((prev) => {
          return prev < banners.length - 1 ? prev + 1 : 0;
        }),
      5000
    );
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className={classes.root}>
      <div
        className={classes.videoContainer}
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        {/* <ReactPlayer
          width={'100%'}
          height={'100%'}
          url="https://www.youtube.com/watch?v=rGOQncWs1oc"
        /> */}
        <TextLogoIcon width={400} height={400} />
        <Headline variant="h2">
          Welcome to GameGreed - best gaming platform
        </Headline>
      </div>
      <div
        key={imageIndex}
        className={cx(
          classes.banner,
          isLogin && cx(classes.banner, classes.innerBanner)
        )}
        style={{
          backgroundImage: isLogin
            ? banners[imageIndex]
            : 'url(images/main-page/video-block/banner.jpg)',
        }}
      >
        <div>
          <Headline variant="h2">
            {isLogin
              ? translation(titles[imageIndex])
              : translation('home.videoBlock.title')}
          </Headline>
          <Text className={classes.description} variant="b3">
            {isLogin
              ? translation(descs[imageIndex])
              : translation('home.videoBlock.description')}
          </Text>
        </div>
        {isLogin ? (
          // <a
          //   href="https://gamegreed.gitbook.io/gamegreed"
          //   target="_blank"
          //   rel="noreferrer"
          // >
          <Button
            color="brand"
            buttonSize="l"
            onClick={() => navigate('/challenges')}
          >
            {translation('home.videoBlock.innerBtn')}
          </Button>
        ) : (
          // </a>
          <Button color="brand" buttonSize="l" onClick={openLogin}>
            {translation('home.videoBlock.btn')}
          </Button>
        )}
      </div>
    </div>
  );
};
