import { FC } from 'react';

import { ICommonIconProps } from './types';

export const TrashIcon: FC<ICommonIconProps> = ({
  color = 'currentColor',
  width = 20,
  height = 20,
  viewBox = '0 0 20 20',
  className = '',
  style,
}) => (
  <svg
    fill="none"
    width={width}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M7.62853 3.3216L7.43634 3.72241H4.87373C4.40125 3.72241 4.01953 4.12322 4.01953 4.61932C4.01953 5.11543 4.40125 5.51624 4.87373 5.51624H15.1242C15.5966 5.51624 15.9784 5.11543 15.9784 4.61932C15.9784 4.12322 15.5966 3.72241 15.1242 3.72241H12.5615L12.3694 3.3216C12.2252 3.01609 11.9289 2.8255 11.6059 2.8255H8.39197C8.06898 2.8255 7.77268 3.01609 7.62853 3.3216ZM15.1242 6.41315H4.87373L5.43964 15.9148C5.48235 16.6239 6.04292 17.1761 6.71827 17.1761H13.2796C13.955 17.1761 14.5155 16.6239 14.5582 15.9148L15.1242 6.41315Z"
      fill={color}
    />
  </svg>
);
