import { FC } from 'react';

import { ICommonIconProps } from './types';

export const GradientSwordsIcon: FC<ICommonIconProps> = ({
  color = 'currentColor',
  width = 16,
  height = 16,
  viewBox = '0 0 16 16',
  className = '',
  style,
}) => (
  <svg
    fill="none"
    width={width}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.33203 1.99873C1.33203 1.63054 1.63051 1.33206 1.9987 1.33206H3.9987C4.17551 1.33206 4.34508 1.4023 4.4701 1.52732L11.6654 8.72259L12.194 8.19399C12.4543 7.93364 12.8764 7.93364 13.1368 8.19399C13.3971 8.45434 13.3971 8.87645 13.1368 9.1368L11.6082 10.6654L13.332 12.3893L13.5273 12.194C13.7876 11.9336 14.2098 11.9336 14.4701 12.194C14.7305 12.4543 14.7305 12.8764 14.4701 13.1368L13.1368 14.4701C12.8764 14.7305 12.4543 14.7305 12.194 14.4701C11.9336 14.2098 11.9336 13.7877 12.194 13.5273L12.3892 13.3321L10.6654 11.6082L9.13677 13.1368C8.87642 13.3971 8.45431 13.3971 8.19396 13.1368C7.93361 12.8764 7.93361 12.4543 8.19396 12.194L8.72256 11.6654L1.52729 4.47013C1.40227 4.34511 1.33203 4.17554 1.33203 3.99873V1.99873ZM11.5273 1.52732C11.6523 1.4023 11.8219 1.33206 11.9987 1.33206H13.9987C14.3669 1.33206 14.6654 1.63054 14.6654 1.99873V3.99873C14.6654 4.17554 14.5951 4.34511 14.4701 4.47013L12.1368 6.80347C11.8764 7.06382 11.4543 7.06382 11.194 6.80347L9.19396 4.80347C8.93361 4.54312 8.93361 4.12101 9.19396 3.86066L11.5273 1.52732ZM2.86063 8.86066C3.12098 8.60031 3.54309 8.60031 3.80344 8.86066L6.4701 11.5273C6.73045 11.7877 6.73045 12.2098 6.4701 12.4701C6.20975 12.7305 5.78764 12.7305 5.52729 12.4701L4.9987 11.9415L3.60817 13.3321L3.80344 13.5273C4.06379 13.7877 4.06379 14.2098 3.80344 14.4701C3.54309 14.7305 3.12098 14.7305 2.86063 14.4701L1.52729 13.1368C1.26694 12.8764 1.26694 12.4543 1.52729 12.194C1.78764 11.9336 2.20975 11.9336 2.4701 12.194L2.66536 12.3893L4.05589 10.9987L2.86063 9.80347C2.60028 9.54312 2.60028 9.12101 2.86063 8.86066Z"
      fill="url(#paint0_linear_5831_88019)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_5831_88019"
        x1="3.41046"
        y1="1.33206"
        x2="13.0388"
        y2="14.459"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2D63FF" />
        <stop offset="1" stopColor="#002BA7" />
      </linearGradient>
    </defs>
  </svg>
);
