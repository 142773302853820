import type { RefObject } from 'react';
import { useEffect, useRef } from 'react';

export const useClickOutside = (
  ref: RefObject<HTMLElement | null>,
  onClick: (event: MouseEvent) => void,
  excludeRefs?: RefObject<HTMLElement | null>[]
): void => {
  const savedCallback = useRef(onClick);

  useEffect(() => {
    savedCallback.current = onClick;
  }, [onClick]);

  useEffect(() => {
    const handler = (event: MouseEvent): void => {
      const { target } = event;

      if (!(target instanceof Element)) {
        return;
      }

      const isExcluded = excludeRefs?.some(
        (ref) => ref.current && ref.current.contains(target)
      );

      if ((ref.current && ref.current.contains(target)) || isExcluded) {
        return;
      }

      savedCallback.current(event);
    };

    window.addEventListener('mousedown', handler);

    return () => {
      window.removeEventListener('mousedown', handler);
    };
  }, [excludeRefs, ref]);
};
