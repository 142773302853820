import { CSSObject } from 'tss-react';

import { makeStyles } from 'theme';

import { DropdownSizeType } from './Dropdown';

export const useStyle = makeStyles<{
  size: DropdownSizeType;
  isOpen: boolean;
}>()((theme, { size, isOpen }) => {
  const sizeMap: Record<DropdownSizeType, CSSObject> = {
    sm: {
      height: 36,
      padding: '8px 16px',
    },
    md: {
      height: 40,
      padding: '10px 16px',
    },
    l: {
      height: 44,
      padding: '12px 16px',
    },
  };
  return {
    title: {
      display: 'inline-block',
      marginBottom: 8,
    },
    dropdown: {
      ...sizeMap[size],
      position: 'relative',
      transitionDuration: '300ms',
      boxShadow: isOpen ? '0px 0px 0px 2.8px #94C5FF' : '',
      border: isOpen
        ? `1px solid ${theme.palette.typo.link}`
        : `1px solid ${theme.palette.bg.border}`,

      ':hover': {
        border: `1px solid ${theme.palette.typo.link}`,
        outline: `1px solid ${theme.palette.typo.link}`,
      },
      width: '100%',
      boxSizing: 'border-box',
      outline: '1px solid transparent',
      backgroundColor: theme.palette.bg.default,
      borderRadius: 6,
      ...theme.layouts.flexAICenter,
    },
    cross: {
      width: 16,
      height: 16,
      position: 'absolute',
      cursor: 'pointer',
      top: '50%',
      transform: 'translateY(-50%)',
      right: 50,
      display: 'block',
    },
    mainContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    img: {
      marginRight: 12,
      height: 20,
    },
    arrowIcon: {
      position: 'absolute',
      top: '50%',
      transform: `translateY(-50%) ${
        isOpen ? 'rotate(180deg)' : 'rotate(0deg)'
      }`,
      transitionDuration: '300ms',
      right: 22,
      color: theme.palette.typo.primary,
    },
  };
});
