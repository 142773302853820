import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Link, useLocation, useMatch } from 'react-router-dom';

import { DISABLED_SUBROUTES } from 'pages';
import { IRoute } from 'types/interfaces';
import { Typography } from 'ui-kit';

import { useStyle } from '../HeaderComponents.styles';

interface IProps {
  route: IRoute;
  isUserExist: boolean;
}

export const Tab: React.FC<IProps> = ({ route, isUserExist }): JSX.Element => {
  const location = useLocation();
  const { classes } = useStyle();
  const match = useMatch(route.path);
  const [urlWithParams, setUrlWithParams] = useState(null);
  const params = route?.params;
  const { t: translation } = useTranslation();
  const { Headline } = Typography;

  const isActive = params
    ? match?.pathname === location?.pathname
    : route.path === location.pathname;

  const isSubrouteDisabled =
    !isUserExist &&
    DISABLED_SUBROUTES.find((disableRoute) => disableRoute.path === route.path);

  useEffect(() => {
    if (params) {
      let url = route.path;
      Object.keys(params).forEach((param) => {
        url = url.replace(`:${param}`, params[param]);
      });
      setUrlWithParams(url);
    }
  }, [params, route.path]);

  return (
    !isSubrouteDisabled && (
      <Link
        to={`${urlWithParams ? urlWithParams : route.path}`}
        className={classes.tab}
      >
        <Headline variant="h4" color={isActive ? 'primary' : 'secondary'}>
          {translation(route.title)}
        </Headline>
      </Link>
    )
  );
};
