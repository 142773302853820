import { makeStyles } from 'theme';

export const useStyle = makeStyles()((theme) => {
  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      fontFamily: 'Ubuntu',
      backdropFilter: 'blur(8px)',
      borderRadius: 6,
      paddingTop: 30,
      paddingLeft: 40,
      paddingRight: 40,
      paddingBottom: 42,
      border: '2px solid rgba(43, 44, 46, 0.64)',
      width: '40%',
      '&:first-of-type': {
        marginRight: 32,
      },
      marginTop: 80,
      height: 'fit-content',
      background:
        'linear-gradient(38.92deg, rgba(10, 109, 224, 0.03) 0%, rgba(10, 109, 224, 0.01) 134.39%)',
    },
    title: {
      display: 'inline-block',
      width: '100%',
      fontSize: '2rem',
    },
    list: {
      display: 'flex',
      flexDirection: 'column',
      gap: '2rem',
      padding: '32px 16px 16px 32px',
      color: theme.palette.typo.system,
      lineHeight: '32px',
      fontSize: '17px',
      fontWeight: 400,
      listStyleType: 'disc',
    },
    condition: {
      display: 'inline-block',
      marginTop: '16px',
      alignSelf: 'flex-end',
      justifySelf: 'flex-end',
    },
  };
});
