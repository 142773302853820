import type { FC } from 'react';

import type { ICommonIconProps } from './types';

export const SearchIcon: FC<ICommonIconProps> = ({
  color = 'currentColor',
  width = 16,
  height = 16,
  viewBox = '0 0 16 16',
  className = '',
  style,
}) => (
  <svg
    color={color}
    width={width}
    height={height}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.98783 10.8717C7.77927 12.6406 4.54641 12.5014 2.49909 10.454C0.302388 8.25734 0.302388 4.69579 2.49909 2.49909C4.69579 0.302388 8.25734 0.302388 10.454 2.49909C12.5014 4.5464 12.6406 7.77926 10.8717 9.98782L15.1681 14.2842C15.4121 14.5283 15.4121 14.924 15.1681 15.1681C14.924 15.4121 14.5283 15.4121 14.2842 15.1681L9.98783 10.8717ZM3.38297 9.57016C1.67443 7.86161 1.67443 5.09151 3.38297 3.38297C5.09151 1.67443 7.86161 1.67443 9.57015 3.38297C11.2774 5.09026 11.2787 7.85754 9.57391 9.56639C9.57265 9.56763 9.57139 9.56888 9.57014 9.57013C9.56889 9.57139 9.56764 9.57265 9.5664 9.57391C7.85755 11.2787 5.09026 11.2774 3.38297 9.57016Z"
      fill="currentColor"
      fillOpacity="0.5"
    />
  </svg>
);
