import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import {
  dota2Items,
  EDota2ItemTypes,
  EUpgradeDota2ItemTypes,
  IItem,
} from 'consts';

import { useStyle } from './ItemTable.styles';

interface IUpgradeItemsProps {
  onItemClick: (item: IItem) => void;
}

export const UpgradeItems: FC<IUpgradeItemsProps> = ({ onItemClick }) => {
  const { classes } = useStyle();
  const { t: translation } = useTranslation();
  return (
    <>
      <div className={classes.categoryContainer}>
        <div className={classes.categoryTitle}>
          {translation('parry.form.heroItemBuild.items.table.upgrade.acc')}
        </div>
        {dota2Items[EDota2ItemTypes.Upgrade][
          EUpgradeDota2ItemTypes.Accessories
        ].map((item) => (
          <img
            title={item.dname}
            className={classes.item}
            key={item.id}
            src={`https://media.steampowered.com${item.img}`}
            alt={item.dname}
            onClick={() => onItemClick(item)}
          />
        ))}
      </div>
      <div className={classes.categoryContainer}>
        <div className={classes.categoryTitle}>
          {translation('parry.form.heroItemBuild.items.table.upgrade.support')}
        </div>
        {dota2Items[EDota2ItemTypes.Upgrade][
          EUpgradeDota2ItemTypes.Support
        ].map((item) => (
          <img
            className={classes.item}
            key={item.id}
            title={item.dname}
            src={`https://media.steampowered.com${item.img}`}
            alt={item.dname}
            onClick={() => onItemClick(item)}
          />
        ))}
      </div>
      <div className={classes.categoryContainer}>
        <div className={classes.categoryTitle}>
          {translation('parry.form.heroItemBuild.items.table.upgrade.magical')}
        </div>
        {dota2Items[EDota2ItemTypes.Upgrade][
          EUpgradeDota2ItemTypes.Magical
        ].map((item) => (
          <img
            className={classes.item}
            title={item.dname}
            key={item.id}
            src={`https://media.steampowered.com${item.img}`}
            alt={item.dname}
            onClick={() => onItemClick(item)}
          />
        ))}
      </div>
      <div className={classes.categoryContainer}>
        <div className={classes.categoryTitle}>
          {translation('parry.form.heroItemBuild.items.table.upgrade.weapons')}
        </div>
        {dota2Items[EDota2ItemTypes.Upgrade][
          EUpgradeDota2ItemTypes.Weapons
        ].map((item) => (
          <img
            className={classes.item}
            title={item.dname}
            key={item.id}
            src={`https://media.steampowered.com${item.img}`}
            alt={item.dname}
            onClick={() => onItemClick(item)}
          />
        ))}
      </div>
      <div className={classes.categoryContainer}>
        <div className={classes.categoryTitle}>
          {translation(
            'parry.form.heroItemBuild.items.table.upgrade.artifacts'
          )}
        </div>
        {dota2Items[EDota2ItemTypes.Upgrade][
          EUpgradeDota2ItemTypes.Artifacts
        ].map((item) => (
          <img
            className={classes.item}
            title={item.dname}
            key={item.id}
            src={`https://media.steampowered.com${item.img}`}
            alt={item.dname}
            onClick={() => onItemClick(item)}
          />
        ))}
      </div>
      <div className={classes.categoryContainer}>
        <div className={classes.categoryTitle}>
          {translation('parry.form.heroItemBuild.items.table.upgrade.armor')}
        </div>
        {dota2Items[EDota2ItemTypes.Upgrade][EUpgradeDota2ItemTypes.Armor].map(
          (item) => (
            <img
              className={classes.item}
              title={item.dname}
              key={item.id}
              src={`https://media.steampowered.com${item.img}`}
              alt={item.dname}
              onClick={() => onItemClick(item)}
            />
          )
        )}
      </div>
    </>
  );
};
