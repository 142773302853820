import { FC } from 'react';

import { ICommonIconProps } from './types';

export const PlayFillIcon: FC<ICommonIconProps> = ({
  color = 'currentColor',
  width = 24,
  height = 24,
  viewBox = '0 0 24 24',
  className = '',
  style,
}) => (
  <svg
    fill="currentColor"
    width={width}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M19.2641 13.5162C20.256 12.7487 20.256 11.2512 19.2641 10.4837C16.2666 8.16434 12.9194 6.33619 9.34784 5.06771L8.69536 4.83597C7.44709 4.39263 6.12858 5.23719 5.95959 6.52574C5.48743 10.126 5.48743 13.8739 5.95959 17.4742C6.12858 18.7627 7.44709 19.6073 8.69536 19.1639L9.34784 18.9322C12.9194 17.6637 16.2666 15.8356 19.2641 13.5162Z"
      fill={color}
    />
  </svg>
);
