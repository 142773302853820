import { FC, useEffect } from 'react';

import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  ECondition,
  MAX_TERM_VALUES,
  OPTIONS_CONDITION,
  OPTIONS_MAIN_TERM,
  OPTIONS_TERM,
} from 'consts';
import { BucketIcon } from 'icons';
import { Button, Checkbox, Counter, Dropdown, Typography } from 'ui-kit';

import { text } from '../../CreateChallenge.model';
import { useStyle } from './GameOptions.styles';

export const GameOptions: FC = () => {
  const { setValue } = useFormContext();
  const { control, getValues, watch } = useFormContext();

  const { t: translation } = useTranslation();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'options',
  });

  const addTerm = (): void => {
    append({
      term: '',
      condition: ECondition.MoreOrEqual,
      quantity: 1,
    });
  };

  const createdOptions = useWatch({ name: 'options', control });

  const additionalTerms = createdOptions?.filter(
    (option) => option?.shouldBeInRow === undefined
  );

  const selectedOptions = additionalTerms.map((option) => option.term);

  // set max value if choose another term and value was greater than max value
  useEffect(() => {
    createdOptions?.forEach((_, index) => {
      const currentValue = getValues(`options.${index}.quantity`);

      if (currentValue > MAX_TERM_VALUES[getValues(`options.${index}.term`)]) {
        setValue(
          `options.${index}.quantity`,
          MAX_TERM_VALUES[getValues(`options.${index}.term`)]
        );
      }
    });
  }, [selectedOptions, watch('winInsensitivity')]);

  const availableTerms = additionalTerms.map((createdOption) => {
    return OPTIONS_TERM(translation).filter(
      (option) =>
        !selectedOptions.includes(option.value) ||
        createdOption.term === option.value
    );
  });
  const { classes } = useStyle();
  const { Headline, Text } = Typography;
  const termValues = createdOptions.map((option, index) => {
    if (index === 0) {
      const mainTerm = OPTIONS_MAIN_TERM[createdOptions[0]['term']];
      return { ...mainTerm, label: translation(mainTerm.label) };
    } else {
      return OPTIONS_TERM(translation).filter(
        (label) => label.value === option.term
      )[0];
    }
  });

  return (
    <section className={classes.section}>
      <Headline variant="h2" className={classes.title}>
        {translation('challenge.form.gameOptions.title')}
      </Headline>
      <Text className={classes.description}>
        {translation('challenge.form.gameOptions.descriptionFirst')}
        <br />
        <br />
        {translation('challenge.form.gameOptions.descriptionSecond')}
      </Text>
      {fields.map((field, index) => {
        return (
          <div key={field.id} className={classes.flexContainer}>
            <div className={classes.inputContainer}>
              <Controller
                name={`options.${index}.term`}
                control={control}
                render={({ field: { onChange } }) => (
                  <Dropdown
                    options={
                      index === 0
                        ? OPTIONS_MAIN_TERM.map((option) => ({
                            value: option.value,
                            label: translation(option.label),
                          }))
                        : availableTerms[index - 1]
                    }
                    placeholder={translation(
                      'challenge.form.gameOptions.chooseTerm'
                    )}
                    title={
                      (index === 0 &&
                        translation('challenge.form.gameOptions.mainTerm')) ||
                      (index === 1 &&
                        translation('challenge.form.gameOptions.addTerm'))
                    }
                    tooltip={index === 0 ? translation(text.main) : ''}
                    defaultValue={
                      index === 0
                        ? {
                            value: OPTIONS_MAIN_TERM[0].value,
                            label: translation(OPTIONS_MAIN_TERM[0].label),
                          }
                        : null
                    }
                    value={termValues[index]}
                    className={classes.fullWidth}
                    onChange={onChange}
                  />
                )}
              />
              {index === 0 && (
                <Controller
                  name={`winInsensitivity`}
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      className={classes.checkBox}
                      label={translation(
                        'challenge.form.gameOptions.winInsensitivity'
                      )}
                      checked={!!field.value}
                      onChange={field.onChange}
                    />
                  )}
                />
              )}
            </div>
            <div className={classes.counterContainer}>
              {index !== 0 ? (
                <Controller
                  name={`options.${index}.condition`}
                  control={control}
                  render={({ field: { onChange } }) => (
                    <Dropdown
                      options={OPTIONS_CONDITION(translation)}
                      onChange={onChange}
                      value={
                        OPTIONS_CONDITION(translation)[
                          createdOptions[index]?.condition
                        ]
                      }
                      defaultValue={OPTIONS_CONDITION(translation)[0]}
                      className={classes.condition}
                      title={
                        index === 1 &&
                        translation('challenge.form.gameOptions.condition')
                      }
                    />
                  )}
                />
              ) : (
                <div className={classes.conditionContainer}>
                  <Controller
                    name={`options.${index}.quantity`}
                    control={control}
                    render={({ field }) => (
                      <Counter
                        className={classes.counter}
                        {...field}
                        title={
                          index === 0 &&
                          translation('challenge.form.gameOptions.value')
                        }
                        maxValue={
                          MAX_TERM_VALUES[getValues(`options.${index}.term`)]
                        }
                      />
                    )}
                  />
                  <Controller
                    name={`options.${index}.shouldBeInRow`}
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        className={classes.checkBox}
                        label={translation('challenge.form.gameOptions.row')}
                        checked={!!field.value}
                        onChange={field.onChange}
                      />
                    )}
                  />
                </div>
              )}
              {index !== 0 && (
                <Controller
                  name={`options.${index}.quantity`}
                  control={control}
                  render={({ field }) => (
                    <Counter
                      className={classes.additionalCounter}
                      title={
                        index === 1 &&
                        translation('challenge.form.gameOptions.value')
                      }
                      {...field}
                      maxValue={
                        MAX_TERM_VALUES[getValues(`options.${index}.term`)]
                      }
                      minValue={0}
                    />
                  )}
                />
              )}
              {index !== 0 && (
                <button
                  onClick={() => {
                    remove(index);
                  }}
                  className={classes.quantityContainer}
                >
                  <BucketIcon />
                </button>
              )}
            </div>
          </div>
        );
      })}

      {fields.length <= OPTIONS_TERM(translation).length && (
        <Button
          className={classes.addButton}
          onClick={addTerm}
          color="secondary"
          buttonSize="l"
        >
          {translation('challenge.form.gameOptions.addMore')}
        </Button>
      )}
    </section>
  );
};
