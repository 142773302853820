import useSWR, { mutate } from 'swr';

import {
  EPredictStatus,
  getActivePrediction,
  getBalance,
  getMatches,
  getStatistic,
  IBalanceResponse,
  IMatch,
  IPredictResponse,
  IStatisticResponse,
} from 'api';
import { ArmIcon, SkeletonIcon, SwordIcon } from 'icons';

export const PREDICT_OPTIONS = [
  { name: '500 GC', value: 500 },
  { name: '1500 GC', value: 1500 },
  { name: '2500 GC', value: 2500 },
];

interface IUsePredictApi {
  activePredict: IPredictResponse;
  statistic: IStatisticResponse;
  balance: IBalanceResponse;
  user: {
    isLogin: boolean;
    hasSteam: boolean;
    exist: boolean;
  };
  refetchGame: () => Promise<void>;
  isLoadingPredict: boolean;
  isRefetching: boolean;
}

export const usePredictionApi = (): IUsePredictApi => {
  const { data: activePredict, isValidating: isValidatingPredict } =
    useSWR('api/predict');
  const { data: statistic, isValidating: isValidatingStatistic } = useSWR(
    'api/statistics',
    getStatistic
  );
  const { data: balance } = useSWR('api/balance', getBalance);
  const { isValidating: isValidatingMatches } = useSWR(
    'api/matches?offset=0&limit=5',
    () => getMatches({ offset: 0, limit: 5, mode: 'ranked' })
  );
  const { data: user, error } = useSWR('api/user');
  const refetchGame = async (): Promise<void> => {
    mutate('api/predict', getActivePrediction);
    if (
      [EPredictStatus.LOSE, EPredictStatus.WIN].includes(activePredict?.status)
    ) {
      mutate('api/statistics', getStatistic);
      mutate('api/matches?offset=0&limit=5', () =>
        getMatches({ offset: 0, limit: 5, mode: 'ranked' })
      );
      mutate('api/balance', getBalance);
    }
  };
  const isLoadingPredict = !statistic || !activePredict || !balance;
  const isRefetching =
    isValidatingStatistic || isValidatingPredict || isValidatingMatches;

  return {
    activePredict,
    statistic,
    balance,
    user: {
      isLogin: !error,
      hasSteam: !!user?.steamId,
      exist: !!user,
    },
    refetchGame,
    isLoadingPredict,
    isRefetching,
  };
};

interface IBlock {
  title: string;
  value: number | string;
}

export const BLOCKS = (predictValue: number, multiplier: number): IBlock[] => {
  return [
    {
      title: 'predictions.predict.block.place',
      value: predictValue,
    },
    {
      title: 'predictions.predict.block.coef',
      value: multiplier,
    },
    {
      title: 'predictions.predict.block.willGet',
      value: (+predictValue * multiplier).toFixed(2),
    },
  ];
};

interface IStats {
  kill: number;
  death: number;
  assist: number;
}

interface IKDA {
  value: number;
  icon: JSX.Element;
}

export const KDA = ({ kill, death, assist }: IStats): IKDA[] => [
  {
    value: kill,
    icon: <SwordIcon width={20} height={20} />,
  },
  {
    value: death,
    icon: <SkeletonIcon width={20} height={20} />,
  },
  {
    value: assist,
    icon: <ArmIcon width={20} height={20} />,
  },
];

export const stats = ({
  stats: { kills, deaths, assists },
}: IMatch): string => {
  return `${kills}/${deaths}/${assists}`;
};
