import React, {
  useEffect,
  useRef,
  useState,
  createContext,
  RefObject,
} from 'react';

export const PipContext = createContext({
  isShowPip: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  togglePip: () => {},
});

interface IPipReturn {
  isShowPip: boolean;
  togglePip: () => void;
}

function usePip(ref: RefObject<HTMLVideoElement>): IPipReturn {
  const [isShowPip, setIsShowPip] = useState(false);

  const enterPictureInPicture = (videoElement: HTMLVideoElement): void => {
    if (
      videoElement &&
      document.pictureInPictureEnabled &&
      !videoElement.disablePictureInPicture
    ) {
      try {
        if (document.pictureInPictureElement) {
          document.exitPictureInPicture();
        }
        videoElement.requestPictureInPicture();
        videoElement.play();
      } catch (err) {
        console.error(err);
      }
    }
  };

  const togglePip = (): void => {
    setIsShowPip((prevState) => !prevState);
  };

  useEffect(() => {
    if (isShowPip) {
      ref.current && enterPictureInPicture(ref.current);
    }
  }, [ref, isShowPip]);

  return {
    togglePip,
    isShowPip,
  };
}

interface IProps {
  children: React.ReactNode;
}

export const PipProvider = ({ children }: IProps): JSX.Element => {
  const videoRef = useRef(null);

  const { togglePip, isShowPip } = usePip(videoRef);

  return (
    <PipContext.Provider value={{ isShowPip, togglePip }}>
      <video
        style={{ display: 'none' }}
        controls={false}
        ref={videoRef}
        muted
        loop
      >
        {/* TODO: add dynamic video loader */}
      </video>
      {children}
    </PipContext.Provider>
  );
};
