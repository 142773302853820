import { makeStyles } from 'theme';

export const useStyle = makeStyles()((theme) => ({
  container: {
    maxWidth: 1232,
    position: 'relative',
    width: '100%',
    padding: 40,
    backgroundImage: "url('/images/home/betaResult.jpg')",
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    marginTop: 40,
    borderRadius: 6,
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
  },
  resultTitle: {
    marginTop: 30,
    marginBottom: 12,
  },
  text: {
    display: 'inline-block',
  },
  numbersContainer: {
    display: 'flex',
    width: '100%',
  },
  subContainer: {
    width: 190,
    paddingRight: 70,
  },
  subLongContainer: {
    width: 190,
    paddingRight: 40,
  },
  divider: {
    backgroundColor: `${theme.palette.typo.primary} !important`,
  },
  infoContainer: {
    display: 'flex',
    gap: 90,
    marginTop: 40,
    maxHeight: 372,
  },
  leadersContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    // width: 540,
    width: '100%',
    zIndex: 2,
  },
  leaderTitleContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  winnerItem: {
    overflow: 'hidden',
    position: 'relative',
    padding: '6px 24px 6px 24px',
    width: '100%',
    borderRadius: 6,
    display: 'flex',
    alignItems: 'center',
    gap: 12,
    backgroundColor: theme.palette.bg.ghost,
    backdropFilter: 'blur(6px)',
  },
  value: {
    display: 'inline-block',
    marginRight: 0,
    marginLeft: 'auto',
  },
  winnerLabel: {
    height: 52,
    width: 4,
    position: 'absolute',
    left: 0,
  },
  firstWinner: {
    backgroundImage: theme.palette.gradient.gold,
  },
  secondWinner: {
    backgroundImage: theme.palette.gradient.silver,
  },

  thirdWinner: {
    backgroundImage: theme.palette.gradient.bronze,
  },
  fourthWinner: {
    backgroundColor: theme.palette.bg.ghostLight,
  },

  avatar: {
    width: 40,
    height: 40,
    borderRadius: '50%',
  },
  howToContainer: {
    width: 480,
    padding: '30px 30px 70px 30px',
    backgroundColor: theme.palette.bg.ghost,
    backdropFilter: 'blur(6px)',
    borderRadius: 6,
    display: 'flex',
    flexDirection: 'column',
  },
  stepContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
    marginTop: 20,
    ' > span': {
      display: 'block',
    },
  },
  warningContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    marginTop: 42,
  },
  warningPoint: {
    display: 'flex',
    gap: 16,
    alignItems: 'flex-start',
    span: {
      minHeight: 24,
      display: 'inline-block',
    },
  },
  invoker: {
    position: 'absolute',
    top: -25,
    right: -115,
  },
}));
