import TagManager from 'react-gtm-module';
import { TFunction } from 'react-i18next';
import useSWR from 'swr';

import {
  activateParry,
  createParry,
  getParriesCount,
  IBalanceResponse,
  IParry,
  ICreatedParry,
  IErrorResponse,
  IUserResponse,
} from 'api';
import { EParryStatus, EDistribution, PARRY_FORM_DATA_KEY } from 'consts';

export enum EParryModals {
  Password = 'password',
  EntryCost = 'entryCost',
  Exit = 'exit',
  Stop = 'stop',
}

const isButtonNotExist = (
  parry: ICreatedParry,
  isFinished: boolean,
  isHistoryMode: boolean,
  isPreviewMode: boolean
): boolean => {
  const { isOwner, status } = parry;
  return (
    status === EParryStatus.Draft ||
    (isFinished && !isOwner) ||
    isHistoryMode ||
    isOwner ||
    isPreviewMode
  );
};

interface IUseParryStatus {
  isAcceptDisabled: boolean;
  isNotEnoughBalance: boolean;
  isMaxParticipation: boolean;
  isFullParticipantParry: boolean;
}

export const getIsWinner = (
  parry: ICreatedParry,
  user: IUserResponse
): boolean => !!parry?.winners?.find((winner) => winner.id === user?.id)?.id;

export const useParryStatus = (
  parry: ICreatedParry
): IUseParryStatus | null => {
  if (!parry) {
    return {
      isAcceptDisabled: false,
      isNotEnoughBalance: false,
      isMaxParticipation: false,
      isFullParticipantParry: false,
    };
  }
  const { data: balance } = useSWR<IBalanceResponse>('api/balance');
  const { data: maxParries } = useSWR('/api/parries-count', getParriesCount);
  const { participants, activeParticipants, entryCost } = parry;
  const isFullParticipantParry =
    participants > 0 && activeParticipants === participants;
  const isNotEnoughBalance = +balance?.balance < +entryCost;
  const isMaxParticipation = maxParries?.asParticipant >= 3;
  const isAcceptDisabled =
    isFullParticipantParry || isNotEnoughBalance || isMaxParticipation;

  return {
    isAcceptDisabled,
    isMaxParticipation,
    isNotEnoughBalance,
    isFullParticipantParry,
  };
};

export enum EButtonType {
  None,
  StopRegistration,
  ExitParry,
  AcceptParry,
}

export const getButtonType = (
  parry: ICreatedParry,
  isHistoryMode: boolean,
  isFinished: boolean,
  isPreviewMode: boolean
): EButtonType => {
  const { isParticipant } = parry;
  const canExit = isParticipant && !isHistoryMode;
  const isEmpty = isButtonNotExist(
    parry,
    isFinished,
    isHistoryMode,
    isPreviewMode
  );

  if (isEmpty) {
    return EButtonType.None;
  }
  if (canExit) {
    return EButtonType.ExitParry;
  }
  return EButtonType.AcceptParry;
};

export const prepareChallangeData = (parry: ICreatedParry): IParry => {
  const {
    title,
    password,
    entryCost,
    reward,
    distribution,
    participants,
    image,
    hero,
    options,
    winnerCount,
    iterations,
    items,
    winInsensitivity,
    targetSteamId,
    expiredIn,
  } = parry;
  TagManager.dataLayer({
    dataLayer: { event: 'parry_create', type: 'draft' },
  });

  return {
    title,
    password,
    entryCost: +entryCost,
    reward: +reward,
    distribution: +distribution,
    participants: +participants,
    image,
    hero: hero ? +hero : null,
    options,
    winnerCount,
    iterations,
    items,
    winInsensitivity,
    targetSteamId,
    expiredIn: +expiredIn,
  };
};

export const createAndPublishParry = (
  parry: ICreatedParry
): Promise<ICreatedParry | IErrorResponse> => {
  const {
    title,
    password,
    entryCost,
    reward,
    distribution,
    participants,
    image,
    hero,
    options,
    winnerCount,
    iterations,
    items,
    winInsensitivity,
    targetSteamId,
    expiredIn,
  } = parry;
  return createParry({
    title,
    password,
    entryCost: +entryCost,
    reward: +reward,
    distribution: +distribution,
    participants: +participants,
    image,
    hero: hero ? +hero : null,
    options,
    winnerCount,
    iterations,
    items,
    winInsensitivity,
    targetSteamId,
    expiredIn,
  });
};

export const finishActivateParry = async (
  id: number
): Promise<IParry | IErrorResponse> => {
  const response = await activateParry(id);
  localStorage.removeItem(PARRY_FORM_DATA_KEY);
  TagManager.dataLayer({
    dataLayer: { event: 'parry_create', type: 'published' },
  });
  return response;
};

interface IUseProfit {
  totalEntryFees: number;
  totalProfit: string;
  establishedReward: number;
}

export const calcProfit = (parry: ICreatedParry): IUseProfit => {
  const totalEntryFees = parry.entryCost * parry.activeParticipants;
  let totalProfit = '';
  if (!parry.winners) {
    return {
      establishedReward: 0,
      totalEntryFees: 0,
      totalProfit: '0',
    };
  }

  if (parry.distribution === EDistribution.FirstToWin) {
    if (parry.winners.length === 1) {
      totalProfit = (totalEntryFees - +parry.reward).toFixed(2);
    } else totalProfit = totalEntryFees.toFixed(2);
  }
  if (parry.distribution === EDistribution.ThreeParticipants) {
    if (parry.winners.length === 1) {
      totalProfit = (totalEntryFees - +parry.reward * 0.5).toFixed(2);
    } else if (parry.winners.length === 2) {
      totalProfit = (totalEntryFees - +parry.reward * 0.8).toFixed(2);
    } else if (parry.winners.length === 3) {
      totalProfit = (totalEntryFees - +parry.reward).toFixed(2);
    } else totalProfit = totalEntryFees.toFixed(2);
  }
  if (parry.distribution === EDistribution.EqualReward) {
    const onePersonReward = +(+parry.reward / parry.winnerCount).toFixed(2);
    totalProfit = (
      totalEntryFees -
      parry.winners.length * onePersonReward
    ).toFixed(2);
  }
  const establishedReward = totalEntryFees - +totalProfit;
  return {
    totalProfit,
    totalEntryFees,
    establishedReward,
  };
};
export const PUBLISH_ERRORS_TEXT_TRANSLATION = (
  translation: TFunction<'translation', undefined>
): Record<string, string> => ({
  network: translation('parryView.errors.network'),
  money: translation('parryView.errors.money'),
  maxLimit: translation('parryView.errors.maxLimit'),
});
