import { BACKEND_URL } from './env';

export interface IPromoCode {
  code: string;
  ownerReward: number;
  userReward: number;
  userId: number;
  registration: boolean;
}

export function activatePromoCode(code: string): Promise<Response> {
  return fetch(`${BACKEND_URL}/api/promo-service/promos/use`, {
    method: 'POST',
    body: JSON.stringify({ code }),
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
  });
}
