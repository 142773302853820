import type { FC } from 'react';

import type { ICommonIconProps } from './types';

export const PlusIcon: FC<ICommonIconProps> = ({
  color = '#181E33',
  width = 16,
  height = 16,
  viewBox = '0 0 16 16',
  className = '',
  style,
}) => (
  <svg
    color={color}
    width={width}
    height={height}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M7.00008 12.3332V6.99984M7.00008 6.99984V1.6665M7.00008 6.99984H12.3334M7.00008 6.99984H1.66675"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);
