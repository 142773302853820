import { makeStyles } from 'theme';

export const useStyle = makeStyles()((theme) => ({
  modalContainer: {
    width: 736,
    paddingTop: 48,
    paddingLeft: 64,
    paddingRight: 64,
    paddingBottom: 56,
    backgroundImage: 'url(/images/modals/modal-reward-bg.png)',
    backgroundSize: '100%',
    backgroundRepeat: 'no-repeat',
    border: `1px solid ${theme.palette.bg.border}`,
    borderRadius: 10,
    backdropFilter: 'blur(4px)',
    position: 'relative',
    backgroundColor: theme.palette.greyBlue,
  },
  title: {
    margin: '0 auto',
    textAlign: 'center',
    width: '100%',
    display: 'inline-block',
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    marginTop: 24,
  },
  line: {
    alignItems: 'center',
    display: 'flex',
  },
  link: {
    display: 'flex',
    gap: 4,
    padding: '0 8px',
    color: theme.palette.typo.link,
    cursor: 'pointer',
  },
  hint: {
    gap: 8,
    paddingLeft: 16,
    width: 500,
  },
  description: {
    textAlign: 'center',
    marginTop: 8,
    marginBottom: 24,
    display: 'inline-block',
  },
  button: {
    width: '100%',
  },
  input: {
    marginTop: 32,
  },
  closeIcon: {
    color: theme.palette.typo.primary,
    position: 'absolute',
    top: 29,
    right: 32,
  },
  video: {
    borderRadius: 10,
    width: '100%',
    aspectRatio: '16/8',
    marginBottom: 24,
  },
  discordLink: {
    color: theme.palette.typo.link,
  },
  discordDescription: {
    textAlign: 'center',
    marginTop: 24,
    display: 'inline-block',
    width: '100%',
  },
  error: {
    ...theme.fontStyles.text14,
    textAlign: 'center',
    fontWeight: '500',
    color: theme.palette.typo.alert,
    position: 'absolute',
    bottom: '-24px',
    left: '0',
    right: '0',
  },
  buttonContainer: {
    position: 'relative',
  },
}));
