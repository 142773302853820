import type { FC } from 'react';

import type { ICommonIconProps } from './types';

export const ArmIcon: FC<ICommonIconProps> = ({
  color = 'currentColor',
  width = 16,
  height = 20,
  viewBox = '0 0 16 20',
  className = '',
  style,
}) => (
  <svg
    fill="none"
    width={width}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M8 7.04688V1.25C8 0.559766 7.42504 0 6.71428 0C6.00353 0 5.42857 0.559766 5.42857 1.25V6.875H7.03571C7.37723 6.875 7.69866 6.94141 8 7.04688ZM4.14286 6.875V2.5C4.14286 1.80977 3.5679 1.25 2.85714 1.25C2.14638 1.25 1.57143 1.80977 1.57143 2.5V6.90625C1.6783 6.89453 1.78277 6.875 1.89286 6.875H4.14286ZM10.5714 7.5C11.2822 7.5 11.8571 6.94023 11.8571 6.25V2.5C11.8571 1.80977 11.2822 1.25 10.5714 1.25C9.86067 1.25 9.28571 1.80977 9.28571 2.5V6.25C9.28571 6.94141 9.86027 7.5 10.5714 7.5ZM14.4286 3.75C13.7178 3.75 13.1429 4.30977 13.1429 5V7.5C13.1429 8.19023 13.7178 8.75 14.4286 8.75C15.1393 8.75 15.7143 8.18984 15.7143 7.49922V5C15.7143 4.30859 15.1397 3.75 14.4286 3.75ZM13.0987 9.61719C12.5965 9.31708 12.2153 8.85865 12.0187 8.31836C11.604 8.58984 11.1058 8.74609 10.5714 8.74609C10.2644 8.74609 9.97397 8.68742 9.70116 8.59152C9.81205 8.92969 9.92857 9.30078 9.92857 9.6875C9.92857 11.241 8.63281 12.5 7.03571 12.5H4.14286C3.78784 12.5 3.5 12.2202 3.5 11.875C3.5 11.5312 3.78928 11.25 4.14286 11.25H7.03571C7.92326 11.25 8.64286 10.5504 8.64286 9.6875C8.64286 8.82461 7.92366 8.125 7.03571 8.125H1.89286C1.00531 8.125 0.285713 8.82422 0.285713 9.6875V12.1645C0.285713 13.4586 0.92857 14.6863 2.00576 15.4508L4.14286 16.9609V20H13.1429V16.6762C14.6817 15.7352 15.7143 14.0816 15.7143 12.1879V9.65234C15.3366 9.86719 14.8987 10 14.4286 10C13.9344 10 13.4844 9.85156 13.0987 9.61719Z"
      fill={color}
      fillOpacity="0.6"
    />
  </svg>
);
