import { useState } from 'react';

import useSWR from 'swr';

import { getActiveChallenges, IChallengesList } from 'api';
import { convertObjValuesToString } from 'helpers';
import { useDebouncedValue } from 'hooks';
import { prevState } from 'middleware/prevState';
const DEFAULT_FILTERS = {
  title: '',
  entryCostFrom: '',
  entryCostTo: '',
  rewardFrom: '',
  rewardTo: '',
  hero: '',
  password: false,
  freeSlots: false,
};

interface IChallengeListFilter {
  filters: typeof DEFAULT_FILTERS;
  activeChallenges: IChallengesList;
  isLoading: boolean;
  changeFilter: (key: string, value: string | boolean) => void;
}

export const useChallengeListFilter = (
  currentPage: number,
  itemPerPage: number
): IChallengeListFilter => {
  const [filters, setFilters] = useState(DEFAULT_FILTERS);
  const changeFilter = (key: string, value: string | boolean): void => {
    setFilters((prevState) => ({ ...prevState, [key]: value }));
  };
  const { password, freeSlots, ...restFilters } = useDebouncedValue(
    filters,
    1000
  );
  const { data: activeChallenges, isValidating } = useSWR(
    () => {
      const params = new URLSearchParams(
        convertObjValuesToString({
          ...(password && { password }),
          ...(freeSlots && { freeSlots }),
          ...restFilters,
          offset: (currentPage - 1) * itemPerPage,
          limit: itemPerPage,
        })
      );

      return `api/challenges/active?${params.toString()}`;
    },
    () =>
      getActiveChallenges({
        ...(password && { password }),
        ...(freeSlots && { freeSlots }),
        ...restFilters,
        offset: (currentPage - 1) * itemPerPage,
        limit: itemPerPage,
      }),
    {
      use: [prevState],
    }
  );
  return {
    activeChallenges,
    isLoading: isValidating || !activeChallenges,
    changeFilter,
    filters,
  };
};
