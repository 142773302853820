import { FC } from 'react';

import { PlayFillIcon } from 'icons';
import { Card, Typography } from 'ui-kit';

import { useStyle } from './GameCard.styles';

interface IGameCardProps {
  className?: string;
  isParticipant?: boolean;
}

export const GameCard: FC<IGameCardProps> = ({
  className = '',
  isParticipant,
}) => {
  const { classes } = useStyle();
  const { Text } = Typography;
  return (
    <Card variant="p8" className={className} color="bgSecondary">
      <div className={classes.contentContainer}>
        <img
          src="/images/main-page/dota2.png"
          alt="Dota 2 image"
          className={classes.img}
        />
        {isParticipant && (
          <>
            <div className={classes.cardBlur} />
            <a href="steam://rungameid/570">
              <div className={classes.iconContainer}>
                <Text>
                  <PlayFillIcon />
                </Text>
              </div>
            </a>
          </>
        )}
      </div>
    </Card>
  );
};
