import { FC } from 'react';

import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { Typography } from 'ui-kit';

import { CATEGORIES_CHALLENGE, EParryViewMode } from '../../../Parries.model';
import { useStyle } from './ParryNavigation.styles';

interface IParryNavigation {
  changeCategory: (category: EParryViewMode) => void;
  activeMode: EParryViewMode;
}

export const ParryNavigation: FC<IParryNavigation> = ({
  changeCategory,
  activeMode,
}) => {
  const { t: translation } = useTranslation();
  const { classes } = useStyle();
  const { Text } = Typography;
  return (
    <div>
      {CATEGORIES_CHALLENGE.map(({ title, mode }) => (
        <button
          key={title}
          onClick={() => {
            changeCategory(mode);
          }}
          className={clsx(
            classes.button,
            mode === activeMode && classes.activeButton
          )}
        >
          <Text color={mode === activeMode ? 'primary' : 'secondary'}>
            {translation(title)}
          </Text>
        </button>
      ))}
    </div>
  );
};
