import { makeStyles } from 'theme';

export const useStyle = makeStyles()(() => ({
  container: {
    display: 'flex',
    height: 1000,
    flexDirection: 'column',
    marginTop: '5rem',
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center',
  },
  request: {
    marginTop: '1rem',
  },
}));
