import { makeStyles } from 'theme';

export const useStyle = makeStyles()((theme) => ({
  tagContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    width: 108,
    justifyContent: 'flex-end',
    gap: 4,
    height: 'fit-content',
  },
  tag: {
    width: 'fit-content',
  },
}));
