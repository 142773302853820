import { FC } from 'react';

import clsx from 'clsx';

import { Typography } from 'ui-kit';

import { useStyle } from './TextSwitch.styles';

export type TextOptionType = {
  label: string;
  value: number;
};

interface ITextSwitchProps {
  currentOption: number;
  onClick: (option: number) => void;
  options: TextOptionType[];
}

export const TextSwitch: FC<ITextSwitchProps> = ({
  onClick,
  currentOption,
  options,
}) => {
  const { classes } = useStyle();
  const { Text } = Typography;
  return (
    <div className={classes.switchContainer}>
      {options.map((option) => (
        <div
          key={option.value}
          className={clsx(
            classes.item,
            currentOption === option.value && classes.active
          )}
          onClick={() => onClick(option.value)}
        >
          <Text variant="b4" color="primary">
            {option.label}
          </Text>
        </div>
      ))}
    </div>
  );
};
