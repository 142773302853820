import { FC } from 'react';

import { motion } from 'framer-motion';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from 'ui-kit';

import {
  isHeroDefault,
  isItemsDefault,
  isOptionsDefault,
} from '../../CreateParry.model';
import { useStyle } from './Footer.styles';

interface IFooterProps {
  currentStep: number;
  onNextClick: () => void;
  onPrevClick: () => void;
}

export const Footer: FC<IFooterProps> = ({
  currentStep,
  onNextClick,
  onPrevClick,
}) => {
  const { t: translation } = useTranslation();
  const { classes } = useStyle();
  const { control } = useFormContext();
  const options = useWatch({ name: 'options', control });
  const winInsensitivity = useWatch({ name: 'winInsensitivity', control });
  const hero = useWatch({ name: 'hero', control });
  const items = useWatch({ name: 'items', control });
  const isSkipHeroBuild = isHeroDefault(hero) && isItemsDefault(items);
  const isSkipGameOptions = isOptionsDefault(options, winInsensitivity);
  return (
    <div className={classes.footerContainer}>
      {currentStep !== 0 && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Button onClick={onPrevClick} color="transparent" buttonSize="l">
            {translation('parry.form.footer.backBtn')}
          </Button>
        </motion.div>
      )}
      <Button
        className={classes.nextButton}
        onClick={onNextClick}
        buttonSize="l"
      >
        {currentStep === 0 && translation('parry.form.footer.continueBtn')}
        {currentStep === 1 &&
          (isSkipHeroBuild
            ? translation('parry.form.footer.skipBtn')
            : translation('parry.form.footer.continueBtn'))}
        {currentStep === 2 &&
          (isSkipGameOptions
            ? translation('parry.form.footer.skipBtn')
            : translation('parry.form.footer.continueBtn'))}
        {currentStep === 3 && translation('parry.form.footer.previewBtn')}
      </Button>
    </div>
  );
};
