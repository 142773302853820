import { FC, useContext, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  createChallenge,
  ICreatedChallenge,
  isErrorResponse,
  updateChallenge,
} from 'api';
import { ModalContext } from 'components';
import { CHALLENGE_FORM_DATA_KEY } from 'consts';
import { BackgroundContext } from 'Layout';
import { Button } from 'ui-kit';

import { prepareChallangeData } from '../../ChallengeView.model';
import { useStyle } from './BottomButtons.styles';

interface IDraftButton {
  challenge: ICreatedChallenge;
}

export const DraftButton: FC<IDraftButton> = ({ challenge }) => {
  const [isDrafting, setIsDrafting] = useState(false);
  const navigate = useNavigate();
  const { t: translation } = useTranslation();
  const { classes } = useStyle();
  const { setBackgroundState } = useContext(BackgroundContext);
  const {
    galleryModal: { resetImage },
  } = useContext(ModalContext);
  const onCreateDraftClick = async (): Promise<void> => {
    try {
      setIsDrafting(true);
      const preparedData = prepareChallangeData(challenge);
      const challengeResponse = challenge?.isFetched
        ? await updateChallenge({ ...preparedData, id: challenge?.id })
        : await createChallenge(preparedData);
      if (!isErrorResponse(challengeResponse)) {
        navigate('/challenges/my/draft');
        localStorage.removeItem(CHALLENGE_FORM_DATA_KEY);
        setBackgroundState(0);
        resetImage();
      }
    } catch (err) {
      console.error('Failed to create draft', err);
    } finally {
      setIsDrafting(false);
    }
  };
  return (
    <Button
      className={classes.draftBtn}
      onClick={onCreateDraftClick}
      color="secondary"
      buttonSize="l"
      disabled={isDrafting}
    >
      {isDrafting
        ? translation('challengeView.creating')
        : translation('challengeView.draft')}
    </Button>
  );
};
