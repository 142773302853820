import { FC } from 'react';

import { IWinner } from 'api';
import { Typography } from 'ui-kit';

import { useStyle } from '../../ChallengeView.styles';

interface IWinnerEqualList {
  winners: IWinner[];
}

export const WinnerEqualList: FC<IWinnerEqualList> = ({ winners }) => {
  const { classes } = useStyle();
  const { Text, Headline } = Typography;
  return (
    <div className={classes.winnerEqualList}>
      {winners.map((winner, index) => (
        <div className={classes.winnerEqualItem} key={index}>
          <Headline className={classes.winnerEqualPlace} variant="h3">
            {index + 1}
          </Headline>
          <img className={classes.winnerEqualAvatar} src={winner.avatarUrl} />
          <Text variant="b4" color="primary">
            {winner.username}
          </Text>
        </div>
      ))}
    </div>
  );
};
