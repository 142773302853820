import { TFunction } from 'react-i18next';

export enum EParryViewMode {
  AllActive = 'allActive',
  Draft = 'draft',
  MyActive = 'myActive',
  History = 'history',
}

export function isParryViewMode(path: string): path is EParryViewMode {
  return Object.values(EParryViewMode).includes(path as EParryViewMode);
}

export const LIMIT_OPTIONS = [15, 30, 50];

export enum EParryRole {
  Both,
  Owner,
  Player,
}

interface ISelectOption {
  value: EParryRole;
  label: string;
}

export const SELECT_FILTER_ROLES = (
  translation: TFunction<'translation', undefined>
): ISelectOption[] => [
  {
    value: EParryRole.Both,
    label: translation('parries-list.roles.option-1'),
  },
  {
    value: EParryRole.Owner,
    label: translation('parries-list.roles.option-2'),
  },
  {
    value: EParryRole.Player,
    label: translation('parries-list.roles.option-3'),
  },
];

export const CATEGORIES_CHALLENGE = [
  {
    title: 'parries-list.category.active',
    mode: EParryViewMode.MyActive,
  },
  { title: 'parries-list.category.draft', mode: EParryViewMode.Draft },
  {
    title: 'parries-list.category.history',
    mode: EParryViewMode.History,
  },
];
