import { makeStyles } from 'theme';

export const useStyle = makeStyles()((theme) => ({
  img: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    zIndex: 1,
  },
  contentContainer: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  cardBlur: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: '2',
    backgroundColor: theme.palette.bg.overlay,
  },
  iconContainer: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translateX(-50%) translateY(-50%)',
    zIndex: 3,
    width: 48,
    height: 48,
    borderRadius: '50%',
    backgroundColor: theme.palette.typo.link,
    cursor: 'pointer',
    ...theme.layouts.flexCenter,
  },
}));
