import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { ICreatedChallenge } from 'api';
import { OPTIONS_TERM } from 'consts';
import { Card, Typography } from 'ui-kit';

import { useStyle } from './ChallengeTags.styles';

interface IFooter {
  challenge: ICreatedChallenge;
}

export const ChallengeTags: FC<IFooter> = ({ challenge }) => {
  const { t: translation } = useTranslation();
  const { classes } = useStyle();
  const { Text } = Typography;
  const additionalOptions = challenge?.options.slice(1);
  const options = OPTIONS_TERM(translation);
  const tags = options
    .filter(
      (option) =>
        additionalOptions.findIndex(
          (challengeOption) => challengeOption.term === option.value
        ) !== -1
    )
    .map((option) => option.label);
  return (
    <div className={classes.tagContainer}>
      {tags.map((tag) => (
        <Card
          key={tag}
          variant="p2x4"
          className={classes.tag}
          color="bgGhost"
          borderRadius="br2"
        >
          {' '}
          <Text variant="b7" color="typoLabel">
            {tag}
          </Text>
        </Card>
      ))}
    </div>
  );
};
