export const HowToConfig = {
  title: 'predictionsAbout.howTo.title',
  steps: {
    stepFirst: {
      titleKey: 'predictionsAbout.howTo.steps.stepFirst.title',
      descriptionKey: 'predictionsAbout.howTo.steps.stepFirst.description',
    },
    stepSecond: {
      titleKey: 'predictionsAbout.howTo.steps.stepSecond.title',
      descriptionKey: 'predictionsAbout.howTo.steps.stepSecond.description',
    },
    stepThird: {
      titleKey: 'predictionsAbout.howTo.steps.stepThird.title',
      descriptionKey: 'predictionsAbout.howTo.steps.stepThird.description',
    },
    stepFourth: {
      titleKey: 'predictionsAbout.howTo.steps.stepFourth.title',
      descriptionKey: 'predictionsAbout.howTo.steps.stepFourth.description',
    },
  },
  heroes: {
    heroFirstUrl: '/images/predictions/dota2-hero-1.png',
    heroSecondUrl: '/images/predictions/dota2-hero-2.png',
  },
};
