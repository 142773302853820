import { makeStyles } from 'theme';

export const useStyle = makeStyles()((theme) => ({
  buttonsContainer: {
    position: 'relative',
    marginTop: '2.5rem',
    marginBottom: '4rem',
    display: 'flex',
    justifyContent: 'flex-end',
    maxWidth: 1170,
    marginLeft: 0,
  },
  buttons: {
    display: 'flex',
    position: 'relative',
  },
  draftBtn: {
    width: 120,
  },
  publishBtnContainer: {
    marginLeft: '1rem',
    position: 'relative',
  },
  error: {
    ...theme.fontStyles.text14,
    position: 'absolute',
    left: 0,
    bottom: '-60%',
    color: theme.palette.typo.alert,
    whiteSpace: 'nowrap',
  },
  errorCount: {
    bottom: '-100%',
  },
}));
