import { FC, useState } from 'react';

import TagManager from 'react-gtm-module';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import { activatePromoCode, isErrorResponse } from 'api';
import { Button, Card, Input, Typography } from 'ui-kit';

import { useStyle } from '../../Profile.styles';

export const PromoCard: FC = () => {
  const [promoCode, setPromoCode] = useState('');
  const [isApplying, setIsApplying] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState('');
  const { classes } = useStyle();
  const { Text } = Typography;
  const { data: balance } = useSWR('api/balance');
  const isFinanceDead = !balance || isErrorResponse(balance);

  const onActivePromoCode = async (): Promise<void> => {
    try {
      setIsSuccess(false);
      setError('');
      setIsApplying(true);
      const promoCodeResponse = await activatePromoCode(promoCode);

      if (promoCodeResponse.status !== 200) {
        const error = await promoCodeResponse.json();
        isErrorResponse(error) && setError(error.message);
      } else {
        TagManager.dataLayer({
          dataLayer: { event: 'promocode_activated', promocode: promoCode },
        });
        setIsSuccess(true);
      }
    } catch (err) {
      console.error('Failed to activate promo code', err);
      setError(translation('profile.promo.serverError'));
    } finally {
      setIsApplying(false);
    }
  };
  const { t: translation } = useTranslation();
  const { Headline } = Typography;
  return (
    <Card variant="p24" color="bgGhost" className={classes.promoBlock}>
      <Headline variant="h4" className={classes.promoTitle}>
        {translation('profile.promo.title')}
      </Headline>
      <Input
        className={classes.input}
        error={error}
        onChange={(e) => {
          setPromoCode(e.target.value);
        }}
        placeholder={translation('profile.promo.placeholder')}
        maxLength={20}
        value={promoCode}
      />
      {error && (
        <small className={classes.promoError}>
          <Text variant="b4" color="alert">
            {error}
          </Text>
        </small>
      )}
      <Button
        onClick={onActivePromoCode}
        className={classes.promoBtn}
        disabled={!promoCode || isFinanceDead}
      >
        {isApplying
          ? translation('profile.promo.processButton')
          : translation('profile.promo.button')}
      </Button>
      {isSuccess && (
        <small className={classes.promoSuccess}>
          <Text variant="b4" color="success">
            {translation('profile.promo.activated')}
          </Text>
        </small>
      )}
    </Card>
  );
};
