import { FC, useContext, useEffect } from 'react';

import clsx from 'clsx';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import { ModalContext } from 'components';
import { correctAbsoluteUrl } from 'helpers';
import { PhotoIcon } from 'icons';
import {
  Button,
  Input,
  Checkbox,
  Counter,
  Duration,
  ErrorMessage,
  Typography,
} from 'ui-kit';

import {
  ParryFormValueType,
  ERROR_MESSAGES_TRANSLATIONS,
  MAX_PARRY_DURATION,
  MIN_PARRY_DURATION,
  text,
} from '../../CreateParry.model';
import { useStyle } from './General.styles';

export const General: FC = () => {
  const { t: translation } = useTranslation();
  const {
    register,
    control,
    setValue,
    formState: { errors },
  } = useFormContext<ParryFormValueType>();
  const isSetPassword = useWatch({ name: 'setPassword', control });
  const isRestartEnabled = useWatch({ name: 'restart', control });
  const isEndlessly = useWatch({ name: 'restartEnd', control });
  const image = useWatch({ name: 'image', control });

  const { classes } = useStyle();
  const { data: user } = useSWR('api/user');
  const ERROR_MESSAGES = ERROR_MESSAGES_TRANSLATIONS(translation);

  useEffect(() => {
    if (isEndlessly) {
      setValue('iterations', 0);
    } else {
      setValue('iterations', 1);
    }
  }, [isEndlessly]);

  useEffect(() => {
    if (isRestartEnabled) {
      setValue('iterations', 1);
    }
  }, [isRestartEnabled]);

  const {
    galleryModal: { selectedImage, openGallery, setImage },
  } = useContext(ModalContext);
  useEffect(() => {
    setImage(image);
  }, []);
  useEffect(() => {
    setValue('image', selectedImage);
  }, [selectedImage]);
  const { Headline, Text } = Typography;
  return (
    <section>
      <Headline variant="h2" className={classes.title}>
        {translation('parry.form.general.title')}
      </Headline>
      <Text className={classes.description}>
        {translation('parry.form.general.description')}
      </Text>
      <div className={classes.settingsContainer}>
        <div className={classes.inputContainer}>
          <Input
            title={translation('parry.form.general.name')}
            {...register('title', {
              required: {
                value: true,
                message: ERROR_MESSAGES['title'],
              },
              maxLength: {
                value: 20,
                message: ERROR_MESSAGES['title'],
              },
              minLength: {
                value: 4,
                message: ERROR_MESSAGES['title'],
              },
            })}
            maxLength={20}
            placeholder={translation('parry.form.general.name')}
            tooltip={translation(text.title)}
            error={errors['title']?.message}
          />
          <div className={classes.durationContainer}>
            <div>
              <Controller
                name="expiredIn"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: ERROR_MESSAGES['duration'],
                  },
                  max: {
                    value: MAX_PARRY_DURATION,
                    message: ERROR_MESSAGES['duration'],
                  },
                  min: {
                    value: MIN_PARRY_DURATION,
                    message: ERROR_MESSAGES['duration'],
                  },
                }}
                render={({ field }) => (
                  <Duration
                    {...field}
                    tooltipText={translation(text.duration)}
                    title={translation('parry.form.general.expiredIn')}
                    error={errors['duration']?.message}
                  />
                )}
              />
            </div>
          </div>
          <Input
            type="password"
            title={translation('parry.form.general.password')}
            autoComplete="new-password"
            placeholder={translation('parry.form.general.password')}
            disabled={!isSetPassword}
            maxLength={20}
            tooltip={translation(text.password)}
            error={errors['password']?.message}
            {...register('password', {
              required: {
                value: isSetPassword,
                message: ERROR_MESSAGES.requiredFiled,
              },
              maxLength: {
                value: isSetPassword ? 20 : null,
                message: ERROR_MESSAGES['password'],
              },
              minLength: {
                value: isSetPassword ? 4 : null,
                message: ERROR_MESSAGES['password'],
              },
            })}
          />{' '}
          <Controller
            name={`setPassword`}
            control={control}
            render={({ field }) => (
              <Checkbox
                className={classes.checkBox}
                label={translation('parry.form.general.setPassword')}
                checked={!!field.value}
                onChange={field.onChange}
              />
            )}
          />
        </div>
        <div className={classes.imageContainer}>
          <Controller
            name={`image`}
            control={control}
            rules={{
              required: {
                value: true,
                message: ERROR_MESSAGES.cover,
              },
            }}
            render={({ field }) => (
              <input className={classes.hiddenInput} {...field} />
            )}
          />

          {image ? (
            <img
              src={correctAbsoluteUrl(image)}
              alt="active-parry-cover"
              className={classes.image}
            />
          ) : (
            <div className={classes.imagePlaceholder}>
              <PhotoIcon />
              {errors['image'] && (
                <ErrorMessage
                  message={errors['image']?.message}
                  className={classes.imgError}
                />
              )}
            </div>
          )}
          <Button
            className={classes.imgButton}
            type="button"
            onClick={openGallery}
          >
            {translation('parry.form.general.chooseCover')}
          </Button>
        </div>
      </div>
    </section>
  );
};
