import { makeStyles } from 'theme';

interface IStyleProps {
  challengeLabel?: string;
  parryLabel?: string;
  predictionLabel?: string;
  profileLabel?: string;
}

export const useStyle = makeStyles<IStyleProps>()(
  (theme, { challengeLabel, predictionLabel, profileLabel, parryLabel }) => ({
    earlyAccess: {
      width: '100%',
      textAlign: 'center',
      position: 'absolute',
      bottom: '68px',
      display: 'flex',
      flexDirection: 'column',
    },
    iconContainer: {
      marginTop: 'auto',
      marginBottom: 100,
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    icon: {
      width: 24,
      height: 24,
      position: 'relative',
      cursor: 'pointer',
      marginBottom: 18,
      color: theme.palette.typo.system,
      transition: 'color 0.3s ease-in-out',
      ':hover': {
        color: theme.palette.control.primary.bg,
      },
      ':active': {
        color: theme.palette.control.primary.bgPressed,
      },
    },

    logo: {
      ...theme.layouts.flexJCcenter,
      zIndex: '4',
      position: 'fixed',
      left: 20,
      top: 20,
      height: '32px',
      width: '32px',
      verticalAlign: 'middle',
    },
    navigation: {
      display: 'flex',
      flexDirection: 'column',
      position: 'fixed',
      left: '0',
      top: '0',
      height: '100%',
      width: '72px',
      paddingTop: '72px',
      borderRight: `1px solid ${theme.palette.bg.ghostLight}`,
      backgroundColor: 'transparent',
      zIndex: '1',
    },

    navIconActive: {
      position: 'relative',
      backgroundColor: `${theme.palette.typo.link} !important`,
      color: `${theme.palette.typo.primary} !important`,
      transitionProperty: 'all',
      transitionTimingFunction: 'ease-in-out',
      transitionDuration: '300ms',
    },
    navIcon: {
      ...theme.layouts.flexAICenter,
      ...theme.layouts.marginXauto,
      justifyContent: 'center',
      marginTop: '24px',
      height: '48px',
      width: '48px',
      borderRadius: '8px',
      position: 'relative',
      color: theme.palette.typo.system,
      transitionProperty: 'color, background-color ',
      transitionTimingFunction: 'ease-in-out',
      transitionDuration: '300ms',
      ':first-child': {
        marginTop: '2rem',
      },
      ':hover': {
        backgroundColor: theme.palette.bg.ghostLight,
        color: theme.palette.typo.primary,
      },
      '::after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        left: '68px',
        height: 44,
        width: 'fit-content',
        padding: '12px',
        backgroundColor: theme.palette.bg.ghostLight,
        ...theme.fontStyles.text14,
        ...theme.fontStyles.fontFamilyNunito,
        borderRadius: '8px',
        opacity: 0,
        zIndex: 4,
      },
      ':nth-child(1):hover::after': {
        content: `'${challengeLabel}'`,
        opacity: 1,
      },
      ':nth-child(2):hover::after': {
        content: `'${predictionLabel}'`,
        opacity: 1,
      },
      ':nth-child(3):hover::after': {
        content: `'${profileLabel}'`,
        opacity: 1,
      },
      ':nth-child(4):hover::after': {
        content: `'${parryLabel}'`,
        opacity: 1,
      },
    },
    navIconCommon: {
      ...theme.layouts.flexAICenter,
      ...theme.layouts.marginXauto,
      justifyContent: 'center',
      marginTop: '24px',
      height: '48px',
      width: '48px',
      borderRadius: '8px',
      position: 'relative',
      color: theme.palette.typo.system,
      transitionProperty: 'color, background-color ',
      transitionTimingFunction: 'ease-in-out',
      transitionDuration: '300ms',
      ':first-child': {
        marginTop: '2rem',
      },
      ':hover': {
        backgroundColor: theme.palette.bg.ghostLight,
        color: theme.palette.typo.primary,
      },
      '::after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        left: '68px',
        height: 44,
        width: 'fit-content',
        padding: '12px',
        backgroundColor: theme.palette.bg.ghostLight,
        ...theme.fontStyles.text14,
        ...theme.fontStyles.fontFamilyNunito,
        borderRadius: '8px',
        opacity: 0,
        zIndex: 4,
      },
      ':nth-child(1):hover::after': {
        content: `'${challengeLabel}'`,
        opacity: 1,
      },
      ':nth-child(2):hover::after': {
        content: `'${parryLabel}'`,
        opacity: 1,
      },
    },
    languageContainer: {
      position: 'absolute',
      left: '50%',
      transform: 'translateX(-50%)',
      bottom: 20,
      cursor: 'pointer',
    },
  })
);
