import { makeStyles } from 'theme';

export const useStyle = makeStyles()((theme) => ({
  'gradient-text-gold': {
    background:
      '-webkit-linear-gradient(168.12deg, #DEBB6C 4.13%, #E3CB8E 101.73%)',
  },

  'gradient-text-silver': {
    background:
      '-webkit-linear-gradient(168.12deg, #C7C8C8 4.13%, #DDDEDE 101.73%)',
  },

  'gradient-text-bronze': {
    background:
      '-webkit-linear-gradient(168.12deg, #AE7C3C 4.13%, #BB9768 101.73%)',
  },

  'gradient-red': {
    background: 'linear-gradient(89.03deg, #d34e31 0.64%, #fb18a0 175.76%)',
  },

  'gold-shadow': {
    boxShadow: '0px 4px 23px rgba(242, 186, 47, 0.5)',
  },
  'silver-shadow': {
    boxShadow: '0px 4px 23px rgba(255, 255, 255, 0.5)',
  },
  'bronze-shadow': {
    boxShadow: '0px 4px 23px rgba(222, 120, 0, 0.5)',
  },

  gradientTextTrophy: {
    backgroundClip: 'text',
    color: 'transparent',
    marginLeft: 8,
  },

  maskGradient: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    background:
      'linear-gradient(180deg, rgba(14, 23, 33, 0) -80.8%, #0E1721 100%)',
  },

  progressSpinner: {
    ...theme.layouts.flexCenter,
    width: '100%',
    marginTop: '170px',
    marginBottom: '1000px',
  },

  // WinnersEqual

  winnersEqualContainer: {
    position: 'relative',
    width: '100%',
    height: 80,
    marginTop: 20,
    ':first-of-type': {
      marginTop: 0,
    },
  },
  winnersLeftBar: {
    width: 'calc(100% - 18px)',
    height: 3,
    position: 'absolute',
    left: 9,
    borderRadius: 2,
    top: 0,
  },
  winnersAmount: {
    marginLeft: '0.25rem',
    ...theme.fontStyles.text14,
    fontWeight: 500,
    color: theme.palette.gold,
  },
  winnersNumber: {
    fontWeight: 500,
    color: theme.palette.gold,
    ...theme.fontStyles.text18,
  },
  winnersReward: {
    ...theme.fontStyles.text18,
  },
  white: {
    color: theme.palette.typo.primary,
  },
  yellow: {
    color: theme.palette.typo.warning,
  },

  // Winners PlaceHolder
  winnersPlaceholder: {
    marginLeft: '0.25rem',
    ...theme.fontStyles.text14,
    color: theme.palette.typo.primary,
  },

  // WinnersEqualList
  winnerEqualList: {
    backgroundColor: theme.palette.bg.secondary,
    height: 'fit-content',
    padding: 20,
    paddingTop: 0,
    paddingBottom: 0,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    overflowY: 'scroll',
    maxHeight: 295,
    '::-webkit-scrollbar': {
      width: 4,
    },
    '::-webkit-scrollbar-thumb': {
      background: theme.palette.control.secondary.bgBorder,
      borderRadius: 11,
      boxShadow: '0px 4px 9px rgba(0, 0, 0, 0.25)',
    },
  },
  winnerEqualItem: {
    ...theme.layouts.flexAICenter,
    marginBottom: 20,
  },
  winnerEqualAvatar: {
    width: 32,
    height: 32,
    borderRadius: '50%',
    marginRight: 12,
  },
  winnerEqualPlace: {
    color: theme.palette.gold,
    marginRight: 12,
  },

  winnersEqualOpenList: {
    borderBottomLeftRadius: '0 !important',
    borderBottomRightRadius: '0 !important',
  },
  // WinnersList

  winnersListContainer: {
    display: 'flex',
    flexDirection: 'column',
  },

  mainInfoContainer: {
    width: 870,
  },
  container: {
    width: '100%',
    display: 'flex',
  },
  winnerPlaceHolderContainer: {
    width: '100%',
    ...theme.layouts.flexJCSpaceBetween,
  },
  winnerPlaceholderCard: {
    marginTop: 20,
    ':first-of-type': {
      marginTop: 0,
    },
    width: '100%',
    height: 80,
    position: 'relative',
  },
  trophyContainer: {
    ...theme.layouts.flexCenter,
    width: 76,
  },
  trophyEqualContainer: {
    ...theme.layouts.flexAICenter,
    width: 150,
  },
  equalTrophy: {
    marginRight: 10,
    height: 32,
    width: 32,
    display: 'inline-block',
  },
  sideBarContainer: {
    display: 'flex',
    marginTop: 30,
    width: 270,
    marginLeft: 30,
    flexDirection: 'column',
  },
  trophyIcon: {
    width: 32,
    height: 32,
  },
}));
