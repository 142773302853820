import { createContext, FC, useState } from 'react';

import clsx from 'clsx';
import { Outlet, useLocation } from 'react-router-dom';

import { Footer } from '../Footer';
import { Header } from '../Header';
import { Logo } from '../Logo';
import { Navigation } from '../Navigation';
import { useStyle } from './Page.styles';

interface IBackgroundContext {
  setBackgroundState?: (state: number) => void;
  backgroundState?: number;
  setChallengeBackground?: (background: string) => void;
  setParryBackground?: (background: string) => void;
}

export const BackgroundContext = createContext<IBackgroundContext>({});

type pageUrl =
  | '/home'
  | '/login'
  | '/predictions'
  | '/challenges'
  | '/challenge'
  | '/home/product'
  | '/profile/referral';

const backgroundMap: Record<pageUrl, string> = {
  '/home': 'home',
  '/login': '',
  '/predictions': 'predictions',
  '/challenges': 'challenges',
  '/challenge': 'smartBackground',
  '/home/product': 'product',
  '/profile/referral': 'referral',
};

const getBackgroundClass = (
  url: string,
  challengeBackground: string,
  parryBackground: string
): string => {
  const className = backgroundMap[url];
  if (className) {
    return className;
  }
  if (challengeBackground) {
    return 'challengeBackground';
  }
  if (parryBackground) {
    return 'parryBackground';
  }

  return 'other';
};

export const Page: FC = () => {
  const { pathname } = useLocation();
  const [backgroundState, setBackgroundState] = useState<number>(0);
  const [challengeBackground, setChallengeBackground] = useState<string>('');
  const [parryBackground, setParryBackground] = useState<string>('');
  const { classes } = useStyle({ step: backgroundState, challengeBackground });
  const backgroundClassName = getBackgroundClass(
    pathname,
    challengeBackground,
    parryBackground
  );
  return (
    <BackgroundContext.Provider
      value={{
        setBackgroundState,
        backgroundState,
        setChallengeBackground,
        setParryBackground,
      }}
    >
      <div>
        <Header />
        <Logo />
      </div>
      <div className={clsx(classes.page, classes[backgroundClassName])}>
        <Navigation />
        <div className={classes.container}>
          <Outlet />
        </div>
        <Footer />
      </div>
    </BackgroundContext.Provider>
  );
};
