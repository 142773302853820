import { makeStyles } from 'theme';

export const useStyle = makeStyles()((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '24px 24px 48px 24px',
    width: 270,
    height: 300,
    borderRadius: 10,
  },
  title: {
    width: '100%',
    height: 28,
    backgroundColor: theme.palette.bg.ghost,
    borderRadius: 2,
  },
  heroContainer: {
    display: 'flex',
    gap: 8,
    width: '100%',
    marginTop: 24,
    ':first-of-type': {
      marginTop: 30,
    },
  },
  heroImg: {
    width: 64,
    height: 40,
    backgroundColor: theme.palette.bg.ghost,
    borderRadius: 2,
  },
  heroInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 6,
  },
  heroName: {
    width: 128,
    height: 20,
    backgroundColor: theme.palette.bg.ghost,
    borderRadius: 2,
  },
  heroInfoSubContainer: {
    display: 'flex',
    gap: 8,
  },
  heroInfo: {
    width: 65,
    height: 14,
    backgroundColor: theme.palette.bg.ghost,
    borderRadius: 2,
  },
}));
