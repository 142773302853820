import { FC } from 'react';

import { Login } from './Login';
import { useStyle } from './LoginReferral.styles';

export const LoginReferral: FC = () => {
  const { classes } = useStyle();

  return (
    <div className={classes.loginContainer}>
      <Login />
    </div>
  );
};
