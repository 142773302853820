import { FC } from 'react';

import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { AlertIcon } from 'icons';
import { Typography } from 'ui-kit';

import { useStyle } from './BottomNotification.styles';

interface IBottomNotificationProps {
  isVisible?: boolean;
}

export const BottomNotification: FC<IBottomNotificationProps> = ({
  isVisible = false,
}) => {
  const { t: translation } = useTranslation();
  const { classes } = useStyle();
  const { Text } = Typography;
  return (
    <div className={clsx(classes.container, !isVisible && classes.visibility)}>
      <Text color="warning">
        <AlertIcon width={24} height={24} />
      </Text>
      <Text variant="b4">
        {translation('notification.wallet')}{' '}
        <a
          target="_blank"
          href="https://gamegreed.gitbook.io/gamegreed/"
          rel="noreferrer"
        >
          <Text variant="b5" color="link">
            {translation('notification.walletLink')}
          </Text>
        </a>
      </Text>
    </div>
  );
};
