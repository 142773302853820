import { makeStyles } from 'theme';

export const useStyle = makeStyles()((theme) => ({
  login: {
    width: 740,
    margin: '0 auto',
    border: `1px solid ${theme.palette.bg.border}`,
    borderRadius: 10,
    padding: '48px 90px',
    boxSizing: 'border-box',
    background: 'rgba(24, 30, 51, 0.72)',
  },
  loginContainer: {
    paddingTop: 300,
    backgroundImage: "url('/images/error-referral-bg.png')",
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    width: '100%',
    height: '100vh',
  },
}));
