export interface IErrorResponse {
  message: string;
  code: number;
}

export const isErrorResponse = (
  response: unknown | IErrorResponse
): response is IErrorResponse => {
  const errResponse = response as IErrorResponse;
  return !!errResponse?.code && !!errResponse?.message;
};
