import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { IMatch } from 'api';
import { Typography } from 'ui-kit';

export const TableResult: FC<{ match: IMatch }> = ({ match }) => {
  const { t: translation } = useTranslation();
  const { Text } = Typography;

  switch (match.result) {
    case 1:
      return (
        <Text variant="b3" color="success">
          {translation('predictions.table.win')}
        </Text>
      );
    case 0:
      return (
        <Text variant="b3" color="red">
          {translation('predictions.table.lose')}
        </Text>
      );
    default:
      return <Text variant="b3">{translation('predictions.table.na')}</Text>;
  }
};
