import { CSSObject } from 'tss-react';

import { makeStyles } from 'theme';

import { IconType } from './CircleIcon';

export const useStyle = makeStyles<{ type: IconType }>()((theme, { type }) => {
  const colorMap: Record<IconType, CSSObject> = {
    success: {
      color: theme.palette.typo.success,
      border: `2px solid ${theme.palette.typo.success}`,
    },
    error: {
      color: theme.palette.typo.alert,
      border: `2px solid ${theme.palette.typo.alert}`,
    },
  };

  return {
    iconContainer: {
      ...colorMap[type],
      ...theme.layouts.flexCenter,
      width: 64,
      height: 64,
      borderRadius: '50%',
    },
  };
});
