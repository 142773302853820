import { Duration as DurationDate } from 'luxon';

export const PERIODS = [
  {
    name: 'days',
    symbol: 'dd',
    max: 99,
  },
  {
    name: 'hours',
    symbol: 'hh',
    max: 23,
  },
  {
    name: 'minutes',
    symbol: 'mm',
    max: 59,
  },
];

export type DhmType = {
  days: string | number;
  hours: string | number;
  minutes: string | number;
};

export const dhmToMilliseconds = (duration: DhmType): number =>
  DurationDate.fromObject({
    day: +duration.days,
    hour: +duration.hours,
    minute: +duration.minutes,
  }).as('milliseconds');

export const millisecondsToDhm = (value: string): DhmType => {
  const [days, hours, minutes] = DurationDate.fromMillis(+value)
    .normalize()
    .toFormat('d:h:m')
    .split(':');
  return {
    minutes: +minutes || '',
    days: +days || '',
    hours: +hours || '',
  };
};
