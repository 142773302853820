import { FC } from 'react';

import { DateTime } from 'luxon';
import TagManager from 'react-gtm-module';
import { useTranslation } from 'react-i18next';
import useSWR, { mutate } from 'swr';

import { IPredictResponse, EPredictStatus, rejectPredict } from 'api';
import { useCountdown } from 'hooks';
import { Typography, Button } from 'ui-kit';

import { BLOCKS } from '../../Prediction.model';
import { PREDICT_MULTIPLIER } from './PredictCard';
import { useStyle } from './PredictCard.styles';
export const PredictionActive: FC = () => {
  const { data: activePredict, mutate: mutatePredict } =
    useSWR<IPredictResponse>('api/predict');
  const [minutes, seconds] = useCountdown(
    DateTime.fromISO(activePredict.createdAt).plus({ minutes: 5 }).toMillis()
  );

  const cancelPredict = async (): Promise<void> => {
    try {
      await rejectPredict();
      TagManager.dataLayer({
        dataLayer: {
          event: 'prediction_cancel',
          coins: `${activePredict.value}`,
        },
      });
      mutatePredict({ status: EPredictStatus.NONE });
      mutate('api/balance');
    } catch (err) {
      console.error('Failed to cancel predict', err);
    }
  };

  const isDisabledCancel = +seconds <= 0 && +minutes <= 0;
  const { t: translation } = useTranslation();
  const { classes } = useStyle();
  const { Text, Headline } = Typography;

  return (
    <>
      <div className={classes.predictParameters}>
        {BLOCKS(activePredict.value, PREDICT_MULTIPLIER).map((block) => (
          <div className={classes.parameter} key={block.title}>
            <Text variant="b5" className={classes.parameterTitle}>
              {translation(block.title)}
            </Text>
            <Text variant="b3" color="yellow">
              {block.value}
            </Text>
          </div>
        ))}
      </div>
      <Text variant="b5" className={classes.cancelText}>
        {translation('predictions.predict.cancel')}
      </Text>
      <div className={classes.timer}>
        <Headline variant="h2">{minutes > 0 ? minutes : '00'}</Headline>
        <Headline variant="h2" className={classes.timerSeparator}>
          :
        </Headline>
        <Headline variant="h2">{seconds > 0 ? seconds : '00'}</Headline>
      </div>
      <Button
        className={classes.cancelButton}
        onClick={cancelPredict}
        disabled={isDisabledCancel}
        color="secondary"
      >
        {translation('predictions.predict.cancelBtn')}
      </Button>
    </>
  );
};
