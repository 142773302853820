import { makeStyles } from 'theme';

export const useStyle = makeStyles()((theme) => ({
  predictContainer: {
    ...theme.layouts.flexJCSpaceBetween,
    flexWrap: 'wrap',
    rowGap: '1rem',
  },
  gameCard: {
    height: 300,
    width: 350,
    padding: '1.5rem',
    borderRadius: '8px',
    backgroundColor: theme.palette.bg.secondary,
  },
  predictBlock: {
    height: 300,
    width: 300,
    padding: '1.5rem',
    borderRadius: '8px',
    backgroundColor: theme.palette.bg.secondary,
  },
  winRateBlock: {
    height: 300,
    width: 275,
    padding: '1.5rem',
    borderRadius: '8px',
    backgroundColor: theme.palette.bg.secondary,
  },
  matchesSection: {
    ...theme.layouts.flexJCSpaceBetween,
    marginTop: '1rem',
    flexWrap: 'wrap',
    rowGap: '1rem',
  },
  matchesBlock: {
    height: 380,
    width: 770,
    position: 'relative',
    overflow: 'hidden',
    borderRadius: '8px',
    backgroundColor: theme.palette.bg.secondary,
  },
  predictBanner: {
    height: 380,
    width: 380,
    overflow: 'hidden',
  },

  // Prediction Ad

  predictionAd: {
    background: 'url("/images/game/bg-game-predict.png")',
    borderRadius: '8px',
    height: '100%',
    position: 'relative',
  },
  predictionAdTitle: {
    padding: '2rem',
    display: 'block',
  },
  predictionAdimg: {
    width: 364,
    marginLeft: 'auto',
    marginTop: 20,
  },
  preloginHero: {
    position: 'absolute',
    top: 175,
    right: -100,
    height: 640,
    width: 'auto',
  },
}));
