import { makeStyles } from 'theme';

export const useStyle = makeStyles<{ checked: boolean }>()(
  (theme, { checked }) => ({
    checkbox: {
      boxSizing: 'border-box',
      width: 20,
      height: 20,
      border: `2px solid ${
        checked
          ? theme.palette.control.secondary.bgBorder
          : theme.palette.control.default.typoLabel
      }`,
      borderRadius: 4,
      backgroundColor: checked
        ? theme.palette.control.primary.bg
        : 'transparent',
      transitionDuration: '300ms',
      ...theme.layouts.flexCenter,
    },
    hideInput: {
      display: 'none',
    },
    check: {
      transitionDuration: '300ms',
      opacity: checked ? 1 : 0,
      color: theme.palette.control.primary.typo,
    },
    checkboxContainer: {
      ...theme.layouts.flexAICenter,
      gap: 8,
      cursor: 'pointer',
      ':hover': {
        div: {
          border: `2px solid ${theme.palette.control.secondary.bgBorder}`,
          transitionDuration: '300ms',
        },
      },
    },
  })
);
