import { FC } from 'react';

import { ICreatedChallenge } from 'api';
import { calcChallengeDuration } from 'helpers';

import {
  EButtonType,
  EChallengeModals,
  getButtonType,
} from '../ChallengeView.model';
import {
  AcceptChallengeButton,
  ExitChallengeButton,
  StopRegButton,
} from './Buttons';

interface IActionButtonProps {
  challenge: ICreatedChallenge;
  openModal: (name: EChallengeModals) => void;
  openSteamModal: () => void;
  openHistoryModal: () => void;
  isHistoryMode: boolean;
  isHistoryClosed: boolean;
  isPreviewMode: boolean;
}

export const ActionButton: FC<IActionButtonProps> = ({
  challenge,
  openModal,
  isHistoryMode,
  isHistoryClosed,
  openSteamModal,
  openHistoryModal,
  isPreviewMode,
}) => {
  const { isFinished } = calcChallengeDuration(challenge);
  const buttonType = getButtonType(
    challenge,
    isHistoryMode,
    isFinished,
    isPreviewMode
  );

  switch (buttonType) {
    case EButtonType.StopRegistration: {
      return <StopRegButton openModal={openModal} challenge={challenge} />;
    }
    case EButtonType.ExitChallenge: {
      return (
        <ExitChallengeButton challenge={challenge} openModal={openModal} />
      );
    }
    case EButtonType.AcceptChallenge: {
      return (
        <AcceptChallengeButton
          isHistoryClosed={isHistoryClosed}
          openModal={openModal}
          challenge={challenge}
          openSteamModal={openSteamModal}
          openHistoryModal={openHistoryModal}
        />
      );
    }
    case EButtonType.None: {
      return null;
    }
  }
};
