import { makeStyles } from 'theme';

export const useStyle = makeStyles()((theme) => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    gap: 8,
    borderRadius: 8,
    width: 370,
    height: 450,
    padding: 20,
  },
  nameFirst: {
    height: 21,
    width: 326,
    backgroundColor: theme.palette.bg.ghost,
    borderRadius: 2,
  },
  nameSecond: {
    height: 21,
    width: 261,
    backgroundColor: theme.palette.bg.ghost,
    borderRadius: 2,
  },
  rulesContainer: {
    display: 'flex',
    gap: 8,
    width: '100%',
  },
  ruleTitleFirst: {
    height: 10,
    width: 55,
    backgroundColor: theme.palette.bg.ghost,
    borderRadius: 2,
  },
  ruleTitleSecond: {
    height: 10,
    width: 33,
    backgroundColor: theme.palette.bg.ghost,
    borderRadius: 2,
  },
  ruleDescFirst: {
    height: 13,
    width: 76,
    backgroundColor: theme.palette.bg.ghost,
    borderRadius: 2,
  },
  ruleDescSecond: {
    height: 13,
    width: 47,
    backgroundColor: theme.palette.bg.ghost,
    borderRadius: 2,
  },
  ruleDescThird: {
    height: 13,
    width: 33,
    backgroundColor: theme.palette.bg.ghost,
    borderRadius: 2,
  },
  verticalDivider: {
    height: 30,
    width: 1,
    backgroundColor: theme.palette.bg.ghost,
  },
  ruleContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 6,
  },
  horizontalDivider: {
    height: 1,
    width: '100%',
    backgroundColor: theme.palette.bg.ghost,
  },
  conditionFirst: {
    height: 24,
    width: 100,
    backgroundColor: theme.palette.bg.ghost,
    borderRadius: 2,
  },
  conditionSecond: {
    height: 24,
    width: 139,
    backgroundColor: theme.palette.bg.ghost,
    borderRadius: 2,
  },
  conditionThird: {
    height: 24,
    width: 170,
    backgroundColor: theme.palette.bg.ghost,
    borderRadius: 2,
  },
  conditionFourth: {
    height: 24,
    width: 110,
    backgroundColor: theme.palette.bg.ghost,
    borderRadius: 2,
  },
  itemContainer: {
    display: 'flex',
    gap: 4,
  },
  item: {
    height: 20,
    width: 30,
    backgroundColor: theme.palette.bg.ghost,
    borderRadius: 2,
  },
}));
