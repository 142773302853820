import { makeStyles } from 'theme';

export const useStyle = makeStyles()((theme) => ({
  ...theme.layouts,
  tab: {
    marginLeft: '1rem',
    backgroundColor: theme.palette.bg.ghost,
    backdropFilter: 'blur(6px)',
    borderRadius: 8,
    padding: '0.7rem 1rem',
  },

  // userCard

  balanceContainer: {
    cursor: 'pointer',
    ...theme.layouts.flexAICenter,
    borderRadius: '8px',
    backgroundColor: theme.palette.bg.ghost,
    backdropFilter: 'blur(6px)',
    padding: '0.5rem 1rem',
    margin: '0 0.5rem',
    ...theme.fontStyles.fontFamilyUbuntu,
    ...theme.fontStyles.text18,
    fontWeight: 500,
    textTransform: 'uppercase',
    color: theme.palette.typo.primary,
    ':hover': {
      backgroundColor: theme.palette.bg.ghostLight,
    },
    ':active': {
      backgroundColor: theme.palette.bg.default,
    },
  },
  balance: {
    marginLeft: '11px',
  },
  balanceIcon: {
    color: theme.palette.typo.system,
    ':active': {
      color: theme.palette.typo.primary,
    },
  },
  userCardContainer: {
    marginLeft: '1rem',
    cursor: 'pointer',
    ...theme.layouts.flexAICenter,
    position: 'relative',
  },
  userCard: {
    position: 'relative',
    ...theme.layouts.flexAICenter,
    width: 'fit-content',
    backgroundColor: theme.palette.bg.ghost,
    backdropFilter: 'blur(6px)',
    borderRadius: '8px',
    height: 48,
    padding: '8px 12px',
    ':hover': {
      backgroundColor: theme.palette.bg.ghostLight,
    },
  },
  open: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    backgroundColor: `${theme.palette.bg.default} !important`,
  },
  image: {
    ...theme.layouts.flexCenter,
    position: 'relative',
    height: '32px',
    width: '32px',
    borderRadius: '50%',
    overflow: 'hidden',
    marginRight: '12px',
  },
  avatar: {
    width: '27px',
    height: '27px',
  },
  arrowContainer: {
    position: 'relative',
  },
  arrowIcon: {
    opacity: 1,
    transition: 'all 0.3s ease-in-out',
  },
  arrowIconOpen: {
    position: 'absolute',
    top: 0,
    left: 0,
    opacity: 0,
    transition: 'all 0.3s ease-in-out',
  },
  menu: {
    position: 'absolute',
    right: 0,
    top: '47px',
    width: '100%',
    borderBottomLeftRadius: '4px',
    borderBottomRightRadius: '4px',
    backgroundColor: theme.palette.bg.default,
  },
  menuItem: {
    margin: '0.25rem 0',
    ...theme.layouts.flexAICenter,
    padding: '0.625rem 1rem',
    ':hover': {
      backgroundColor: theme.palette.bg.ghost,
    },
  },
  menuIcon: {
    marginRight: '0.625rem',
    width: '20px',
    height: '20px',
    color: theme.palette.typo.system,
  },
  userName: {
    color: theme.palette.typo.primary,
    marginRight: '8px',
  },

  // Header

  header: {
    position: 'fixed',
    left: 0,
    top: 0,
    ...theme.layouts.flexAICenter,
    zIndex: 4,
    height: 72,
    width: '100%',
    paddingLeft: '108px',
    paddingRight: '135px',
  },
  userMenuContainer: {
    marginLeft: 'auto',
    display: 'flex',
  },
  balancePlaceholder: {
    width: 140,
    height: 48,
    borderRadius: 8,
    marginRight: 16,
  },
  userPlaceholder: {
    width: 140,
    height: 48,
    borderRadius: 8,
  },
  placeholderContainer: {
    display: 'flex',
    padding: 12,
    gap: 12,
    height: 'fit-content',
    width: 'fit-content',
    alignItems: 'center',
    borderRadius: 8,
  },
  balanceIconPlaceholder: {
    width: 24,
    height: 24,
    borderRadius: 2,
    backgroundColor: theme.palette.bg.ghost,
  },
  balanceSkeleton: {
    width: 84,
    height: 24,
    borderRadius: 2,
    backgroundColor: theme.palette.bg.ghost,
  },
}));
