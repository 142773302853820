import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import type { IStatisticResponse } from 'api';
import { DonutChart, Title, Typography } from 'ui-kit';

import { useStyle } from './WinRateCard.styles';

interface IWinRateCardProps {
  statistic: IStatisticResponse;
}

export const WinRateCard: FC<IWinRateCardProps> = ({ statistic }) => {
  const { t: translation } = useTranslation();
  const { classes } = useStyle();

  const { Text, Headline } = Typography;
  return (
    <div>
      <Title className={classes.winRateTitle}>
        {translation('predictions.winRate.title')}
      </Title>
      {statistic?.totalGames > 0 ? (
        <div className={classes.winRateContent}>
          <DonutChart
            values={[statistic.wins, statistic.totalGames - statistic.wins]}
          />
          <div className={classes.winRateData}>
            <Text className={classes.winRateDataTitle} variant="b3">
              {translation('predictions.winRate.title')}
            </Text>
            <Headline className={classes.winPercent}>
              {Math.floor((statistic.wins / statistic.totalGames) * 100)} %
            </Headline>
            <div className={classes.rateBlock}>
              <div className={classes.flexColumn}>
                <Text variant="b7" className={classes.winTerm}>
                  {translation('predictions.winRate.wins')}
                </Text>
                <div className={classes.flexAICenter}>
                  <div className={classes.rateGreen} />
                  <Text variant="b3" className={classes.winAmount}>
                    {statistic.wins}
                  </Text>
                </div>
              </div>
              <div className={classes.flexColumn}>
                <Text variant="b7" className={classes.winTerm}>
                  {translation('predictions.winRate.lose')}
                </Text>
                <div className={classes.flexAICenter}>
                  <div className={classes.rateRed} />
                  <Text variant="b3" className={classes.winAmount}>
                    {statistic.totalGames - statistic.wins}
                  </Text>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={classes.noRateBlock}>
          <Text variant="b5" className={classes.noRateTitle}>
            {translation('predictions.noStatistic.title')}
          </Text>
          <Text variant="b5" className={classes.noRateDescr}>
            {translation('predictions.noStatistic.description')}
          </Text>
        </div>
      )}
    </div>
  );
};
