import { FC } from 'react';

import clsx from 'clsx';

import { useStyle } from './HorisontalDivider.styles';

export interface IHorizontalDividerProps {
  className?: string;
}

export const HorizontalDivider: FC<IHorizontalDividerProps> = ({
  className = '',
}) => {
  const { classes } = useStyle();
  return <div className={clsx(classes.divider, className)} />;
};
