import { makeStyles } from 'theme';

export const useStyle = makeStyles()((theme) => ({
  modal: {
    padding: 40,
    width: 400,
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
  button: {
    width: '100%',
    marginTop: 32,
  },
  icon: {
    marginBottom: 20,
  },
}));
