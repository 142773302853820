import { FC } from 'react';

import { ICommonIconProps } from './types';

export const UICheck20Icon: FC<ICommonIconProps> = ({
  color = 'currentColor',
  width = 20,
  height = 20,
  viewBox = '0 0 20 20',
  className = '',
  style,
}) => (
  <svg
    fill="none"
    width={width}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.4706 5.67517C16.7849 5.98947 16.7849 6.49904 16.4706 6.81334L8.95913 14.3248C8.64484 14.6391 8.13527 14.6391 7.82097 14.3248L3.52869 10.0325C3.2144 9.71825 3.2144 9.20868 3.52869 8.89438C3.84298 8.58009 4.35256 8.58009 4.66685 8.89438L8.39005 12.6176L15.3325 5.67517C15.6468 5.36088 16.1563 5.36088 16.4706 5.67517Z"
      fill={color}
    />
  </svg>
);
