import { FC } from 'react';

import { ICommonIconProps } from './types';

export const RecycleIcon: FC<ICommonIconProps> = ({
  color = '#8289A3',
  width = 16,
  height = 16,
  viewBox = '0 0 16 16',
  className = '',
  style,
}) => (
  <svg
    fill="none"
    width={width}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M15.3017 8.08161L16.5775 10.2916C16.8335 10.7349 16.9683 11.2378 16.9683 11.7497C16.9683 12.2616 16.8337 12.7645 16.5778 13.2079C16.3219 13.6513 15.9538 14.0195 15.5106 14.2755C15.0673 14.5316 14.5644 14.6665 14.0525 14.6666H12.3334V16.3333L8.16671 13.4166L12.3334 10.4999V12.1666H14.0517C14.1186 12.167 14.1846 12.1512 14.2441 12.1206C14.3036 12.09 14.3548 12.0454 14.3933 11.9908C14.4319 11.9361 14.4566 11.8729 14.4655 11.8066C14.4743 11.7403 14.467 11.6728 14.4442 11.6099L14.4125 11.5416L13.1367 9.33161L15.3017 8.08161ZM5.44755 5.81995L5.88921 10.8866L4.44755 10.0533L3.58755 11.5416C3.55374 11.5994 3.5344 11.6644 3.53116 11.7312C3.52793 11.7981 3.5409 11.8647 3.56898 11.9254C3.59705 11.9862 3.6394 12.0392 3.6924 12.08C3.7454 12.1209 3.80749 12.1483 3.87338 12.1599L3.94838 12.1666H6.50004V14.6666H3.94838C3.4364 14.6666 2.93345 14.5318 2.49007 14.2758C2.04669 14.0198 1.67851 13.6516 1.42253 13.2082C1.16654 12.7649 1.03178 12.2619 1.03179 11.7499C1.03179 11.238 1.16656 10.735 1.42255 10.2916L2.28171 8.80328L0.838379 7.96995L5.44671 5.81995H5.44755ZM10.4584 0.474115C10.9017 0.729948 11.27 1.09828 11.5259 1.54161L12.3842 3.02995L13.8284 2.19661L13.3867 7.26328L8.77671 5.11328L10.22 4.27995L9.36088 2.79161C9.3277 2.73354 9.28098 2.68433 9.22469 2.64821C9.1684 2.61208 9.10421 2.59009 9.03759 2.58411C8.97097 2.57814 8.9039 2.58835 8.84208 2.61389C8.78026 2.63943 8.72553 2.67953 8.68254 2.73078L8.63921 2.79161L7.36421 5.00161L5.19838 3.75161L6.47338 1.54161C6.6649 1.20981 6.9199 0.918993 7.22384 0.685766C7.52777 0.452538 7.87468 0.281473 8.24474 0.182342C8.6148 0.0832106 9.00076 0.057955 9.38058 0.108018C9.7604 0.158081 10.1266 0.282482 10.4584 0.474115Z"
      fill={color}
    />
  </svg>
);
