import type { FC } from 'react';

import type { ICommonIconProps } from './types';

export const ArrowIcon: FC<ICommonIconProps> = ({
  color = 'currentColor',
  width = 14,
  height = 8,
  viewBox = '0 0 14 8',
  className = '',
  style,
}) => (
  <svg
    color={color}
    fill="none"
    width={width}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M0.636048 0.293031C0.823576 0.10556 1.07788 0.000244141 1.34305 0.000244141C1.60821 0.000244141 1.86252 0.10556 2.05005 0.293031L7.00005 5.24303L11.95 0.293031C12.1386 0.110873 12.3913 0.0100779 12.6534 0.0123563C12.9156 0.0146347 13.1665 0.119804 13.3519 0.305212C13.5373 0.49062 13.6424 0.741432 13.6447 1.00363C13.647 1.26583 13.5462 1.51843 13.364 1.70703L7.70705 7.36403C7.51952 7.5515 7.26521 7.65682 7.00005 7.65682C6.73488 7.65682 6.48058 7.5515 6.29305 7.36403L0.636048 1.70703C0.448577 1.5195 0.343262 1.26519 0.343262 1.00003C0.343262 0.734866 0.448577 0.480558 0.636048 0.293031Z"
      fill={color}
    />
  </svg>
);
