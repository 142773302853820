import { makeStyles } from 'theme';

export const useStyle = makeStyles()((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: 300,
    height: 300,
    borderRadius: 10,
    padding: '24px 14px 64px 24px',
  },
  title: {
    borderRadius: 2,
    width: 106,
    height: 28,
    backgroundColor: theme.palette.bg.ghost,
  },
  dataContainer: {
    display: 'flex',
    gap: 24,
    alignItems: 'flex-end',
    marginTop: 38,
  },
  circle: {
    ...theme.layouts.flexCenter,
    borderRadius: '50%',
    width: 144,
    height: 144,
    boxSizing: 'border-box',
    border: `14px solid ${theme.palette.bg.ghost}`,
  },
  circleInfo: {
    display: 'flex',
    flexDirection: 'column',
    gap: 3,
  },
  circleTitle: {
    borderRadius: 2,
    width: 46,
    height: 13,
    backgroundColor: theme.palette.bg.ghost,
  },
  circleDescription: {
    borderRadius: 2,
    width: 58,
    height: 21,
    backgroundColor: theme.palette.bg.ghost,
  },
  statisticContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  statisticTitle: {
    borderRadius: 2,
    width: 73,
    height: 20,
    backgroundColor: theme.palette.bg.ghost,
  },
  statisticDescription: {
    marginTop: 6,
    borderRadius: 2,
    width: 73,
    height: 38,
    backgroundColor: theme.palette.bg.ghost,
  },
  subTitleContainer: {
    width: '100%',
    display: 'flex',
    gap: 20,
    marginTop: 24,
  },
  subtitle: {
    borderRadius: 2,
    width: 34,
    height: 12,
    backgroundColor: theme.palette.bg.ghost,
  },
  winRateContainer: {
    marginTop: 7,
    width: '100%',
    display: 'flex',
    gap: 13,
  },
  subContainer: {
    width: '100%',
    display: 'flex',
    gap: 4,
    alignItems: 'center',
  },
  miniCircle: {
    borderRadius: '50%',
    width: 6,
    height: 6,
    backgroundColor: theme.palette.bg.ghost,
  },
  miniDescription: {
    width: 31,
    height: 20,
    backgroundColor: theme.palette.bg.ghost,
    borderRadius: 2,
  },
}));
