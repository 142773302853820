import { makeStyles } from 'theme';

export const useStyle = makeStyles()((theme) => ({
  spinner: {
    ...theme.layouts.flexCenter,
    width: '100%',
    marginTop: '70px',
  },
  animateSpin: {
    animation: 'spin 1s linear infinite',
    '@keyframes spin': {
      from: {
        transform: 'rotate(0deg)',
      },
      to: {
        transform: 'rotate(360deg)',
      },
    },
  },
  predictTitle: {
    ...theme.layouts.flexJCSpaceBetween,
    alignItems: 'flex-start',
  },
  titleText: {
    marginBottom: '1.5rem',
  },
  updateIcon: {
    color: theme.palette.typo.primary,
    ':hover': {
      transform: 'rotate(-180deg)',
      transitionProperty: 'transform',
      transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
      transitionDuration: '400ms',
    },
  },

  // Prediction card Active

  predictParameters: {
    ...theme.layouts.flexJCSpaceBetween,
    marginBottom: '1.5rem',
  },
  parameter: {
    display: 'flex',
    flexDirection: 'column',
  },
  parameterTitle: {
    opacity: '0.7',
    marginBottom: '0.5rem',
  },
  cancelText: {
    opacity: '0.7',
    marginBottom: '0.75rem',
  },
  timer: {
    textAlign: 'center',
    marginBottom: '0.75rem',
  },
  timerSeparator: {
    width: '30px',
    margin: '0 0.5rem',
  },
  cancelButton: {
    width: '100%',
  },

  // Predictions Form

  predictForm: {
    ...theme.layouts.flexAICenter,
    width: '100%',
    position: 'relative',
    marginBottom: '1.25rem',
  },
  formDefaults: {
    ...theme.fontStyles.text14,
    width: 'calc(100% / 3)',
    height: '44px',
    backgroundColor: theme.palette.bg.ghost,
    color: theme.palette.typo.system,
  },
  active: {
    color: theme.palette.typo.primary,
  },
  left: {
    borderTopLeftRadius: '0.375rem',
    borderBottomLeftRadius: '0.375rem',
  },
  right: {
    borderTopRightRadius: '0.375rem',
    borderBottomRightRadius: '0.375rem',
  },
  selectedDefault: {
    ...theme.layouts.flexCenter,
    ...theme.fontStyles.text14,
    position: 'absolute',
    width: 'calc(100% / 3)',
    color: theme.palette.typo.primary,
  },
  selectedText: {
    ...theme.layouts.flexCenter,
    backgroundColor: theme.palette.typo.link,
    width: '90%',
    height: '36px',
    borderRadius: '6px',
  },
  inputBlock: {
    position: 'relative',
  },
  input: {
    width: '100%',
    marginBottom: '0.5rem',
  },
  alertIcon: {
    position: 'absolute',
    right: '0.75rem',
    top: '0.675rem',
  },
  errorInput: {
    position: 'absolute',
    left: '0',
    top: '3rem',
  },
  predictButton: {
    marginTop: '2.5rem',
    width: '100%',
  },

  // Prediction Result

  predictResult: {
    ...theme.layouts.flexJCSpaceBetween,
  },
  predictAnimation: {
    width: '144px',
    height: '144px',
  },
  kda: {
    ...theme.layouts.flexJCSpaceBetween,
    width: '100px',
    height: '50px',
    marginBottom: '1.5rem',
  },
  kdaItem: {
    ...theme.layouts.flexJCSpaceBetween,
    alignItems: 'center',
    flexDirection: 'column',
  },
  result: {
    display: 'flex',
    flexDirection: 'column',
  },
  resultDescr: {
    marginBottom: '0.5rem',
  },
  resultWin: {
    color: theme.palette.typo.warning,
  },
  resultLose: {
    color: theme.palette.typo.alert,
  },
  makeNextBtn: {
    width: '100%',
    marginTop: '1rem',
  },
}));
