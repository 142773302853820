import { ChallengeView } from './ChallengeView';

export const CHALLENGE_VIEW = {
  name: 'challenges',
  routes: [
    {
      title: 'View',
      path: '/challenges/:id',
      element: <ChallengeView />,
    },
  ],
};
