import { useState } from 'react';

import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

import { ArrowIcon } from 'icons';
import { Dividers, Typography } from 'ui-kit';

import { useStyle } from './Faq.styles';

interface IQuestion {
  title: string;
  content: string;
}

export function Faq(): JSX.Element {
  const {
    i18n: { language },
  } = useTranslation();
  const questions =
    language == 'en'
      ? [
          {
            title: 'What is Game Greed?',
            content:
              'A platform that allows increasing ways for streamers to interact with their audience and monetize gaming time.',
          },
          {
            title: 'How does the platform work?',
            content:
              'We collect open game data shared by the game creator with all developers.',
          },
          {
            title: 'Why is Steam ID needed?',
            content: 'To match game results.',
          },
          {
            title: 'What can you do with GC?',
            content:
              'You can exchange them for real money or skins, which will be added in the near future.',
          },
          {
            title: 'Will you steal my SteamID?',
            content:
              'Under no circumstances do we have any access to your ID or your passwords.',
          },
        ]
      : [
          {
            title: 'Что такое Game Greed?',
            content:
              'Платформа, которая позволяет увеличить способы взаимодействия стримеров с аудиторией и монетизировать игровое время.',
          },
          {
            title: 'Как работает платформа?',
            content:
              'Мы собираем открытые данные из игры, которыми делится создатель игры со всеми разработчиками.',
          },
          {
            title: 'Зачем нужен Steam ID?',
            content: 'Для того, чтобы мы могли сопоставить результаты матчей.',
          },
          {
            title: 'Что можно делать с GC?',
            content:
              'Вы можете обменивать их на реальные деньги или скины, которые будут добавлены в ближайшем будущем.',
          },
          {
            title: 'Вы заберете наш стим ID?',
            content:
              'Ни в коем случае, мы не имеем совершенно никакого доступа к вашему ID и тем более к вашим паролям.',
          },
        ];

  const { classes, theme } = useStyle();
  const { Headline, Text } = Typography;
  const { HorizontalDivider } = Dividers;
  const [isVisible, setIsVisible] = useState<boolean[]>(
    Array.from(questions, () => false)
  );
  return (
    <div className={classes.root}>
      <Headline variant="h2">F. A. Q.</Headline>
      <div className={classes.questionsBlock}>
        {questions.map(({ title, content }, idx) => (
          <div key={`question-${title}`} className={classes.question}>
            <div
              className={classes.titleContainer}
              onClick={(e) => {
                e.stopPropagation();
                isVisible.splice(idx, 1, !isVisible[idx]);
                setIsVisible([...isVisible]);
              }}
            >
              <Headline variant="h3">{title}</Headline>
              <motion.div
                animate={isVisible[idx] ? 'open' : 'closed'}
                variants={{
                  open: { rotate: 180 },
                  closed: { rotate: 0 },
                }}
              >
                <ArrowIcon
                  color={theme.palette.typo.primary}
                  className={classes.arrow}
                />
              </motion.div>
            </div>
            <motion.div
              initial={'closed'}
              animate={isVisible[idx] ? 'open' : 'closed'}
              exit={'closed'}
              transition={{ duration: 0.5 }}
              variants={{
                open: { height: 'auto', opacity: 1 },
                closed: { height: 0, opacity: 0 },
              }}
            >
              <Text variant="b6">{content} </Text>
            </motion.div>
            <HorizontalDivider />
          </div>
        ))}
      </div>
    </div>
  );
}
