import { FC, useState } from 'react';

import { AnimatePresence, motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import useSWR from 'swr';

import { ICreatedChallenge } from 'api';
import { GameCard } from 'components';
import { EChallengeStatus } from 'consts';
import { calcChallengeDuration } from 'helpers';
import { InfinityIcon, LinkIcon } from 'icons';
import { Card, Dividers, Timer, Typography } from 'ui-kit';

import { EChallengeModals, getIsWinner } from '../../ChallengeView.model';
import { AvatarList } from '../AvatarList';
import { useStyle } from './ChallengeHeader.styles';
import { OwnerHeader } from './OwnerHeader';

interface IChallengeHeaderProps {
  challenge: ICreatedChallenge;
  onOpenModal: (mode: EChallengeModals) => void;
  onStopCycleClick: () => void;
}

export const ChallengeHeader: FC<IChallengeHeaderProps> = ({
  challenge,
  onOpenModal,
  onStopCycleClick,
}) => {
  const { challengeDuration } = calcChallengeDuration(challenge);
  const [isCopied, setIsCopied] = useState<boolean>(false);
  const { t: translation } = useTranslation();
  const [searchParams] = useSearchParams();
  const isPreview = searchParams.get('mode') === 'preview';

  const {
    participants,
    owner,
    winnerCount,
    winners,
    title,
    status,
    isOwner,
    isParticipant,
    avatars,
    activeParticipants,
  } = challenge;
  const { Headline, Text } = Typography;
  const { VerticalDivider } = Dividers;

  const { data: user } = useSWR('api/user');
  const isWinner = getIsWinner(challenge, user);
  const isDone = status === EChallengeStatus.Done;
  const isLose = isDone && isParticipant && !isWinner;
  const isActive = status === EChallengeStatus.Active;
  const hasControl = isOwner;
  const isSmallInfo = isWinner || isLose;
  const onCopyLinkClick = (): void => {
    if (!isPreview && !isCopied) {
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 1000);
      navigator.clipboard.writeText(window.location.href);
    }
  };
  const onStopRegistrationClick = (): void =>
    onOpenModal(EChallengeModals.Stop);

  const { classes } = useStyle({ isSmall: isSmallInfo });

  return (
    <Card
      variant="challengeHeader"
      className={classes.headerContainer}
      color="bgSecondary"
    >
      <GameCard
        className={classes.game}
        isParticipant={isParticipant && isActive}
      />
      <div className={classes.titleContainer}>
        <Headline variant="h2">{title}</Headline>
        {!isPreview && (
          <span onClick={onCopyLinkClick}>
            <Text color={isCopied ? 'success' : 'primary'}>
              <LinkIcon className={classes.copyIcon} />
            </Text>
            <AnimatePresence>
              {isCopied && (
                <motion.span
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.3 }}
                >
                  <Text variant="b5" color="success">
                    {translation('challengeView.copy')}
                  </Text>
                </motion.span>
              )}
            </AnimatePresence>
          </span>
        )}
      </div>
      <div className={classes.infoContainer}>
        {!isDone && <Timer time={challengeDuration} />}
        <VerticalDivider />
        <div className={classes.participantsContainer}>
          <div className={classes.dataContainer}>
            <Text variant="b6" color="system">
              {translation('challengeView.participants')}
            </Text>
            <Text
              variant="b3"
              color={activeParticipants > 0 ? 'link' : 'primary'}
            >
              {activeParticipants || 0}
              {' / '}
              {participants > 0 ? (
                participants
              ) : (
                <InfinityIcon
                  width={16}
                  height={16}
                  className={classes.infIcon}
                />
              )}
            </Text>
          </div>
          <AvatarList avatars={avatars} usersLength={activeParticipants} />
        </div>
        <VerticalDivider />
        <div className={classes.dataContainer}>
          <Text variant="b6" color="system">
            {translation('challengeView.winners')}
          </Text>
          <Text variant="b3">
            {winners?.length | 0}
            {' / '}
            {winnerCount}
          </Text>
        </div>
        <VerticalDivider />
        <div className={classes.dataContainer}>
          <Text variant="b6" color="system">
            {translation('challengeView.owner')}
          </Text>
          <div className={classes.ownerContainer}>
            <img
              src={isPreview ? user?.avatarUrl : owner?.avatarUrl}
              className={classes.ownerAvatar}
              alt="Owner avatar"
            />
            <Text variant="b3">
              {isPreview ? user?.username : owner?.username}
            </Text>
          </div>
        </div>
      </div>
      {isWinner && (
        <>
          <div className={classes.resultContainer}>
            <Headline variant="h2">
              {translation('challengeView.winTitle')}
            </Headline>
            <Text variant="b4">{translation('challengeView.winSubtitle')}</Text>
          </div>
          <img
            src="/images/challenge/win.png"
            alt="win hero"
            className={classes.resultPicture}
          />
          <img
            src="/images/challenge/winShadow.png"
            alt="win shadow"
            className={classes.resultShadow}
          />
        </>
      )}
      {isLose && (
        <>
          <div className={classes.resultContainer}>
            <Headline variant="h2">
              {translation('challengeView.loseTitle')}
            </Headline>
            <Text variant="b4">
              {translation('challengeView.loseSubtitle')}
            </Text>
          </div>
          <img
            src="/images/challenge/lose.png"
            alt="win hero"
            className={classes.resultPicture}
          />
          <img
            src="/images/challenge/loseShadow.png"
            alt="win shadow"
            className={classes.resultShadow}
          />
        </>
      )}
      {isDone && isOwner && (
        <img
          src="/images/challenge/doneShadow.png"
          alt="done shadow"
          className={classes.resultShadow}
        />
      )}
      {(isPreview || hasControl) && (
        <OwnerHeader
          challenge={challenge}
          onStopCycleClick={onStopCycleClick}
          onStopRegistrationClick={onStopRegistrationClick}
        />
      )}
    </Card>
  );
};
