import { useRef, useEffect, useCallback } from 'react';

import { Fetcher, SWRConfiguration, SWRHook, SWRResponse } from 'swr';

interface IPrevStateReturn extends SWRResponse {
  isPrevious: boolean;
  resetPreviousData: () => void;
}

// This is a SWR middleware for keeping the data even if key changes.
export function prevState(useSWRNext: SWRHook) {
  return (
    key: string,
    fetcher: Fetcher,
    config: SWRConfiguration
  ): IPrevStateReturn => {
    const previousDataRef = useRef(null);

    const swr = useSWRNext(key, fetcher, config);

    useEffect(() => {
      if (swr.data !== undefined) {
        previousDataRef.current = swr.data;
      }
    }, [swr.data]);

    const resetPreviousData = useCallback(() => {
      previousDataRef.current = undefined;
    }, []);

    // Fallback to previous data if the current data is undefined.
    const dataOrPreviousData =
      swr.data === undefined ? previousDataRef.current : swr.data;

    // Is it showing previous data?
    const isPrevious =
      swr.data === undefined && previousDataRef.current !== undefined;

    return Object.assign({}, swr, {
      data: dataOrPreviousData,
      isPrevious,
      resetPreviousData,
    });
  };
}
