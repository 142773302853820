import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { Typography } from '../../../../../ui-kit';
import { ReferralLink } from '../ReferralLink';
import { useStyle } from './ReferralCard.styles';

export const ReferralCard: FC = () => {
  const { t: translation } = useTranslation();
  const { classes } = useStyle();
  const { Headline, Text } = Typography;
  return (
    <div className={classes.card}>
      <div className={classes.articleContainer}>
        <div className={classes.cardArticle}>
          <Headline variant="h4" className={classes.cardTitle}>
            {translation('referral.paragraphs.parOne.title')}
          </Headline>
          <div className={classes.cardDescription}>
            <Text variant="b4" className={classes.lightWhite}>
              {translation('referral.paragraphs.parOne.description1')}
            </Text>

            <Text variant="b4" color="primary">
              {' '}
              {translation('referral.paragraphs.parOne.description2')}{' '}
            </Text>
            <Text variant="b4" className={classes.lightWhite}>
              {translation('referral.paragraphs.parOne.description3')}
            </Text>
          </div>
        </div>
        <div className={classes.cardArticle}>
          <Headline variant="h4" className={classes.cardTitle}>
            {translation('referral.paragraphs.parTwo.title')}
          </Headline>
          <div className={classes.cardDescription}>
            <Text variant="b4" className={classes.lightWhite}>
              {translation('referral.paragraphs.parTwo.description1')}
              <br />
              {translation('referral.paragraphs.parTwo.description2')}
              <br />
              {translation('referral.paragraphs.parTwo.description3')}
            </Text>
            <br />
            <br />
            <Text variant="b4" className={classes.lightWhite}>
              {translation('referral.paragraphs.parTwo.description4')}{' '}
            </Text>
            <Text variant="b4" color="yellow">
              1000 {translation('referral.paragraphs.coins')}
            </Text>
            <Text variant="b4" className={classes.lightWhite}>
              {translation('referral.paragraphs.parTwo.description5')}
            </Text>
            <Text variant="b4" color="yellow">
              100 {translation('referral.paragraphs.coins')}
            </Text>
            <Text variant="b4" className={classes.lightWhite}>
              {translation('referral.paragraphs.parTwo.description6')}
            </Text>
          </div>
        </div>
        <div className={classes.cardArticle}>
          <Headline variant="h3" className={classes.cardTitle}>
            {translation('referral.paragraphs.parThree.title')}
          </Headline>
          <div className={classes.cardDescription}>
            <Text variant="b4" className={classes.lightWhite}>
              {translation('referral.paragraphs.parThree.description1')}{' '}
            </Text>
            <Text variant="b4" color="yellow">
              (10 {translation('referral.paragraphs.coins')})
            </Text>{' '}
            <Text variant="b4" className={classes.lightWhite}>
              {translation('referral.paragraphs.parThree.description2')}
            </Text>
            <br />
            <br />
            <Text variant="b4" className={classes.lightWhite}>
              {translation('referral.paragraphs.parThree.description3')}
            </Text>
            <Text variant="b4">
              {' '}
              {translation('referral.paragraphs.parThree.description4')}
            </Text>
            <Text variant="b4" className={classes.lightWhite}>
              {translation('referral.paragraphs.parThree.description5')}
            </Text>{' '}
            <Text variant="b4">
              {translation('referral.paragraphs.parThree.description6')}
            </Text>{' '}
            <Text variant="b4" className={classes.lightWhite}>
              {translation('referral.paragraphs.parThree.description7')}{' '}
            </Text>
            <Text variant="b4" color="yellow" className={classes.gold}>
              100
            </Text>
            <Text variant="b4" className={classes.lightWhite}>
              {translation('referral.paragraphs.parThree.description8')}{' '}
            </Text>
            <Text variant="b4" color="yellow">
              10
            </Text>
            <Text variant="b4" className={classes.lightWhite}>
              {translation('referral.paragraphs.parThree.description9')}{' '}
            </Text>
            <Text variant="b4" color="yellow">
              0.1
            </Text>{' '}
            <Text variant="b4" className={classes.lightWhite}>
              {translation('referral.paragraphs.parThree.description10')}{' '}
            </Text>
            <Text variant="b4" color="yellow">
              0.01
            </Text>{' '}
            <Text variant="b4" className={classes.lightWhite}>
              {translation('referral.paragraphs.parThree.description11')}
            </Text>
          </div>
        </div>
      </div>
      <ReferralLink />
    </div>
  );
};
