import { FC } from 'react';

import { ICommonIconProps } from './types';

export const ShieldIcon: FC<ICommonIconProps> = ({
  color = '#8289A3',
  width = 16,
  height = 16,
  viewBox = '0 0 16 16',
  className = '',
  style,
}) => (
  <svg
    fill="none"
    width={width}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M8.87961 7.99998L14.3996 1.59998L5.59961 7.99998H7.19961L1.59961 14.4L10.3996 7.99998H8.87961ZM5.43961 8.79998H3.11961L5.11961 7.35998L11.1196 2.95998L7.99961 1.59998L2.39961 3.99998V6.39998C2.39961 7.99998 2.79961 9.51998 3.59961 10.88L5.43961 8.79998ZM10.5596 7.19998H12.7996L10.8796 8.63998L5.19961 12.8C5.99961 13.52 6.95961 14.08 7.99961 14.4C11.3596 13.2 13.6796 9.99998 13.5996 6.39998V3.99998L13.4396 3.91998L10.5596 7.19998Z"
      fill={color}
    />
  </svg>
);
