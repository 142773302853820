import type { FC } from 'react';

import type { ICommonIconProps } from './types';

export const CloseIcon: FC<ICommonIconProps> = ({
  color = 'currentColor',
  width = 12,
  height = 12,
  viewBox = '0 0 12 12',
  className = '',
  style,
}) => (
  <svg
    fill="none"
    width={width}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M11.6924 1.79254C12.1025 1.38247 12.1025 0.717618 11.6924 0.30755C11.2824 -0.102517 10.6175 -0.102517 10.2075 0.30755L6 4.515L1.79255 0.307551C1.38248 -0.102516 0.717631 -0.102516 0.307564 0.307551C-0.102504 0.717618 -0.102504 1.38247 0.307563 1.79254L4.51501 5.99999L0.30755 10.2075C-0.102517 10.6175 -0.102517 11.2824 0.30755 11.6924C0.717618 12.1025 1.38247 12.1025 1.79254 11.6924L6 7.48497L10.2075 11.6924C10.6175 12.1025 11.2824 12.1025 11.6925 11.6924C12.1025 11.2824 12.1025 10.6175 11.6925 10.2075L7.48498 5.99999L11.6924 1.79254Z"
      fill={color}
    />
  </svg>
);
