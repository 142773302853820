import { FC } from 'react';

import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import { Typography } from 'ui-kit';

import { useStyle } from '../Prediction.styles';

export const PredictionAd: FC = () => {
  const { t: translation } = useTranslation();
  const { classes } = useStyle();

  const { Headline } = Typography;
  const { data: user } = useSWR('api/user');
  const isPrelogin = !user;

  return (
    <div className={classes.predictionAd}>
      <Headline className={classes.predictionAdTitle}>
        {translation('predictions.ad.title')}
      </Headline>
      {/* {!isPrelogin && ( */}
      <img
        src="/images/game/money.png"
        alt="prediction"
        srcSet="/images/game/money@3x.png 1024w, /images/game/money@2x.png 768w, /images/game/money.png 320w"
        className={classes.predictionAdimg}
      />
      {/* )} */}
    </div>
  );
};
