import React, { forwardRef, useEffect, useRef, useState } from 'react';

import clsx from 'clsx';
import { motion } from 'framer-motion';

import { ErrorMessage } from '../ErrorMessage';
import { Tooltip } from '../Tooltip';
import { Typography } from '../Typography';
import {
  dhmToMilliseconds,
  DhmType,
  millisecondsToDhm,
  PERIODS,
} from './Duration.model';
import { useStyle } from './Duration.styles';

interface IDurationProps {
  onChange: (value: string) => void;
  value?: string;
  name?: string;
  tooltipText?: string;
  title?: string;
  error?: string;
}

export const Duration = forwardRef<HTMLInputElement, IDurationProps>(
  ({ onChange, value, name, tooltipText, title, error = '' }, ref) => {
    const inputRefs = useRef([]);
    const [activeIndex, setActiveIndex] = useState(1);
    const [isTooltipVisible, setIsTooltipVisible] = useState(false);
    const [duration, setDuration] = useState<DhmType>(() =>
      millisecondsToDhm(value)
    );

    const { Text } = Typography;

    useEffect(() => {
      inputRefs.current = inputRefs.current.slice(0, PERIODS.length);
    }, []);

    const onChangeDuration = (
      durationFieldName: string,
      value: string | number
    ): void => {
      setDuration((prevDuration) => ({
        ...prevDuration,
        [durationFieldName]: value,
      }));

      onChange(String(dhmToMilliseconds(duration)));
    };
    const { classes } = useStyle();
    return (
      <>
        {title && (
          <Text variant="b6" color="typoLabel" className={classes.title}>
            {title}
          </Text>
        )}
        <Tooltip
          position={'right'}
          content={tooltipText}
          color="blue"
          type="focus"
          isVisible={isTooltipVisible}
          className={classes.container}
        >
          <input
            name={name}
            type="text"
            className={classes.hiddenInput}
            ref={ref}
            onFocus={() => {
              inputRefs.current[activeIndex].focus();
            }}
            defaultValue={value}
          />
          {PERIODS.map((field, index, arr) => {
            return (
              <button
                className={classes.button}
                key={field.name}
                type="button"
                tabIndex={-1}
                onClick={() => {
                  inputRefs.current[index].focus();
                }}
              >
                <input
                  type="number"
                  value={duration[field.name]}
                  placeholder={`00 ${field.symbol}`}
                  className={clsx(
                    classes.dataInput,
                    index === 0 && classes.firstInput,
                    index === arr.length - 1 && classes.lastInput,
                    !!duration[field.name] && classes.filledInput
                  )}
                  onFocus={() => {
                    setActiveIndex(index);
                    setIsTooltipVisible(true);
                  }}
                  onBlur={() => {
                    setIsTooltipVisible(false);
                    const changedValue = /^[1-9]$/.test(
                      `${duration[field.name]}`
                    )
                      ? `0${duration[field.name]}`
                      : duration[field.name];

                    onChangeDuration(field.name, changedValue);
                  }}
                  onChange={(e) => {
                    const isValid =
                      e.target.validity.valid &&
                      !/^0{2,10}/.test(e.target.value);
                    onChangeDuration(
                      field.name,
                      isValid ? e.target.value : duration[field.name]
                    );
                  }}
                  ref={(el) => (inputRefs.current[index] = el)}
                  min={0}
                  max={field.max}
                />
                {!!duration[field.name] && (
                  <span
                    className={clsx(
                      classes.durationSymbol,
                      index === arr.length - 1 && classes.lastSymbol
                    )}
                  >
                    {' '}
                    {field.symbol}
                  </span>
                )}
              </button>
            );
          })}

          <motion.button
            className={classes.animationBox}
            initial={{
              x: '100%',
            }}
            animate={{
              x: `${activeIndex === -1 ? 100 : activeIndex * 100}%`,
            }}
            transition={{ type: 'tween', duration: 0.2 }}
            onClick={() => {
              inputRefs.current[activeIndex].focus();
            }}
            type="button"
          >
            <div className={classes.animationBorder} />
          </motion.button>
          {error && (
            <ErrorMessage className={classes.errorMessage} message={error} />
          )}
        </Tooltip>
      </>
    );
  }
);
