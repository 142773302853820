import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { Typography } from 'ui-kit';

import { Hero } from './Hero';
import { useStyle } from './HeroAndItemBuild.styles';
import { ItemList } from './ItemList';
import { ItemTable } from './ItemTable';

export const HeroAndItemBuild: FC = () => {
  const { t: translation } = useTranslation();

  const { classes } = useStyle();
  const { Headline, Text } = Typography;
  return (
    <section>
      <Headline variant="h2" className={classes.title}>
        {translation('challenge.form.heroItemBuild.title')}
      </Headline>
      <Text className={classes.description}>
        {translation('challenge.form.heroItemBuild.description')}
      </Text>
      <div className={classes.container}>
        <Hero />
        <ItemList />
      </div>
      <ItemTable />
    </section>
  );
};
