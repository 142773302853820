import { FC } from 'react';

import clsx from 'clsx';

import { Typography } from 'ui-kit';

import { useStyle } from './ChallengeComponents.styles';
const AVATARS_VISIBLE = 3;

interface IAvatarListProps {
  avatars: string[];
  usersLength: number;
}

export const AvatarList: FC<IAvatarListProps> = ({
  avatars = [],
  usersLength,
}) => {
  const { classes } = useStyle();
  const { Text } = Typography;
  return (
    <div className={classes.flex}>
      {avatars
        .filter((avatar) => avatar)
        .slice(0, AVATARS_VISIBLE)
        .map((url, index) => (
          <img
            src={url}
            alt={`avatar-${index}`}
            key={index}
            className={clsx(
              classes.avatar,
              index > 0 && classes.avatarNotFirst
            )}
          />
        ))}
      {usersLength - AVATARS_VISIBLE > 0 && (
        <div className={classes.avatarsMore}>
          <Text variant="b4">+{usersLength - AVATARS_VISIBLE}</Text>
        </div>
      )}
    </div>
  );
};
