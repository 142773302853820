import { FC } from 'react';

import { SkeletonLoader } from 'ui-kit';

import { useStyle } from './PredictCardSkeleton.styles';

export const PredictCardSkeleton: FC = () => {
  const { classes } = useStyle();
  return (
    <SkeletonLoader className={classes.container}>
      <div className={classes.titleContainer}>
        <div className={classes.title} />
        <div className={classes.tooltip} />
      </div>
      <div className={classes.selectorContainer}>
        <div className={classes.selector} />
      </div>
      <div className={classes.input} />
      <div className={classes.button} />
    </SkeletonLoader>
  );
};
