import { FC, useContext, useState } from 'react';

import TagManager from 'react-gtm-module';
import { useTranslation } from 'react-i18next';
import useSWR, { mutate } from 'swr';

import {
  ICreatedChallenge,
  IUserResponse,
  participateInChallenge,
  EUserPermissions,
} from 'api';
import { ModalContext } from 'components';
import { hasPermissions, removeZeroesAfterComma } from 'helpers';
import { AcceptFlagIcon, LockIcon } from 'icons';
import { Button, Typography } from 'ui-kit';

import {
  EChallengeModals,
  useChallengeStatus,
} from '../../ChallengeView.model';
import { useStyle } from './Buttons.styles';

interface IAcceptChallengeButton {
  isHistoryClosed: boolean;
  openModal: (EChallengeModals) => void;
  challenge: ICreatedChallenge;
  openSteamModal: () => void;
  openHistoryModal: () => void;
}

export const AcceptChallengeButton: FC<IAcceptChallengeButton> = ({
  isHistoryClosed,
  openModal,
  challenge,
  openSteamModal,
  openHistoryModal,
}) => {
  const [acceptError, setAcceptError] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const { isNotEnoughBalance, isFullParticipantChallenge, isMaxParticipation } =
    useChallengeStatus(challenge);
  const { data: user } = useSWR<IUserResponse>('api/user');
  const acceptChallenge = async (id: number): Promise<void> => {
    try {
      setIsProcessing(true);
      await participateInChallenge(id);
      await mutate(`api/challenge/${id}`);
    } catch (err) {
      console.log(err);
      setAcceptError(err);
    } finally {
      setIsProcessing(false);
    }
  };
  const isRestrictAccept =
    isMaxParticipation && !hasPermissions(user, EUserPermissions.infinitePart);
  const AcceptDisabled =
    isNotEnoughBalance || isFullParticipantChallenge || isRestrictAccept;
  const { t: translation } = useTranslation();
  const { classes } = useStyle();
  const { id, hasPassword } = challenge;
  const {
    loginModal: { openLogin },
  } = useContext(ModalContext);

  const onAcceptChallengeClick = (): void => {
    if (!user) {
      openLogin();
      return;
    }
    if (!user.steamId) {
      openSteamModal();
      return;
    }
    if (isHistoryClosed) {
      openHistoryModal();
      return;
    }
    if (challenge.entryCost > 0 && !hasPassword) {
      openModal(EChallengeModals.EntryCost);
      return;
    }
    if (hasPassword) {
      openModal(EChallengeModals.Password);
      return;
    }
    TagManager.dataLayer({
      dataLayer: { event: 'challenge_accepted', payable: 'no' },
    });
    acceptChallenge(id);
  };
  const { Headline, Text } = Typography;
  return (
    <div className={classes.acceptBtnContainer}>
      <Button
        className={classes.acceptBtn}
        disabled={AcceptDisabled}
        onClick={() => onAcceptChallengeClick()}
      >
        <div className={classes.flagContainer}>
          {challenge?.hasPassword ? (
            <LockIcon className={classes.lockIcon} />
          ) : (
            <AcceptFlagIcon />
          )}
          {isRestrictAccept && (
            <Text variant="b5">
              {translation('challengeView.errors.acceptLimit')}
            </Text>
          )}
          {!isRestrictAccept && (
            <Headline variant="h3">
              {isProcessing
                ? translation('challengeView.actions.accepting')
                : translation('challengeView.actions.acceptChallenge')}
            </Headline>
          )}
        </div>

        <Headline variant="h3">
          {`${removeZeroesAfterComma(String(challenge?.entryCost))} GC`}
        </Headline>
      </Button>
      {(AcceptDisabled || !!acceptError) && (
        <div className={classes.acceptError}>
          {isNotEnoughBalance &&
            translation('challengeView.errors.moneyParticipate')}
          {!!acceptError && translation('challengeView.errors.server')}
        </div>
      )}
    </div>
  );
};
