import { About } from './About';
import { Prediction } from './Prediction';

export const PREDICTIONS = {
  name: 'predictions',
  routes: [
    {
      title: 'header.tabs.about',
      path: '/predictions/about',
      element: <About />,
    },
    {
      title: 'header.tabs.predictions',
      path: '/predictions',
      element: <Prediction />,
    },
  ],
};
