import { makeStyles } from 'theme';

export const useStyle = makeStyles()(() => ({
  title: {
    marginBottom: 16,
    display: 'inline-block',
  },
  description: {
    display: 'inline-block',
  },
  headerContainer: {
    position: 'relative',
    zIndex: 10,
    display: 'flex',
    justifyContent: 'space-between',
  },
  headerItem: {
    width: 'calc((100% - 72px) /3)',
  },
  backgroundPic: {
    position: 'absolute',
    width: '60%',
    left: '20%',
    top: 130,
  },
  header: {
    position: 'relative',
    zIndex: 1,
    background:
      'linear-gradient(38.92deg, rgba(22, 28, 40, 0.4) 0%, rgba(22, 28, 40, 0.1) 134.39%)',
    backdropFilter: 'blur(6px)',
    borderRadius: 6,
    border: '2px solid rgba(43, 44, 46, 0.64)',
    boxShadow:
      '0px 0px 12px 1px #001017, 0px 0px 40px 4px rgba(0, 16, 23, 0.24)',
    padding: 40,
    marginTop: 330,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  button: {
    marginRight: 0,
    marginLeft: 'auto',
    marginTop: 32,
    minWidth: 200,
  },
  parryTitle: {
    marginLeft: 0,
    marginRight: 'auto',
    marginBottom: 24,
  },
}));
