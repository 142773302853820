import { makeStyles } from 'theme';

export const useStyle = makeStyles()((theme) => ({
  adCards: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '24px',
    paddingRight: '8px',
  },
  description: {
    maxWidth: 600,
    color: theme.palette.typo.primary,
    fontWeight: 500,
    marginBottom: 40,
    ...theme.fontStyles.text17,
    lineHeight: '24px',
  },
}));
