export interface IDota2Hero {
  id: number;
  name: string;
  localized_name: string;
  primary_attr: string;
  attack_type: string;
  roles: string[];
  legs: number;
}

export function getDota2Heroes(): Promise<IDota2Hero[]> {
  return fetch(`https://api.opendota.com/api/heroes`).then((data) =>
    data.json()
  );
}

export function getDota2HeroImage(name: string): string {
  return `https://cdn.cloudflare.steamstatic.com/apps/dota2/images/dota_react/heroes/${name}.png`;
}
