import { makeStyles } from 'theme';

export const useStyle = makeStyles()(() => ({
  firstRoad: {
    marginTop: 64,
  },
  secondRoad: {
    marginTop: 200,
  },
}));
