export function getPagesCount(pageNumber: number, perPage: number): number {
  const plusItems = pageNumber % perPage;
  return plusItems === 0
    ? pageNumber / perPage
    : Math.floor(pageNumber / perPage) + 1;
}

export function getPaginatorTemplate(
  pagesCount: number,
  pageNumber: number
): Array<number | null> {
  if (pagesCount <= 4) {
    return Array(pagesCount)
      .fill(null, 0, pagesCount)
      .map((_, index) => index + 1);
  }
  const isFirstPages = [1, 2].includes(pageNumber);
  const isLastPages = [pagesCount, pagesCount - 1, pagesCount - 2].includes(
    pageNumber
  );

  if (isFirstPages) {
    return [1, 2, 3, null, pagesCount];
  }
  if (isLastPages) {
    return [1, null, pagesCount - 2, pagesCount - 1, pagesCount];
  }
  return [1, null, pageNumber, pageNumber + 1, null, pagesCount];
}
