import { FC } from 'react';

import { SkeletonLoader } from 'ui-kit';

import { useStyle } from './WinRateSkeleton.styles';

export const WinRateSkeleton: FC = () => {
  const { classes } = useStyle();
  return (
    <SkeletonLoader className={classes.container}>
      <div className={classes.title} />
      <div className={classes.dataContainer}>
        <div className={classes.circle}>
          <div className={classes.circleInfo}>
            <div className={classes.circleTitle} />
            <div className={classes.circleDescription} />
          </div>
        </div>
        <div className={classes.statisticContainer}>
          <div className={classes.statisticTitle} />
          <div className={classes.statisticDescription} />
          <div className={classes.subTitleContainer}>
            <div className={classes.subtitle} />
            <div className={classes.subtitle} />
          </div>
          <div className={classes.winRateContainer}>
            <div className={classes.subContainer}>
              <div className={classes.miniCircle} />
              <div className={classes.miniDescription} />
            </div>
            <div className={classes.subContainer}>
              <div className={classes.miniCircle} />
              <div className={classes.miniDescription} />
            </div>
          </div>
        </div>
      </div>
    </SkeletonLoader>
  );
};
