import { makeStyles } from 'theme';

export const useStyle = makeStyles()((theme) => ({
  container: {
    position: 'relative',
    display: 'flex',
    height: 44,
    width: 240,
  },
  hiddenInput: {
    opacity: 0,
    width: 0,
    height: 0,
  },
  button: {
    position: 'relative',
    width: 'calc(100% / 3)',
    ...theme.layouts.flexCenter,
  },
  dataInput: {
    width: '100%',
    height: '100%',
    textAlign: 'center',
    outline: 'none',
    backgroundColor: theme.palette.control.default.bg,
    color: theme.palette.typo.primary,
    borderTop: `1px solid ${theme.palette.bg.border}`,
    borderBottom: `1px solid ${theme.palette.bg.border}`,
  },
  firstInput: {
    borderLeft: `1px solid ${theme.palette.bg.border}`,
    borderTopLeftRadius: '0.375rem',
    borderBottomLeftRadius: '0.375rem',
  },
  lastInput: {
    borderRight: `1px solid ${theme.palette.bg.border}`,
    borderTopRightRadius: '0.375rem',
    borderBottomRightRadius: '0.375rem',
  },
  filledInput: {
    paddingRight: '1.25rem',
    position: 'relative',
  },
  durationSymbol: {
    position: 'absolute',
    right: 20,
    color: theme.palette.typo.primary,
  },
  lastSymbol: {
    right: 15,
  },
  animationBox: {
    position: 'absolute',
    top: -2,
    width: 'calc(100% / 3)',
    height: 48,
    ...theme.layouts.flexJCcenter,
  },
  animationBorder: {
    width: 80,
    height: '100%',
    border: `3px solid ${theme.palette.typo.link}`,
    borderRadius: '0.375rem',
  },
  title: {
    display: 'inline-block',
    marginBottom: 8,
  },
  errorMessage: {
    position: 'absolute',
    bottom: -6,
    left: 0,
    transform: 'translateY(100%)',
  },
}));
