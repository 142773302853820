import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { getDota2HeroImage, ICreatedParry, IListedParry } from 'api';
import { OPTIONS_MAIN_TERM } from 'consts';
import { removeZeroesAfterComma } from 'helpers';
import { useDota2Hero } from 'hooks';
import {
  GradientEntryCostIcon,
  GradientSwordsIcon,
  LashHitIcon,
  ShieldIcon,
  TrophyGradeIcon,
} from 'icons';
import { BuilderItemsView, Card, Typography } from 'ui-kit';

import { calcRewardLabel } from '../../ParryCard.model';
import { useStyle } from './ParryRules.styles';

interface IParryRules {
  parry: IListedParry;
}

export const ParryRules: FC<IParryRules> = ({ parry }) => {
  const { t: translation } = useTranslation();
  const { classes } = useStyle();
  const { options } = parry;
  const { term, quantity } = options[0];
  const termKey = OPTIONS_MAIN_TERM[term]?.label;
  const { Text } = Typography;
  const hero = useDota2Hero(parry);
  const heroImageName = hero?.name.replace('npc_dota_hero_', '');
  return (
    <div className={classes.parryRulesContainer}>
      <div className={classes.labelContainer}>
        <Card
          variant="p4x8"
          className={classes.label}
          color="bgGhost"
          borderRadius="br4"
          title={translation('parries-list.parry-card.fee-title')}
        >
          <GradientEntryCostIcon
            className={classes.icon}
            width={24}
            height={24}
          />
          <Text variant="b6">
            {translation('parries-list.parry-card.fee')}{' '}
            {removeZeroesAfterComma(String(parry?.entryCost))} GC
          </Text>
        </Card>
        <Card
          variant="p4x8"
          className={classes.label}
          color="bgGhost"
          borderRadius="br4"
        >
          <GradientSwordsIcon className={classes.icon} />
          <Text variant="b6">
            {`${translation('parries-list.parry-card.article')} ${translation(
              termKey
            )} ${translation('parries-list.parry-card.games')}: ${quantity}`}
          </Text>
        </Card>
        {parry?.hero && (
          <Card
            variant="p4x8"
            className={classes.label}
            color="bgGhost"
            borderRadius="br4"
          >
            <img
              src={getDota2HeroImage(heroImageName)}
              alt="dota2_hero"
              className={classes.heroImg}
            />
            <Text variant="b6">{hero.localized_name}</Text>
          </Card>
        )}
        {!parry?.hero && (
          <Card
            variant="p4x8"
            className={classes.label}
            color="bgGhost"
            borderRadius="br4"
          >
            <ShieldIcon className={classes.icon} />
            <Text variant="b6">
              {translation('parries-list.parryCard.any')}
            </Text>
          </Card>
        )}
        <BuilderItemsView items={parry?.items} size="small" />
      </div>
    </div>
  );
};
