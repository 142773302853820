import { useMemo } from 'react';

import useSWR from 'swr';

import {
  getDota2Heroes,
  ICreatedChallenge,
  ICreatedParry,
  IDota2Hero,
  IListedParry,
} from 'api';

export const useDota2Hero = (
  challenge: ICreatedChallenge | IListedParry
): IDota2Hero => {
  const { data: dota2Heroes } = useSWR(
    () => (challenge.hero ? 'api/dota2-heroes' : null),
    getDota2Heroes
  );
  return useMemo(() => {
    if (dota2Heroes && challenge?.hero) {
      return dota2Heroes.find((dota2Hero) => dota2Hero.id === challenge.hero);
    }

    return {
      id: 0,
      name: '',
      localized_name: '',
      primary_attr: '',
      attack_type: '',
      roles: [],
      legs: 0,
    };
  }, [dota2Heroes, challenge?.hero]);
};
