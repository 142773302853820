import { FC } from 'react';

import { useNavigate } from 'react-router-dom';

import { AdCard } from '../AdCard';
import { useStyle } from './AdsBlock.styles';

interface IAds {
  title: string;
  titleRegistered?: string;
  text: string;
  btnText: string;
  bgUrl: string;
  onClick?: VoidFunction;
  isAuth?: boolean;
}

const ads: IAds[] = [
  {
    title: 'home.predictions.title',
    titleRegistered: 'home.predictions.titleRegistered',
    text: 'home.predictions.description',
    btnText: 'home.predictions.btnText',
    bgUrl: '/images/main-page/predictions-bg.png',
  },
  {
    title: 'home.challenges.title',
    titleRegistered: 'home.challenges.titleRegistered',
    text: 'home.challenges.description',
    btnText: 'home.challenges.btnText',
    bgUrl: '/images/main-page/challenge-bg.png',
  },
];

export const AdsBlock: FC = () => {
  const { classes } = useStyle();
  const navigate = useNavigate();
  const onPredictionClick = (): void => {
    navigate('/predictions');
  };

  const onChallengeClick = (): void => {
    navigate('/challenges');
  };
  return (
    <div className={classes.adCards}>
      <AdCard {...ads[0]} onClick={onPredictionClick} />
      <AdCard {...ads[1]} onClick={onChallengeClick} />
    </div>
  );
};
