import { makeStyles } from 'theme';

export const useStyle = makeStyles()((theme) => ({
  container: {
    position: 'relative',
    width: 144,
  },
  dataContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    ...theme.layouts.flexAICenter,
    flexDirection: 'column',
    transform: 'translateX(-50%) translateY(-50%)',
  },
  matches: {
    textTransform: 'uppercase',
    opacity: 0.4,
  },
}));
