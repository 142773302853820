import React, { forwardRef, InputHTMLAttributes, useState } from 'react';

import clsx from 'clsx';

import { CloseIcon, EyeIcon, SearchIcon } from 'icons';
import { ErrorMessage, Typography, Tooltip } from 'ui-kit';

import { useStyle } from './Input.styles';

export type InputSizeType = 'sm' | 'md' | 'l';
type IconInputType = 'eye' | 'alert' | 'close' | 'search' | 'send' | 'none';

export interface IInputProps extends InputHTMLAttributes<HTMLInputElement> {
  iconType?: IconInputType;
  error?: string;
  tooltip?: React.ReactNode | string;
  title?: string;
  inputSize?: InputSizeType;
  onIconClick?: () => void;
}

const iconMap: Record<IconInputType, React.ReactNode> = {
  eye: <EyeIcon />,
  alert: <div />,
  search: <SearchIcon />,
  close: <CloseIcon />,
  send: <div />,
  none: null,
};

export const Input = forwardRef<HTMLInputElement, IInputProps>(
  (
    {
      iconType = 'none',
      error = '',
      className,
      disabled,
      tooltip,
      title,
      inputSize = 'md',
      onBlur,
      onIconClick,
      ...rest
    },
    ref
  ) => {
    const { classes } = useStyle({ size: inputSize });
    const [isTooltipVisible, setIsTooltipVisible] = useState(false);
    const customOnFocus = (): void => {
      setIsTooltipVisible(true);
    };
    const handleOnBlur = (args): void => {
      if (onBlur) {
        onBlur(args);
      }
      setIsTooltipVisible(false);
    };
    const { Text } = Typography;

    return (
      <div className={clsx(className, classes.inputContainer)}>
        {title && (
          <Text variant="b6" color="typoLabel" className={classes.title}>
            {title}
          </Text>
        )}
        <Tooltip
          position={'right'}
          content={tooltip}
          isVisible={isTooltipVisible}
          type="focus"
          color="blue"
        >
          <Text variant="b5">
            <input
              className={clsx(
                classes.input,
                error && classes.errorInput,
                disabled && classes.disabled
              )}
              ref={ref}
              disabled={disabled}
              onFocus={customOnFocus}
              onBlur={handleOnBlur}
              {...rest}
            />
          </Text>
          {iconType !== 'none' && (
            <button
              type="button"
              className={classes.icon}
              onClick={onIconClick}
            >
              <Text color="system">{iconMap[iconType]}</Text>
            </button>
          )}
        </Tooltip>
        {error && (
          <ErrorMessage className={classes.errorMessage} message={error} />
        )}
      </div>
    );
  }
);
