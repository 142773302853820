import { makeStyles } from 'theme';

export const useStyle = makeStyles()(() => ({
  modal: {
    width: 530,
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    padding: '16px 20px',
  },
  text: {
    display: 'inline-block',
  },
  title: {
    display: 'inline-block',
    marginBottom: 32,
  },
  infoContainer: {
    display: 'flex',
    width: '100%',
    gap: 12,
    flexDirection: 'column',
  },
  currencyContainer: {
    display: 'flex',
    height: 40,
    width: '100%',
    gap: 20,
  },
  currencyItem: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
  },
  addressInput: {
    width: '100%',
  },
  amountInput: {
    width: 172,
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
  cancelBtn: {
    marginRight: 20,
    marginLeft: 'auto',
  },
  usdtIcon: {
    display: 'inline-block',
    marginLeft: 6,
  },
  amountContainer: {
    display: 'flex',
    width: '100%',
    gap: 16,
  },
  exchange: {
    display: 'inline-block',
    marginTop: 40,
  },
}));
