import { FC } from 'react';

import clsx from 'clsx';

import { useStyle } from './Text.styles';

export type TextVariantType = 'b1' | 'b2' | 'b3' | 'b4' | 'b5' | 'b6' | 'b7';

export type TextColorType =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'brand'
  | 'system'
  | 'link'
  | 'success'
  | 'warning'
  | 'alert'
  | 'menu2'
  | 'typoLabel'
  | 'yellow'
  | 'red'
  | 'label'
  | 'typoPlaceholder'
  | 'bronze'
  | 'silver'
  | 'gold'
  | 'lightGreen';

export interface IBodyProps {
  variant?: TextVariantType;
  color?: TextColorType;
  children: React.ReactNode | string;
  className?: string;
  block?: boolean;
  onClick?: VoidFunction;
}

export const Text: FC<IBodyProps> = ({
  variant = 'b1',
  color = 'primary',
  children,
  className,
  block = false,
  onClick,
}) => {
  const { classes } = useStyle({ variant, color, block });
  return (
    <span className={clsx(className, classes.text)} onClick={onClick}>
      {children}
    </span>
  );
};
