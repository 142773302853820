import { makeStyles } from 'theme';

export const useStyle = makeStyles()(() => ({
  heroWidth: {
    width: 404,
  },
  button: {
    marginRight: 16,
    padding: '10px 26px',
  },
}));
