import type { FC } from 'react';

import type { ICommonIconProps } from './types';

export const ProfileSmall: FC<ICommonIconProps & { isFill?: boolean }> = ({
  width = 20,
  height = 20,
  viewBox = '0 0 20 20',
  className = '',
  style,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M9.90741 1.66602C7.92789 1.66602 6.32318 3.36406 6.32318 5.45871C6.32318 7.55336 7.92789 9.2514 9.90741 9.2514C11.8869 9.2514 13.4916 7.55336 13.4916 5.45871C13.4916 3.36406 11.8869 1.66602 9.90741 1.66602Z"
      fill="currentcolor"
    />
    <path
      d="M6.08423 11.2742C4.10471 11.2742 2.5 12.9722 2.5 15.0669V16.2687C2.5 17.0305 3.02175 17.68 3.73228 17.8028C7.82196 18.5093 11.9929 18.5093 16.0825 17.8028C16.7931 17.68 17.3148 17.0305 17.3148 16.2687V15.0669C17.3148 12.9722 15.7101 11.2742 13.7306 11.2742H13.4048C13.2284 11.2742 13.0532 11.3037 12.8856 11.3616L12.0583 11.6474C10.6607 12.1303 9.15412 12.1303 7.75647 11.6474L6.92922 11.3616C6.76159 11.3037 6.58637 11.2742 6.41003 11.2742H6.08423Z"
      fill="currentcolor"
    />
  </svg>
);
