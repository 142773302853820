import { useContext, useEffect } from 'react';

import { ProgressSpinner } from 'primereact/progressspinner';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import useSWR from 'swr';

import {
  getParry,
  getParryHistory,
  ICreatedParry,
  IListedParry,
  IUserResponse,
} from 'api';
import { ModalContext } from 'components';
import { EParryModals, EViewParryMode, PARRY_FORM_DATA_KEY } from 'consts';
import { useCheckHistory } from 'hooks';
import { BackgroundContext } from 'Layout';
import { BottomNotification, Button } from 'ui-kit';

import {
  ActionButton,
  BottomActionButtons,
  AdditionalTerms,
  ParryHeader,
  GeneralTerms,
} from './components';
import { getIsWinner } from './ParryView.model';
import { useStyle } from './ParryView.styles';

export function ParryView(): JSX.Element {
  const { t: translation } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const { classes } = useStyle();

  const viewMode = searchParams.get('mode');
  const isPreviewMode = viewMode === EViewParryMode.Preview;
  const isHistoryMode = viewMode === EViewParryMode.History;

  const { isHistoryFailed } = useCheckHistory();
  const localParry: ICreatedParry = JSON.parse(
    localStorage.getItem(PARRY_FORM_DATA_KEY)
  );
  const { data: parry } = useSWR(
    isHistoryMode ? `api/parry/history/${id}` : `api/parry/${id}`,
    isPreviewMode
      ? () => new Promise<ICreatedParry>((resolve) => resolve(localParry))
      : () => {
          if (isHistoryMode) {
            return getParryHistory(+id);
          }
          return getParry(+id);
        }
  );

  const { data: user } = useSWR<IUserResponse>('api/user');

  const isWinner = getIsWinner(parry, user);

  const {
    steamModal: { openSteam },
    historyModal: { openHistory },
    cycleModal: { openCycle, setCycleId },
  } = useContext(ModalContext);

  const onStopCycleClick = (): void => {
    setCycleId(parry?.id);
    openCycle();
  };

  const {
    parryConfirmation: {
      setParryModalMode,
      setParryId,
      setParryConfirmationValue,
    },
  } = useContext(ModalContext);

  const onOpenModalClick = (mode: EParryModals): void => {
    setParryModalMode(mode);
    setParryId(parry?.id);
    setParryConfirmationValue(+parry.entryCost);
  };

  const { setParryBackground } = useContext(BackgroundContext);

  useEffect(() => {
    if (parry) {
      setParryBackground(parry?.image);
    }
  }, [parry]);

  useEffect(() => {
    return () => setParryBackground('');
  }, []);

  const onBackClick = (): void => navigate(-1);

  if (!parry) {
    return <ProgressSpinner className={classes.progressSpinner} />;
  }

  if ((parry as any)?.code === 400) {
    navigate('/parries');
  }

  return (
    <>
      <Button onClick={onBackClick} color="secondary">
        {translation('parry.back')}
      </Button>
      <ParryHeader
        parry={parry as any}
        onOpenModal={onOpenModalClick}
        onStopCycleClick={onStopCycleClick}
      />
      <div className={classes.container}>
        <div className={classes.mainInfoContainer}>
          <GeneralTerms parry={parry as any as IListedParry} />
          <AdditionalTerms parry={parry} />
        </div>
      </div>
      {isPreviewMode && <BottomActionButtons parry={parry} />}
      {!isPreviewMode && <BottomNotification />}
    </>
  );
}
