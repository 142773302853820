import { makeStyles } from 'theme';

export const useStyle = makeStyles()((theme) => ({
  modal: {
    position: 'relative',
    width: '736px',
    backgroundColor: theme.palette.bg.system,
    padding: '3rem 72px 1.5rem 72px',
  },
  videoContainer: {
    position: 'absolute',
    zIndex: '1',
    top: '0',
    right: '0',
    bottom: '0',
    left: '0',
    backgroundImage: 'url("/images/modals/make-profile-public-modal.png")',
    width: '736px',
    height: '360px',
  },
  content: {
    position: 'relative',
    zIndex: '2',
  },
  modalTitle: {
    display: 'inline-block',
    textAlign: 'center',
    marginBottom: '1rem',
    width: '100%',
  },
  modalDescr: {
    textAlign: 'center',
    width: '100%',
    display: 'inline-block',
    marginBottom: '2rem',
  },
  video: {
    width: '592px',
    height: '336px',
    marginBottom: '1.5rem',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '0.75rem',
  },
  modalBtn: {
    ...theme.layouts.marginXauto,
    marginBottom: '1rem',
    width: '400px',
  },
  backBtn: {
    ...theme.layouts.marginXauto,
    width: '400px',
  },
}));
