import { makeStyles } from 'theme';

export const useStyle = makeStyles()((theme) => ({
  container: {
    color: theme.palette.typo.primary,
    padding: '1.5rem 1rem',
  },
  title: {
    marginBottom: '2.5rem',
  },
  linkContainer: {
    marginBottom: '1.25rem',
    display: 'inline-block',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
  button: {
    marginRight: '0.75rem',
  },
}));
