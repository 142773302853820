import { FC } from 'react';

import clsx from 'clsx';

import { useStyle } from './Divider.styles';

interface IDivider {
  isActive: boolean;
}

export const Divider: FC<IDivider> = ({ isActive }) => {
  const { classes } = useStyle();
  return (
    <div
      className={clsx(
        classes.divider,
        isActive ? classes.dividerActive : classes.dividerInactive
      )}
    />
  );
};
