import React, { FC, useEffect, useRef, useState } from 'react';

import { Input, Typography } from 'ui-kit';

import { DropdownPositionType, IDropdownOption } from '../Dropdown';
import { useStyle } from './DropdownOption.styles';

interface IDropdownOptionsProps {
  options: IDropdownOption[];
  filter?: boolean;
  onOptionClick: (IDropdownOption) => void;
  onInputChange?: (param) => void;
  position: DropdownPositionType;
}

export const DropdownOptions: FC<IDropdownOptionsProps> = ({
  options,
  filter,
  onOptionClick,
  onInputChange,
  position,
}) => {
  const { Text } = Typography;
  const inputRef = useRef(null);
  const [search, setSearch] = useState<string>('');
  const filteredOptions =
    filter && !!search
      ? options?.filter((option) =>
          option.label.toLowerCase().includes(search.toLowerCase())
        )
      : options;
  const onSearchChange = (e): void => setSearch(e.target.value);
  useEffect(() => {
    if (filter) {
      inputRef.current.focus();
    }
  }, []);
  const hasScroll = filteredOptions?.length > 8;
  const { classes } = useStyle({ position, hasScroll });
  return (
    <div className={classes.optionsContainer}>
      {filter && (
        <Input
          iconType="search"
          inputSize="sm"
          className={classes.input}
          onChange={(e) => {
            if (onInputChange) return onInputChange(e.target.value);
            return onSearchChange(e);
          }}
          ref={inputRef}
        />
      )}
      {filteredOptions?.map((option) => (
        <div
          onClick={() => onOptionClick(option)}
          key={option.value}
          className={classes.item}
        >
          {option?.imgUrl && (
            <img
              src={option?.imgUrl}
              className={classes.img}
              alt="dota 2 hero"
            />
          )}
          <Text variant="b5">{option.label}</Text>
        </div>
      ))}
    </div>
  );
};
