import { CSSObject } from 'tss-react';

import { makeStyles } from 'theme';

import { ButtonSizeType, ButtonType, ColorType } from './Button';

export const useStyle = makeStyles<{
  color: ColorType;
  type: ButtonType;
  size: ButtonSizeType;
  disabled: boolean;
}>()((theme, { color, size, type, disabled }) => {
  const sizeMap: Record<ButtonSizeType, CSSObject> = {
    sm: { height: 38, padding: type === 'button' ? '6px 20px' : '6px' },
    md: { height: 40, padding: type === 'button' ? '8px 20px' : '8px' },
    l: { height: 44, padding: type === 'button' ? '10px 20px' : '10px' },
    xl: {
      height: 48,
      padding: type === 'button' ? '12px 20px' : '12px',
    },
  };
  const colorMap: Record<ColorType, CSSObject> = {
    primary: {
      backgroundColor: theme.palette.control.primary.bg,
      ':hover': {
        backgroundColor: !disabled && theme.palette.control.primary.bgHover,
      },
      ':active': {
        backgroundColor: !disabled && theme.palette.control.primary.bgPressed,
      },
    },
    secondary: {
      backgroundColor: theme.palette.control.secondary.bg,
      border: `1px solid ${theme.palette.control.secondary.bgBorder}`,
      ':hover': {
        span: {
          color: !disabled && theme.palette.typo.primary,
        },
        backgroundColor: !disabled && theme.palette.control.secondary.bgHover,
        border:
          !disabled && `1px solid ${theme.palette.control.secondary.bgHover}`,
      },
      ':active': {
        span: {
          color: !disabled && theme.palette.typo.primary,
        },
        backgroundColor: !disabled && theme.palette.control.primary.bgPressed,
        border:
          !disabled && `1px solid ${theme.palette.control.primary.bgPressed}`,
      },
    },
    transparent: {
      border: '1px solid transparent',
      backgroundColor: 'transparent',
      ':hover': {
        backgroundColor: theme.palette.control.secondary.bgHover,
        span: {
          color: theme.palette.typo.linkHover,
        },
      },
      ':active': {
        backgroundColor: theme.palette.control.secondary.bgPressed,
        span: {
          color: theme.palette.typo.linkPressed,
        },
      },
    },

    link: {
      border: '1px solid transparent',
      backgroundColor: 'transparent',
      ':hover': {
        span: {
          color: theme.palette.typo.linkHover,
        },
      },
      ':active': {
        span: {
          color: theme.palette.typo.linkPressed,
        },
      },
    },
    brand: {
      transitionDuration: '300ms',
      position: 'relative',
      zIndex: 3,
      '&:before': {
        content: "''",
        transitionDuration: '300ms',
        position: 'absolute',
        inset: 0,
        borderRadius: '6px',
        padding: '2px',
        background:
          'linear-gradient(88.89deg, #03D7BD 0.73%, #00D1FF 102.93%);',
        mask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
        maskComposite: 'xor',
        webkitMaskComposite: 'xor',
      },
      '&:after': {
        left: 0,
        top: 0,
        opacity: 0,
        borderRadius: 6,
        transitionDuration: '300ms',
        zIndex: -1,
        position: 'absolute',
        width: '100%',
        height: '100%',
        background: 'linear-gradient(88.89deg, #03D7BD 0.73%, #00D1FF 102.93%)',
        content: "''",
        display: 'block',
      },
      '&:hover:after': {
        transitionDuration: '300ms',
        opacity: '1',
      },
      ':active': {
        ':after': {
          backgroundImage: theme.palette.control.brand.bgPressed,
          backgroundColor: 'transparent',
        },
        ':before': {
          backgroundImage: theme.palette.control.brand.bgPressed,
          backgroundColor: 'transparent',
        },
      },
    },
  };
  return {
    button: {
      transitionDuration: '300ms',
      borderRadius: 6,
      position: 'relative',
      span: {
        ...theme.layouts.flexJCcenter,
      },
      ...colorMap[color],
      ...sizeMap[size],
    },
    disabled: {
      opacity: 0.4,
    },
  };
});
