import { makeStyles } from 'theme';

export const useStyle = makeStyles()(() => ({
  description: {
    maxWidth: 600,
    marginBottom: 40,
    display: 'inline-block',
  },
  container: {
    display: 'flex',
    alignItems: 'flex-end',
    '> div:first-of-type': {
      marginRight: 52,
    },
    marginBottom: 32,
  },
  title: {
    display: 'inline-block',
    width: '100%',
    marginBottom: '1.75rem',
  },
  titleContainer: {
    marginBottom: '2rem',
  },
}));
