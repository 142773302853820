import { makeStyles } from 'theme';

export const useStyle = makeStyles()((theme) => ({
  itemContainer: {
    ...theme.layouts.flexAICenter,
    justifyContent: 'space-between',
  },
  openLock: {
    display: 'none',
  },
  img: {
    width: '100%',
    height: '100%',
    borderRadius: 4,
  },
  item: {
    color: theme.palette.bg.border,
    border: `1px solid ${theme.palette.bg.border}`,
    borderRadius: 4,
    width: 60,
    height: 40,
    cursor: 'pointer',
    transitionDuration: '300ms',
    backgroundColor: theme.palette.control.default.bg,
    marginRight: 12,
    ':nth-of-type(6)': {
      marginRight: 20,
    },
    ...theme.fontStyles.text12,
    ...theme.layouts.flexCenter,
    ':hover': {
      color: theme.palette.typo.system,
      border: `1px solid ${theme.palette.typo.system}`,
      transitionDuration: '300ms',
      'svg:first-of-type': {
        display: 'block',
      },
      'svg:nth-of-type(2)': {
        display: 'none',
      },
    },
  },
  bucketIcon: {
    cursor: 'pointer',
  },
  title: {
    display: 'inline-block',
    marginBottom: 8,
  },
}));
