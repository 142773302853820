import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { Typography } from 'ui-kit';

import { useStyle } from './ParriesNotFount.styles';

interface IParriesNotFound {
  title: string;
}

export const ParriesNotFound: FC<IParriesNotFound> = ({ title }) => {
  const { t: translation } = useTranslation();
  const { classes } = useStyle();
  const { Headline, Text } = Typography;
  return (
    <div className={classes.container}>
      <Headline>
        {translation('parries-list.not-found')}{' '}
        {`${title ? `${translation('parries-list.for')} "${title}"` : ''}`}{' '}
      </Headline>
      <Text className={classes.request}>
        {translation('parries-list.search-again')}
      </Text>
    </div>
  );
};
