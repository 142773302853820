import { makeStyles } from 'theme';

export const useStyle = makeStyles()((theme) => ({
  // Table
  spinner: {
    ...theme.layouts.flexCenter,
    width: '100%',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  paginator: {
    padding: '0 1.5rem 1.5rem 1.5rem',
  },
  heroIcon: {
    width: 32,
    height: 20,
    objectFit: 'cover',
  },
  table: {
    padding: '1.5rem',
    '.p-datatable-thead > tr > th': {
      border: 'none',
      background: 'none',
      color: 'rgba(236, 246, 255, 0.7)',
      paddingTop: 0,
    },
    '.p-datatable-tbody > tr': {
      lineHeight: '40px',
    },
    '.p-datatable-tbody > tr > td': {
      border: 'none',
      padding: '0 1rem',
    },
    '.p-datatable-tbody > tr:nth-child(2n) > td': {
      padding: '12px 1rem',
    },
    '.p-datatable-tbody > tr, .custom-table-matches .p-datatable-tbody > th': {
      background: 'none',
    },
    '.p-datatable-tbody > tr:nth-child(2n + 1)': {
      background: 'rgba(53, 57, 64, 0.25)',
    },
    '.p-datatable-emptymessage': {
      color: 'rgba(236, 246, 255, 0.7)',
    },
  },
  preloginContainer: {
    marginTop: 80,
    marginLeft: 40,
    display: 'flex',
    flexDirection: 'column',
    gap: 14,
  },
  preloginTitle: {
    display: 'inline-block',
  },
  preloginDescription: {
    display: 'inline-block',
  },
}));
