import TagManager from 'react-gtm-module';
import { UseFormSetError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSWRConfig } from 'swr';

import { IUserResponse, registerUser } from 'api';

import { FormLoginValues } from '../Login';

type IRegister = (data: FormLoginValues) => Promise<IUserResponse>;

export function useRegister(
  setError: UseFormSetError<FormLoginValues>
): IRegister {
  const navigate = useNavigate();
  const { mutate } = useSWRConfig();
  const { t: translation } = useTranslation();

  return async (data: FormLoginValues): Promise<IUserResponse> => {
    //add google analytics
    TagManager.dataLayer({
      dataLayer: {
        event: 'sign_up',
        method: 'email',
      },
    });

    const user = await registerUser({
      password: data.password,
      email: data.email,
      code: data.code,
    });

    if (user.code === 400) {
      if (user.message.includes('email')) {
        setError('email', {
          type: 'custom',
          message: translation('login.errors.exist'),
        });
        return;
      }
      if (user.message.includes('password')) {
        setError('password', { type: 'custom', message: user.message });
        return;
      }
    }

    mutate('api/user', user);
    navigate('/?registration=true');
    return user;
  };
}
