import { CreateChallenge } from './CreateChallenge';

export const CREATE_CHALLENGE = {
  name: 'challenge',
  routes: [
    {
      title: 'CreateChallenge',
      path: 'challenge',
      element: <CreateChallenge />,
    },
  ],
};
