import { makeStyles } from 'theme';

export const useStyle = makeStyles()((theme) => ({
  tab: {
    opacity: 0.56,
    marginRight: 32,
    transitionDuration: '300ms',
    cursor: 'pointer',
    position: 'relative',
    ':hover': {
      opacity: 1,
    },
  },
  tabsContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
    marginBottom: 19,
    paddingLeft: 16,
  },
  activeTab: {
    color: theme.palette.typo.primary,
    opacity: 1,
    transitionDuration: '300ms',
  },
  underline: {
    position: 'absolute',
    bottom: '-10px',
    height: 3,
    backgroundColor: theme.palette.typo.link,
    transitionProperty: 'all',
    transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
    transitionDuration: '300ms',
    borderRadius: 2,
  },
}));
