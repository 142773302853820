import { FC } from 'react';

import { ICommonIconProps } from './types';

export const AcceptFlagIcon: FC<ICommonIconProps> = ({
  color = 'currentColor',
  width = 32,
  height = 32,
  viewBox = '0 0 32 32',
  className = '',
  style,
}) => (
  <svg
    fill="none"
    width={width}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.0012 4.53374C23.4228 5.12575 22.9912 5.4486 22.337 5.77843C21.4418 6.22977 20.5649 6.44569 19.2708 6.53349C18.3541 6.59565 17.4287 6.54999 15.2609 6.33567C13.5681 6.16827 11.8527 6.11225 11.0348 6.19761C9.36594 6.37181 8.05299 6.93322 7.00728 7.91967C6.73296 8.17843 6.68908 8.24059 6.7285 8.31433C6.75429 8.36243 7.51328 11.0446 8.41521 14.2747C9.31714 17.5048 10.0657 20.176 10.0788 20.2108C10.0949 20.2538 10.2035 20.185 10.4186 19.9956C11.9048 18.6868 13.5795 18.1818 16.1491 18.2675C16.6519 18.2842 17.3797 18.3288 17.7664 18.3666C18.1532 18.4044 18.9337 18.4816 19.5009 18.5381C20.9664 18.6842 22.9189 18.6858 23.6965 18.5414C24.507 18.3908 25.0194 18.2283 25.6438 17.9236C26.3185 17.5944 26.8634 17.2017 27.3897 16.6656C27.7819 16.2662 27.9258 16.0496 27.9766 15.7824C28.0364 15.4683 27.9902 15.4204 27.2123 14.989C25.9908 14.3117 24.8243 13.4984 23.8199 12.6238C23.5979 12.4304 23.3971 12.2171 23.3736 12.1498C23.3409 12.0562 23.3892 11.7918 23.5791 11.0247C24.0412 9.15757 24.4143 7.22382 24.6864 5.28458C24.8031 4.45367 24.8235 4.20381 24.7822 4.11329C24.6847 3.8992 24.5504 3.97148 24.0012 4.53374ZM4.66457 8.13826C4.32203 8.24734 4.00781 8.69953 4.00781 9.08346C4.00781 9.26914 9.05673 27.3256 9.17913 27.5777C9.30078 27.8282 9.56634 27.9977 9.83903 27.9989C9.93939 27.9993 10.1653 27.9568 10.341 27.9044C10.8467 27.7539 11.0864 27.4953 11.0864 27.1007C11.0864 26.9791 10.0921 23.3422 8.55664 17.8474C7.16526 12.8684 6.0071 8.74261 5.98296 8.67904C5.90983 8.48665 5.68688 8.25573 5.48638 8.1647C5.27548 8.06888 4.91846 8.05739 4.66457 8.13826Z"
      fill="#ECF6FF"
    />
  </svg>
);
