import { FC } from 'react';

import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import { ICreatedParry, IListedParry, getSteamUsers } from 'api';
import { useDota2Hero } from 'hooks';
import { ProfileSmall, SwordIcon } from 'icons';
import { BuilderItemsView, Card, Typography, Dividers } from 'ui-kit';

import {
  isHeroDefault,
  isItemsDefault,
} from '../../../CreateParry/CreateParry.model';
import { useStyle } from './GeneralTerms.styles';

interface IGeneralTermsProps {
  parry: IListedParry;
}

export const GeneralTerms: FC<IGeneralTermsProps> = ({ parry }) => {
  const { classes } = useStyle();
  const { t: translation } = useTranslation();

  const { VerticalDivider } = Dividers;
  const { Text, Headline } = Typography;

  const { items, hero, options } = parry;
  const withoutItems = isItemsDefault(items);
  const withoutHero = isHeroDefault(hero);
  const { localized_name, name } = useDota2Hero(parry);
  const mainTerm = options[0];
  const heroImg = `https://gamegreed-cdn.ams3.cdn.digitaloceanspaces.com/heroes/${
    withoutHero ? 'any_hero' : name.replace('npc_dota_hero_', '')
  }.png`;

  return (
    <div className={classes.generalContainer}>
      <Card variant="p16" className={classes.mainTermCard} color="bgSecondary">
        <div className={classes.mainTermCard} style={{ height: '100%' }}>
          <SwordIcon className={classes.icon} />
          <div className={classes.mainTermContent}>
            <Text variant="b6" color="system">
              {mainTerm['term'] == 0
                ? `${translation('parry.form.gameOptions.win')} ${translation(
                    'parryView.games'
                  )}`
                : `${translation('parry.form.gameOptions.lose')} ${translation(
                    'parryView.games'
                  )}`}
            </Text>
            <VerticalDivider />
            <Headline variant="h3">
              {!mainTerm['shouldBeInRow']
                ? mainTerm['quantity']
                : `${mainTerm['quantity']} ${translation('parryView.row')}`}
            </Headline>
          </div>
        </div>
      </Card>
      {!withoutItems && (
        <Card variant="p16" className={classes.subTermCard} color="bgSecondary">
          <div className={classes.cardContent}>
            <Text variant="b6" color="system">
              {translation('parryView.items')}
            </Text>
            <BuilderItemsView items={items} />
          </div>
        </Card>
      )}

      <Card variant="p16" className={classes.subTermCard} color="bgSecondary">
        <div className={classes.heroContent}>
          <Text variant="b6" color="system">
            {translation('parryView.hero')}
          </Text>
          <Headline variant="h3">
            {withoutHero
              ? translation('parries-list.parryCard.any')
              : localized_name}
          </Headline>
          <img src={heroImg} className={classes.heroImg} alt="Hero img" />
        </div>
      </Card>
    </div>
  );
};
