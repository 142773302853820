import { makeStyles } from 'theme';

export const useStyle = makeStyles()((theme) => ({
  howTo: {
    position: 'relative',
  },
  howToTitle: {
    marginBottom: 64,
  },
  step: {
    padding: 16,
    borderRadius: 6,
    borderLeft: `6px solid ${theme.palette.typo.link}`,
    background:
      'linear-gradient(38.92deg, rgba(22, 28, 40, 0.6) 0%, rgba(22, 28, 40, 0.2) 134.39%)',
    boxShadow:
      '0px 0px 12px 1px #001017, 0px 0px 40px 4px rgba(0, 16, 23, 0.24)',
    minWidth: 300,
  },
  stepTitle: {
    fontFamily: 'Ubuntu',
    color: theme.palette.typo.primary,
    fontSize: '18px',
    fontWeight: '500',
    marginBottom: '8px',
  },
  svgRoute: {
    margin: 0,
    width: '100%',
    zIndex: 2,
  },
  firstStep: {
    position: 'absolute',
    top: 60,
    left: '7%',
  },
  secondStep: {
    position: 'absolute',
    left: '72.5%',
    transform: 'translateX(-50%)',
    top: 60,
  },
  thirdStep: {
    position: 'absolute',
    top: 390,
    right: '7%',
    maxWidth: 360,
    transform: 'translateY(-50%)',
  },
  fourthStep: {
    position: 'absolute',
    transform: 'translateX(-50%)',
    maxWidth: 300,
    left: '47.5%',
    top: 600,
  },
  heroOne: {
    position: 'absolute',
    width: 260,
    left: '35%',
    zIndex: 1,
    top: -64,
  },
  heroTwo: {
    position: 'absolute',
    height: 280,
    bottom: 60,
    right: '40%',
  },
  heroThree: {
    position: 'absolute',
    width: 270,
    left: '31%',
    bottom: -440,
    zIndex: 5,
  },
}));
