import { useTranslation } from 'react-i18next';

import { Button, Typography } from 'ui-kit';

import { useStyle } from './AdCard.style';

interface IAdCardProps {
  title: string;
  titleRegistered?: string;
  text: string;
  btnText: string;
  bgUrl: string;
  onClick?: VoidFunction;
  isAuth?: boolean;
}

export default function AdCard({
  title,
  titleRegistered,
  text,
  btnText,
  bgUrl,
  onClick,
  isAuth,
}: IAdCardProps): JSX.Element {
  const { classes } = useStyle();
  const { t: translation } = useTranslation();
  const { Headline, Text } = Typography;
  return (
    <div
      className={classes.adCard}
      style={{
        backgroundImage: `url(${bgUrl})`,
      }}
    >
      <div className={classes.adCardContent}>
        <Headline>
          {isAuth && titleRegistered
            ? translation(titleRegistered)
            : translation(title)}
        </Headline>
        <Text variant="b2">
          {isAuth && titleRegistered
            ? translation(titleRegistered)
            : translation(text)}
        </Text>
        <div className={classes.flexColumn}>
          <Button
            onClick={onClick && onClick}
            className={classes.adCardButton}
            color="brand"
            buttonSize="xl"
          >
            {translation(btnText)}
          </Button>
        </div>
      </div>
    </div>
  );
}
