import { makeStyles } from 'theme';

export const useStyle = makeStyles()((theme) => ({
  divider: {
    height: 1,
    width: '100%',
    borderRadius: 2,
    backgroundColor: theme.palette.bg.border,
  },
}));
