import { makeStyles } from 'theme';

export const useStyle = makeStyles()((theme) => ({
  exitBtn: {
    border: `2px solid ${theme.palette.typo.link} !important`,
    borderRadius: 8,
    width: '100%',
    justifyContent: 'flex-start !important',
    height: 80,
    paddingLeft: 41,
    outline: 'none !important',
    transitionDuration: '300ms',
    marginBottom: 20,
    ':hover': {
      border: `2px solid ${theme.palette.typo.link} !important`,
      transitionDuration: '300ms',
      backgroundColor: `${theme.palette.typo.link} !important`,
      'span, svg': {
        color: theme.palette.typo.primary,
        transitionDuration: '300ms',
      },
    },
    ':active, :focus': {
      backgroundColor: `${theme.palette.typo.linkPressed} !important`,
      border: `2px solid ${theme.palette.typo.linkPressed} !important`,
      boxShadow: 'none !important',
      'span, svg': {
        color: theme.palette.typo.primary,
        transitionDuration: '300ms',
      },
    },
  },
  exitIcon: {
    marginRight: 12,
  },
  acceptBtnContainer: {
    position: 'relative',
    marginBottom: 20,
  },
  acceptBtn: {
    borderRadius: 8,
    transitionDuration: '300ms',
    backgroundImage:
      'linear-gradient(143.74deg, rgba(45, 99, 255, 0.7) 6.6%, rgba(0, 43, 167, 0.7) 93.95%)',
    border: 'none !important',
    backgroundColor: 'transparent !important',
    ':hover': {
      backgroundImage:
        'linear-gradient(143.74deg, #2D63FF 6.6%, #002BA7 93.95%) !important',
      border: 'none !important',
      backgroundColor: 'transparent !important',
    },
    ':active': {
      backgroundImage:
        'linear-gradient(143.74deg, rgba(45, 99, 255, 0.6) 6.6%, rgba(0, 43, 167, 0.6) 93.95%) !important',
      backgroundColor: 'transparent !important',
      border: 'none !important',
      'span, svg': {
        opacity: '0.7',
      },
    },
    ':focus': {
      outline: 'none !important',
      boxShadow: 'none !important',
    },
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '80px !important',
    width: '100%',
  },
  passwordIcon: {
    marginLeft: '0.75rem',
    color: theme.palette.typo.primary,
  },
  acceptError: {
    ...theme.fontStyles.text14,
    position: 'absolute',
    bottom: '-22%',
    left: 0,
    marginTop: '0.25rem',
    color: theme.palette.typo.alert,
  },
  stopRegBtn: {
    backgroundColor: theme.palette.typo.link,
    justifyContent: 'center',
    width: '100%',
  },
  flagContainer: {
    marginLeft: 0,
    marginRight: 'auto',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 8,
  },
  lockIcon: {
    height: 32,
    width: 32,
  },
  exitButtonContainer: {
    ...theme.layouts.flexCenter,
    width: '100%',
  },
}));
