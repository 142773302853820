import { FC } from 'react';

import { ICommonIconProps } from './types';

export const UICross24Icon: FC<ICommonIconProps> = ({
  color = 'currentColor',
  width = 24,
  height = 24,
  viewBox = '0 0 24 24',
  className = '',
  style,
}) => (
  <svg
    fill="none"
    width={width}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M17.6924 7.79254C18.1025 7.38247 18.1025 6.71762 17.6924 6.30755C17.2824 5.89748 16.6175 5.89748 16.2075 6.30755L12 10.515L7.79255 6.30755C7.38248 5.89748 6.71763 5.89748 6.30756 6.30755C5.8975 6.71762 5.8975 7.38247 6.30756 7.79254L10.515 12L6.30755 16.2075C5.89748 16.6175 5.89748 17.2824 6.30755 17.6924C6.71762 18.1025 7.38247 18.1025 7.79254 17.6924L12 13.485L16.2075 17.6924C16.6175 18.1025 17.2824 18.1025 17.6925 17.6924C18.1025 17.2824 18.1025 16.6175 17.6925 16.2075L13.485 12L17.6924 7.79254Z"
      fill={color}
    />
  </svg>
);
