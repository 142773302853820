import { FC } from 'react';

import { ICommonIconProps } from './types';

export const ExitChallengeIcon: FC<ICommonIconProps> = ({
  color = 'currentColor',
  width = 32,
  height = 32,
  viewBox = '0 0 32 32',
  className = '',
  style,
}) => (
  <svg
    fill="none"
    width={width}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.6613 26.6655C14.6613 26.3119 14.5208 25.9728 14.2708 25.7228C14.0208 25.4728 13.6817 25.3323 13.3281 25.3323H6.66239V6.66825H13.3281C13.6817 6.66825 14.0208 6.52779 14.2708 6.27778C14.5208 6.02777 14.6613 5.68867 14.6613 5.3351C14.6613 4.98153 14.5208 4.64244 14.2708 4.39242C14.0208 4.14241 13.6817 4.00195 13.3281 4.00195H6.66239C5.95524 4.00195 5.27706 4.28287 4.77703 4.78289C4.27701 5.28292 3.99609 5.9611 3.99609 6.66825V25.3323C3.99609 26.0395 4.27701 26.7176 4.77703 27.2177C5.27706 27.7177 5.95524 27.9986 6.66239 27.9986H13.3281C13.6817 27.9986 14.0208 27.8582 14.2708 27.6081C14.5208 27.3581 14.6613 27.019 14.6613 26.6655Z"
      fill={color}
    />
    <path
      d="M28.9508 16.9337C29.1946 16.6858 29.3315 16.3522 29.332 16.0045V15.9965C29.3313 15.6442 29.1909 15.3067 28.9414 15.0579L23.6088 9.72535C23.4859 9.59802 23.3388 9.49646 23.1761 9.42659C23.0135 9.35672 22.8385 9.31995 22.6615 9.31841C22.4845 9.31687 22.3089 9.3506 22.1451 9.41763C21.9813 9.48467 21.8324 9.58366 21.7072 9.70883C21.5821 9.834 21.4831 9.98285 21.416 10.1467C21.349 10.3105 21.3153 10.4861 21.3168 10.6631C21.3184 10.8401 21.3551 11.015 21.425 11.1777C21.4949 11.3403 21.5964 11.4874 21.7238 11.6104L24.7807 14.6673H12.0011C11.6475 14.6673 11.3085 14.8078 11.0584 15.0578C10.8084 15.3078 10.668 15.6469 10.668 16.0005C10.668 16.3541 10.8084 16.6931 11.0584 16.9432C11.3085 17.1932 11.6475 17.3336 12.0011 17.3336H24.7807L21.7238 20.3905C21.4809 20.642 21.3465 20.9787 21.3496 21.3283C21.3526 21.6778 21.4928 22.0122 21.74 22.2594C21.9872 22.5065 22.3215 22.6468 22.6711 22.6498C23.0206 22.6528 23.3574 22.5185 23.6088 22.2756L28.9508 16.9337Z"
      fill={color}
    />
  </svg>
);
