import { makeStyles } from 'theme';

export const useStyle = makeStyles()((theme) => ({
  divider: {
    backgroundColor: theme.palette.bg.border,
    width: 1,
    height: 30,
    marginLeft: 16,
    marginRight: 16,
  },
  infoContainer: {
    marginTop: 16,
    ...theme.layouts.flexJCSpaceBetween,
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    paddingBottom: 16,
  },
  horizontalDivider: {
    backgroundColor: theme.palette.bg.border,
    height: 1,
    width: '100%',
    borderRadius: 2,
  },
  infIcon: {
    display: 'inline',
  },
}));
