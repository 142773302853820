import { FC } from 'react';

import { ICommonIconProps } from './types';

export const EntryCostIcon: FC<ICommonIconProps> = ({
  color = 'currentColor',
  width = 20,
  height = 20,
  viewBox = '0 0 20 20',
  className = '',
  style,
}) => (
  <svg
    fill="none"
    width={width}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M17.3265 12.8761V13.9474C17.3265 14.59 17.0163 15.1395 16.5856 15.5713C16.1598 15.9992 15.5818 16.3482 14.9313 16.6245C13.627 17.1771 11.8831 17.5 9.99918 17.5C8.11525 17.5 6.37135 17.1779 5.06709 16.6245C4.41659 16.3482 3.83854 15.9992 3.41275 15.5713C3.01789 15.1766 2.72479 14.6808 2.67839 14.1061L2.67188 13.9474V12.8761C3.05045 13.0845 3.45508 13.2692 3.88821 13.4247C5.54092 14.0176 7.70248 14.3484 9.99918 14.3484C12.2959 14.3484 14.4574 14.0176 16.1101 13.4247C16.435 13.3079 16.7436 13.1753 17.0375 13.0276L17.3265 12.8761ZM2.67188 8.53395C3.05045 8.74237 3.45508 8.92711 3.88821 9.08263C5.54092 9.67553 7.70248 10.0063 9.99918 10.0063C12.2959 10.0063 14.4574 9.67553 16.1101 9.08263C16.5304 8.93223 16.9372 8.74872 17.3265 8.53395V10.9853C16.7859 11.3899 16.1853 11.713 15.5451 11.9437C14.1163 12.4568 12.155 12.7703 9.99918 12.7703C7.84414 12.7703 5.88286 12.4568 4.45322 11.9437C3.81305 11.713 3.21249 11.3899 2.67188 10.9853V8.53395ZM9.99918 2.5C11.8831 2.5 13.627 2.82211 14.9313 3.37553C15.5818 3.65184 16.1598 4.00079 16.5856 4.42868C16.9805 4.82342 17.2736 5.31921 17.32 5.89395L17.3265 6.05263V6.64316C16.7859 7.04782 16.1853 7.37094 15.5451 7.60158C14.1163 8.11474 12.155 8.42816 9.99918 8.42816C7.84414 8.42816 5.88286 8.11474 4.45322 7.60158C3.90273 7.40357 3.38132 7.13657 2.90228 6.80737L2.67188 6.64316V6.05263C2.67188 5.41 2.98206 4.86053 3.41275 4.42868C3.83854 4.00079 4.41659 3.65184 5.06709 3.37553C6.37135 2.82289 8.11525 2.5 9.99918 2.5Z"
      fill={color}
    />
  </svg>
);
