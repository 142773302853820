import { FC } from 'react';

import { ICommonIconProps } from './types';

export const OneD3LogoIcon: FC<ICommonIconProps> = ({
  color = 'currentColor',
  width = 95,
  height = 35,
  viewBox = '0 0 95 35',
  className = '',
  style,
}) => (
  <svg
    fill="none"
    width={width}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.9689 25.7366H39.143C40.768 25.749 42.3899 25.6186 43.9794 25.3476C45.3402 25.1205 46.6249 24.6621 47.7465 24.0033C48.8255 23.3346 49.6638 22.4486 50.1765 21.4355C50.7899 20.1391 51.0781 18.7583 51.0242 17.3723C51.0754 16.0096 50.7925 14.6522 50.1905 13.3771C49.6916 12.3672 48.856 11.4867 47.7747 10.8319C46.6483 10.1778 45.3658 9.71636 44.0076 9.47628C42.4117 9.18616 40.7793 9.04675 39.143 9.06092H18.9689V25.7366ZM25.1369 21.4807V13.3128H38.5485C39.7281 13.287 40.9059 13.4028 42.0429 13.6564C42.8744 13.8376 43.5842 14.2728 44.0208 14.8686C44.4895 15.6473 44.7035 16.5089 44.6421 17.3722C44.7005 18.2451 44.4869 19.1159 44.0208 19.906C43.5943 20.5073 42.8909 20.9506 42.0617 21.1408C40.9243 21.3929 39.7466 21.5075 38.5674 21.4807H25.1369Z"
      fill="white"
    />
    <path
      d="M0.131836 11.5041V15.7373L7.76538 13.8907C7.73712 14.2948 7.70887 14.7403 7.68061 15.2237C7.65236 15.707 7.63823 16.2584 7.63823 16.7606V25.7366H13.7884V9.05719H8.06207L0.131836 11.5041Z"
      fill="white"
    />
    <path
      d="M82.504 19.5891C82.1042 19.0632 81.5515 18.6227 80.8934 18.3052C80.1591 17.9466 79.3668 17.6709 78.5387 17.4858C78.5048 17.4765 78.4705 17.4689 78.4351 17.4631C78.8213 17.3574 79.1886 17.2516 79.5277 17.1421C80.4379 16.8728 81.2415 16.4124 81.8541 15.8091C82.4083 15.1735 82.6848 14.4088 82.6407 13.634C82.6995 12.5761 82.2041 11.5467 81.275 10.7944C80.3614 10.0768 78.8623 9.55198 76.7778 9.21967C73.9985 8.83957 71.1808 8.67143 68.3623 8.71744C67.229 8.71744 66.0378 8.73757 64.7881 8.77786C63.5448 8.81938 62.2921 8.87602 61.0206 8.9478C59.7494 9.01954 58.525 9.11395 57.31 9.21967C56.095 9.3254 54.9553 9.44247 53.8488 9.57464L54.6682 13.7058C55.9021 13.5774 57.2957 13.4528 58.8452 13.3282C60.3944 13.2035 62.0567 13.1205 63.8274 13.045C65.5981 12.9694 67.4016 12.9354 69.2243 12.9354C71.1454 12.9354 72.629 12.972 73.6745 13.045C74.4287 13.058 75.1702 13.205 75.8453 13.4754C76.242 13.6743 76.4745 14.0295 76.4526 14.4044C76.4771 14.6959 76.3513 14.9826 76.1041 15.1974C75.7816 15.4139 75.3838 15.5457 74.9645 15.575C74.2709 15.6568 73.5701 15.6934 72.869 15.6845H64.9576V18.9132H73.3588C74.0565 18.901 74.7542 18.9466 75.4403 19.0491C75.8657 19.0988 76.2597 19.2563 76.5656 19.4985C76.812 19.7478 76.936 20.0607 76.9141 20.3783C76.9303 20.75 76.6821 21.0969 76.2737 21.2733C75.5533 21.537 74.7723 21.6784 73.9804 21.6887C72.8784 21.7604 71.301 21.7982 69.2714 21.7982C67.4879 21.7982 65.7002 21.7491 63.9076 21.6509C62.1151 21.5527 60.4133 21.4268 58.8027 21.2733C57.1921 21.126 55.7559 20.99 54.5032 20.8579L53.6838 24.9928C54.7342 25.1552 55.855 25.3025 57.0368 25.4308C58.2187 25.5592 59.4669 25.6725 60.7618 25.7632C62.0567 25.8538 63.399 25.9218 64.7881 25.9708C66.1772 26.0199 67.6137 26.0426 69.0593 26.0426C71.374 26.0643 73.6877 25.9659 75.9866 25.7481C77.4574 25.6246 78.8996 25.3374 80.2717 24.8946C81.1669 24.6096 81.9298 24.1097 82.4569 23.4635C82.8962 22.8412 83.1173 22.1353 83.0974 21.4205C83.1094 20.7783 82.9045 20.1454 82.504 19.5891Z"
      fill="white"
    />
    <path
      d="M93.6193 8.74H83.259C83.01 8.73823 82.7806 8.63071 82.6566 8.45748C82.5327 8.28424 82.5327 8.07129 82.6562 7.89792L87.8362 0.681621C87.9605 0.507488 88.1914 0.400024 88.4412 0.400024C88.6914 0.400024 88.9223 0.507488 89.0467 0.681621L94.2266 7.89792C94.3509 8.07186 94.3502 8.28562 94.2251 8.45911C94.1 8.63257 93.869 8.73958 93.6193 8.74Z"
      fill="#FF0000"
    />
    <path
      d="M39.5636 33.9644C39.5041 33.9644 39.4506 33.9468 39.4031 33.9112C39.3556 33.8708 39.3319 33.8227 39.3319 33.7673V28.8544C39.3319 28.7988 39.3556 28.7533 39.4031 28.7179C39.4506 28.6775 39.5041 28.6573 39.5636 28.6573H42.0049C42.581 28.6573 43.0802 28.7381 43.5018 28.8999C43.9237 29.0566 44.2564 29.2891 44.4998 29.5974C44.7432 29.9057 44.8739 30.2848 44.892 30.7346C44.9037 30.952 44.9097 31.144 44.9097 31.3108C44.9097 31.4776 44.9037 31.6671 44.892 31.8794C44.8682 32.3545 44.7405 32.7463 44.5088 33.0547C44.2828 33.3627 43.9622 33.5929 43.5462 33.7445C43.1307 33.891 42.6315 33.9644 42.0494 33.9644H39.5636ZM41.2477 32.6754H42.0049C42.2009 32.6754 42.3674 32.6475 42.5038 32.5921C42.6405 32.5366 42.7475 32.4506 42.8248 32.3343C42.902 32.213 42.9434 32.0589 42.9495 31.8718C42.9555 31.7253 42.9581 31.5938 42.9581 31.4776C42.9642 31.3614 42.9642 31.2451 42.9581 31.1289C42.9581 31.0126 42.9555 30.8837 42.9495 30.7422C42.9374 30.4642 42.8425 30.262 42.6643 30.1357C42.4921 30.0093 42.2574 29.9461 41.9605 29.9461H41.2477V32.6754Z"
      fill="white"
    />
    <path
      d="M46.0903 33.9644C46.0312 33.9644 45.9777 33.9468 45.9302 33.9112C45.8827 33.8708 45.859 33.8227 45.859 33.7673V28.8544C45.859 28.7988 45.8827 28.7533 45.9302 28.7179C45.9777 28.6775 46.0312 28.6573 46.0903 28.6573H47.6052C47.6704 28.6573 47.7239 28.6775 47.7657 28.7179C47.8132 28.7533 47.8369 28.7988 47.8369 28.8544V33.7673C47.8369 33.8227 47.8132 33.8708 47.7657 33.9112C47.7239 33.9468 47.6704 33.9644 47.6052 33.9644H46.0903Z"
      fill="white"
    />
    <path
      d="M51.7012 34.0401C51.1131 34.0401 50.6083 33.9644 50.1863 33.8128C49.7647 33.6561 49.4351 33.4236 49.1974 33.1153C48.96 32.8069 48.8323 32.4253 48.8142 31.9704C48.8086 31.7581 48.8055 31.5307 48.8055 31.2881C48.8055 31.0404 48.8086 30.8079 48.8142 30.5906C48.8323 30.1508 48.96 29.7819 49.1974 29.4837C49.4411 29.1855 49.7768 28.9606 50.2044 28.8089C50.632 28.6573 51.1308 28.5815 51.7012 28.5815C52.1586 28.5815 52.5655 28.627 52.9219 28.7179C53.2783 28.8089 53.5812 28.9327 53.8306 29.0894C54.0861 29.2461 54.2793 29.423 54.4101 29.6201C54.5465 29.8122 54.6177 30.0118 54.6237 30.2191C54.6237 30.2646 54.603 30.305 54.5615 30.3404C54.5257 30.3707 54.4813 30.3859 54.4278 30.3859H52.7882C52.729 30.3859 52.6812 30.3783 52.6457 30.3631C52.61 30.3429 52.5776 30.3176 52.5478 30.2873C52.518 30.2368 52.4706 30.1786 52.405 30.1129C52.3459 30.0472 52.2596 29.9916 52.1466 29.9462C52.0339 29.8956 51.8855 29.8703 51.7012 29.8703C51.4221 29.8703 51.2051 29.931 51.051 30.0523C50.8965 30.1736 50.8132 30.3657 50.8012 30.6285C50.7835 31.0531 50.7835 31.4877 50.8012 31.9325C50.8132 32.2206 50.8991 32.4304 51.0596 32.5616C51.2262 32.6882 51.4488 32.7514 51.728 32.7514C51.9122 32.7514 52.0753 32.7261 52.2181 32.6754C52.3666 32.6251 52.4822 32.5466 52.5655 32.4405C52.6488 32.3293 52.6902 32.1877 52.6902 32.0159V31.9401H52.0576C51.9925 31.9401 51.936 31.9199 51.8885 31.8794C51.8467 31.839 51.8259 31.7935 51.8259 31.743V31.0379C51.8259 30.9823 51.8467 30.9368 51.8885 30.9014C51.936 30.861 51.9925 30.8408 52.0576 30.8408H54.401C54.4662 30.8408 54.5227 30.861 54.5702 30.9014C54.6177 30.9368 54.6414 30.9823 54.6414 31.0379V31.9628C54.6414 32.4076 54.5167 32.7841 54.2673 33.0925C54.0179 33.4009 53.6735 33.6359 53.2339 33.7974C52.7942 33.9593 52.2833 34.0401 51.7012 34.0401Z"
      fill="white"
    />
    <path
      d="M55.8206 33.9644C55.7611 33.9644 55.7076 33.9468 55.6601 33.9112C55.6127 33.8708 55.5889 33.8227 55.5889 33.7673V28.8544C55.5889 28.7988 55.6127 28.7533 55.6601 28.7179C55.7076 28.6775 55.7611 28.6573 55.8206 28.6573H57.3355C57.4007 28.6573 57.4542 28.6775 57.4957 28.7179C57.5431 28.7533 57.5669 28.7988 57.5669 28.8544V33.7673C57.5669 33.8227 57.5431 33.8708 57.4957 33.9112C57.4542 33.9468 57.4007 33.9644 57.3355 33.9644H55.8206Z"
      fill="white"
    />
    <path
      d="M60.3266 33.9644C60.2615 33.9644 60.205 33.9468 60.1575 33.9112C60.1157 33.8708 60.0949 33.8227 60.0949 33.7673V30.0674H58.5623C58.4972 30.0674 58.4406 30.0498 58.3932 30.0144C58.3517 29.9739 58.3306 29.9259 58.3306 29.8703V28.8544C58.3306 28.7988 58.3517 28.7533 58.3932 28.7179C58.4406 28.6775 58.4972 28.6573 58.5623 28.6573H63.5701C63.6353 28.6573 63.6888 28.6775 63.7302 28.7179C63.7777 28.7533 63.8018 28.7988 63.8018 28.8544V29.8703C63.8018 29.9259 63.7777 29.9739 63.7302 30.0144C63.6888 30.0498 63.6353 30.0674 63.5701 30.0674H62.0375V33.7673C62.0375 33.8227 62.0137 33.8708 61.9663 33.9112C61.9244 33.9468 61.8709 33.9644 61.8058 33.9644H60.3266Z"
      fill="white"
    />
    <path
      d="M64.8024 33.9644C64.7432 33.9644 64.6897 33.9468 64.6423 33.9112C64.5948 33.8708 64.571 33.8227 64.571 33.7673V28.8544C64.571 28.7988 64.5948 28.7533 64.6423 28.7179C64.6897 28.6775 64.7432 28.6573 64.8024 28.6573H69.1685C69.2337 28.6573 69.2902 28.6775 69.3377 28.7179C69.3852 28.7533 69.4093 28.7988 69.4093 28.8544V29.749C69.4093 29.7995 69.3852 29.845 69.3377 29.8855C69.2902 29.9259 69.2337 29.9461 69.1685 29.9461H66.4152V30.6967H68.9726C69.0378 30.6967 69.0943 30.7169 69.1418 30.7573C69.1892 30.7927 69.213 30.8382 69.213 30.8938V31.7126C69.213 31.7682 69.1892 31.8163 69.1418 31.8567C69.0943 31.8921 69.0378 31.9097 68.9726 31.9097H66.4152V32.6754H69.2397C69.3053 32.6754 69.3614 32.6956 69.4093 32.736C69.4567 32.7767 69.4805 32.8245 69.4805 32.8803V33.7673C69.4805 33.8227 69.4567 33.8708 69.4093 33.9112C69.3614 33.9468 69.3053 33.9644 69.2397 33.9644H64.8024Z"
      fill="white"
    />
    <path
      d="M73.084 34.0401C72.5197 34.0401 72.0265 33.967 71.6049 33.8202C71.183 33.6686 70.8503 33.4413 70.6069 33.138C70.3692 32.8348 70.2385 32.4506 70.2147 31.9856C70.2091 31.7783 70.2061 31.556 70.2061 31.3184C70.2061 31.0808 70.2091 30.8534 70.2147 30.6361C70.2385 30.1812 70.3722 29.8021 70.616 29.4988C70.8654 29.1905 71.2011 28.9606 71.6226 28.8089C72.0502 28.6573 72.5374 28.5815 73.084 28.5815C73.4759 28.5815 73.8413 28.6219 74.18 28.7028C74.5244 28.7786 74.8303 28.8974 75.0978 29.0591C75.3649 29.2158 75.5759 29.4129 75.7303 29.6505C75.8848 29.883 75.965 30.1559 75.9707 30.4693C75.9707 30.5147 75.95 30.5527 75.9085 30.583C75.8727 30.6133 75.8283 30.6285 75.7748 30.6285H74.2689C74.18 30.6285 74.1145 30.6133 74.073 30.583C74.0316 30.5527 73.9958 30.4996 73.966 30.4238C73.8888 30.2065 73.7761 30.0599 73.6273 29.984C73.4789 29.9082 73.2977 29.8703 73.084 29.8703C72.8226 29.8703 72.6146 29.931 72.4601 30.0523C72.3057 30.1685 72.2228 30.3757 72.2107 30.674C72.193 31.0884 72.193 31.513 72.2107 31.9477C72.2228 32.2459 72.3057 32.4556 72.4601 32.577C72.6146 32.6931 72.8226 32.7514 73.084 32.7514C73.2977 32.7514 73.4789 32.711 73.6273 32.6299C73.7818 32.5491 73.8948 32.4051 73.966 32.1978C73.9898 32.117 74.0225 32.0639 74.064 32.0386C74.1114 32.0083 74.18 31.9932 74.2689 31.9932H75.7748C75.8283 31.9932 75.8727 32.0083 75.9085 32.0386C75.95 32.069 75.9707 32.1069 75.9707 32.1524C75.965 32.4658 75.8848 32.7411 75.7303 32.9787C75.5759 33.2111 75.3649 33.4083 75.0978 33.5701C74.8303 33.7269 74.5244 33.8455 74.18 33.9263C73.8413 34.0023 73.4759 34.0401 73.084 34.0401Z"
      fill="white"
    />
    <path
      d="M77.0648 33.9644C77.0053 33.9644 76.9518 33.9468 76.9043 33.9112C76.8569 33.876 76.8331 33.8279 76.8331 33.7673V28.8544C76.8331 28.7988 76.8569 28.7533 76.9043 28.7179C76.9518 28.6775 77.0053 28.6573 77.0648 28.6573H78.5172C78.5824 28.6573 78.6359 28.6775 78.6777 28.7179C78.7252 28.7533 78.7489 28.7988 78.7489 28.8544V30.5678H80.5663V28.8544C80.5663 28.7988 80.5874 28.7533 80.6289 28.7179C80.6763 28.6775 80.7329 28.6573 80.798 28.6573H82.2504C82.316 28.6573 82.3695 28.6775 82.4109 28.7179C82.4584 28.7533 82.4821 28.7988 82.4821 28.8544V33.7673C82.4821 33.8227 82.4584 33.8708 82.4109 33.9112C82.3695 33.9468 82.316 33.9644 82.2504 33.9644H80.798C80.7329 33.9644 80.6763 33.9468 80.6289 33.9112C80.5874 33.8708 80.5663 33.8227 80.5663 33.7673V32.0083H78.7489V33.7673C78.7489 33.8227 78.7252 33.8708 78.6777 33.9112C78.6359 33.9468 78.5824 33.9644 78.5172 33.9644H77.0648Z"
      fill="white"
    />
  </svg>
);
