import { FC } from 'react';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Button, Typography } from 'ui-kit';

import { useStyle } from './Footer.styles';

export const Footer: FC = () => {
  const { t: translation } = useTranslation();
  const { classes } = useStyle();
  return (
    <div className={classes.cardContainer}>
      <Link to="/challenges">
        <Button className={classes.button} buttonSize="l">
          {translation('challenges-about.footer.button')}
        </Button>
      </Link>
    </div>
  );
};
