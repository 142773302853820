import { FC, useRef } from 'react';

import { useTranslation } from 'react-i18next';
import { mutate } from 'swr';

import { stopChallengeRestart } from 'api';
import { useClickOutside } from 'hooks';
import { Button, Typography } from 'ui-kit';

import { Modal } from '../Modal';
import { useStyle } from './ModalCycle.styles';

interface ICycleModalProps {
  onClose: () => void;
  isOpen: boolean;
  id: number;
}

export const ModalCycle: FC<ICycleModalProps> = ({ onClose, isOpen, id }) => {
  const { classes } = useStyle();
  const ref = useRef(null);
  useClickOutside(ref, onClose);
  const { t: translation } = useTranslation();

  const onStopCycleClick = async (): Promise<void> => {
    const response = await stopChallengeRestart(id);
    if (response.ok) {
      onClose();
      mutate(`api/challenge/${id}`);
    }
  };
  const { Text, Headline } = Typography;
  return (
    <Modal isOpen={isOpen}>
      <div className={classes.modal} ref={ref}>
        <Headline variant="h3" className={classes.title}>
          {translation('challenge.form.cycleModal.title')}
        </Headline>
        <Text variant="b4" className={classes.description}>
          {translation('challenge.form.cycleModal.description')}
          <br />
          <br />
          {translation('challenge.form.cycleModal.question')}
        </Text>
        <div className={classes.buttonContainer}>
          <Button
            className={classes.button}
            onClick={onClose}
            color="secondary"
          >
            {translation('challenge.form.cycleModal.cancelBtn')}
          </Button>
          <Button className={classes.button} onClick={onStopCycleClick}>
            {translation('challenge.form.cycleModal.submitBtn')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
