import { StrictMode } from 'react';

import { createRoot } from 'react-dom/client';
import TagManager from 'react-gtm-module';
import { BrowserRouter } from 'react-router-dom';

import App from './App';

const tagManagerArgs = {
  gtmId: 'GTM-N7FDCD46',
};

TagManager.initialize(tagManagerArgs);

const container = document.getElementById('root');

if (container) {
  const root = createRoot(container);

  root.render(
    <StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </StrictMode>
  );
}
