import { FC } from 'react';

import clsx from 'clsx';

import { CloseIcon, UiCheckIcon } from 'icons';

import { useStyle } from './CircleIcon.styles';

export type IconType = 'error' | 'success';

const iconMap: Record<IconType, React.ReactNode> = {
  error: <CloseIcon height={16} width={16} />,
  success: <UiCheckIcon />,
};

interface ICircleIconProps {
  type?: IconType;
  className?: string;
}

export const CircleIcon: FC<ICircleIconProps> = ({
  type = 'success',
  className = '',
}) => {
  const { classes } = useStyle({ type });
  return (
    <div className={clsx(className, classes.iconContainer)}>
      {iconMap[type]}
    </div>
  );
};
