import { FC } from 'react';

import {
  IHorizontalDividerProps,
  HorizontalDivider,
} from './HorizontalDivider';
import { VerticalDivider } from './VerticalDivider';

interface IDividers {
  VerticalDivider: FC;
  HorizontalDivider: FC<IHorizontalDividerProps>;
}

export const Dividers: IDividers = {
  VerticalDivider: VerticalDivider,
  HorizontalDivider: HorizontalDivider,
};
