import clsx from 'clsx';

import { InfoIcon } from 'icons';
import { Typography, Tooltip } from 'ui-kit';

import { useStyle } from './Title.styles';

interface IProps {
  children: React.ReactNode;
  className?: string;
  tooltipText?: string;
}

export function Title({
  children,
  className,
  tooltipText,
}: IProps): JSX.Element {
  const { classes } = useStyle();
  const { Headline } = Typography;
  return (
    <h5 className={clsx(classes.flexAICenter, className)}>
      <Headline variant="h3" color="primary" className={classes.title}>
        {' '}
        {children}
      </Headline>
      {tooltipText && (
        <Tooltip position="bottom" content={tooltipText}>
          <InfoIcon className={classes.infoIcon} />
        </Tooltip>
      )}
    </h5>
  );
}
