import { FC } from 'react';

import clsx from 'clsx';
import { DurationObjectUnits } from 'luxon';

import { Typography } from 'ui-kit';

import { useStyle } from './Timer.styles';

export interface ITimerProps {
  time: DurationObjectUnits;
  className?: string;
}

export const Timer: FC<ITimerProps> = ({ time, className = '' }) => {
  const { Text } = Typography;
  const { classes } = useStyle();
  const { days, hours, minutes } = time;
  const hasRound = minutes > 59.5;
  return (
    <Text variant="b5">
      <div className={clsx(classes.timer, className)}>
        <div>{days.toFixed(0)}</div>
        <div>dd</div> <div>:</div>
        <div>{(hasRound ? hours + 1 : hours).toFixed(0)}</div>
        <div>hh</div>
        <div>:</div>
        <div>{hasRound ? 0 : minutes.toFixed(0)}</div>
        <div>mm</div>
      </div>
    </Text>
  );
};
