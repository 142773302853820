import { BACKEND_URL } from './env';
import { IErrorResponse } from './interface';

interface IMatchesFilter {
  offset: number;
  limit: number;
  mode: string;
}

export interface IMatch {
  id: number;
  date: number;
  img: string;
  stats: { kills: number; deaths: number; assists: number };
  result: number | null;
  earned: number | null;
}

export interface IMatchesResponse {
  matches: IMatch[];
  total: number;
}

export interface IStatisticResponse {
  totalGames: number;
  wins: number;
  heroes: [
    {
      name: string;
      url: string;
      totalGames: number;
      wins: number;
    }
  ];
}

export function getMatches({
  offset,
  limit,
  mode,
}: IMatchesFilter): Promise<IMatchesResponse | IErrorResponse> {
  return fetch(
    `${BACKEND_URL}/api/dota2-service/matches?offset=${offset}&limit=${limit}&mode=${mode}`,
    {
      credentials: 'include',
    }
  ).then((data) => data.json());
}

export function getStatistic(): Promise<IStatisticResponse> {
  return fetch(`${BACKEND_URL}/api/dota2-service/matches/statistic`, {
    credentials: 'include',
  }).then((data) => data.json());
}
