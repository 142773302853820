import React, { FC, useState } from 'react';

import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';

import { TooltipArrowIcon } from 'icons';

import { Typography } from '../Typography';
import { useStyle } from './Tooltip.styles';

export type TooltipPositionType = 'right' | 'top' | 'bottom' | 'left';

export type TooltipColorType = 'gray' | 'blue';

type TooltipType = 'hover' | 'focus';

interface ITooltipProps {
  content: React.ReactNode;
  children: React.ReactNode;
  position?: TooltipPositionType;
  className?: string;
  color?: TooltipColorType;
  isVisible?: boolean;
  type?: TooltipType;
}

export const Tooltip: FC<ITooltipProps> = ({
  position = 'top',
  content,
  children,
  color = 'gray',
  type = 'hover',
  className,
  isVisible = false,
}) => {
  const [isShow, setIsShow] = useState(false);

  const showPopup = (): void => {
    setIsShow(true);
  };

  const hidePopup = (): void => {
    setIsShow(false);
  };
  const { classes } = useStyle({ position, color });
  const isTooltipVisible =
    (type === 'hover' && isShow && !!content) ||
    (type === 'focus' && isVisible && !!content);

  const { Text } = Typography;
  return (
    <div
      className={clsx(classes.container, className)}
      onMouseLeave={hidePopup}
      onMouseMove={showPopup}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {children}
      <AnimatePresence>
        {isTooltipVisible && (
          <motion.div
            className={classes.tooltip}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            key="tooltip"
          >
            <TooltipArrowIcon className={classes.tooltipArrow} />
            <Text variant="b5">{content}</Text>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
