import { FC, useEffect, useState } from 'react';

import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useSWR from 'swr';

import { getReferralUser, isLoginUser } from 'api';
import { LanguageSelector } from 'components';
import { useLoginServices, useLanguageUpdate } from 'hooks';
import { SteamIcon } from 'icons';
import { Button, Checkbox, Input, Typography } from 'ui-kit';

import { useLogin, useRegister } from './hooks';
import { useStyle } from './Login.styles';

const defaultLoginValues = {
  email: '',
  password: '',
  subscribeNews: false,
  code: '',
};

export type FormLoginValues = typeof defaultLoginValues;
type LoginModeType = 'login' | 'signup' | 'referralReg' | 'failedReferralReg';

export const Login: FC = () => {
  const [searchParams] = useSearchParams();
  const referralCode = searchParams.get('referal');
  const type = searchParams.get('type');
  const [loginMode, setLoginMode] = useState<LoginModeType>(
    type === 'register' ? 'signup' : 'login'
  );

  useEffect(() => {
    const checkReferral = async (): Promise<void> => {
      if (referralCode) {
        const referralUser = await getReferralUser(referralCode);
        if (referralUser?.status === 200) {
          setLoginMode('referralReg');
        } else {
          setLoginMode('failedReferralReg');
        }
      }
    };
    checkReferral();
  }, []);
  const { Headline, Text } = Typography;
  const {
    control,
    formState: { errors },
    handleSubmit,
    setError,
    setFocus,
  } = useForm<FormLoginValues>({ defaultValues: defaultLoginValues });
  const { classes } = useStyle();
  const { t: translation } = useTranslation();
  const { data: user } = useSWR('api/user', isLoginUser);
  const navigate = useNavigate();

  useEffect(() => {
    console.log(user);
    if (user?.id) {
      navigate('/home');
    }
  }, [user]);

  const toggleLogin = (): void =>
    setLoginMode((prevState) => (prevState === 'login' ? 'signup' : 'login'));
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const togglePassword = (): void => {
    setFocus('password');
    setIsPasswordVisible((prevState) => !prevState);
  };

  const { onSteamClick, steamUrlError } = useLoginServices(
    loginMode === 'referralReg' ? referralCode : ''
  );
  const createUser = useRegister(setError);
  const loginUser = useLogin(setError);
  const { updateLanguage } = useLanguageUpdate();

  const onSubmit = handleSubmit(async (values) => {
    try {
      if (loginMode === 'login') {
        const user = await loginUser(values);
        updateLanguage(user?.id);
      } else {
        const newUser = await createUser({ ...values, code: referralCode });
        if (newUser !== undefined) {
          loginUser(values);
        }
      }
    } catch (err) {
      console.error(err);
    }
  });
  return (
    <div className={classes.contentContainer}>
      <LanguageSelector className={classes.languageContainer} />
      <div className={classes.titleContainer}>
        <Headline className={classes.title}>
          {loginMode === 'login' && translation('login.login.title')}
          {loginMode === 'signup' && translation('login.signup.title')}
          {loginMode === 'failedReferralReg' &&
            translation('login.invalidReferral.title')}
          {loginMode === 'referralReg' && translation('login.referral')}
        </Headline>
        <Text>
          {loginMode === 'login' && translation('login.login.description')}
          {loginMode === 'signup' && translation('login.signup.description')}
          {loginMode === 'failedReferralReg' &&
            translation('login.invalidReferral.suggestion')}
          {loginMode === 'referralReg' &&
            translation('login.referralDescription')}
        </Text>{' '}
        {(loginMode === 'login' || loginMode === 'signup') && (
          <button onClick={toggleLogin}>
            <Text color="link">
              {translation(
                loginMode === 'login'
                  ? 'login.login.suggestion'
                  : 'login.signup.suggestion'
              )}
            </Text>
          </button>
        )}
      </div>
      <div className={classes.formContainer}>
        <form onSubmit={onSubmit} className={classes.form}>
          <Controller
            name="email"
            control={control}
            rules={{
              required: translation('login.login.error.emailReq'),
              pattern: {
                value: /^[A-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i,
                message: translation('login.login.error.invalidEmail'),
              },
            }}
            render={({ field }) => (
              <Input
                {...field}
                title={translation('login.fields.email')}
                placeholder={translation('login.common.placeholder.email')}
                error={errors['email']?.message}
              />
            )}
          />
          <Controller
            name="password"
            control={control}
            rules={{
              required: translation('login.login.error.passwordReq'),
              minLength: {
                value: loginMode === 'login' ? 0 : 8,
                message: translation('login.signup.error.passwordMinLength'),
              },
              pattern:
                loginMode === 'login'
                  ? undefined
                  : {
                      value: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/g,
                      message: translation(
                        'login.signup.error.passwordPattern'
                      ),
                    },
            }}
            key="password"
            render={({ field }) => (
              <Input
                {...field}
                title={translation('login.fields.password')}
                error={errors['password']?.message}
                type={isPasswordVisible ? 'text' : 'password'}
                placeholder={translation('login.common.placeholder.password')}
                iconType={'eye'}
                onIconClick={togglePassword}
              />
            )}
          />
          {loginMode !== 'login' && (
            <Controller
              name="subscribeNews"
              control={control}
              render={({ field }) => (
                <Checkbox
                  onChange={(e) => field.onChange(e.target.checked)}
                  checked={field.value}
                  label={translation('login.signup.news')}
                />
              )}
            />
          )}
          <Button type="submit" className={classes.button}>
            <Text>
              {translation(
                loginMode === 'login'
                  ? 'login.login.button'
                  : 'login.signup.button'
              )}
            </Text>
          </Button>
        </form>
        <div className={classes.orContainer}>
          <span className={classes.verticalDivider} />
          <Text>{translation('login.common.or')}</Text>
          <span className={classes.verticalDivider} />
        </div>
        <Button
          color="secondary"
          buttonSize="xl"
          onClick={onSteamClick}
          disabled={!!steamUrlError}
        >
          <SteamIcon className="mr-2.5" />
          {translation(
            loginMode === 'login'
              ? 'login.common.steamLogin'
              : 'login.common.steam'
          )}
        </Button>
        <Text variant="b4" className={classes.termsOfUse}>
          {translation(
            `login.terms.${loginMode === 'login' ? 'login' : 'register'}`
          )}{' '}
          <Text variant="b4" color="link">
            <a
              href={
                window.location.origin + '/legal/world/Profile.TermsOfUse.pdf'
              }
              target="_blank"
              rel="noreferrer"
            >
              {translation('login.terms.term')}
            </a>
          </Text>
        </Text>
      </div>
    </div>
  );
};
