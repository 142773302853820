import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { getDota2HeroImage, ICreatedChallenge } from 'api';
import { OPTIONS_MAIN_TERM } from 'consts';
import { removeZeroesAfterComma } from 'helpers';
import { useDota2Hero } from 'hooks';
import {
  GradientEntryCostIcon,
  GradientSwordsIcon,
  LashHitIcon,
  ShieldIcon,
  TrophyGradeIcon,
} from 'icons';
import { BuilderItemsView, Card, Typography } from 'ui-kit';

import { calcRewardLabel } from '../../ChallengeCard.model';
import { useStyle } from './ChallengeRules.styles';

interface IChallengeRules {
  challenge: ICreatedChallenge;
}

export const ChallengeRules: FC<IChallengeRules> = ({ challenge }) => {
  const { t: translation } = useTranslation();
  const { classes } = useStyle();
  const { options, winInsensitivity } = challenge;
  const { term, quantity } = options[0];
  const termKey = OPTIONS_MAIN_TERM[term]?.label;
  const isWinInsensitive = winInsensitivity
    ? 'challenges-list.challenge-card.not'
    : '';
  const { Text } = Typography;
  const hero = useDota2Hero(challenge);
  const heroImageName = hero?.name.replace('npc_dota_hero_', '');
  return (
    <div className={classes.challengeRulesContainer}>
      <div className={classes.labelContainer}>
        <Card
          variant="p4x8"
          color="bgGhost"
          className={classes.label}
          borderRadius="br4"
          title={translation('challenges-list.challenge-card.reward-title')}
        >
          <TrophyGradeIcon className={classes.icon} />
          <Text variant="b6">{calcRewardLabel(challenge)} GC</Text>
        </Card>
        <Card
          variant="p4x8"
          className={classes.label}
          color="bgGhost"
          borderRadius="br4"
          title={translation('challenges-list.challenge-card.fee-title')}
        >
          <GradientEntryCostIcon
            className={classes.icon}
            width={24}
            height={24}
          />
          <Text variant="b6">
            {removeZeroesAfterComma(String(challenge?.entryCost))} GC
          </Text>
        </Card>
        {winInsensitivity ? (
          <Card
            variant="p4x8"
            className={classes.label}
            color="bgGhost"
            borderRadius="br4"
          >
            <LashHitIcon className={classes.icon} />
            <Text variant="b6">
              {`${translation(
                'challenges-list.challenge-card.win'
              )} ${translation(isWinInsensitive)} ${translation(
                'challenges-list.challenge-card.necessary'
              )}`}
            </Text>
          </Card>
        ) : (
          <Card
            variant="p4x8"
            className={classes.label}
            color="bgGhost"
            borderRadius="br4"
          >
            <GradientSwordsIcon className={classes.icon} />
            <Text variant="b6">
              {`${translation(
                'challenges-list.challenge-card.article'
              )} ${translation(termKey)} ${translation(
                'challenges-list.challenge-card.games'
              )}: ${quantity}`}
            </Text>
          </Card>
        )}
        {challenge?.hero && (
          <Card
            variant="p4x8"
            className={classes.label}
            color="bgGhost"
            borderRadius="br4"
          >
            <img
              src={getDota2HeroImage(heroImageName)}
              alt="dota2_hero"
              className={classes.heroImg}
            />
            <Text variant="b6">{hero.localized_name}</Text>
          </Card>
        )}
        {!challenge?.hero && (
          <Card
            variant="p4x8"
            className={classes.label}
            color="bgGhost"
            borderRadius="br4"
          >
            <ShieldIcon className={classes.icon} />
            <Text variant="b6">
              {translation('challenges-list.challengeCard.any')}
            </Text>
          </Card>
        )}
        <BuilderItemsView items={challenge?.items} size="small" />
      </div>
    </div>
  );
};
