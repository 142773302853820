import { makeStyles } from 'theme';

export const useStyle = makeStyles()((theme) => ({
  ...theme.layouts,
  spinner: {
    ...theme.layouts.flexCenter,
    width: '100%',
    marginTop: '70px',
  },
  winRateContent: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  winRateTitle: {
    marginBottom: '2.5rem',
  },
  winRateData: {
    ...theme.layouts.flexCenter,
    flexDirection: 'column',
    width: '60%',
    alignItems: 'center',
    paddingLeft: 12,
  },
  winAmount: {
    marginLeft: '0.25rem',
  },
  winTerm: {
    textTransform: 'uppercase',
    opacity: 0.3,
  },
  winRateDataTitle: {
    // textTransform: 'uppercase',
    opacity: 0.3,
    width: '100%',
  },
  winPercent: {
    marginBottom: '1.5rem',
    width: '100%',
    whiteSpace: 'nowrap',
  },
  rateBlock: {
    display: 'flex',
    width: '100%',
    '> div': {
      ':first-of-type': {
        width: '50%',
      },
    },
  },
  rateGreen: {
    width: 6,
    height: 6,
    borderRadius: '50%',
    backgroundColor: theme.palette.typo.success,
  },
  rateRed: {
    width: 6,
    height: 6,
    borderRadius: '50%',
    backgroundColor: theme.palette.typo.alert,
  },
  flexColumn: {
    flexDirection: 'column',
  },
  noRateBlock: {
    ...theme.layouts.flexCenter,
    flexDirection: 'column',
    opacity: '0.5',
    marginTop: '3rem',
  },
  noRateTitle: {
    marginBottom: '1.5rem',
  },
  noRateDescr: {
    textAlign: 'center',
  },
}));
