import { FC } from 'react';

import { ICommonIconProps } from './types';

export const TotalProfitIcon: FC<ICommonIconProps> = ({
  color = 'currentColor',
  width = 24,
  height = 24,
  viewBox = '0 0 24 24',
  className = '',
  style,
}) => (
  <svg
    fill="none"
    width={width}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.5 4.16667V14.7917C2.5 15.51 2.78534 16.1988 3.29325 16.7067C3.80116 17.2147 4.49004 17.5 5.20833 17.5H9.38454C9.30701 17.3938 9.23929 17.2789 9.18297 17.1565C8.8566 16.4469 8.97318 15.6122 9.48149 15.0192L11.2463 12.9602L9.48149 10.9012C8.97318 10.3082 8.8566 9.4735 9.18297 8.7639C9.50934 8.05429 10.2189 7.59961 11 7.59961H16.2809C16.8846 7.59961 17.4656 7.81973 17.9167 8.21553V7.70833C17.9167 7.09865 17.711 6.50679 17.3329 6.02847C16.9549 5.55015 16.4265 5.21334 15.8333 5.0725V4.375C15.8333 4.12877 15.7848 3.88495 15.6906 3.65747C15.5964 3.42998 15.4583 3.22328 15.2842 3.04917C15.11 2.87506 14.9033 2.73695 14.6759 2.64273C14.4484 2.5485 14.2046 2.5 13.9583 2.5H4.375C3.9138 2.50001 3.46879 2.67001 3.12504 2.97748C2.78128 3.28495 2.56291 3.70832 2.51167 4.16667H2.5ZM4.375 5C4.20924 5 4.05027 4.93415 3.93306 4.81694C3.81585 4.69973 3.75 4.54076 3.75 4.375C3.75 4.20924 3.81585 4.05027 3.93306 3.93306C4.05027 3.81585 4.20924 3.75 4.375 3.75H13.9583C14.1241 3.75 14.2831 3.81585 14.4003 3.93306C14.5175 4.05027 14.5833 4.20924 14.5833 4.375V5H4.375Z"
      fill="#8188A2"
    />
    <path
      d="M10.9993 8.87949C10.7181 8.87949 10.4626 9.0432 10.3451 9.2987C10.2276 9.5542 10.2696 9.85473 10.4526 10.0683L12.9314 12.9602L10.4526 15.8521C10.2696 16.0656 10.2276 16.3662 10.3451 16.6217C10.4626 16.8772 10.7181 17.0409 10.9993 17.0409H16.2803C16.5986 17.0409 16.9038 16.9144 17.1289 16.6894C17.354 16.4643 17.4805 16.159 17.4805 15.8407V14.8805C17.4805 14.4828 17.158 14.1604 16.7603 14.1604C16.3626 14.1604 16.0402 14.4828 16.0402 14.8805V15.6006H12.5651L14.4266 13.4288C14.6578 13.1592 14.6578 12.7612 14.4266 12.4915L12.5651 10.3197H16.0402V11.0399C16.0402 11.4376 16.3626 11.76 16.7603 11.76C17.158 11.76 17.4805 11.4376 17.4805 11.0399V10.0797C17.4805 9.76138 17.354 9.4561 17.1289 9.23102C16.9038 9.00594 16.5986 8.87949 16.2803 8.87949H10.9993Z"
      fill={color}
    />
  </svg>
);
