import { FC } from 'react';

import { ICommonIconProps } from './types';

type challengeRewardType = 'gold' | 'silver' | 'bronze';

export const ChallengeRewardIcon: FC<
  ICommonIconProps & { type?: challengeRewardType }
> = ({
  color = 'currentColor',
  width = 32,
  height = 32,
  viewBox = '0 0 32 32',
  className = '',
  style,
  type = 'gold',
}) => (
  <svg
    fill="none"
    width={width}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    {type === 'gold' && (
      <>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.17934 3.50578C7.17934 3.31086 7.25677 3.12391 7.39461 2.98608C7.53244 2.84825 7.71938 2.77081 7.91431 2.77081H24.0836C24.2785 2.77081 24.4655 2.84825 24.6033 2.98608C24.7411 3.12391 24.8186 3.31086 24.8186 3.50578V4.24075H28.4934C28.6883 4.24075 28.8753 4.31818 29.0131 4.45602C29.1509 4.59385 29.2284 4.78079 29.2284 4.97572V9.38553C29.2284 10.3602 28.8412 11.2949 28.152 11.984C27.4629 12.6732 26.5282 13.0604 25.5535 13.0604H24.3166C23.184 16.2648 20.2522 18.6197 16.7339 18.91V23.3499H21.8787C22.0736 23.3499 22.2606 23.4274 22.3984 23.5652C22.5362 23.703 22.6137 23.89 22.6137 24.0849V28.4947C22.6137 28.6896 22.5362 28.8766 22.3984 29.0144C22.2606 29.1522 22.0736 29.2297 21.8787 29.2297H10.1192C9.92429 29.2297 9.73734 29.1522 9.59951 29.0144C9.46168 28.8766 9.38424 28.6896 9.38424 28.4947V24.0849C9.38424 23.89 9.46168 23.703 9.59951 23.5652C9.73734 23.4274 9.92429 23.3499 10.1192 23.3499H15.264V18.91C11.7464 18.6197 8.81391 16.2648 7.68132 13.0604H6.44437C5.46974 13.0604 4.53503 12.6732 3.84587 11.984C3.1567 11.2949 2.76953 10.3602 2.76953 9.38553V4.97572C2.76953 4.78079 2.84697 4.59385 2.9848 4.45602C3.12263 4.31818 3.30957 4.24075 3.5045 4.24075H7.17934V3.50578ZM24.8186 5.71069V11.5904H25.5535C26.1383 11.5904 26.6991 11.3581 27.1126 10.9446C27.5261 10.5311 27.7584 9.9703 27.7584 9.38553V5.71069H24.8186ZM4.23947 5.71069H7.17934V11.5904H6.44437C5.85959 11.5904 5.29877 11.3581 4.88527 10.9446C4.47177 10.5311 4.23947 9.9703 4.23947 9.38553V5.71069Z"
          fill="url(#paint0_linear_5661_83503)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_5661_83503"
            x1="2.76953"
            y1="4.09376"
            x2="9.06832"
            y2="34.0248"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#DEBB6C" />
            <stop offset="1" stopColor="#E3CB8E" />
          </linearGradient>
        </defs>
      </>
    )}
    {type === 'silver' && (
      <>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.17934 3.50578C7.17934 3.31086 7.25677 3.12391 7.39461 2.98608C7.53244 2.84825 7.71938 2.77081 7.91431 2.77081H24.0836C24.2785 2.77081 24.4655 2.84825 24.6033 2.98608C24.7411 3.12391 24.8186 3.31086 24.8186 3.50578V4.24075H28.4934C28.6883 4.24075 28.8753 4.31818 29.0131 4.45602C29.1509 4.59385 29.2284 4.78079 29.2284 4.97572V9.38553C29.2284 10.3602 28.8412 11.2949 28.152 11.984C27.4629 12.6732 26.5282 13.0604 25.5535 13.0604H24.3166C23.184 16.2648 20.2522 18.6197 16.7339 18.91V23.3499H21.8787C22.0736 23.3499 22.2606 23.4274 22.3984 23.5652C22.5362 23.703 22.6137 23.89 22.6137 24.0849V28.4947C22.6137 28.6896 22.5362 28.8766 22.3984 29.0144C22.2606 29.1522 22.0736 29.2297 21.8787 29.2297H10.1192C9.92429 29.2297 9.73734 29.1522 9.59951 29.0144C9.46168 28.8766 9.38424 28.6896 9.38424 28.4947V24.0849C9.38424 23.89 9.46168 23.703 9.59951 23.5652C9.73734 23.4274 9.92429 23.3499 10.1192 23.3499H15.264V18.91C11.7464 18.6197 8.81391 16.2648 7.68132 13.0604H6.44437C5.46974 13.0604 4.53503 12.6732 3.84587 11.984C3.1567 11.2949 2.76953 10.3602 2.76953 9.38553V4.97572C2.76953 4.78079 2.84697 4.59385 2.9848 4.45602C3.12263 4.31818 3.30957 4.24075 3.5045 4.24075H7.17934V3.50578ZM24.8186 5.71069V11.5904H25.5535C26.1383 11.5904 26.6991 11.3581 27.1126 10.9446C27.5261 10.5311 27.7584 9.9703 27.7584 9.38553V5.71069H24.8186ZM4.23947 5.71069H7.17934V11.5904H6.44437C5.85959 11.5904 5.29877 11.3581 4.88527 10.9446C4.47177 10.5311 4.23947 9.9703 4.23947 9.38553V5.71069Z"
          fill="url(#paint0_linear_5661_83517)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_5661_83517"
            x1="2.76953"
            y1="4.09376"
            x2="9.06832"
            y2="34.0248"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#C7C8C8" />
            <stop offset="1" stopColor="#DDDEDE" />
          </linearGradient>
        </defs>
      </>
    )}
    {type === 'bronze' && (
      <>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.17934 3.50578C7.17934 3.31086 7.25677 3.12391 7.39461 2.98608C7.53244 2.84825 7.71938 2.77081 7.91431 2.77081H24.0836C24.2785 2.77081 24.4655 2.84825 24.6033 2.98608C24.7411 3.12391 24.8186 3.31086 24.8186 3.50578V4.24075H28.4934C28.6883 4.24075 28.8753 4.31818 29.0131 4.45602C29.1509 4.59385 29.2284 4.78079 29.2284 4.97572V9.38553C29.2284 10.3602 28.8412 11.2949 28.152 11.984C27.4629 12.6732 26.5282 13.0604 25.5535 13.0604H24.3166C23.184 16.2648 20.2522 18.6197 16.7339 18.91V23.3499H21.8787C22.0736 23.3499 22.2606 23.4274 22.3984 23.5652C22.5362 23.703 22.6137 23.89 22.6137 24.0849V28.4947C22.6137 28.6896 22.5362 28.8766 22.3984 29.0144C22.2606 29.1522 22.0736 29.2297 21.8787 29.2297H10.1192C9.92429 29.2297 9.73734 29.1522 9.59951 29.0144C9.46168 28.8766 9.38424 28.6896 9.38424 28.4947V24.0849C9.38424 23.89 9.46168 23.703 9.59951 23.5652C9.73734 23.4274 9.92429 23.3499 10.1192 23.3499H15.264V18.91C11.7464 18.6197 8.81391 16.2648 7.68132 13.0604H6.44437C5.46974 13.0604 4.53503 12.6732 3.84587 11.984C3.1567 11.2949 2.76953 10.3602 2.76953 9.38553V4.97572C2.76953 4.78079 2.84697 4.59385 2.9848 4.45602C3.12263 4.31818 3.30957 4.24075 3.5045 4.24075H7.17934V3.50578ZM24.8186 5.71069V11.5904H25.5535C26.1383 11.5904 26.6991 11.3581 27.1126 10.9446C27.5261 10.5311 27.7584 9.9703 27.7584 9.38553V5.71069H24.8186ZM4.23947 5.71069H7.17934V11.5904H6.44437C5.85959 11.5904 5.29877 11.3581 4.88527 10.9446C4.47177 10.5311 4.23947 9.9703 4.23947 9.38553V5.71069Z"
          fill="url(#paint0_linear_5661_83531)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_5661_83531"
            x1="2.76953"
            y1="4.09376"
            x2="47.6574"
            y2="-104.852"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#AE7C3C" />
            <stop offset="1" stopColor="#BB9768" />
          </linearGradient>
        </defs>
      </>
    )}
  </svg>
);
