import { FC } from 'react';

import { ICommonIconProps } from './types';

export const LikeIcon: FC<ICommonIconProps> = ({
  color = 'currentColor',
  width = 32,
  height = 32,
  viewBox = '0 0 32 32',
  className = '',
  style,
}) => (
  <svg
    fill="none"
    width={width}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M0 4.2241H2.57812V11.0286H0V4.2241ZM10.7656 7.06814C10.7656 6.82268 10.6842 6.59591 10.547 6.41334C10.8211 6.21472 11 5.89221 11 5.52859C11 4.92645 10.5101 4.43656 9.90795 4.43656H7.42554L7.77101 3.24326V2.67149C7.77101 1.61792 6.91384 0.760757 5.86027 0.760757C5.6048 0.760757 5.39698 0.968597 5.39698 1.22405V2.49162C5.2247 2.76735 4.26796 3.84093 3.22266 4.96572V10.6052L4.11464 10.9396C4.64291 11.1381 5.20267 11.2396 5.76699 11.2393H9.2049C9.80704 11.2393 10.2969 10.7494 10.2969 10.1472C10.2972 9.91107 10.2205 9.68127 10.0784 9.49261C10.3526 9.29401 10.5313 8.97129 10.5313 8.60767C10.5313 8.36221 10.4498 8.13544 10.3126 7.95287C10.5868 7.75431 10.7656 7.43181 10.7656 7.06814Z"
      fill="white"
    />
  </svg>
);
