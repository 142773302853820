import { makeStyles } from 'theme';

export const useStyle = makeStyles()(() => ({
  headerContainer: {
    width: 870,
    display: 'flex',
    gap: 20,
    justifyContent: 'flex-end',
  },
  balanceContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
    marginLeft: 0,
    marginRight: 'auto',
  },
}));
