import { DateTime, Duration, DurationObjectUnits } from 'luxon';

import { EChallengeStatus, EDistribution, EParryStatus } from 'consts';

import { ICreatedChallenge, ICreatedParry, IUserResponse } from '../api';

export const convertObjValuesToString = <T>(
  obj: T | Record<string, unknown>
): Record<string, string> => {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (value === '' || value === null || value === undefined) {
      return { ...acc };
    }
    if (typeof value !== 'string') {
      return { ...acc, [key]: value.toString() };
    }
    return { ...acc, [key]: value };
  }, {});
};

export const correctAbsoluteUrl = (url: string): string =>
  url[0] === '/' ? url : `/${url}`;

export enum EWinnerPlace {
  FIRST,
  SECOND,
  THIRD,
}

const threeParticipantsMultiplierMap = [0.5, 0.3, 0.2];

export const calcReward = (
  distribution: number,
  reward: number,
  place: EWinnerPlace,
  winnerCount?: number
): string => {
  if (distribution === EDistribution.ThreeParticipants) {
    return (+reward * threeParticipantsMultiplierMap[place]).toFixed(2);
  }
  if (distribution === EDistribution.FirstToWin) {
    return (+reward)?.toFixed(2);
  }
  return (reward / winnerCount).toFixed(2);
};

export const removeZeroesAfterComma = (number: string): string => {
  if ((+number * 100) % 100 === 0) {
    return (+number).toFixed(0);
  }
  return number;
};

interface ICalcChallengeDuration {
  isFinished: boolean;
  isFinishingSoon: boolean;
  formattedString: string;
  challengeDuration: DurationObjectUnits;
}

export const calcChallengeDuration = (
  challenge: ICreatedChallenge
): ICalcChallengeDuration => {
  if (!challenge) {
    return {
      isFinished: false,
      isFinishingSoon: false,
      formattedString: '',
      challengeDuration: { hours: 0, minutes: 0, days: 0 },
    };
  }
  const challengeDuration = getChallengeLeftTime(challenge);
  const { minutes, hours, days } = challengeDuration;
  const { status } = challenge;
  const formattedString = getFormattedString(challengeDuration);
  const isFinished =
    (minutes <= 0 && hours <= 0 && days <= 0) ||
    status === EChallengeStatus.Done;
  const isFinishingSoon =
    status === EChallengeStatus.Active &&
    !days &&
    +hours <= 1 &&
    +minutes?.toFixed(0) <= 59;
  return {
    isFinished,
    isFinishingSoon,
    formattedString,
    challengeDuration,
  };
};

const getFormattedString = (challengeDuration: DurationObjectUnits): string => {
  const { days, hours, minutes } = challengeDuration;
  const hasRound = minutes > 59.5;
  return `${days > 0 ? `${days}d` : ''}  ${
    hours > 0 ? `${hasRound ? hours + 1 : hours}h` : ''
  } ${hasRound ? 0 : minutes?.toFixed(0)} m`;
};

const getChallengeLeftTime = (
  challenge: ICreatedChallenge
): DurationObjectUnits =>
  challenge?.createdAt
    ? DateTime.fromISO(challenge?.createdAt)
        .plus(Duration.fromMillis(challenge?.duration))
        .diff(DateTime.now(), ['day', 'hour', 'minute'])
        .toObject()
    : DateTime.now()
        .plus(Duration.fromMillis(challenge?.duration))
        .diff(DateTime.now(), ['day', 'hour', 'minute'])
        .toObject();

export const hasPermissions = (
  user: IUserResponse,
  permission: string
): boolean => {
  if (!user) {
    return false;
  }
  const hasPermissions = !!user.permissions.find((elem) => elem === permission);
  return hasPermissions;
};
