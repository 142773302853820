import { FC, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import { removeZeroesAfterComma } from 'helpers';
import { useClickOutside } from 'hooks';
import { Button, InputNumber, Typography } from 'ui-kit';

import { makeDeposit } from '../../../../api';
import { Modal } from '../Modal';
import { useStyle } from './ManualDeposit.styles';

interface IManualDepositProps {
  isOpen: boolean;
  onClose: () => void;
}

export const ManualDeposit: FC<IManualDepositProps> = ({ isOpen, onClose }) => {
  const ref = useRef(null);
  useClickOutside(ref, onClose);
  const { classes } = useStyle();
  const [amountGc, setAmountGc] = useState<number>(null);
  const [error, setError] = useState<string>('');

  const { Headline, Text } = Typography;
  const { data: transactions } = useSWR('api/transactions');
  const { t: translation } = useTranslation();
  const onDepositClick = async () => {
    const response = await makeDeposit(amountGc);
    if (response) {
      window.location.href = response;
    }
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div ref={ref} className={classes.modalContainer}>
        <Headline variant="h3" block>
          {translation('profile.depositModal.title')}
        </Headline>
        <Text block variant="b5" className={classes.description}>
          {translation('profile.depositModal.description')}{' '}
          <a>
            <Text variant="b5" color="link">
              Discord
            </Text>
          </a>
        </Text>
        {transactions?.count === 0 && (
          <div className={classes.promoBanner}>
            <Headline variant="h2" block>
              + 10%
            </Headline>
            <Text block variant="b4">
              {translation('profile.depositModal.promo')}
            </Text>
          </div>
        )}
        <div className={classes.inputContainer}>
          <InputNumber
            title="GC"
            placeholder="2000 GC"
            suffix=" GC"
            className={classes.input}
            value={amountGc}
            decimalScale={2}
            onValueChange={({ value }) => {
              if (+value < 2000) {
                setError(translation('profile.depositModal.error'));
              } else {
                setError('');
              }
              setAmountGc(+value);
            }}
            error={error}
          />
          <Text variant="b5" block>
            =
          </Text>
          <InputNumber
            title="USD"
            placeholder="10 USD"
            suffix=" USD"
            decimalScale={2}
            className={classes.input}
            value={amountGc / 1000}
            onValueChange={({ value }) => {
              if (+value < 2) {
                setError('Minimum 2000 GC');
              } else {
                setError('');
              }
              setAmountGc(+value * 1000);
            }}
          />
        </div>
        <div className={classes.bottomContainer}>
          <div className={classes.taxContainer}>
            {transactions?.count === 0 && (
              <div className={classes.taxDataContainer}>
                <Text variant="b6" block color="typoLabel">
                  {translation('profile.depositModal.bonus')}
                </Text>
                <Text block variant="b5">
                  {`+ ${removeZeroesAfterComma(
                    (amountGc * 0.1).toFixed(2)
                  )} GC`}
                </Text>
              </div>
            )}
            <div className={classes.taxDataContainer}>
              <Text variant="b6" block color="typoLabel">
                {translation('profile.depositModal.fee')}
              </Text>
              <Text block variant="b5">
                {`+ $${removeZeroesAfterComma(
                  (amountGc * 0.000025).toFixed(2)
                )}`}
              </Text>
            </div>
            <div className={classes.taxDataContainer}>
              <Text variant="b6" block color="typoLabel">
                {translation('profile.depositModal.tax')}
              </Text>
              <Text block variant="b5">
                {`+ $${removeZeroesAfterComma(
                  (amountGc * 0.00003).toFixed(2)
                )}`}
              </Text>
            </div>
          </div>
          <div className={classes.buttonContainer}>
            <Button
              className={classes.buyBtn}
              disabled={!!error}
              onClick={onDepositClick}
            >{`${translation('profile.depositModal.btn')} $${(
              Math.floor((amountGc / 10) * 1.055) / 100
            ).toFixed(2)}`}</Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
