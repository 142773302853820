import { FC } from 'react';

import { useStyle } from './SkeletonLoader.styles';

interface ISkeletonLoaderProps {
  className?: string;
  children?: React.ReactNode;
}

export const SkeletonLoader: FC<ISkeletonLoaderProps> = ({
  className = '',
  children,
}) => {
  const { classes, cx } = useStyle();
  return (
    <div className={cx(classes.loaderContainer, className)}>
      {children}
      <div className={classes.loader}></div>
    </div>
  );
};
