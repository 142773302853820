import { makeStyles } from 'theme';

export const useStyle = makeStyles()((theme) => ({
  disabled: {
    opacity: 0.4,
  },
  container: {
    display: 'flex',
    height: 44,
  },
  minusContainer: {
    backgroundColor: theme.palette.control.default.bg,
    borderTopLeftRadius: '0.375rem',
    borderBottomLeftRadius: '0.375rem',
    paddingLeft: '2rem',
  },
  plusContainer: {
    backgroundColor: theme.palette.control.default.bg,
    borderTopRightRadius: '0.375rem',
    borderBottomRightRadius: '0.375rem',
    paddingRight: '2rem',
  },
  disableButton: {
    color: theme.palette.typo.system,
    opacity: 0.3,
  },
  input: {
    outline: 'none',
    textAlign: 'center',
    flexGrow: 1,
    backgroundColor: theme.palette.control.default.bg,
    color: theme.palette.typo.primary,
    padding: '0 1rem',
  },
  title: {
    display: 'block',
    marginBottom: 8,
    width: '100%',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
  },
}));
