import { FC } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button, Typography } from 'ui-kit';

import { useStyle } from './Header.styles';

export const Header: FC = () => {
  const { t: translation } = useTranslation();
  const { classes } = useStyle();
  const navigate = useNavigate();
  const onChallengesClick: () => void = () => {
    navigate('/challenges');
  };
  const { Headline, Text } = Typography;

  return (
    <>
      <div className={classes.header}>
        <Headline className={classes.challengeTitle} variant="h0" color="brand">
          {translation('challenges-about.challenges.title')}
        </Headline>
        <div className={classes.headerContainer}>
          <div className={classes.headerItem}>
            <Headline className={classes.title}>
              {translation('challenges-about.challenges.what-1.title')}
            </Headline>
            <Text variant="b2" className={classes.description}>
              {translation('challenges-about.challenges.what-1.description')}
            </Text>
          </div>
          <div className={classes.headerItem}>
            <Headline className={classes.title}>
              {translation('challenges-about.challenges.what-2.title')}
            </Headline>
            <Text variant="b2" className={classes.description}>
              {translation('challenges-about.challenges.what-2.description')}
            </Text>
          </div>
          <div className={classes.headerItem}>
            <Headline className={classes.title}>
              {translation('challenges-about.challenges.what-3.title')}
            </Headline>
            <Text variant="b2" className={classes.description}>
              {translation('challenges-about.challenges.what-3.description')}
            </Text>
          </div>
        </div>
        <Button
          onClick={onChallengesClick}
          className={classes.button}
          buttonSize="l"
        >
          {translation('challenges-about.challenges.btnText')}
        </Button>
      </div>
      <img
        className={classes.backgroundPic}
        src="/images/challenges/challenge-header.png"
        alt="Header background"
      />
    </>
  );
};
