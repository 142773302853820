import { makeStyles } from 'theme';

export const useStyle = makeStyles()((theme) => ({
  table: {
    width: 920,
    backgroundColor: theme.palette.control.default.bg,
    borderRadius: 8,
    marginBottom: 100,
    padding: 32,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    gap: 32,
  },
  categoryContainer: {
    width: 264,
    flexWrap: 'wrap',
    display: 'flex',
    gap: 8,
  },
  item: {
    cursor: 'pointer',
    width: 60,
    height: 40,
    borderRadius: 4,
    border: '1px solid transparent',
    transitionDuration: '300ms',
    ':hover': {
      border: `1px solid ${theme.palette.typo.link}`,
      transitionDuration: '300ms',
    },
    ':not:last-of-type': {
      marginRight: 0,
    },
  },
  categoryTitle: {
    ...theme.fontStyles.text17,
    color: theme.palette.typo.primary,
    fontWeight: 500,
    width: '100%',
    marginBottom: 16,
  },
}));
