import { FC } from 'react';

import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import { IPredictResponse, EPredictStatus } from 'api';
import { Typography, Button } from 'ui-kit';

import { KDA } from '../../Prediction.model';
import { useStyle } from './PredictCard.styles';
interface IPredictionResult {
  onClick: () => void;
}

export const PredictionResult: FC<IPredictionResult> = ({ onClick }) => {
  const { data: activePredict } = useSWR<IPredictResponse>('api/predict');

  const isWin = activePredict.status === EPredictStatus.WIN;
  const { t: translation } = useTranslation();
  const { classes } = useStyle();

  const { Text, Headline } = Typography;
  return (
    <>
      <div className={classes.predictResult}>
        <video
          src={activePredict.animation}
          autoPlay
          muted
          loop
          className={classes.predictAnimation}
        />
        <div>
          <div className={classes.kda}>
            {KDA({
              kill: activePredict.stats?.kills ?? 0,
              death: activePredict.stats?.deaths ?? 0,
              assist: activePredict.stats?.assists ?? 0,
            }).map((stat, index) => (
              <div className={classes.kdaItem} key={index}>
                <Text>{stat.icon}</Text>
                <Text variant="b3">{stat.value}</Text>
              </div>
            ))}
          </div>
          <div className={classes.result}>
            <Text variant="b7" className={classes.resultDescr}>
              {isWin
                ? translation('predictions.result.win')
                : translation('predictions.result.lose')}
            </Text>
            <Headline variant="h2" color={isWin ? 'yellow' : 'red'}>
              {isWin
                ? activePredict.value.toFixed(2)
                : `- ${activePredict.value.toFixed(2)}`}{' '}
              GC
            </Headline>
          </div>
        </div>
      </div>
      <Button onClick={onClick} className={classes.makeNextBtn}>
        {translation('predictions.result.next')}
      </Button>
    </>
  );
};
