import { makeStyles } from 'theme';

export const useStyle = makeStyles()((theme) => ({
  loaderContainer: {
    width: '300px',
    height: '100px',
    position: 'relative',
    backgroundColor: theme.palette.bg.ghost,
    overflow: 'hidden',
  },
  loader: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    left: 0,
    top: 0,
    '@keyframes shimmer': {
      '100%': {
        transform: 'translateX(100%)',
      },
    },
    transform: 'translateX(-100%)',
    backgroundImage:
      'linear-gradient(90deg,rgba(236, 246, 255, 0) 0%, rgba(236, 246, 255, 0.1) 20%, rgba(236, 246, 255, 0.1) 60%, rgba(236, 246, 255, 0))',
    animation: 'shimmer 2s infinite',
    content: "''",
  },
}));
