import { makeStyles } from 'theme';

export const useStyle = makeStyles()((theme) => ({
  challengeRulesContainer: {
    display: 'flex',
  },
  labelContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 8,
    width: 220,
    height: 'fit-content',
  },
  label: {
    width: 'fit-content',
  },
  icon: {
    marginRight: 8,
    width: 16,
    height: 16,
  },
  heroImg: {
    marginRight: 9,
    width: 32,
    height: 16,
  },
}));
