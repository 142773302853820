import { makeStyles } from 'theme';

export const useStyle = makeStyles()((theme) => ({
  ...theme.fontStyles,
  ...theme.layouts,

  hero: {
    display: 'flex',
    position: 'relative',
  },

  heroContent: {
    height: '268px',
    width: '100%',
    minWidth: '770px',
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flexEnd',
  },

  heroTitle: {
    marginBottom: '24px',
  },

  heroText: {
    width: '320px',
    marginBottom: '32px',
  },

  heroBtn: {
    marginRight: '40px',
  },

  heroImage: {
    position: 'absolute',
    right: '0',
    top: '0',
  },
  heroLinkContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  cryptoLink: {
    display: 'flex',
    alignItems: 'center',
    background: theme.palette.control.brand.bgBorder,
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
  },
  cryptoIcon: {
    marginLeft: 8,
  },
}));
