import { FC } from 'react';

import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { Typography } from 'ui-kit';

import {
  CATEGORIES_CHALLENGE,
  EChallengeViewMode,
} from '../../../Challenges.model';
import { useStyle } from './ChallengeNavigation.styles';

interface IChallengeNavigation {
  changeCategory: (category: EChallengeViewMode) => void;
  activeMode: EChallengeViewMode;
}

export const ChallengeNavigation: FC<IChallengeNavigation> = ({
  changeCategory,
  activeMode,
}) => {
  const { t: translation } = useTranslation();
  const { classes } = useStyle();
  const { Text } = Typography;
  return (
    <div>
      {CATEGORIES_CHALLENGE.map(({ title, mode }) => (
        <button
          key={title}
          onClick={() => {
            changeCategory(mode);
          }}
          className={clsx(
            classes.button,
            mode === activeMode && classes.activeButton
          )}
        >
          <Text color={mode === activeMode ? 'primary' : 'secondary'}>
            {translation(title)}
          </Text>
        </button>
      ))}
    </div>
  );
};
