import { useRef, useState } from 'react';

import TagManager from 'react-gtm-module';
import { TFunction, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { mutate } from 'swr';

import {
  deactivateParticipationInParry,
  isErrorResponse,
  participateInParry,
  disableParry,
  getParry,
  IErrorResponse,
  IParry,
} from 'api';
import { EParryModals } from 'consts';
import { useClickOutside } from 'hooks';
import { Button, Input, Typography } from 'ui-kit';

import { Modal } from '../Modal';
import { useStyle } from './ModalParryConfirmation.styles';

interface IProps {
  id: number;
  parryWinning: boolean;
  confirmationValue?: number;
  modalVisibleName: EParryModals;
  setIsModalProcessing: (isModalProcessing: boolean) => void;
  isModalProcessing: boolean;
  onClose: () => void;
}

interface IModalBlock {
  title: string;
  text: (value?: number) => string | JSX.Element;
  action: (
    id: number,
    win: boolean,
    password?: string
  ) => Promise<void | IErrorResponse | Response | IParry>;
  redirectUrl?: string;
  mutateKey?: () => string;
}

const MODALS_CONTENT_TRANSLATIONS = (
  translation: TFunction<'translation', undefined>
): Record<EParryModals, IModalBlock> => {
  return {
    [EParryModals.EntryCost]: {
      title: translation('parryView.modals.title'),
      text: (value: number) => (
        <div>
          {translation('parryView.modals.acceptDesc')}{' '}
          <span className="text-yellow">{value} GC</span>
          <br />
          {translation('parryView.modals.acceptDesc2')}
        </div>
      ),
      action: (id: number, win: boolean) => {
        TagManager.dataLayer({
          dataLayer: { event: 'parry_accepted', payable: 'yes' },
        });
        return participateInParry(id, win);
      },
      mutateKey: () => '/parries/parryList',
    },
    [EParryModals.Exit]: {
      title: translation('parryView.actions.exitParry'),
      text: () => translation('parryView.modals.exitDesc'),
      action: (id: number) => {
        TagManager.dataLayer({
          dataLayer: { event: 'parry_exit' },
        });
        return deactivateParticipationInParry(id);
      },
    },
    [EParryModals.Password]: {
      title: translation('parryView.modals.password'),
      text: (value: number) => (
        <div>
          {translation('parryView.modals.passwordDesc')}{' '}
          <span className="text-yellow">{value} GC</span>
        </div>
      ),
      action: (id: number, win: boolean, password: string) => {
        TagManager.dataLayer({
          dataLayer: { event: 'parry_accepted', payable: 'yes' },
        });
        return participateInParry(id, win, password);
      },
    },
    [EParryModals.Stop]: {
      title: translation('parryView.actions.stopRegistration'),
      text: () => translation('parryView.modals.stopRegistrationDesc'),
      action: (id: number) => disableParry(id),
      mutateKey: () => '/parries/parryList',
    },
  };
};

export function ModalParryConfirmation({
  id,
  confirmationValue,
  modalVisibleName,
  setIsModalProcessing,
  isModalProcessing,
  onClose,
  parryWinning,
}: IProps): JSX.Element {
  const { t: translation } = useTranslation();
  const navigate = useNavigate();

  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const { classes } = useStyle();
  const ref = useRef(null);

  useClickOutside(ref, onClose);

  const MODALS_CONTENT = MODALS_CONTENT_TRANSLATIONS(translation);

  const parryAction = async (): Promise<void> => {
    try {
      setIsModalProcessing(true);
      const parry = await MODALS_CONTENT[modalVisibleName].action(
        id,
        parryWinning,
        password
      );

      if (!isErrorResponse(parry)) {
        mutate(`api/parry/${id}`, getParry(id));
        mutate(`api/balance`);
        onClose();
        MODALS_CONTENT[modalVisibleName]?.mutateKey &&
          mutate(MODALS_CONTENT[modalVisibleName].mutateKey());
        MODALS_CONTENT[modalVisibleName]?.redirectUrl &&
          navigate(MODALS_CONTENT[modalVisibleName].redirectUrl);
      } else {
        setError(parry.message);
      }
    } catch (err) {
      console.error('Failed parry action', err);
    } finally {
      setIsModalProcessing(false);
    }
  };

  const { Headline, Text } = Typography;

  return (
    <Modal isOpen={!!modalVisibleName} onClose={onClose}>
      <div className={classes.modal} ref={ref}>
        <Headline variant="h3" className={classes.modalTitle}>
          {MODALS_CONTENT[modalVisibleName]?.title}
        </Headline>
        <Text variant="b3" className={classes.modalContainer}>
          {MODALS_CONTENT[modalVisibleName]?.text(confirmationValue)}
        </Text>
        {modalVisibleName === EParryModals.Password && (
          <div className={classes.modalContent}>
            <Input
              className={classes.modalInput}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              type="password"
              value={password}
              error={error}
            />
          </div>
        )}
        <div className={classes.buttonContainer}>
          <Button color="secondary" onClick={onClose}>
            {translation('parryView.modals.cancel')}
          </Button>

          <Button
            className={classes.btn}
            onClick={parryAction}
            disabled={isModalProcessing}
          >
            {isModalProcessing
              ? translation('parryView.modals.submitting')
              : translation('parryView.modals.submit')}
          </Button>
        </div>
      </div>
    </Modal>
  );
}
