import { makeStyles } from 'theme';

export const useStyle = makeStyles()(() => ({
  button: {
    marginRight: 16,
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
}));
