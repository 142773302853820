import { FC } from 'react';

import { SkeletonLoader } from 'ui-kit';

import { useStyle } from './ParryCardSkeleton.styles';

export const ParryCardSkeleton: FC = () => {
  const { classes } = useStyle();
  return (
    <SkeletonLoader className={classes.card}>
      <div className={classes.nameFirst} />
      <div className={classes.nameSecond} />
      <div className={classes.rulesContainer}>
        <div className={classes.ruleContainer}>
          <div className={classes.ruleTitleFirst} />
          <div className={classes.ruleDescFirst} />
        </div>
        <div className={classes.verticalDivider} />
        <div className={classes.ruleContainer}>
          <div className={classes.ruleTitleFirst} />
          <div className={classes.ruleDescFirst} />
        </div>
        <div className={classes.verticalDivider} />
        <div className={classes.ruleContainer}>
          <div className={classes.ruleTitleSecond} />
          <div className={classes.ruleDescSecond} />
        </div>
        <div className={classes.verticalDivider} />
        <div className={classes.ruleContainer}>
          <div className={classes.ruleTitleSecond} />
          <div className={classes.ruleDescThird} />
        </div>
      </div>
      <div className={classes.conditionFirst} />
      <div className={classes.conditionSecond} />
      <div className={classes.conditionThird} />
      <div className={classes.conditionFourth} />
      <div className={classes.itemContainer}>
        <div className={classes.item} />
        <div className={classes.item} />
        <div className={classes.item} />
        <div className={classes.item} />
        <div className={classes.item} />
        <div className={classes.item} />
      </div>
    </SkeletonLoader>
  );
};
