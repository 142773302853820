import { FC } from 'react';

import { dota2ItemsMap, EItemState } from 'consts';
import { LockIcon } from 'icons';

import { useStyle } from './BuilderItemView.styles';

type ItemType = {
  item: number;
  place: number;
  type: EItemState;
};

export type BuilderItemSizeType = 'small' | 'medium';

interface IBuilderItemsViewProps {
  size?: BuilderItemSizeType;
  items: ItemType[];
}

export const BuilderItemsView: FC<IBuilderItemsViewProps> = ({
  items,
  size = 'medium',
}) => {
  const { classes } = useStyle({ size });

  const itemsWithPic = items.map((item) => ({
    ...item,
    ...dota2ItemsMap[item.item],
    url: `https://media.steampowered.com${dota2ItemsMap[item.item]?.img}`,
  }));
  return (
    <div className={classes.itemContainer}>
      {itemsWithPic?.map((item) => (
        <div className={classes.item} key={item.place}>
          {item.type === EItemState.Locked && (
            <LockIcon className={classes.lockIcon} />
          )}
          {item.type === EItemState.Filled && (
            <img
              src={item.url}
              alt="selected item"
              title={item.dname}
              className={classes.img}
            />
          )}
        </div>
      ))}
    </div>
  );
};
