import { FC } from 'react';

import { generatePath, useNavigate } from 'react-router-dom';
import useSWR from 'swr';

import { IUserResponse } from 'api';
import { RoadHowTo } from 'components';

import { HowToConfig } from './About.model';
import { AboutFooter, Header } from './components';

export const About: FC = () => {
  const { error } = useSWR<IUserResponse>('api/user');
  const isLogin = !error;
  const navigate = useNavigate();

  const onMakePredictionClick: () => void = () =>
    navigate(generatePath('/predictions'));
  return (
    <div>
      <Header onMakePredictionClick={onMakePredictionClick} />
      <RoadHowTo text={HowToConfig} />
      <AboutFooter
        isLogin={isLogin}
        onMakePredictionClick={onMakePredictionClick}
      />
    </div>
  );
};
