import { BACKEND_URL } from './env';
import { IErrorResponse } from './interface';

export interface IPredictResponse {
  createdAt?: string;
  updatedAt?: string;
  value?: number;
  status: EPredictStatus;
  stats?: { kills: number; deaths: number; assists: number };
  animation?: string;
}

export enum EPredictStatus {
  WIN = 'win',
  LOSE = 'lose',
  PROGRESS = 'progress',
  NONE = 'none',
}

export function makePredict(
  predictValue: number
): Promise<IPredictResponse | IErrorResponse> {
  return fetch(`${BACKEND_URL}/api/prediction-service/predictions`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify({ value: predictValue }),
  }).then((data) => data.json());
}

export function rejectPredict(): Promise<Response> {
  return fetch(`${BACKEND_URL}/api/prediction-service/predictions`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  });
}

export function deactivatePredict(): Promise<Response> {
  return fetch(`${BACKEND_URL}/api/prediction-service/predictions`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  });
}

export function getActivePrediction(): Promise<IPredictResponse> {
  return fetch(`${BACKEND_URL}/api/prediction-service/predictions/active`, {
    method: 'GET',

    credentials: 'include',
  }).then((data) => data.json());
}

export interface ILeaderBoardItem {
  earned: number;
  count: string;
  userId: number;
  username: string;
  avatarUrl: string;
}

export function getLeaderBoard(): Promise<ILeaderBoardItem[]> {
  return fetch(
    `${BACKEND_URL}/api/prediction-service/predictions/leaderboard`
  ).then((data) => data.json());
}

interface IPredictCount {
  count: number;
}

export function getPredictCount(): Promise<IPredictCount> {
  return fetch(`${BACKEND_URL}/api/prediction-service/predictions/count`, {
    credentials: 'include',
  }).then((data) => data.json());
}
