import { About } from './About';
import { CREATE_PARRY } from './CreateParry';
import { EParryViewMode } from './Parries.model';
import { ParryList } from './ParryList';
import { PARRY_VIEW } from './ParryView';
import { Workshop } from './Workshop';

export const PARRY_LIST = {
  name: 'parries',
  routes: [
    {
      title: 'header.tabs.about',
      path: '/parries/about',
      element: <About />,
    },
    {
      title: 'header.tabs.parryList',
      path: '/parries',
      element: <ParryList />,
    },
    {
      title: 'header.tabs.myParries',
      path: '/parries/my/:mode',
      params: { mode: EParryViewMode.MyActive },
      element: <Workshop />,
    },
  ],
};

export const PARRIES = [CREATE_PARRY, PARRY_LIST, PARRY_VIEW];
