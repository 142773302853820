import { FC, useRef } from 'react';

import { useTranslation } from 'react-i18next';

import { useClickOutside } from 'hooks';

import { Typography } from '../../../../ui-kit';
import { Modal } from '../Modal';
import { useStyle } from './BetaHintModal.styles';

interface IRewardsModalProps {
  isOpen?: boolean;
  onClose: () => void;
}

export const BetaHintModal: FC<IRewardsModalProps> = ({ isOpen, onClose }) => {
  const { classes } = useStyle();

  const ref = useRef(null);

  useClickOutside(ref, onClose);

  const { t: translation } = useTranslation();

  const { Headline, Text } = Typography;

  return (
    <Modal isOpen={isOpen} onClose={onClose} className={classes.root}>
      <div ref={ref}>
        <Headline className={classes.title}>
          {translation('home.rewardsModal.title')}
        </Headline>
        <ul className={classes.list}>
          <li>
            <Text variant="b2">
              {translation('home.rewardsModal.opportunity-1-p1')}{' '}
              <Text variant="b2" color="brand">
                {translation('home.rewardsModal.opportunity-1-win-lose')}{' '}
              </Text>
              {translation('home.rewardsModal.opportunity-1-p2')}{' '}
              <Text variant="b2" color="brand">
                {translation('home.rewardsModal.opportunity-1-points')}
              </Text>
            </Text>
          </li>
          <li>
            <Text variant="b2">
              {translation('home.rewardsModal.opportunity-2-p1')}{' '}
              <Text variant="b2" color="brand">
                {translation('home.rewardsModal.opportunity-2-3points')}
              </Text>{' '}
              {translation('home.rewardsModal.opportunity-2-p2')}{' '}
              <Text variant="b2" color="brand">
                {translation('home.rewardsModal.opportunity-2-1point')}
              </Text>
            </Text>
          </li>
          <li>
            <Text variant="b2">
              {translation('home.rewardsModal.opportunity-3')}{' '}
              <Text variant="b2" color="brand">
                {translation('home.rewardsModal.opportunity-3-1point')}
              </Text>
            </Text>
          </li>
        </ul>
      </div>
      <Text variant="b6" className={classes.condition}>
        {translation('home.rewardsModal.condition')}
      </Text>
    </Modal>
  );
};
