import { useEffect, useState } from 'react';

import useSWR, { mutate } from 'swr';

import {
  getActiveParriesMy,
  getHistoryParries,
  getDraftParries,
  ICreatedParry,
  IListedParry,
} from 'api';
import { convertObjValuesToString } from 'helpers';
import { prevState } from 'middleware/prevState';

import { EParryRole, EParryViewMode } from '../Parries.model';

export const updateDraftParries = (
  title: string,
  currentPage: number,
  itemPerPage: number
): void => {
  const params = new URLSearchParams(
    convertObjValuesToString({
      title,
      offset: (currentPage - 1) * itemPerPage,
      limit: itemPerPage,
    })
  );
  mutate(`api/parries/draft?${params.toString()}`);
};

interface IParams {
  activeMode: EParryViewMode;
  debouncedFilters: {
    title: string;
    roles: EParryRole;
  };
  itemPerPage: number;
  currentPage: number;
}

interface IUseGetParryListResult {
  parries: {
    total: number;
    data: IListedParry[];
  };
  isLoading: boolean;
}

export function useGetParriesList({
  debouncedFilters,
  activeMode,
  itemPerPage,
  currentPage,
}: IParams): IUseGetParryListResult {
  const [parries, setParries] = useState<{
    total: number;
    data: IListedParry[];
  }>({ total: 0, data: [] });

  // EParryViewMode.MyActive
  const { data: activeParries, isValidating: isValidatingMyActive } = useSWR(
    () => {
      const params = new URLSearchParams(
        convertObjValuesToString({
          asOwner:
            debouncedFilters.roles === EParryRole.Both
              ? undefined
              : [EParryRole.Both, EParryRole.Owner].includes(
                  debouncedFilters.roles
                ),
          title: debouncedFilters.title,
          offset: 0,
          limit: 8,
        })
      );

      return activeMode === EParryViewMode.MyActive
        ? `api/parries/active?${params.toString()}`
        : null;
    },
    () =>
      getActiveParriesMy({
        title: debouncedFilters.title,
        asOwner:
          debouncedFilters.roles === EParryRole.Both
            ? undefined
            : [EParryRole.Both, EParryRole.Owner].includes(
                debouncedFilters.roles
              ),
        offset: 0,
        limit: 8,
      }),
    { use: [prevState] }
  );

  // EParryViewMode.Draft
  const { data: draftParries, isValidating: isValidatingDraft } = useSWR(
    () => {
      const params = new URLSearchParams(
        convertObjValuesToString({
          title: debouncedFilters.title,
          offset: (currentPage - 1) * itemPerPage,
          limit: itemPerPage,
        })
      );
      return activeMode === EParryViewMode.Draft
        ? `api/parries/draft?${params.toString()}`
        : null;
    },
    () =>
      getDraftParries({
        title: debouncedFilters.title,
        offset: (currentPage - 1) * itemPerPage,
        limit: itemPerPage,
      }),
    { use: [prevState] }
  );

  // EParryViewMode.History
  const { data: historyParries, isValidating: isValidatingHistory } = useSWR(
    () => {
      const params = new URLSearchParams(
        convertObjValuesToString({
          title: debouncedFilters.title,
          asOwner:
            debouncedFilters.roles === EParryRole.Both
              ? undefined
              : [EParryRole.Both, EParryRole.Owner].includes(
                  debouncedFilters.roles
                ),
          offset: (currentPage - 1) * itemPerPage,
          limit: itemPerPage,
        })
      );
      return activeMode === EParryViewMode.History
        ? `api/parries/history?${params.toString()}`
        : null;
    },
    () =>
      getHistoryParries({
        title: debouncedFilters.title,
        asOwner:
          debouncedFilters.roles === EParryRole.Both
            ? undefined
            : [EParryRole.Both, EParryRole.Owner].includes(
                debouncedFilters.roles
              ),
        offset: (currentPage - 1) * itemPerPage,
        limit: itemPerPage,
      }),
    { use: [prevState] }
  );

  useEffect(() => {
    if (activeParries && activeMode === EParryViewMode.MyActive) {
      setParries({
        data: activeParries.result,
        total: activeParries.total,
      });
    } else if (draftParries && activeMode === EParryViewMode.Draft) {
      setParries({
        data: draftParries.result,
        total: draftParries.total,
      });
    } else if (historyParries && activeMode === EParryViewMode.History) {
      setParries({
        data: historyParries.result,
        total: historyParries.total,
      });
    } else {
      setParries({
        data: [],
        total: 0,
      });
    }
  }, [activeMode, activeParries, draftParries, historyParries]);

  return {
    parries,
    isLoading:
      isValidatingHistory ||
      isValidatingMyActive ||
      isValidatingDraft ||
      !parries,
  };
}
