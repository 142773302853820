import { FC } from 'react';

import { ICommonIconProps } from './types';

export const TowerIcon: FC<ICommonIconProps> = ({
  color = 'currentColor',
  width = 24,
  height = 24,
  viewBox = '0 0 24 24',
  className = '',
  style,
}) => (
  <svg
    fill="none"
    width={width}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.28951 1.63291C5.16158 1.76084 5.1566 1.85441 5.1566 4.125V6.48418H12.0015H18.8465V4.125C18.8465 1.85441 18.8415 1.76084 18.7136 1.63291C18.5895 1.50884 18.4921 1.5 17.2515 1.5C16.011 1.5 15.9136 1.50884 15.7895 1.63291C15.6662 1.75625 15.6566 1.85441 15.6566 2.99525V4.22468H14.7647H13.8729L13.8509 3.0021C13.8315 1.91129 13.8154 1.76443 13.7025 1.63976C13.5806 1.50525 13.5171 1.5 12.0015 1.5C10.486 1.5 10.4225 1.50525 10.3006 1.63976C10.1876 1.76443 10.1716 1.91129 10.1521 3.0021L10.1302 4.22468H9.23837H8.34647V2.99525C8.34647 1.85441 8.3369 1.75625 8.21356 1.63291C8.08949 1.50884 7.99206 1.5 6.75153 1.5C5.511 1.5 5.41358 1.50884 5.28951 1.63291ZM5.42242 7.45184C5.42242 7.47231 5.66312 7.78631 5.95732 8.14962L6.49222 8.81013H12.0015H17.5108L18.0457 8.14962C18.3399 7.78631 18.5806 7.47231 18.5806 7.45184C18.5806 7.4313 15.62 7.41456 12.0015 7.41456C8.38302 7.41456 5.42242 7.4313 5.42242 7.45184ZM6.85261 9.89003C6.76542 10.2698 4.69141 21.9927 4.69141 22.1059C4.69141 22.1764 4.75122 22.294 4.82432 22.3671C4.95271 22.4955 5.04581 22.5 7.54468 22.5H10.1321L10.1531 19.5593C10.1763 16.3218 10.1654 16.4108 10.6078 15.8521C11.2643 15.0232 12.7388 15.0232 13.3952 15.8521C13.8377 16.4108 13.8268 16.3218 13.8499 19.5593L13.871 22.5H16.4584C18.9573 22.5 19.0504 22.4955 19.1787 22.3671C19.2518 22.294 19.3117 22.1756 19.3117 22.1041C19.3117 21.9911 17.2404 10.2874 17.1501 9.89003L17.1161 9.74051H12.0015H6.88697L6.85261 9.89003Z"
      fill="#8188A2"
    />
  </svg>
);
