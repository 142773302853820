import { FC } from 'react';

import { ICommonIconProps } from './types';

export const PinIcon: FC<ICommonIconProps> = ({
  color = 'currentColor',
  width = 20,
  height = 20,
  viewBox = '0 0 20 20',
  className = '',
  style,
}) => (
  <svg
    fill="none"
    width={width}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <g clipPath="url(#clip0_57_1663)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.19919 -0.0195312C9.72001 -0.0195312 10.2409 -0.0195312 10.7617 -0.0195312C14.0314 0.483055 16.1863 2.30597 17.2265 5.44922C17.6614 7.19203 17.5442 8.89777 16.875 10.5664C16.1798 12.2571 15.2944 13.8326 14.2187 15.293C12.9788 16.9564 11.6181 18.5189 10.1367 19.9805C10.0325 19.9805 9.92837 19.9805 9.82419 19.9805C7.51568 17.7705 5.56908 15.2835 3.98435 12.5195C3.21666 11.167 2.72187 9.72164 2.49998 8.18359C2.32125 5.46277 3.27828 3.2427 5.37107 1.52344C6.51154 0.67327 7.78759 0.158946 9.19919 -0.0195312ZM10.8008 10.8008C10.6885 10.8126 10.5844 10.8386 10.4883 10.8789C8.76423 11.0034 7.53376 10.2873 6.79685 8.73047C6.28619 7.13316 6.67033 5.79852 7.94919 4.72656C9.40224 3.7983 10.8215 3.85039 12.207 4.88281C13.5099 6.17676 13.7378 7.6416 12.8906 9.27734C12.3794 10.0377 11.6828 10.5455 10.8008 10.8008Z"
        fill="#8188A1"
      />
      <path
        opacity="0.961"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8008 10.8008C10.7117 10.8681 10.6075 10.8941 10.4883 10.8789C10.5844 10.8386 10.6886 10.8126 10.8008 10.8008Z"
        fill="#86D770"
      />
    </g>
    <defs>
      <clipPath id="clip0_57_1663">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
