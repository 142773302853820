import { Navigate, Outlet, useLocation } from 'react-router-dom';
import useSWR from 'swr';

import { isErrorResponse, isLoginUser } from 'api';
import { CHALLENGE_FORM_DATA_KEY } from 'consts';

export function RequireAuth(): JSX.Element {
  const location = useLocation();

  const { data: user, error } = useSWR('api/user', isLoginUser);

  if (isErrorResponse(user) && !error) {
    //clean form cache
    localStorage.removeItem(CHALLENGE_FORM_DATA_KEY);
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return <Outlet />;
}
