import { FC } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button } from 'ui-kit';

import { useStyle } from './LoginButtons.styles';

export const LoginButtons: FC = () => {
  const { t: translation } = useTranslation();
  const { classes } = useStyle();
  const navigate = useNavigate();
  return (
    <div>
      <Button
        className={classes.button}
        onClick={() => navigate('/login')}
        color="secondary"
        buttonSize="xl"
      >
        {translation('header.user.loginOld')}
      </Button>
      <Button
        className={classes.button}
        buttonSize="xl"
        onClick={() => navigate('/login?type=register')}
      >
        {translation('header.user.signup')}
      </Button>
    </div>
  );
};
