import { useState } from 'react';

import { ArrowIcon } from 'icons';
import { Typography } from 'ui-kit';

import { useStyle } from './Streamers.styles';

const streamers = [
  {
    name: 'Meepoplayer',
    avatar: '/images/home/streamers/meepo.jpg',
    url: '',
  },
  {
    name: 'Canceldota',
    avatar: '/images/home/streamers/cancel.jpg',
    url: '',
  },
  { name: 'Ar1se', avatar: '/images/home/streamers/ar1se.jpg', url: '' },
];

const { Headline, Text } = Typography;

function Card({ streamer }): JSX.Element {
  const { avatar, name, url } = streamer ?? { avatar: '', name: '', url: '' };
  const { classes } = useStyle();
  return (
    <a href={url} target="_blank" rel="noreferrer">
      <img src={avatar} className={classes.avatar} />
      <Text variant="b6">{name}</Text>
    </a>
  );
}

declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Array<T> {
    wrap(start: number, end: number): Array<T>;
  }
}

Array.prototype.wrap = function (start, end) {
  const res = [];
  let index = start;

  for (let i = start; i < end; i++) {
    if (index >= this.length) {
      index = 0;
    }

    res.push(this[index]);
    index++;
  }
  return res;
};

export function Streamers(): JSX.Element {
  const { classes, theme } = useStyle();
  const [index, setIndex] = useState(0);

  const relativeIndex: number = index % streamers.length;
  const SHOWN_ITEMS = 3;

  return (
    <div className={classes.root}>
      <Headline variant="h3">Our streamers</Headline>
      <div className={classes.streamers}>
        <div onClick={() => index > 0 && setIndex(index - 1)}>
          <ArrowIcon
            style={{ transform: 'rotate(90deg)' }}
            color={theme.palette.typo.primary}
            className={classes.arrow}
          />
        </div>
        {streamers
          .wrap(relativeIndex, relativeIndex + SHOWN_ITEMS)
          .map((streamer) => (
            <Card key={streamer.name} streamer={streamer} />
          ))}
        <div onClick={() => setIndex(index + 1)}>
          <ArrowIcon
            style={{ transform: 'rotate(-90deg)' }}
            color={theme.palette.typo.primary}
            className={classes.arrow}
          />
        </div>
      </div>
    </div>
  );
}
