import { FC } from 'react';

import { UICross24Icon } from 'icons';

import { useStyle } from './Cross.styles';

interface ICrossProps {
  className?: string;
}

export const Cross: FC<ICrossProps> = ({ className = '' }) => {
  const { classes, cx } = useStyle();
  return (
    <div className={cx(classes.iconContainer, className)}>
      <UICross24Icon />
    </div>
  );
};
