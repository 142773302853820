import { makeStyles } from 'theme';

export const useStyle = makeStyles()((theme) => ({
  animationContainer: {
    position: 'fixed',
    ...theme.layouts.flexCenter,
    backgroundColor: 'rgba(24, 24, 24, 0.9)',
    height: '100vh',
    width: '100%',
    zIndex: 11,
  },
  modalContainer: {
    borderRadius: 10,
    backgroundColor: 'rgb(33, 40, 67)',
    position: 'relative',
  },
  closeIcon: {
    position: 'absolute',
    top: '1rem',
    right: '1rem',
    color: theme.palette.typo.system,
    zIndex: 2,
  },
}));
