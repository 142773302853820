import { makeStyles } from 'theme';

export const useStyle = makeStyles()((theme) => ({
  contentContainer: {
    display: 'flex',
    width: 870,
    gap: 150,
    ...theme.layouts.marginXauto,
  },
  titleContainer: {
    width: 350,
    gap: 12,
  },
  title: {
    marginBottom: 12,
    width: '100%',
    display: 'block',
  },
  formContainer: {
    width: 370,
    display: 'flex',
    flexDirection: 'column',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  checkboxContainer: {
    ...theme.layouts.flexAICenter,
    width: '100',
  },
  button: {
    height: 48,
    marginTop: 24,
  },
  steamButton: {
    width: '100%',
  },
  disabled: { opacity: 0.4 },
  inputTitle: {
    marginBottom: 8,
    display: 'inline-block',
  },
  inputContainer: {
    position: 'relative',
  },
  verticalDivider: {
    height: '1px',
    width: '45%',
    backgroundColor: theme.palette.typo.primary,
    opacity: 0.2,
  },
  orContainer: {
    margin: '1.5rem 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  languageContainer: {
    cursor: 'pointer',
    position: 'absolute',
    top: 24,
    right: 40,
  },
  termsOfUse: {
    display: 'block',
    textAlign: 'center',
    width: '100%',
    marginTop: 32,
  },
}));
