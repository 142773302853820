import { FC, useEffect, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { Typography } from '../Typography';
import { useStyle } from './NavigationTabs.styles';

export type NavigationTabType = {
  labelKey: string;
  id: number;
};

interface INavigationTabsProps {
  tabs: NavigationTabType[];
  onTabClick: (id: number) => void;
  selectedTab: number;
}

export const NavigationTabs: FC<INavigationTabsProps> = ({
  selectedTab,
  tabs,
  onTabClick,
}) => {
  const { t: translation } = useTranslation();
  const { classes, cx } = useStyle();
  const [tabUnderlineWidth, setTabUnderlineWidth] = useState(100);
  const [tabUnderlineLeft, setTabUnderlineLeft] = useState(0);
  const tabsRef = useRef([]);
  useEffect(() => {
    function setTabPosition(): void {
      const currentTab = tabsRef.current[selectedTab];
      setTabUnderlineLeft(currentTab?.offsetLeft ?? 0);
      setTabUnderlineWidth(currentTab?.clientWidth ?? 0);
    }

    setTabPosition();
    window.addEventListener('resize', setTabPosition);

    return () => window.removeEventListener('resize', setTabPosition);
  }, [selectedTab]);

  const { Text } = Typography;

  return (
    <div className={classes.tabsContainer}>
      {tabs.map((tab) => (
        <div
          key={tab.id}
          onClick={() => onTabClick(tab.id)}
          className={cx(
            classes.tab,
            selectedTab === tab.id && classes.activeTab
          )}
          ref={(el) => (tabsRef.current[tab.id] = el)}
        >
          <Text variant="b2">{translation(tab.labelKey)}</Text>
        </div>
      ))}
      <span
        className={classes.underline}
        style={{ left: tabUnderlineLeft, width: tabUnderlineWidth }}
      />
    </div>
  );
};
