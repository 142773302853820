import useSWR, { mutate } from 'swr';

import {
  EPredictStatus,
  getActivePrediction,
  getMatches,
  getStatistic,
  isErrorResponse,
} from 'api';

interface IMatchHistory {
  refetchGame: () => Promise<void>;
  isRefetching: boolean;
  isHistoryFailed: boolean;
}

export const useCheckHistory: () => IMatchHistory = () => {
  const { data: activePredict, isValidating: isValidatingPredict } = useSWR(
    'api/predict',
    getActivePrediction
  );
  const { isValidating: isValidatingStatistic } = useSWR(
    'api/statistics',
    getStatistic
  );
  const { isValidating: isValidatingMatches } = useSWR(
    'api/matches?offset=0&limit=5',
    () => getMatches({ offset: 0, limit: 5, mode: 'ranked' })
  );
  const isRefetching =
    isValidatingStatistic || isValidatingPredict || isValidatingMatches;
  const refetchGame = async (): Promise<void> => {
    mutate('api/predict');
    if (
      [EPredictStatus.LOSE, EPredictStatus.WIN].includes(activePredict?.status)
    ) {
      mutate('api/statistics');
      mutate('api/matches?offset=0&limit=5');
      mutate('api/balance');
    }
  };
  const isHistoryFailed =
    isErrorResponse(activePredict) && activePredict?.code === 400;
  return {
    refetchGame,
    isRefetching,
    isHistoryFailed,
  };
};
