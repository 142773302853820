import { DateTime } from 'luxon';
import { TFunction } from 'react-i18next';
import { mutate } from 'swr';

import {
  EParryStatus,
  ECondition,
  EDistribution,
  EItemState,
  EMainTerm,
} from 'consts';

export const defaultValues = {
  title: '',
  expiredIn: '',
  password: '',
  setPassword: false,
  image: '',
  hero: 0,
  options: [
    {
      term: EMainTerm.Win,
      condition: ECondition.Equal,
      quantity: 1,
      shouldBeInRow: false,
    },
  ],
  entryCost: 0,
  freeForAll: false,
  items: [
    { type: EItemState.Free, place: 0, item: null, url: '', dname: '' },
    { type: EItemState.Free, place: 1, item: null, url: '', dname: '' },
    { type: EItemState.Free, place: 2, item: null, url: '', dname: '' },
    { type: EItemState.Free, place: 3, item: null, url: '', dname: '' },
    { type: EItemState.Free, place: 4, item: null, url: '', dname: '' },
    { type: EItemState.Free, place: 5, item: null, url: '', dname: '' },
  ],
  reward: 0,
  distribution: EDistribution.ThreeParticipants,
  participants: 0,
  limitedParticipants: false,
  winnerCount: 0,
  isFetched: false,
  id: 0,
  restart: false,
  iterations: 1,
  restartEnd: false,
  winInsensitivity: false,
  targetSteamId: undefined,
};

export const text = {
  title: 'parry.tooltip.title',
  performer: 'parry.tooltip.performer',
  duration: 'parry.tooltip.duration',
  password: 'parry.tooltip.password',
  main: 'parry.tooltip.main',
  hero: 'parry.tooltip.hero',
  entryFee: 'parry.tooltip.entryFee',
  playersLimit: 'parry.tooltip.playersLimit',
  reward: 'parry.tooltip.reward',
  rewardDistribution: {
    firstToWin: 'parry.tooltip.rewardDistribution.firstToWin',
    threeToWin: 'parry.tooltip.rewardDistribution.threeToWin',
    equalWin: 'parry.tooltip.rewardDistribution.equalWin',
  },
  winners: 'parry.tooltip.winners',
};

export type ParryFormValueKey = keyof typeof defaultValues;
export type ParryFormValueType = typeof defaultValues;
export const ERROR_MESSAGES_TRANSLATIONS = (
  translation: TFunction<'translation', undefined>
): Record<string, string> => ({
  title: translation('parry.form.errors.title'),
  password: translation('parry.form.errors.title'),
  duration: translation('parry.form.errors.duration'),
  cover: translation('parry.form.errors.cover'),
  entry: translation('parry.form.errors.entryMin'),
  rewardGreaterThanBalance: translation(
    'parry.form.errors.rewardGreaterThanBalance'
  ),
  requiredFiled: translation('parry.form.errors.requiredFiled'),
  rewardMin: translation('parry.form.errors.rewardMin'),
  winnerCountRequired: translation('parry.form.errors.winnerCount'),
  winnerCountBig: translation('parry.form.errors.winnerCountBig'),
  entryFeeMin: translation('parry.form.errors.entryfeeMin'),
});

export const preparePreviewParryData = async (
  data: ParryFormValueType,
  username: string,
  randomId: string
): Promise<void> => {
  const {
    title,
    expiredIn,
    entryCost,
    reward,
    distribution,
    hero,
    participants,
    options,
    password,
    setPassword,
    winnerCount,
    isFetched,
    id,
    image,
    iterations,
    items,
    targetSteamId,
  } = data;
  return mutate(`api/parry/${randomId}`, {
    id: id || randomId,
    title,
    expiredIn: Number(expiredIn),
    entryCost,
    reward,
    ...(setPassword && { password }),
    distribution,
    hero: hero ? hero : null,
    participants,
    image,
    options,
    status: EParryStatus.Draft,
    avatars: [],
    activeParticipants: 0,
    owner: username,
    createdAt: DateTime.now().toISO(),
    hasPassword: !!password,
    winnerCount,
    isParticipant: false,
    isOwner: true,
    isFetched,
    iterations,
    items,
    targetSteamId,
  });
};

export const MIN_PARRY_DURATION = 60 * 60 * 1000 - 1;
export const MAX_PARRY_DURATION = 7 * 24 * 60 * 60 * 1000 + 1;

export const isOptionsDefault = (
  options: ParryFormValueType['options'],
  winInsensitivity: ParryFormValueType['winInsensitivity']
): boolean => {
  const { term, shouldBeInRow, quantity, condition } = options[0];
  return (
    options.length === 1 &&
    term === EMainTerm.Win &&
    condition === ECondition.Equal &&
    quantity === 1 &&
    !shouldBeInRow &&
    !winInsensitivity
  );
};

export const isHeroDefault = (hero: ParryFormValueType['hero']): boolean =>
  !hero;

export const isItemsDefault = (
  items: { place: number; item: number; type: EItemState }[] = []
): boolean =>
  items.every((item) => item.item === null && item.type === EItemState.Free);

export const validationFields: Record<string, ParryFormValueKey[]> = {
  firstStep: ['expiredIn', 'title', 'image', 'password'],
  fourthStep: ['reward', 'entryCost', 'reward', 'winnerCount', 'participants'],
};
