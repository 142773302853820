import { makeStyles } from 'theme';

export const useStyle = makeStyles()((theme) => ({
  userInfo: {
    width: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginLeft: 12,
    marginRight: 'auto',
  },
  gradientTextTrophy: {
    backgroundClip: 'text',
    color: 'transparent',
  },
  'gradient-text-gold': {
    background:
      '-webkit-linear-gradient(168.12deg, #DEBB6C 4.13%, #E3CB8E 101.73%)',
  },

  'gradient-text-silver': {
    background:
      '-webkit-linear-gradient(168.12deg, #C7C8C8 4.13%, #DDDEDE 101.73%)',
  },

  'gradient-text-bronze': {
    background:
      '-webkit-linear-gradient(168.12deg, #AE7C3C 4.13%, #BB9768 101.73%)',
  },

  'gradient-red': {
    background: 'linear-gradient(89.03deg, #d34e31 0.64%, #fb18a0 175.76%)',
  },

  'gold-shadow': {
    boxShadow: '0px 4px 23px rgba(242, 186, 47, 0.5)',
  },
  'silver-shadow': {
    boxShadow: '0px 4px 23px rgba(255, 255, 255, 0.5)',
  },
  'bronze-shadow': {
    boxShadow: '0px 4px 23px rgba(222, 120, 0, 0.5)',
  },
  winnerItem: {
    width: '100%',
    height: 80,
    marginTop: 20,
    ':first-of-type': {
      marginTop: 0,
    },
    position: 'relative',
  },
  winnersLeftBar: {
    width: 'calc(100% - 18px)',
    height: 3,
    position: 'absolute',
    left: 9,
    top: 0,
  },
  winnersIcon: {
    borderRadius: '50%',
    width: 32,
    height: 32,
    marginRight: 12,
  },
  winnersUserName: {
    ...theme.fontStyles.text14,
    color: theme.palette.typo.primary,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  winnerItemContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));
