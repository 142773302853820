import { makeStyles } from 'theme';

export const useStyle = makeStyles()((theme) => ({
  ...theme.layouts,
  spinner: {
    ...theme.layouts.flexCenter,
    width: '100%',
    marginTop: '70px',
  },
  title: {
    marginBottom: '1.5rem',
  },
  heroName: {
    display: 'flex',
    marginBottom: '1.5rem',
  },
  heroIcon: {
    width: 64,
    height: 40,
  },
  heroDescr: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '0.5rem',
  },
  heroGames: {
    opacity: '0.7',
  },
  heroWins: {
    marginLeft: '0.375rem',
  },
  noStatisticBlock: {
    ...theme.layouts.flexCenter,
    flexDirection: 'column',
    opacity: '0.5',
    marginTop: '3rem',
  },
  noStatisticTitle: {
    marginBottom: '1.5rem',
  },
  noStatisticDescr: {
    textAlign: 'center',
  },
}));
