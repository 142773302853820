import TagManager from 'react-gtm-module';
import { UseFormSetError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSWRConfig } from 'swr';

import { isLoginUser, IUserResponse, loginUser } from 'api';

import { FormLoginValues } from '../Login';

type ILogin = (data: FormLoginValues) => Promise<IUserResponse>;

export function useLogin(setError: UseFormSetError<FormLoginValues>): ILogin {
  const navigate = useNavigate();
  const { mutate } = useSWRConfig();
  const { t: translation } = useTranslation();
  return async (data: FormLoginValues): Promise<IUserResponse> => {
    //add google analytics
    TagManager.dataLayer({
      dataLayer: {
        event: 'login',
        method: 'email',
      },
    });
    const userLogin = await loginUser({
      password: data.password,
      email: data.email,
    });
    if (userLogin.code === 403) {
      setError('password', {
        type: 'custom',
        message: translation('login.errors.wrong'),
      });
    }
    if (userLogin.code === 404) {
      setError('email', {
        type: 'custom',
        message: translation('login.errors.notFound'),
      });
    }
    const user = await isLoginUser();
    mutate('api/user', user);
    navigate('/home');
    return user;
  };
}
