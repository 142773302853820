import { useRef } from 'react';

import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useClickOutside } from 'hooks';
import { Button } from 'ui-kit';

import { Modal } from '../Modal';
import { useStyle } from './Modal.styles';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

export function ModalLogin({ isOpen, onClose }: IProps): JSX.Element {
  const navigate = useNavigate();
  const ref = useRef(null);
  const { t: translation } = useTranslation();
  useClickOutside(ref, onClose);
  const { classes } = useStyle();

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className={classes.modal} ref={ref}>
        <h6 className={classes.modalTitle}>
          {translation('modal.login.title')}
        </h6>

        <Button
          className={classes.modalLoginBtn}
          onClick={() => {
            navigate('/login');
            onClose();
          }}
          color="secondary"
        >
          {translation('modal.login.loginButton')}
        </Button>
        <Button
          className={clsx('p-button', classes.modalRegisterBtn)}
          onClick={() => {
            navigate('/login?type=register');
            onClose();
          }}
        >
          {translation('modal.login.signupButton')}
        </Button>
      </div>
    </Modal>
  );
}
