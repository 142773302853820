import React, { FC, forwardRef } from 'react';

import clsx from 'clsx';

import { MinusIcon, PlusIcon } from 'icons';

import { Typography } from '../Typography';
import { useStyle } from './Counter.styles';

interface ICounterProps {
  className?: string;
  maxValue?: number;
  minValue?: number;
  onChange: (value: number) => void;
  name?: string;
  value: number;
  disabled?: boolean;
  title?: string;
}

export const Counter: FC<ICounterProps> = forwardRef<
  HTMLInputElement,
  ICounterProps
>(
  (
    {
      maxValue = 99,
      minValue = 1,
      onChange,
      name,
      value,
      title,
      className,
      disabled,
    },
    ref
  ) => {
    const { classes } = useStyle();
    const getValidValue = (validatingValue: number): number => {
      if (validatingValue < minValue) {
        return minValue;
      }
      if (validatingValue > maxValue) {
        return maxValue;
      }
      return validatingValue;
    };
    const { Text } = Typography;

    return (
      <div
        className={clsx(
          className,
          disabled && classes.disabled,
          classes.titleContainer
        )}
      >
        {title && (
          <Text variant="b6" color="typoLabel" className={classes.title}>
            {title}
          </Text>
        )}
        <div className={classes.container}>
          <button
            type="button"
            className={classes.minusContainer}
            onClick={() => {
              onChange(getValidValue(value - 1));
            }}
            disabled={disabled}
          >
            <MinusIcon
              color="white"
              className={clsx(value <= minValue && classes.disableButton)}
            />
          </button>
          <input
            ref={ref}
            name={name}
            type="number"
            pattern="(^\d$)"
            value={String(value).replace(/^0+(?=.+)/, '')}
            disabled={disabled}
            className={classes.input}
            onChange={(e) => {
              onChange(e.target.validity.valid ? +e.target.value : +value);
            }}
            onBlur={(e) => onChange(getValidValue(+e.target.value))}
            min={minValue}
            max={maxValue}
          />
          <button
            type="button"
            className={classes.plusContainer}
            disabled={disabled}
            onClick={() => {
              onChange(getValidValue(value + 1));
            }}
          >
            <PlusIcon
              color="white"
              className={clsx(value >= maxValue && classes.disableButton)}
            />
          </button>
        </div>
      </div>
    );
  }
);
