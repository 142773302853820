import { makeStyles } from 'theme';

export const useStyle = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '1rem 1rem 1rem 2rem',
    backgroundColor: theme.palette.bg.toneLight,
    margin: '32px 8px 0 0',
    borderRadius: '4px',
    gap: '1rem',
  },
  questionsBlock: {
    display: 'flex',
    flexDirection: 'column',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  arrow: {
    backgroundColor: theme.palette.bg.ghostLight,
    width: 32,
    height: 32,
    borderRadius: '50%',
    padding: '0.5rem',
    cursor: 'pointer',
  },
  question: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.75rem',
    marginTop: '1rem',
  },
}));
