import { EParryStatus, ECondition, EItemState, EMainTerm, ETerm } from 'consts';
import { convertObjValuesToString } from 'helpers';

import { BACKEND_URL } from './env';
import { IErrorResponse } from './interface';

export interface IParry {
  title: string;
  password: string;
  entryCost: number;
  reward: number;
  distribution: number;
  participants: number;
  image: string;
  hero: number;
  winnerCount: number;
  iterations: number;
  options: {
    term: EMainTerm | ETerm;
    condition: ECondition;
    quantity: number;
    shouldBeInRow?: boolean;
  }[];
  items: { item: number; place: number; type: EItemState }[];
  winInsensitivity: boolean;
  targetSteamId?: string;
  expiredIn: number;
}

export interface IParryWinner {
  avatarUrl: string;
  username: string;
  id: number;
}

export interface ICreatedParry extends IParry {
  id: number;
  status: EParryStatus;
  avatars: string[];
  activeParticipants: number;
  owner: { username: string; avatarUrl: string };
  createdAt?: string;
  hasPassword: boolean;
  winners: IParryWinner[];
  isParticipant: boolean;
  isOwner: boolean;
  isFetched?: boolean;
  step?: number;
  cycleRunning: boolean;
  items: { item: number; place: number; type: EItemState }[];
  image: string;
  expiredIn: number;
}

export interface IListedParry {
  id: number;
  title: string;
  hasPassword: boolean;
  entryCost: number;
  image: string;
  status: EParryStatus;
  activeParticipants: number;
  isOwner: boolean;
  isParticipant: boolean;
  hero: number;
  createdAt?: string;
  owner: { id: number; username: string; avatarUrl: string };
  options: {
    term: EMainTerm | ETerm;
    condition: ECondition;
    quantity: number;
    shouldBeInRow?: boolean;
  }[];
  items: { item: number; place: number; type: EItemState }[];
  participants: number;
  toWin: number;
  toLose: number;
  avatars: string[];
}

export interface IParriesList {
  result: IListedParry[];
  total: number;
}

export interface IParriesCount {
  asOwner: number;
  asParticipant: number;
}

interface IFilterPagination {
  offset: number;
  limit: number;
}

interface IFilterParams extends IFilterPagination {
  title?: string;
  entryCostForm?: string;
  entryCostTo?: string;
  rewardFrom?: string;
  rewardTo?: string;
  playersFrom?: string;
  playersTo?: string;
  asParticipant?: boolean;
  hero?: string;
  password?: boolean;
  freeSlots?: boolean;
  orderBy?: string;
  orderType?: string;
}

interface IFilterMyParry extends IFilterPagination {
  title?: string;
  asOwner?: boolean;
}

export function createParry(
  parry: IParry
): Promise<ICreatedParry | IErrorResponse> {
  return fetch(`${BACKEND_URL}/api/parry-service/parries`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify(parry),
  }).then((data) => data.json());
}

export function startParry(
  id: number
): Promise<ICreatedParry | IErrorResponse> {
  return fetch(`${BACKEND_URL}/api/parry-service/parries/${id}/start`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  }).then((data) => data.json());
}

export function updateParry(
  parry: IParry & { id: number }
): Promise<IParry | IErrorResponse> {
  return fetch(`${BACKEND_URL}/api/parry-service/parries/${parry.id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify(parry),
  }).then((data) => data.json());
}

export function getActiveParries(
  filters: IFilterParams
): Promise<IParriesList> {
  const params = new URLSearchParams(
    convertObjValuesToString<IFilterParams>(filters)
  );

  return fetch(
    `${BACKEND_URL}/api/parry-service/parries/active?orderType=DESC&${params.toString()}`,
    {
      credentials: 'include',
    }
  ).then((data) => data.json());
}

export function getActiveParriesMy(
  filters: IFilterMyParry
): Promise<IParriesList> {
  const params = new URLSearchParams(
    convertObjValuesToString<IFilterMyParry>(filters)
  );
  return fetch(
    `${BACKEND_URL}/api/parry-service/parries/active/my?orderType=DESC&${params.toString()}`,
    {
      credentials: 'include',
    }
  ).then((data) => data.json());
}

export function getParry(id: number): Promise<ICreatedParry> {
  return fetch(`${BACKEND_URL}/api/parry-service/parries/${id}`, {
    credentials: 'include',
  }).then((data) => data.json());
}

export function getParryHistory(id: number): Promise<ICreatedParry> {
  return fetch(`${BACKEND_URL}/api/parry-service/parries/history/${id}`, {
    credentials: 'include',
  }).then((data) => data.json());
}

export function getDraftParries(
  filters: IFilterMyParry
): Promise<IParriesList> {
  const params = new URLSearchParams(
    convertObjValuesToString<IFilterMyParry>(filters)
  );
  return fetch(
    `${BACKEND_URL}/api/parry-service/parries?${params.toString()}&orderBy=createdAt&orderType=DESC`,
    {
      credentials: 'include',
    }
  ).then((data) => data.json());
}

export function deleteDraftParry(id: number): Promise<Response> {
  return fetch(`${BACKEND_URL}/api/parry-service/parries/${id}`, {
    method: 'DELETE',
    credentials: 'include',
  });
}

export function activateParry(id: number): Promise<IParry | IErrorResponse> {
  return fetch(`${BACKEND_URL}/api/parry-service/parries/${id}/activate`, {
    method: 'PUT',
    credentials: 'include',
  }).then((data) => data.json());
}

export function disableParry(id: number): Promise<Response> {
  return fetch(`${BACKEND_URL}/api/parry-service/parries/${id}/disable`, {
    method: 'PUT',

    credentials: 'include',
  });
}

export function participateInParry(
  id: number,
  win: boolean,
  password?: string
): Promise<IParry | IErrorResponse> {
  return fetch(`${BACKEND_URL}/api/parry-service/parries/${id}/participation`, {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify({ win, password }),
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((data) => data.json());
}

export function deactivateParticipationInParry(
  id: number
): Promise<Response | void> {
  return fetch(`${BACKEND_URL}/api/parry-service/parries/${id}/participation`, {
    method: 'DELETE',
    credentials: 'include',
  });
}

export function getHistoryParries(
  filters: IFilterMyParry
): Promise<IParriesList> {
  const params = new URLSearchParams(
    convertObjValuesToString<IFilterMyParry>(filters)
  );

  return fetch(
    `${BACKEND_URL}/api/parry-service/parries/history?${params.toString()}&orderBy=createdAt&orderType=DESC`,
    {
      credentials: 'include',
    }
  ).then((data) => data.json());
}

export function getParriesCount(): Promise<IParriesCount> {
  return fetch(`${BACKEND_URL}/api/parry-service/parries/count`, {
    credentials: 'include',
  }).then((data) => data.json());
}

export function stopParryRestart(parryId: number): Promise<Response> {
  return fetch(`${BACKEND_URL}/api/parry-service/parries/${parryId}/cycle`, {
    method: 'DELETE',
    credentials: 'include',
  });
}
