import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { Typography } from 'ui-kit';

import { useStyle } from './ChallengesNotFount.styles';

interface IChallengesNotFound {
  title: string;
}

export const ChallengesNotFound: FC<IChallengesNotFound> = ({ title }) => {
  const { t: translation } = useTranslation();
  const { classes } = useStyle();
  const { Headline, Text } = Typography;
  return (
    <div className={classes.container}>
      <Headline>
        {translation('challenges-list.not-found')}{' '}
        {`${title ? `${translation('challenges-list.for')} "${title}"` : ''}`}{' '}
      </Headline>
      <Text className={classes.request}>
        {translation('challenges-list.search-again')}
      </Text>
    </div>
  );
};
