import type { FC } from 'react';

import type { ICommonIconProps } from './types';

export const SwordsIcon: FC<ICommonIconProps & { isFill?: boolean }> = ({
  width = 24,
  height = 24,
  viewBox,
  className = '',
  style,
}) => {
  const viewBoxCustom = viewBox ? '-1 -1 20 20' : '-1 -1 20 20';
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 3C2 2.44772 2.44772 2 3 2H6C6.26522 2 6.51957 2.10536 6.70711 2.29289L17.5 13.0858L18.2929 12.2929C18.6834 11.9024 19.3166 11.9024 19.7071 12.2929C20.0976 12.6834 20.0976 13.3166 19.7071 13.7071L17.4142 16L20 18.5858L20.2929 18.2929C20.6834 17.9024 21.3166 17.9024 21.7071 18.2929C22.0976 18.6834 22.0976 19.3166 21.7071 19.7071L19.7071 21.7071C19.3166 22.0976 18.6834 22.0976 18.2929 21.7071C17.9024 21.3166 17.9024 20.6834 18.2929 20.2929L18.5858 20L16 17.4142L13.7071 19.7071C13.3166 20.0976 12.6834 20.0976 12.2929 19.7071C11.9024 19.3166 11.9024 18.6834 12.2929 18.2929L13.0858 17.5L2.29289 6.70711C2.10536 6.51957 2 6.26522 2 6V3ZM17.2929 2.29289C17.4804 2.10536 17.7348 2 18 2H21C21.5523 2 22 2.44772 22 3V6C22 6.26522 21.8946 6.51957 21.7071 6.70711L18.2071 10.2071C17.8166 10.5976 17.1834 10.5976 16.7929 10.2071L13.7929 7.20711C13.4024 6.81658 13.4024 6.18342 13.7929 5.79289L17.2929 2.29289ZM4.29289 13.2929C4.68342 12.9024 5.31658 12.9024 5.70711 13.2929L9.70711 17.2929C10.0976 17.6834 10.0976 18.3166 9.70711 18.7071C9.31658 19.0976 8.68342 19.0976 8.29289 18.7071L7.5 17.9142L5.41421 20L5.70711 20.2929C6.09763 20.6834 6.09763 21.3166 5.70711 21.7071C5.31658 22.0976 4.68342 22.0976 4.29289 21.7071L2.29289 19.7071C1.90237 19.3166 1.90237 18.6834 2.29289 18.2929C2.68342 17.9024 3.31658 17.9024 3.70711 18.2929L4 18.5858L6.08579 16.5L4.29289 14.7071C3.90237 14.3166 3.90237 13.6834 4.29289 13.2929Z"
        fill="currentcolor"
      />
    </svg>
  );
};
