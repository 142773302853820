import { FC, useRef } from 'react';

import { useTranslation } from 'react-i18next';

import { useClickOutside } from 'hooks';
import { Button, CircleIcon, Typography } from 'ui-kit';

import { Modal } from '../Modal';
import { useStyle } from './WalletError.styles';

interface IWalletErrorProps {
  isOpen: boolean;
  onClose: () => void;
}

export const WalletError: FC<IWalletErrorProps> = ({ isOpen, onClose }) => {
  const { classes } = useStyle();
  const { Text, Headline } = Typography;
  const { t: translation } = useTranslation();
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className={classes.modal}>
        <CircleIcon type="error" className={classes.icon} />
        <Headline variant="h3">{translation('profile.error.title')}</Headline>
        <Text>
          {translation('profile.error.description-1')}{' '}
          <Text color="link">
            <a
              href="https://discord.gg/7fKEWhnh4x"
              target="_blank"
              rel="noreferrer"
            >
              Discord
            </a>{' '}
          </Text>
          {translation('profile.error.description-2')}
        </Text>
        <Button onClick={onClose} className={classes.button} buttonSize="l">
          {translation('profile.error.btn')}
        </Button>
      </div>
    </Modal>
  );
};
