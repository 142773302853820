import { makeStyles } from 'theme';

export const useStyle = makeStyles()((theme) => ({
  iconContainer: {
    width: 28,
    height: 28,
    color: theme.palette.typo.system,
    borderRadius: '50%',
    transitionDuration: '300ms',
    cursor: 'pointer',
    ...theme.layouts.flexCenter,
    ':hover': {
      color: theme.palette.control.secondary.typo,
      backgroundColor: theme.palette.control.secondary.bg,
      transitionDuration: '300ms',
      ':active': {
        backgroundColor: theme.palette.control.secondary.bgPressed,
        transitionDuration: '300ms',
      },
    },
  },
}));
