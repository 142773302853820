import { FC } from 'react';

import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { ICreatedChallenge } from 'api';
import { calcChallengeDuration, removeZeroesAfterComma } from 'helpers';
import {
  EntryCostIcon,
  EstablishedRewardIcon,
  RecycleIcon,
  SmallLockIcon,
  TotalEntryFeeeIcon,
  TotalProfitIcon,
  TrophyIcon,
} from 'icons';
import { Button, Dividers, Typography } from 'ui-kit';

import { calcProfit } from '../../ChallengeView.model';
import { useStyle } from './ChallengeHeader.styles';

interface IOwnerHeader {
  challenge: ICreatedChallenge;
  onStopRegistrationClick: () => void;
  onStopCycleClick: () => void;
}

export const OwnerHeader: FC<IOwnerHeader> = ({
  challenge,
  onStopRegistrationClick,
  onStopCycleClick,
}) => {
  const {
    entryCost,
    reward,
    password,
    iterations,
    cycleRunning,
    step,
    status,
    activeParticipants,
  } = challenge;

  const { classes } = useStyle({ isSmall: false });
  const [searchParams] = useSearchParams();
  const isPreview = searchParams.get('mode') === 'preview';
  const isRecycle = iterations !== 1 && cycleRunning;
  const { Text } = Typography;
  const { t: translation } = useTranslation();
  const { VerticalDivider } = Dividers;
  const { isFinished } = calcChallengeDuration(challenge);
  const { totalProfit, establishedReward, totalEntryFees } =
    calcProfit(challenge);
  const isShowProfit = activeParticipants > 0 && isFinished;
  return (
    <>
      <div className={classes.divider} />
      <div className={classes.controlContainer}>
        <div className={classes.controlDataContainer}>
          <div className={classes.dataContainer}>
            <Text variant="b6" color="system">
              {translation('challengeView.entryFee')}
            </Text>
            <div className={classes.iconContainer}>
              <Text color="system">
                <EntryCostIcon
                  className={classes.controlIcon}
                  height={20}
                  width={20}
                />
              </Text>
              <Text variant="b3">
                {removeZeroesAfterComma(String(entryCost))}
                {' GC'}
              </Text>
            </div>
          </div>
          <VerticalDivider />
          <div className={classes.dataContainer}>
            <Text variant="b6" color="system">
              {translation('challengeView.reward')}
            </Text>
            <div className={classes.iconContainer}>
              <Text color="system">
                <TrophyIcon
                  isFill
                  className={classes.controlIcon}
                  height={20}
                  width={20}
                />
              </Text>
              <Text variant="b3">
                {removeZeroesAfterComma(String(reward))}
                {' GC'}
              </Text>
            </div>
          </div>
          {password && (
            <>
              <VerticalDivider />
              <div className={classes.dataContainer}>
                <Text variant="b6" color="system">
                  {translation('challengeView.password')}
                </Text>
                <div className={classes.iconContainer}>
                  <Text color="system">
                    <SmallLockIcon
                      className={classes.controlIcon}
                      height={20}
                      width={20}
                    />
                  </Text>
                  <Text variant="b3">{password}</Text>
                </div>
              </div>
            </>
          )}
          {iterations !== 1 && (
            <>
              <VerticalDivider />
              <div className={classes.dataContainer}>
                <Text variant="b6" color="system">
                  {translation('challengeView.iteration')}
                </Text>
                <div className={classes.iconContainer}>
                  <Text color="system">
                    <RecycleIcon
                      className={classes.controlIcon}
                      height={20}
                      width={20}
                    />
                  </Text>
                  <Text variant="b3">
                    {iterations
                      ? `${step || 1} / ${iterations}`
                      : translation('challengeView.endlessly')}
                  </Text>
                </div>
              </div>
            </>
          )}
          {isShowProfit && (
            <>
              <VerticalDivider />
              <div className={classes.dataContainer}>
                <Text variant="b6" color="system">
                  {translation('challengeView.establishedReward')}
                </Text>
                <div className={classes.iconContainer}>
                  <Text color="system">
                    <EstablishedRewardIcon
                      className={classes.controlIcon}
                      height={20}
                      width={20}
                    />
                  </Text>
                  <Text variant="b3">{establishedReward}</Text>
                </div>
              </div>
              <VerticalDivider />
              <div className={classes.dataContainer}>
                <Text variant="b6" color="system">
                  {translation('challengeView.totalEntryFee')}
                </Text>
                <div className={classes.iconContainer}>
                  <Text color="system">
                    <TotalEntryFeeeIcon
                      className={classes.controlIcon}
                      height={20}
                      width={20}
                    />
                  </Text>
                  <Text variant="b3">{totalEntryFees}</Text>
                </div>
              </div>
              <VerticalDivider />
              <div className={classes.dataContainer}>
                <Text variant="b6" color="system">
                  {translation('challengeView.totalProfit')}
                </Text>
                <div className={classes.iconContainer}>
                  <Text color="system">
                    <TotalProfitIcon
                      className={classes.controlIcon}
                      height={20}
                      width={20}
                    />
                  </Text>
                  <Text variant="b3">
                    {removeZeroesAfterComma(totalProfit)}
                  </Text>
                </div>
              </div>
            </>
          )}
        </div>
        {isRecycle && status == 'active' && (
          <Button
            className={classes.stopCycle}
            color="secondary"
            onClick={onStopCycleClick}
          >
            {translation('challengeView.stopCycle')}
          </Button>
        )}
        {!isPreview && status == 'active' && (
          <Button onClick={onStopRegistrationClick}>
            {activeParticipants === 0
              ? translation('challengeView.cancelBtn')
              : translation('challengeView.stopReg')}
          </Button>
        )}
      </div>
    </>
  );
};
