import { CSSObject } from 'tss-react';

import { makeStyles } from 'theme';

import { DropdownPositionType } from '../Dropdown';

export const useStyle = makeStyles<{
  position: DropdownPositionType;
  hasScroll: boolean;
}>()((theme, { position, hasScroll }) => {
  const positionMap: Record<DropdownPositionType, CSSObject> = {
    top: {
      top: 0,
      transform: 'translate(-50%, calc(-100% - 4px))',
    },
    bottom: {
      bottom: 0,
      transform: 'translate(-50%, calc(100% + 4px))',
    },
  };
  return {
    optionsContainer: {
      width: '100%',
      position: 'absolute',
      left: '50%',
      maxHeight: 366,
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: theme.palette.bg.default,
      border: `2px solid ${theme.palette.bg.border}`,
      borderRadius: 6,
      overflowY: 'scroll',
      overflowX: 'hidden',
      '::-webkit-scrollbar': {
        width: hasScroll ? 12 : 0,
      },
      '::-webkit-scrollbar-thumb': {
        border: '4px solid rgba(0, 0, 0, 0)',
        backgroundClip: 'padding-box',
        backgroundColor: '#2D63FF',
        borderRadius: 8,
      },
      zIndex: 10,
      ...positionMap[position],
    },
    item: {
      padding: '8px 16px',
      display: 'flex',
      width: '100%',
      ':hover': {
        backgroundColor: theme.palette.bg.ghost,
      },
      ':active': {
        backgroundColor: theme.palette.bg.ghostLight,
      },
    },
    img: {
      marginRight: 12,
      height: 20,
    },
    input: {
      width: 'calc(100% - 32px)',
      marginLeft: 16,
      marginTop: 16,
    },
  };
});
