import { TFunction } from 'react-i18next';

import {
  ECondition,
  EDistribution,
  EMainTerm,
  ETerm,
  IOptionTerm,
} from './challenges';

export const PARRY_OPTIONS_TERM = (
  translation: TFunction<'translation', undefined>
): IOptionTerm[] => [
  {
    value: ETerm.HeroKills,
    label: translation('parry.form.gameOptions.kills'),
  },
  {
    value: ETerm.Deaths,
    label: translation('parry.form.gameOptions.deaths'),
  },
  {
    value: ETerm.Assists,
    label: translation('parry.form.gameOptions.assists'),
  },
  {
    value: ETerm.CreepKills,
    label: translation('parry.form.gameOptions.lastHit'),
  },
  {
    value: ETerm.CreepDenies,
    label: translation('parry.form.gameOptions.denies'),
  },
  {
    value: ETerm.BuildingDamage,
    label: translation('parry.form.gameOptions.towersDamage'),
  },
];

export const PARRY_MAX_TERM_VALUES = {
  [ETerm.HeroKills]: 999,
  [ETerm.Deaths]: 999,
  [ETerm.Assists]: 999,
  [ETerm.CreepKills]: 9999,
  [ETerm.CreepDenies]: 9999,
  [ETerm.BuildingDamage]: 99999,
};

interface IOptionMainTerm {
  value: EMainTerm;
  label: string;
}

export const PARRY_OPTIONS_MAIN_TERM: IOptionMainTerm[] = [
  {
    value: EMainTerm.Win,
    label: 'parry.form.gameOptions.win',
  },
];

interface IOptionCondition {
  value: ECondition;
  label: string;
}

export const PARRY_OPTIONS_CONDITION = (
  translation: TFunction<'translation', undefined>
): IOptionCondition[] => [
  {
    value: ECondition.MoreOrEqual,
    label: translation('parry.form.gameOptions.moreOrEqual'),
  },
  {
    value: ECondition.Equal,
    label: translation('parry.form.gameOptions.equal'),
  },
  {
    value: ECondition.LessOrEqual,
    label: translation('parry.form.gameOptions.lessOrEqual'),
  },
];

export enum EParryStatus {
  Draft = 'draft',
  Active = 'active',
  Started = 'started',
  Done = 'done',
  Disabled = 'disabled',
}

interface IOptionDistribution {
  value: EDistribution;
  label: string;
}

export const PARRY_OPTIONS_DISTRIBUTION = (
  translation: TFunction<'translation', undefined>
): IOptionDistribution[] => [
  {
    value: EDistribution.FirstToWin,
    label: translation('parry.form.costReward.firstToWin'),
  },
  {
    value: EDistribution.ThreeParticipants,
    label: translation('parry.form.costReward.amongThree'),
  },
  {
    value: EDistribution.EqualReward,
    label: translation('parry.form.costReward.equalReward'),
  },
];

export const PARRY_FORM_DATA_KEY = 'gg-parry-form';

export enum EParryModals {
  Password = 'password',
  EntryCost = 'entryCost',
  Exit = 'exit',
  Stop = 'stop',
}

export enum EViewParryMode {
  Preview = 'preview',
  History = 'history',
}
