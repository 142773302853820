import { About } from './About';
import { ChallengeList } from './ChallengeList';
import { EChallengeViewMode } from './Challenges.model';
import { CHALLENGE_VIEW } from './ChallengeView';
import { CREATE_CHALLENGE } from './CreateChallenge';
import { Workshop } from './Workshop';

export const CHALLENGES_LIST = {
  name: 'challenges',
  routes: [
    {
      title: 'header.tabs.about',
      path: '/challenges/about',
      element: <About />,
    },
    {
      title: 'header.tabs.challengeList',
      path: '/challenges',
      element: <ChallengeList />,
    },
    {
      title: 'header.tabs.myChallenges',
      path: '/challenges/my/:mode',
      params: { mode: EChallengeViewMode.MyActive },
      element: <Workshop />,
    },
  ],
};

export const CHALLENGES = [CREATE_CHALLENGE, CHALLENGES_LIST, CHALLENGE_VIEW];
