import { FC } from 'react';

import { useLocation } from 'react-router-dom';

import { AUTH_ROUTES, ROUTES } from 'pages';

import { Tab } from './Tab';

interface ITabsProps {
  isUserExist: boolean;
}

export const Tabs: FC<ITabsProps> = ({ isUserExist }) => {
  const location = useLocation();
  const pageName = location.pathname.split('/')[1];

  const allRoutes = [...ROUTES, ...AUTH_ROUTES];
  const currentRoute = allRoutes.find((route) => route.name === pageName);

  return (
    <>
      {currentRoute.routes.length > 1 &&
        currentRoute.routes.map((route) => (
          <Tab
            key={`header-tabs-${route.path}`}
            route={route}
            isUserExist={isUserExist}
          />
        ))}
    </>
  );
};
