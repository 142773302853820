import { FC, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { useClickOutside } from 'hooks';
import { NavigationTabs, NavigationTabType, Button } from 'ui-kit';

import { Modal } from '../Modal';
import { useStyle } from './GalleryModal.styles';
import { ImagesTable } from './ImagesTable';

interface IGalleryModalProps {
  isOpen: boolean;
  onClose: () => void;
  setSelectedImage: (string) => void;
}

const galleryTabs: NavigationTabType[] = [
  {
    labelKey: 'Hero',
    id: 0,
  },
  {
    labelKey: 'Dota2',
    id: 1,
  },
  {
    labelKey: 'Memes',
    id: 2,
  },
  {
    labelKey: 'Art',
    id: 3,
  },
];

const INITIAL_IMAGE = '/images/modals/gallery/challenge-1.png';

export const GalleryModal: FC<IGalleryModalProps> = ({
  isOpen,
  onClose,
  setSelectedImage,
}) => {
  const { t: translation } = useTranslation();
  const [src, setSrc] = useState(INITIAL_IMAGE);
  const [activeTab, setActiveTab] = useState(galleryTabs[0].id);
  const ref = useRef(null);
  useClickOutside(ref, onClose);

  const { classes } = useStyle();

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className={classes.modalContainer} ref={ref}>
        <div className={classes.leftBlock}>
          <NavigationTabs
            tabs={galleryTabs}
            onTabClick={setActiveTab}
            selectedTab={activeTab}
          />
          <div>
            <ImagesTable
              activeImage={src}
              tabIndex={activeTab}
              srcSetter={(newSrc: string) => {
                setSrc(newSrc);
              }}
            />
          </div>
        </div>
        <div className={classes.rightBlock}>
          <img className={classes.imagePreview} src={src} alt="cover preview" />
          <div className={classes.btnContainer}>
            <Button onClick={onClose} color="secondary">
              {translation('challenge.gallery.cancelBtn')}
            </Button>
            <Button
              className={classes.selectButton}
              onClick={() => {
                setSelectedImage(src);
                onClose();
              }}
            >
              {translation('challenge.gallery.chooseBtn')}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
