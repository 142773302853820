import { CSSObject } from 'tss-react';

import { makeStyles } from 'theme';

import {
  CardBackgroundType,
  CardBorderRadiusType,
  CardPaddingType,
} from './Card';

export const useStyle = makeStyles<{
  variant: CardPaddingType;
  color: CardBackgroundType;
  border: CardBorderRadiusType;
}>()((theme, { variant, color, border }) => {
  const colorMap: Record<CardBackgroundType, CSSObject> = {
    bgGhost: {
      backgroundColor: theme.palette.bg.ghost,
    },
    bgSecondary: {
      backgroundColor: theme.palette.bg.secondary,
    },
  };

  const borderRadiusMap: Record<CardBorderRadiusType, CSSObject> = {
    br2: {
      borderRadius: 2,
    },
    br4: { borderRadius: 4 },
    br8: { borderRadius: 8 },
    br12: { borderRadius: 12 },
    br16: { borderRadius: 16 },
  };

  const paddingMap: Record<CardPaddingType, CSSObject> = {
    p4x8: {
      padding: '4px 8px',
      whiteSpace: 'nowrap',
    },
    p20: {
      padding: 20,
    },
    p24: {
      padding: 24,
    },
    p2x4: {
      padding: '2px 4px',
    },
    challengeHeader: {
      padding: '32px 32px 32px 206px',
    },
    parryHeader: {
      padding: '32px 32px 32px 206px',
    },
    p8: {
      padding: 8,
    },
    p16: {
      padding: 16,
    },
    p12x16: {
      padding: '12px 16px',
    },
    p16x32: {
      padding: '16px 32px',
    },
    p32: {
      padding: 32,
    },
    p32x16x16x16: {
      padding: '32px 16px 16px 16px',
    },
  };

  return {
    card: {
      ...paddingMap[variant],
      ...colorMap[color],
      ...borderRadiusMap[border],
      ...theme.layouts.flexCenter,
    },
  };
});
