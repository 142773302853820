import { BACKEND_URL } from './env';

interface ITransactionCount {
  count: number;
}

interface IWithdrawDetails {
  amount: number;
  pan: string;
  holder: string;
  year: number;
  month: number;
}

export function getTransactionCount(): Promise<ITransactionCount> {
  return fetch(`${BACKEND_URL}/api/oned3-service/transactions`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  }).then((data) => data.json());
}

export function makeDeposit(amount: number): Promise<string> {
  return fetch(`${BACKEND_URL}/api/oned3-service/deposite`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ amount }),
  }).then((data) => data.text());
}

export function withdrawFiat(details: IWithdrawDetails): Promise<Response> {
  return fetch(`${BACKEND_URL}/api/oned3-service/withdraw`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(details),
  });
}
