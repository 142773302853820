import { FC } from 'react';

import { ICommonIconProps } from './types';

export const CheckSmallIcon: FC<ICommonIconProps> = ({
  color = 'currentColor',
  width = 24,
  height = 24,
  viewBox = '0 0 15 24',
  className = '',
  style,
}) => (
  <svg
    fill="none"
    width={width}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M15.2002 0.359933C15.5537 0.65457 15.6015 1.18004 15.3069 1.5336L6.97352 11.5336C6.82363 11.7135 6.60499 11.822 6.3711 11.8326C6.1372 11.8432 5.90964 11.7549 5.74408 11.5894L0.744078 6.58937C0.418641 6.26394 0.418641 5.7363 0.744078 5.41086C1.06951 5.08542 1.59715 5.08542 1.92259 5.41086L6.27733 9.76561L14.0265 0.466631C14.3211 0.113066 14.8466 0.0652964 15.2002 0.359933Z"
      fill={color}
    />
  </svg>
);
