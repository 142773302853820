import { FC, useContext } from 'react';

import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ICreatedChallenge } from 'api';
import { ModalContext } from 'components';
import {
  dota2ItemsMap,
  EChallengeStatus,
  CHALLENGE_FORM_DATA_KEY,
} from 'consts';
import { calcChallengeDuration, correctAbsoluteUrl } from 'helpers';
import {
  EditIcon,
  FlagOwnerIcon,
  FlagParticipantIcon,
  FlagPasswordIcon,
  TrashIcon,
} from 'icons';
import { Button, Card, Dividers, Typography } from 'ui-kit';

import { useStyle } from './ChallengeCard.styles';
import { ChallengeInfo, ChallengeRules, ChallengeTags } from './components';

interface IChallengeCardProps {
  disabled?: boolean;
  isShowDuration?: boolean;
  challenge: ICreatedChallenge;
}

export const ChallengeCard: FC<IChallengeCardProps> = ({
  challenge,
  isShowDuration,
}) => {
  const {
    image,
    title,
    status,
    id,
    isOwner,
    isParticipant,
    hasPassword,
    iterations,
    cycleRunning,
  } = challenge;
  const isDraft = status === EChallengeStatus.Draft;
  const { classes } = useStyle({
    imageUrl: correctAbsoluteUrl(image),
    isDraft,
  });
  const { Text } = Typography;
  const { isFinishingSoon } = calcChallengeDuration(challenge);
  const isDaily = iterations !== 1 && cycleRunning;
  const navigate = useNavigate();
  const { t: translation } = useTranslation();
  const FinishingBadge: FC<{ isDaily: boolean }> = ({ isDaily }) => (
    <div
      className={clsx(
        classes.finishingBadge,
        isDaily && classes.finishingShift
      )}
    >
      <Text variant="b6">
        {translation('challenges-list.challengeCard.soon')}
      </Text>
    </div>
  );

  const DailyBadge: FC = () => (
    <div className={classes.dailyBadge}>
      <Text variant="b6">
        {translation('challenges-list.challengeCard.daily')}
      </Text>
    </div>
  );

  const {
    deleteModal: { openDelete },
  } = useContext(ModalContext);
  const onCardClick = (): void => {
    if (
      status === EChallengeStatus.Active ||
      status === EChallengeStatus.Disabled
    ) {
      navigate(`/challenges/${id}`);
    }
    if (status === EChallengeStatus.Done) {
      navigate(`/challenges/${id}?mode=history`);
    }
  };

  const onEditClick = (): void => {
    const items = challenge?.items.map((item) => ({
      ...item,
      ...dota2ItemsMap[item.item],
      url: `https://media.steampowered.com${dota2ItemsMap[item.item]?.img}`,
    }));
    localStorage.setItem(
      CHALLENGE_FORM_DATA_KEY,
      JSON.stringify({ ...challenge, items, isFetched: true })
    );
    navigate('/challenge');
  };

  const onDeleteClick = (): void => {
    openDelete(id);
  };

  const { VerticalDivider } = Dividers;
  const withDivider = challenge?.options?.length > 1;

  return (
    <Card
      className={classes.challengeCard}
      color="bgSecondary"
      onClick={onCardClick}
    >
      {isDraft && (
        <div className={classes.draftButtons}>
          <Button onClick={onEditClick} contentType="icon">
            <EditIcon />
          </Button>
          <Button onClick={onDeleteClick} contentType="icon" color="secondary">
            <TrashIcon />
          </Button>
        </div>
      )}
      <div className={classes.badgesContainer}>
        {isFinishingSoon && <FinishingBadge isDaily={isDaily} />}
        {isDaily && <DailyBadge />}
      </div>
      {hasPassword && <FlagPasswordIcon className={classes.flagIcon} />}
      {isParticipant && <FlagParticipantIcon className={classes.flagIcon} />}
      {isOwner && <FlagOwnerIcon className={classes.flagIcon} />}
      <div className={classes.challengeCardContent}>
        <Text variant="b1">{title}</Text>
        <ChallengeInfo challenge={challenge} isShowDuration={isShowDuration} />
        <div className={classes.dataAndTagContainer}>
          <ChallengeRules challenge={challenge} />
          {withDivider && <VerticalDivider />}
          <ChallengeTags challenge={challenge} />
        </div>
      </div>
    </Card>
  );
};
