import type { FC } from 'react';

import type { ICommonIconProps } from './types';

export const SteamIcon: FC<ICommonIconProps> = ({
  color = 'currentColor',
  width = 24,
  height = 24,
  viewBox = '0 0 24 24',
  className = '',
  style,
}) => (
  <svg
    color={color}
    fill="none"
    width={width}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M24 12C24 18.4219 18.6194 23.625 11.9806 23.625C6.47419 23.625 1.83871 20.0484 0.416129 15.1688L5.02258 17.0109C5.33226 18.5156 6.71129 19.6547 8.35645 19.6547C10.2532 19.6547 11.8355 18.1359 11.7532 16.2094L15.8419 13.3875C18.3629 13.4484 20.4774 11.4703 20.4774 9.00469C20.4774 6.58594 18.4452 4.62187 15.9435 4.62187C13.4419 4.62187 11.4097 6.59063 11.4097 9.00469V9.06094L8.54516 13.0781C7.79516 13.0359 7.05968 13.2375 6.44032 13.6453L0 11.0672C0.493548 5.08125 5.66613 0.375 11.9806 0.375C18.6194 0.375 24 5.57812 24 12ZM7.53387 18.0141L6.05806 17.4234C6.32926 17.969 6.79752 18.3993 7.37419 18.6328C8.67581 19.1578 10.171 18.5578 10.7129 17.3016C10.9742 16.6922 10.979 16.0219 10.7177 15.4125C10.4565 14.8031 9.96774 14.325 9.33871 14.0719C8.71452 13.8188 8.04677 13.8281 7.45645 14.0438L8.98065 14.6531C9.93871 15.0375 10.3935 16.1016 9.99193 17.0297C9.59032 17.9625 8.49193 18.3984 7.53387 18.0141ZM15.9435 11.925C14.279 11.925 12.9242 10.6125 12.9242 9.00469C12.9242 7.39688 14.279 6.08438 15.9435 6.08438C17.6081 6.08438 18.9629 7.39688 18.9629 9.00469C18.9629 10.6125 17.6129 11.925 15.9435 11.925ZM15.9484 11.1938C17.2016 11.1938 18.2177 10.2094 18.2177 9C18.2177 7.78594 17.2016 6.80625 15.9484 6.80625C14.6952 6.80625 13.679 7.79063 13.679 9C13.6839 10.2094 14.7 11.1938 15.9484 11.1938Z"
      fill={color}
    />
  </svg>
);
