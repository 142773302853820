import { FC, useContext } from 'react';

import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import { ModalContext } from 'components';
import { Button, Card, Typography } from 'ui-kit';

import { useStyle } from './Header.styles';

export const Header: FC = () => {
  const { classes } = useStyle();
  const { data: balance } = useSWR('api/balance');
  const { Headline, Text } = Typography;
  const {
    manualDeposit: { openManualDeposit },
    withdrawFiat: { openWithdrawFiat },
  } = useContext(ModalContext);
  const { t: translation } = useTranslation();

  return (
    <Card className={classes.headerContainer} variant="p16x32" color="bgGhost">
      <div className={classes.balanceContainer}>
        <Text variant="b4" color="typoLabel" block>
          {translation('profile.header.balance')}
        </Text>
        <Headline variant="h2" block>
          {`${balance?.balance} GC`}
        </Headline>
        <Text variant="b6" color="typoPlaceholder" block>
          {`≈ ${(balance?.balance / 1000).toFixed(1)} USD`}
        </Text>
      </div>
      {/* {canWithdraw ? ( */}
      <Button
        buttonSize="xl"
        color="secondary"
        onClick={openWithdrawFiat}
        disabled={false}
      >
        {translation('profile.header.withdraw')}
      </Button>
      {/* ) : 
        {/* <Tooltip
          content={translation('profile.header.tooltip')}
          position="bottom"
        >
          <Button buttonSize="xl" color="secondary" disabled={true}>
            {translation('profile.header.withdraw')}
          </Button>
        </Tooltip>
      )} */}

      <Button buttonSize="xl" onClick={openManualDeposit}>
        {translation('profile.header.deposit')}
      </Button>
    </Card>
  );
};
