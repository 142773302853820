import { FC } from 'react';

import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import { deactivatePredict, EPredictStatus, IPredictResponse } from 'api';
import { UpdateIcon } from 'icons';
import { Title } from 'ui-kit';

import { useCheckHistory } from '../../../../../hooks';
import { useStyle } from './PredictCard.styles';
import { PredictionActive } from './PredictionActive';
import { PredictionForm } from './PredictionForm';
import { PredictionResult } from './PredictionResult';

export const PREDICT_MULTIPLIER = 1.6;

interface IPredictCardProps {
  balance: number;
  activePredict: IPredictResponse;
  refetchGame: () => void;
  isRefetching: boolean;
}

export const PredictCard: FC<IPredictCardProps> = ({
  balance,
  refetchGame,
  isRefetching,
}) => {
  const { classes } = useStyle();
  const { data: activePredict, mutate } =
    useSWR<IPredictResponse>('api/predict');
  const { data: user } = useSWR('api/user');
  const { isHistoryFailed } = useCheckHistory();
  const isPrelogin = !user || !user?.steamId || isHistoryFailed;

  const resetPredict = async (): Promise<void> => {
    try {
      await deactivatePredict();
      mutate({ status: EPredictStatus.NONE });
    } catch (e) {
      console.error('Failed to deactivate predict');
    }
  };
  const { t: translations } = useTranslation();
  return (
    <>
      <div className={classes.predictTitle}>
        <Title
          className={classes.titleText}
          tooltipText={translations('predictions.predict.tooltip')}
        >
          {translations(
            isPrelogin
              ? `predictions.predict.title.${EPredictStatus.NONE}`
              : `predictions.predict.title.${activePredict?.status}`
          )}
        </Title>
        {activePredict?.status === EPredictStatus.PROGRESS && (
          <button onClick={refetchGame}>
            <UpdateIcon
              className={clsx(
                classes.updateIcon,
                isRefetching && classes.animateSpin
              )}
            />
          </button>
        )}
      </div>

      {(activePredict?.status === EPredictStatus.NONE || isPrelogin) && (
        <PredictionForm balance={balance} />
      )}
      {[EPredictStatus.WIN, EPredictStatus.LOSE].includes(
        activePredict?.status
      ) && <PredictionResult onClick={resetPredict} />}
      {activePredict?.status === EPredictStatus.PROGRESS && (
        <PredictionActive />
      )}
    </>
  );
};
