import { makeStyles } from 'theme';

export const useStyle = makeStyles()((theme) => ({
  dots: {
    marginRight: '0.25rem',
    color: theme.palette.typo.primary,
    opacity: 0.7,
  },
  pageButton: {
    borderRadius: '0.25rem',
    marginRight: 4,
    ':last-of-type': {
      marginRight: 0,
    },
  },
  smallButton: {
    width: 20,
    height: 20,
  },
  bigButton: {
    width: 42,
    height: 42,
  },
  halfOpacity: {
    opacity: 0.5,
  },
  active: {
    backgroundColor: theme.palette.typo.link,
  },
}));
