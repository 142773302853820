import { FC } from 'react';

import { getPaginatorTemplate } from '../utils';
import { PageButton } from './PageButton';
import { useStyle } from './PageControls.styles';

interface IPageControlsProps {
  totalPages: number;
  setCurrentPage: (value: number) => void;
  isMarkedActive: boolean;
  currentPage: number;
}

export const PageControls: FC<IPageControlsProps> = ({
  totalPages,
  setCurrentPage,
  isMarkedActive,
  currentPage,
}: IPageControlsProps) => {
  const buttonsTemplate = getPaginatorTemplate(totalPages, currentPage);
  const { classes } = useStyle();

  return (
    <>
      {buttonsTemplate.map((value) =>
        value === null ? (
          <span className={classes.dots}>...</span>
        ) : (
          <PageButton
            key={value}
            onClick={() => setCurrentPage(value)}
            isMarkedActive={isMarkedActive}
            isActive={currentPage === value}
          >
            {value}
          </PageButton>
        )
      )}
    </>
  );
};
