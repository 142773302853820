import { ICreatedChallenge } from 'api';
import { EDistribution } from 'consts';
import { calcReward, EWinnerPlace, removeZeroesAfterComma } from 'helpers';

export const calcRewardLabel = (challenge: ICreatedChallenge): string => {
  const { distribution, reward, winnerCount } = challenge;
  if (distribution === EDistribution.ThreeParticipants) {
    return `${removeZeroesAfterComma(
      calcReward(distribution, reward, EWinnerPlace.THIRD)
    )}-${removeZeroesAfterComma(
      calcReward(distribution, reward, EWinnerPlace.FIRST)
    )}`;
  }
  return removeZeroesAfterComma(
    calcReward(distribution, reward, EWinnerPlace.FIRST, winnerCount)
  );
};
