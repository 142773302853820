import { FC, useRef, useState } from 'react';

import TagManager from 'react-gtm-module';
import { useTranslation } from 'react-i18next';
import { mutate } from 'swr';

import { isErrorResponse, makePredict } from 'api';
import { useClickOutside } from 'hooks';
import { Title, Button, Typography } from 'ui-kit';

import { Modal } from '../Modal';
import { useStyle } from './ModalPredictionConfirmation.styles';

interface IModalPredictionConfirmationProps {
  confirmationValue: number;
  isOpen: boolean;
  onClose: () => void;
}

export const ModalPredictionConfirmation: FC<
  IModalPredictionConfirmationProps
> = ({ confirmationValue, isOpen, onClose }) => {
  const [isMakingPredict, setIsMakingPredict] = useState(false);
  const ref = useRef(null);

  useClickOutside(ref, onClose);

  const { classes } = useStyle();

  const createPrediction = async (): Promise<void> => {
    try {
      setIsMakingPredict(true);
      const predict = await makePredict(+confirmationValue);
      if (!isErrorResponse(predict)) {
        TagManager.dataLayer({
          dataLayer: {
            event: 'prediction_start',
            coins: `${confirmationValue}`,
          },
        });
        mutate('api/predict', { ...predict, value: predict.value.toFixed(2) });
        onClose();
        mutate('api/balance');
      }
    } catch (err) {
      console.error('Failed to create predict', err);
    } finally {
      setIsMakingPredict(false);
    }
  };
  const { t: translation } = useTranslation();
  const { Text } = Typography;
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className={classes.container} ref={ref}>
        <Title className={classes.title}>
          {translation('predictions.modal.title')}
        </Title>
        <Text className={classes.linkContainer}>
          {translation('predictions.modal.descriptionFirst')}
        </Text>{' '}
        <Text color="link">
          <a
            href="https://dota2.fandom.com/wiki/Matchmaking#Ranked_Match"
            target="_blank"
            rel="noreferrer"
            className="text-blue"
          >
            {translation('predictions.modal.descriptionSecond')}
          </a>
        </Text>
        <br />
        <Text className={classes.linkContainer}>
          {' '}
          {translation('predictions.modal.descriptionThird')}
        </Text>
        <div className={classes.buttonContainer}>
          {!isMakingPredict && (
            <Button
              className={classes.button}
              color="secondary"
              onClick={() => {
                TagManager.dataLayer({
                  dataLayer: {
                    event: 'prediction_close',
                    coins: `${confirmationValue}`,
                  },
                });
                onClose();
              }}
            >
              {translation('predictions.modal.cancelBtn')}
            </Button>
          )}
          <Button onClick={createPrediction}>
            {isMakingPredict
              ? translation('predictions.modal.predictProgress')
              : `${translation(
                  'predictions.modal.predictBtn'
                )} ${confirmationValue} GC`}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
