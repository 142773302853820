import { FC, useContext, useEffect, useState } from 'react';

import clsx from 'clsx';
import { motion } from 'framer-motion';
import TagManager from 'react-gtm-module';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import { ModalContext } from 'components';
import { useCheckHistory } from 'hooks';
import { InputNumber, Button, Typography } from 'ui-kit';

import { PREDICT_OPTIONS } from '../../Prediction.model';
import { useStyle } from './PredictCard.styles';

interface IPredictionFormProps {
  balance: number;
}

interface IOption {
  name: string;
  value: number;
}

export const PredictionForm: FC<IPredictionFormProps> = ({ balance }) => {
  const { Text } = Typography;
  const [error, setError] = useState('');
  const [isSelectionVisible, setIsSelectionVisible] = useState(true);
  const {
    predictionConfirmationModal: {
      predictionValue,
      setPredictionValue,
      openPredictionConfirmation,
    },
    steamModal: { openSteam },
    historyModal: { openHistory },
  } = useContext(ModalContext);
  const { data: user } = useSWR('api/user');
  const { isHistoryFailed } = useCheckHistory();
  const onChangePredict = (value: number): void => {
    setPredictionValue(value);
  };
  const activeIndex = PREDICT_OPTIONS.findIndex(
    (option) => predictionValue === option.value
  );
  const isDisabled = balance < +predictionValue || !!error;
  const { classes } = useStyle();

  useEffect(() => {
    if (+predictionValue > 2500 || +predictionValue < 500) {
      setError(translation('predictions.requirements.main'));
    } else {
      setError('');
    }
  }, [predictionValue]);

  const onInputFocus = (): void => {
    setIsSelectionVisible(false);
  };

  const onPredictionOptionClick = (option: IOption): void => {
    setIsSelectionVisible(true);
    onChangePredict(option.value);
  };

  const onMakePredictionClick = (): void => {
    if (!user?.steamId) {
      openSteam();
      return;
    }
    if (isHistoryFailed) {
      openHistory();
      return;
    }
    openPredictionConfirmation();
    TagManager.dataLayer({ dataLayer: { event: 'prediction_open' } });
  };

  const { t: translation } = useTranslation();

  return (
    <>
      <div className={classes.predictForm}>
        {PREDICT_OPTIONS.map((option, index) => (
          <button
            key={option.name}
            onClick={() => {
              onPredictionOptionClick(option);
            }}
            className={clsx(
              classes.formDefaults,
              predictionValue === option.value && classes.active,
              index === 0 && classes.left,
              index === PREDICT_OPTIONS.length - 1 && classes.right
            )}
          >
            {option.name}
          </button>
        ))}
        {isSelectionVisible && activeIndex !== -1 && (
          <motion.div
            className={classes.selectedDefault}
            initial={{
              x: '100%',
            }}
            animate={{
              x: `${activeIndex === -1 ? 100 : activeIndex * 100}%`,
            }}
            transition={{ type: 'tween', duration: 0.2 }}
          >
            <div className={classes.selectedText}>
              {PREDICT_OPTIONS[activeIndex]?.name}
            </div>
          </motion.div>
        )}
      </div>
      {/* <div className={classes.inputBlock}>
        <InputNumber
          value={+predictionValue}
          onChange={(event) => {
            onChangePredict(+event.target.value);
          }}
          onFocus={onInputFocus}
          className={classes.input}
          max={99999}
          error={error ? translation('predictions.requirements.secondary') : ''}
          inputSize="sm"
        />
      </div> */}
      <Text variant="b5" color="warning">
        {translation('predictions.requirements.warn')}
      </Text>
      <Button
        onClick={onMakePredictionClick}
        className={classes.predictButton}
        disabled={isDisabled}
        buttonSize="l"
      >
        {balance < +predictionValue
          ? translation('predictions.fillUpBtn')
          : translation('predictions.makeBtn')}
      </Button>
    </>
  );
};
