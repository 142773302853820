export interface IItem {
  id: number;
  img: string;
  dname?: string;
  qual?: string;
  cost?: number;
}

export enum EDota2ItemTypes {
  Basic,
  Upgrade,
}

export enum EBasicDota2ItemTypes {
  Consumable,
  Attribute,
  Equipment,
  Misc,
  Secret,
}

export enum EUpgradeDota2ItemTypes {
  Accessories,
  Support,
  Magical,
  Weapons,
  Artifacts,
  Armor,
}
// categorized items is here: https://dota2.fandom.com/wiki/Items
// json with items to format is here: https://github.com/odota/dotaconstants/blob/master/build/items.json
export const dota2Items: Record<
  EDota2ItemTypes,
  | Record<EBasicDota2ItemTypes, IItem[]>
  | Record<EUpgradeDota2ItemTypes, IItem[]>
> = {
  [EDota2ItemTypes.Basic]: {
    [EBasicDota2ItemTypes.Consumable]: [
      {
        id: 38,
        img: '/apps/dota2/images/dota_react/items/clarity.png?t=1593393829403',
        dname: 'Clarity',
        qual: 'consumable',
      },
      {
        id: 237,
        img: '/apps/dota2/images/dota_react/items/faerie_fire.png?t=1593393829403',
        dname: 'Faerie Fire',
        qual: 'consumable',
      },
      {
        id: 188,
        img: '/apps/dota2/images/dota_react/items/smoke_of_deceit.png?t=1593393829403',
        dname: 'Smoke of Deceit',
        qual: 'consumable',
      },
      {
        id: 42,
        img: '/apps/dota2/images/dota_react/items/ward_observer.png?t=1593393829403',
        dname: 'Observer Ward',
        qual: 'consumable',
      },
      {
        id: 43,
        img: '/apps/dota2/images/dota_react/items/ward_sentry.png?t=1593393829403',
        dname: 'Sentry Ward',
        qual: 'consumable',
      },
      {
        id: 216,
        img: '/apps/dota2/images/dota_react/items/enchanted_mango.png?t=1593393829403',
        dname: 'Enchanted Mango',
        qual: 'consumable',
      },
      {
        id: 39,
        img: '/apps/dota2/images/dota_react/items/flask.png?t=1593393829403',
        dname: 'Healing Salve',
        qual: 'consumable',
      },
      {
        id: 44,
        img: '/apps/dota2/images/dota_react/items/tango.png?t=1593393829403',
        dname: 'Tango',
      },
      {
        id: 40,
        img: '/apps/dota2/images/dota_react/items/dust.png?t=1593393829403',
        dname: 'Dust of Appearance',
        qual: 'consumable',
      },
      {
        id: 41,
        img: '/apps/dota2/images/dota_react/items/bottle.png?t=1593393829403',
        dname: 'Bottle',
        qual: 'common',
      },
      {
        id: 609,
        img: '/apps/dota2/images/dota_react/items/aghanims_shard.png?t=1593393829403',
        dname: "Aghanim's Shard",
        qual: 'rare',
      },
      {
        id: 1123,
        img: '/apps/dota2/images/dota_react/items/blood_grenade.png?t=1593393829403',
        dname: 'Blood Grenade',
        qual: 'consumable',
      },
      {
        id: 46,
        img: '/apps/dota2/images/dota_react/items/tpscroll.png?t=1593393829403',
        dname: 'Town Portal Scroll',
        qual: 'consumable',
      },
    ],
    [EBasicDota2ItemTypes.Attribute]: [
      {
        id: 16,
        img: '/apps/dota2/images/dota_react/items/branches.png?t=1593393829403',
        dname: 'Iron Branch',
        qual: 'consumable',
      },
      {
        id: 13,
        img: '/apps/dota2/images/dota_react/items/gauntlets.png?t=1593393829403',
        dname: 'Gauntlets of Strength',
        qual: 'component',
      },
      {
        id: 14,
        img: '/apps/dota2/images/dota_react/items/slippers.png?t=1593393829403',
        dname: 'Slippers of Agility',
        qual: 'component',
      },
      {
        id: 15,
        img: '/apps/dota2/images/dota_react/items/mantle.png?t=1593393829403',
        dname: 'Mantle of Intelligence',
        qual: 'component',
      },
      {
        id: 20,
        img: '/apps/dota2/images/dota_react/items/circlet.png?t=1593393829403',
        dname: 'Circlet',
        qual: 'component',
      },
      {
        id: 17,
        img: '/apps/dota2/images/dota_react/items/belt_of_strength.png?t=1593393829403',
        dname: 'Belt of Strength',
        qual: 'component',
      },
      {
        id: 18,
        img: '/apps/dota2/images/dota_react/items/boots_of_elves.png?t=1593393829403',
        dname: 'Band of Elvenskin',
        qual: 'component',
      },
      {
        id: 19,
        img: '/apps/dota2/images/dota_react/items/robe.png?t=1593393829403',
        dname: 'Robe of the Magi',
        qual: 'component',
      },
      {
        id: 261,
        img: '/apps/dota2/images/dota_react/items/crown.png?t=1593393829403',
        dname: 'Crown',
        qual: 'component',
      },
      {
        id: 21,
        img: '/apps/dota2/images/dota_react/items/ogre_axe.png?t=1593393829403',
        dname: 'Ogre Axe',
        qual: 'component',
      },
      {
        id: 22,
        img: '/apps/dota2/images/dota_react/items/blade_of_alacrity.png?t=1593393829403',
        dname: 'Blade of Alacrity',
        qual: 'component',
      },
      {
        id: 23,
        img: '/apps/dota2/images/dota_react/items/staff_of_wizardry.png?t=1593393829403',
        dname: 'Staff of Wizardry',
        qual: 'component',
      },
      {
        id: 1122,
        img: '/apps/dota2/images/dota_react/items/diadem.png?t=1593393829403',
        dname: 'Diadem',
        qual: 'component',
      },
    ],
    [EBasicDota2ItemTypes.Equipment]: [
      {
        id: 11,
        img: '/apps/dota2/images/dota_react/items/quelling_blade.png?t=1593393829403',
        dname: 'Quelling Blade',
        qual: 'component',
      },
      {
        id: 12,
        img: '/apps/dota2/images/dota_react/items/ring_of_protection.png?t=1593393829403',
        dname: 'Ring of Protection',
        qual: 'component',
      },
      {
        id: 265,
        img: '/apps/dota2/images/dota_react/items/infused_raindrop.png?t=1593393829403',
        dname: 'Infused Raindrops',
        qual: 'component',
      },
      {
        id: 181,
        img: '/apps/dota2/images/dota_react/items/orb_of_venom.png?t=1593393829403',
        dname: 'Orb of Venom',
        qual: 'component',
      },
      {
        id: 240,
        img: '/apps/dota2/images/dota_react/items/blight_stone.png?t=1593393829403',
        dname: 'Blight Stone',
        qual: 'component',
      },
      {
        id: 2,
        img: '/apps/dota2/images/dota_react/items/blades_of_attack.png?t=1593393829403',
        dname: 'Blades of Attack',
        qual: 'component',
      },
      {
        id: 25,
        img: '/apps/dota2/images/dota_react/items/gloves.png?t=1593393829403',
        dname: 'Gloves of Haste',
        qual: 'component',
      },
      {
        id: 4,
        img: '/apps/dota2/images/dota_react/items/chainmail.png?t=1593393829403',
        dname: 'Chainmail',
        qual: 'component',
      },
      {
        id: 6,
        img: '/apps/dota2/images/dota_react/items/helm_of_iron_will.png?t=1593393829403',
        dname: 'Helm of Iron Will',
        qual: 'component',
      },
      {
        id: 3,
        img: '/apps/dota2/images/dota_react/items/broadsword.png?t=1593393829403',
        dname: 'Broadsword',
        qual: 'component',
      },
      {
        id: 485,
        img: '/apps/dota2/images/dota_react/items/blitz_knuckles.png?t=1593393829403',
        dname: 'Blitz Knuckles',
        qual: 'component',
      },
      {
        id: 7,
        img: '/apps/dota2/images/dota_react/items/javelin.png?t=1593393829403',
        dname: 'Javelin',
        qual: 'component',
      },
      {
        id: 5,
        img: '/apps/dota2/images/dota_react/items/claymore.png?t=1593393829403',
        dname: 'Claymore',
        qual: 'component',
      },
      {
        id: 8,
        img: '/apps/dota2/images/dota_react/items/mithril_hammer.png?t=1593393829403',
        dname: 'Mithril Hammer',
        qual: 'component',
      },
    ],
    [EBasicDota2ItemTypes.Misc]: [
      {
        id: 1,
        img: '/apps/dota2/images/dota_react/items/blink.png?t=1593393829403',
        dname: 'Blink Dagger',
        qual: 'component',
      },
      {
        id: 244,
        img: '/apps/dota2/images/dota_react/items/wind_lace.png?t=1593393829403',
        dname: 'Wind Lace',
        qual: 'component',
      },
      {
        id: 26,
        img: '/apps/dota2/images/dota_react/items/lifesteal.png?t=1593393829403',
        dname: 'Morbid Mask',
        qual: 'component',
      },
      {
        id: 473,
        img: '/apps/dota2/images/dota_react/items/voodoo_mask.png?t=1593393829403',
        dname: 'Voodoo Mask',
        qual: 'component',
      },
      {
        id: 27,
        img: '/apps/dota2/images/dota_react/items/ring_of_regen.png?t=1593393829403',
        dname: 'Ring of Regen',
        qual: 'component',
      },
      {
        id: 28,
        img: '/apps/dota2/images/dota_react/items/sobi_mask.png?t=1593393829403',
        dname: "Sage's Mask",
        qual: 'component',
      },
      {
        id: 29,
        img: '/apps/dota2/images/dota_react/items/boots.png?t=1593393829403',
        dname: 'Boots of Speed',
        qual: 'component',
      },
      {
        id: 30,
        img: '/apps/dota2/images/dota_react/items/gem.png?t=1593393829403',
        dname: 'Gem of True Sight',
        qual: 'component',
      },
      {
        id: 31,
        img: '/apps/dota2/images/dota_react/items/cloak.png?t=1593393829403',
        dname: 'Cloak',
        qual: 'component',
      },
      {
        id: 34,
        img: '/apps/dota2/images/dota_react/items/magic_stick.png?t=1593393829403',
        dname: 'Magic Stick',
        qual: 'component',
      },
      {
        id: 37,
        img: '/apps/dota2/images/dota_react/items/ghost.png?t=1593393829403',
        dname: 'Ghost Scepter',
        qual: 'component',
      },
      {
        id: 56,
        img: '/apps/dota2/images/dota_react/items/ring_of_health.png?t=1593393829403',
        dname: 'Ring of Health',
        qual: 'component',
      },
      {
        id: 279,
        img: '/apps/dota2/images/dota_react/items/ring_of_tarrasque.png?t=1593393829403',
        dname: 'Ring of Tarrasque',
        qual: 'component',
      },
      {
        id: 1802,
        img: '/apps/dota2/images/dota_react/items/tiara_of_selemene.png?t=1593393829403',
        dname: 'Tiara of Selemene',
        qual: 'component',
      },
      {
        id: 593,
        img: '/apps/dota2/images/dota_react/items/fluffy_hat.png?t=1593393829403',
        dname: 'Fluffy Hat',
        qual: 'secret_shop',
      },
      {
        id: 215,
        img: '/apps/dota2/images/dota_react/items/shadow_amulet.png?t=1593393829403',
        dname: 'Shadow Amulet',
      },
    ],
    [EBasicDota2ItemTypes.Secret]: [
      {
        id: 9,
        img: '/apps/dota2/images/dota_react/items/platemail.png?t=1593393829403',
        dname: 'Platemail',
        qual: 'secret_shop',
      },
      {
        id: 24,
        img: '/apps/dota2/images/dota_react/items/ultimate_orb.png?t=1593393829403',
        dname: 'Ultimate Orb',
        qual: 'secret_shop',
      },
      {
        id: 32,
        img: '/apps/dota2/images/dota_react/items/talisman_of_evasion.png?t=1593393829403',
        dname: 'Talisman of Evasion',
        qual: 'secret_shop',
      },
      {
        id: 51,
        img: '/apps/dota2/images/dota_react/items/demon_edge.png?t=1593393829403',
        dname: 'Demon Edge',
        qual: 'secret_shop',
      },
      {
        id: 52,
        img: '/apps/dota2/images/dota_react/items/eagle.png?t=1593393829403',
        dname: 'Eaglesong',
        qual: 'secret_shop',
      },
      {
        id: 53,
        img: '/apps/dota2/images/dota_react/items/reaver.png?t=1593393829403',
        dname: 'Reaver',
        qual: 'secret_shop',
      },
      {
        id: 54,
        img: '/apps/dota2/images/dota_react/items/relic.png?t=1593393829403',
        dname: 'Sacred Relic',
        qual: 'secret_shop',
      },
      {
        id: 55,
        img: '/apps/dota2/images/dota_react/items/hyperstone.png?t=1593393829403',
        dname: 'Hyperstone',
        qual: 'secret_shop',
      },
      {
        id: 58,
        img: '/apps/dota2/images/dota_react/items/mystic_staff.png?t=1593393829403',
        dname: 'Mystic Staff',
        qual: 'secret_shop',
      },
      {
        id: 59,
        img: '/apps/dota2/images/dota_react/items/energy_booster.png?t=1593393829403',
        dname: 'Energy Booster',
        qual: 'secret_shop',
      },
      {
        id: 60,
        img: '/apps/dota2/images/dota_react/items/point_booster.png?t=1593393829403',
        dname: 'Point Booster',
        qual: 'secret_shop',
      },
      {
        id: 61,
        img: '/apps/dota2/images/dota_react/items/vitality_booster.png?t=1593393829403',
        dname: 'Vitality Booster',
        qual: 'secret_shop',
      },
      {
        id: 1125,
        img: '/apps/dota2/images/dota_react/items/cornucopia.png?t=1593393829403',
        dname: 'Cornucopia',
        qual: 'common',
      },
    ],
  },
  [EDota2ItemTypes.Upgrade]: {
    [EUpgradeDota2ItemTypes.Accessories]: [
      {
        id: 247,
        img: '/apps/dota2/images/dota_react/items/moon_shard.png?t=1593393829403',
        dname: 'Moon Shard',
        qual: 'consumable',
      },
      {
        id: 36,
        img: '/apps/dota2/images/dota_react/items/magic_wand.png?t=1593393829403',
        dname: 'Magic Wand',
        qual: 'common',
      },
      {
        id: 48,
        img: '/apps/dota2/images/dota_react/items/travel_boots.png?t=1593393829403',
        dname: 'Boots of Travel',
        qual: 'common',
      },
      {
        id: 50,
        img: '/apps/dota2/images/dota_react/items/phase_boots.png?t=1593393829403',
        dname: 'Phase Boots',
        qual: 'common',
      },
      {
        id: 63,
        img: '/apps/dota2/images/dota_react/items/power_treads.png?t=1593393829403',
        dname: 'Power Treads',
        qual: 'common',
      },
      {
        id: 65,
        img: '/apps/dota2/images/dota_react/items/hand_of_midas.png?t=1593393829403',
        dname: 'Hand of Midas',
        qual: 'common',
      },
      {
        id: 67,
        img: '/apps/dota2/images/dota_react/items/oblivion_staff.png?t=1593393829403',
        dname: 'Oblivion Staff',
        qual: 'common',
      },
      {
        id: 69,
        img: '/apps/dota2/images/dota_react/items/pers.png?t=1593393829403',
        dname: 'Perseverance',
        qual: 'common',
      },
      {
        id: 73,
        img: '/apps/dota2/images/dota_react/items/bracer.png?t=1593393829403',
        dname: 'Bracer',
        qual: 'common',
      },
      {
        id: 75,
        img: '/apps/dota2/images/dota_react/items/wraith_band.png?t=1593393829403',
        dname: 'Wraith Band',
        qual: 'common',
      },
      {
        id: 77,
        img: '/apps/dota2/images/dota_react/items/null_talisman.png?t=1593393829403',
        dname: 'Null Talisman',
        qual: 'common',
      },
      {
        id: 164,
        img: '/apps/dota2/images/dota_react/items/helm_of_the_dominator.png?t=1593393829403',
        dname: 'Helm of the Dominator',
        qual: 'artifact',
      },
      {
        id: 635,
        img: '/apps/dota2/images/dota_react/items/helm_of_the_overlord.png?t=1593393829403',
        dname: 'Helm of the Overlord',
        qual: 'artifact',
      },
      {
        id: 172,
        img: '/apps/dota2/images/dota_react/items/mask_of_madness.png?t=1593393829403',
        dname: 'Mask of Madness',
        qual: 'artifact',
      },
      {
        id: 178,
        img: '/apps/dota2/images/dota_react/items/soul_ring.png?t=1593393829403',
        dname: 'Soul Ring',
        qual: 'common',
      },
      {
        id: 569,
        img: '/apps/dota2/images/dota_react/items/orb_of_corrosion.png?t=1593393829403',
        dname: 'Orb of Corrosion',
        qual: 'rare',
      },
      {
        id: 596,
        img: '/apps/dota2/images/dota_react/items/falcon_blade.png?t=1593393829403',
        dname: 'Falcon Blade',
        qual: 'rare',
      },
    ],
    [EUpgradeDota2ItemTypes.Support]: [
      {
        id: 79,
        img: '/apps/dota2/images/dota_react/items/mekansm.png?t=1593393829403',
        dname: 'Mekansm',
        qual: 'rare',
      },
      {
        id: 81,
        img: '/apps/dota2/images/dota_react/items/vladmir.png?t=1593393829403',
        dname: "Vladmir's Offering",
        qual: 'rare',
      },
      {
        id: 86,
        img: '/apps/dota2/images/dota_react/items/buckler.png?t=1593393829403',
        dname: 'Buckler',
        qual: 'rare',
      },
      {
        id: 88,
        img: '/apps/dota2/images/dota_react/items/ring_of_basilius.png?t=1593393829403',
        dname: 'Ring of Basilius',
        qual: 'rare',
      },
      {
        id: 269,
        img: '/apps/dota2/images/dota_react/items/holy_locket.png?t=1593393829403',
        dname: 'Holy Locket',
        qual: 'rare',
      },
      {
        id: 90,
        img: '/apps/dota2/images/dota_react/items/pipe.png?t=1593393829403',
        dname: 'Pipe of Insight',
        qual: 'rare',
      },
      {
        id: 92,
        img: '/apps/dota2/images/dota_react/items/urn_of_shadows.png?t=1593393829403',
        dname: 'Urn of Shadows',
        qual: 'rare',
      },
      {
        id: 94,
        img: '/apps/dota2/images/dota_react/items/headdress.png?t=1593393829403',
        dname: 'Headdress',
        qual: 'rare',
      },
      {
        id: 267,
        img: '/apps/dota2/images/dota_react/items/spirit_vessel.png?t=1593393829403',
        dname: 'Spirit Vessel',
        qual: 'rare',
      },
      {
        id: 180,
        img: '/apps/dota2/images/dota_react/items/arcane_boots.png?t=1593393829403',
        dname: 'Arcane Boots',
        qual: 'rare',
      },
      {
        id: 185,
        img: '/apps/dota2/images/dota_react/items/ancient_janggo.png?t=1593393829403',
        dname: 'Drum of Endurance',
        qual: 'rare',
      },
      {
        id: 931,
        img: '/apps/dota2/images/dota_react/items/boots_of_bearing.png?t=1593393829403',
        dname: 'Boots of Bearing',
        qual: 'rare',
      },
      {
        id: 1128,
        img: '/apps/dota2/images/dota_react/items/pavise.png?t=1593393829403',
        dname: 'Pavise',
        qual: 'rare',
      },
      {
        id: 1806,
        img: '/apps/dota2/images/dota_react/items/devastator.png?t=1593393829403',
        dname: 'Parasma',
        qual: 'rare',
      },
      {
        id: 231,
        img: '/apps/dota2/images/dota_react/items/guardian_greaves.png?t=1593393829403',
        dname: 'Guardian Greaves',
        qual: 'rare',
      },
      {
        id: 214,
        img: '/apps/dota2/images/dota_react/items/tranquil_boots.png?t=1593393829403',
        dname: 'Tranquil Boots',
        qual: 'rare',
      },
    ],
    [EUpgradeDota2ItemTypes.Magical]: [
      {
        id: 534,
        img: '/apps/dota2/images/dota_react/items/witch_blade.png?t=1593393829403',
        dname: 'Witch Blade',
        qual: 'common',
      },
      {
        id: 96,
        img: '/apps/dota2/images/dota_react/items/sheepstick.png?t=1593393829403',
        dname: 'Scythe of Vyse',
        qual: 'rare',
      },
      {
        id: 98,
        img: '/apps/dota2/images/dota_react/items/orchid.png?t=1593393829403',
        dname: 'Orchid Malevolence',
        qual: 'rare',
      },
      {
        id: 100,
        img: '/apps/dota2/images/dota_react/items/cyclone.png?t=1593393829403',
        dname: "Eul's Scepter of Divinity",
        qual: 'rare',
      },
      {
        id: 610,
        img: '/apps/dota2/images/dota_react/items/wind_waker.png?t=1593393829403',
        dname: 'Wind Waker',
        qual: 'rare',
      },
      {
        id: 232,
        img: '/apps/dota2/images/dota_react/items/aether_lens.png?t=1593393829403',
        dname: 'Aether Lens',
        qual: 'rare',
      },
      {
        id: 102,
        img: '/apps/dota2/images/dota_react/items/force_staff.png?t=1593393829403',
        dname: 'Force Staff',
        qual: 'rare',
      },
      {
        id: 204,
        img: '/apps/dota2/images/dota_react/items/dagon_5.png?t=1593393829403',
        dname: 'Dagon',
        qual: 'rare',
      },
      {
        id: 108,
        img: '/apps/dota2/images/dota_react/items/ultimate_scepter.png?t=1593393829403',
        dname: "Aghanim's Scepter",
        qual: 'rare',
      },
      {
        id: 271,
        img: '/apps/dota2/images/dota_react/items/ultimate_scepter_2.png?t=1593393829403',
        dname: "Aghanim's Blessing",
        qual: 'rare',
      },
      {
        id: 110,
        img: '/apps/dota2/images/dota_react/items/refresher.png?t=1593393829403',
        dname: 'Refresher Orb',
        qual: 'rare',
      },
      {
        id: 1466,
        img: '/apps/dota2/images/dota_react/items/gungir.png?t=1593393829403',
        dname: 'Gleipnir',
        qual: 'artifact',
      },
      {
        id: 235,
        img: '/apps/dota2/images/dota_react/items/octarine_core.png?t=1593393829403',
        dname: 'Octarine Core',
        qual: 'rare',
      },
      {
        id: 229,
        img: '/apps/dota2/images/dota_react/items/solar_crest.png?t=1593393829403',
        dname: 'Solar Crest',
        qual: 'rare',
      },
      {
        id: 190,
        img: '/apps/dota2/images/dota_react/items/veil_of_discord.png?t=1593393829403',
        dname: 'Veil of Discord',
        qual: 'rare',
      },
      {
        id: 206,
        img: '/apps/dota2/images/dota_react/items/rod_of_atos.png?t=1593393829403',
        dname: 'Rod of Atos',
        qual: 'rare',
      },
      {
        id: 254,
        img: '/apps/dota2/images/dota_react/items/glimmer_cape.png?t=1593393829403',
        dname: 'Glimmer Cape',
        qual: 'rare',
      },
    ],
    [EUpgradeDota2ItemTypes.Armor]: [
      {
        id: 263,
        img: '/apps/dota2/images/dota_react/items/hurricane_pike.png?t=1593393829403',
        dname: 'Hurricane Pike',
        qual: 'epic',
      },
      {
        id: 112,
        img: '/apps/dota2/images/dota_react/items/assault.png?t=1593393829403',
        dname: 'Assault Cuirass',
        qual: 'epic',
      },
      {
        id: 114,
        img: '/apps/dota2/images/dota_react/items/heart.png?t=1593393829403',
        dname: 'Heart of Tarrasque',
        qual: 'epic',
      },
      {
        id: 116,
        img: '/apps/dota2/images/dota_react/items/black_king_bar.png?t=1593393829403',
        dname: 'Black King Bar',
        qual: 'epic',
      },
      {
        id: 119,
        img: '/apps/dota2/images/dota_react/items/shivas_guard.png?t=1593393829403',
        dname: "Shiva's Guard",
        qual: 'epic',
      },
      {
        id: 121,
        img: '/apps/dota2/images/dota_react/items/bloodstone.png?t=1593393829403',
        dname: 'Bloodstone',
        qual: 'epic',
      },
      {
        id: 123,
        img: '/apps/dota2/images/dota_react/items/sphere.png?t=1593393829403',
        dname: "Linken's Sphere",
        qual: 'epic',
      },
      {
        id: 226,
        img: '/apps/dota2/images/dota_react/items/lotus_orb.png?t=1593393829403',
        dname: 'Lotus Orb',
        qual: 'epic',
      },
      {
        id: 256,
        img: '/apps/dota2/images/dota_react/items/aeon_disk.png?t=1593393829403',
        dname: 'Aeon Disk',
        qual: 'epic',
      },
      {
        id: 125,
        img: '/apps/dota2/images/dota_react/items/vanguard.png?t=1593393829403',
        dname: 'Vanguard',
        qual: 'epic',
      },
      {
        id: 242,
        img: '/apps/dota2/images/dota_react/items/crimson_guard.png?t=1593393829403',
        dname: 'Crimson Guard',
        qual: 'epic',
      },
      {
        id: 127,
        img: '/apps/dota2/images/dota_react/items/blade_mail.png?t=1593393829403',
        dname: 'Blade Mail',
        qual: 'epic',
      },
      {
        id: 129,
        img: '/apps/dota2/images/dota_react/items/soul_booster.png?t=1593393829403',
        dname: 'Soul Booster',
        qual: 'epic',
      },
      {
        id: 692,
        img: '/apps/dota2/images/dota_react/items/eternal_shroud.png?t=1593393829403',
        dname: 'Eternal Shroud',
        qual: 'epic',
      },
      {
        id: 147,
        img: '/apps/dota2/images/dota_react/items/manta.png?t=1593393829403',
        dname: 'Manta Style',
        qual: 'epic',
      },
    ],
    [EUpgradeDota2ItemTypes.Weapons]: [
      {
        id: 1808,
        img: '/apps/dota2/images/dota_react/items/angels_demise.png?t=1593393829403',
        dname: 'Khanda',
        qual: 'common',
      },
      {
        id: 250,
        img: '/apps/dota2/images/dota_react/items/bloodthorn.png?t=1593393829403',
        dname: 'Bloodthorn',
        qual: 'epic',
      },
      {
        id: 223,
        img: '/apps/dota2/images/dota_react/items/meteor_hammer.png?t=1593393829403',
        dname: 'Meteor Hammer',
        qual: 'epic',
      },
      {
        id: 225,
        img: '/apps/dota2/images/dota_react/items/nullifier.png?t=1593393829403',
        dname: 'Nullifier',
        qual: 'epic',
      },
      {
        id: 133,
        img: '/apps/dota2/images/dota_react/items/rapier.png?t=1593393829403',
        dname: 'Divine Rapier',
        qual: 'epic',
      },
      {
        id: 135,
        img: '/apps/dota2/images/dota_react/items/monkey_king_bar.png?t=1593393829403',
        dname: 'Monkey King Bar',
        qual: 'epic',
      },
      {
        id: 137,
        img: '/apps/dota2/images/dota_react/items/radiance.png?t=1593393829403',
        dname: 'Radiance',
        qual: 'epic',
      },
      {
        id: 139,
        img: '/apps/dota2/images/dota_react/items/butterfly.png?t=1593393829403',
        dname: 'Butterfly',
        qual: 'epic',
      },
      {
        id: 141,
        img: '/apps/dota2/images/dota_react/items/greater_crit.png?t=1593393829403',
        dname: 'Daedalus',
        qual: 'epic',
      },
      {
        id: 143,
        img: '/apps/dota2/images/dota_react/items/basher.png?t=1593393829403',
        dname: 'Skull Basher',
        qual: 'epic',
      },
      {
        id: 145,
        img: '/apps/dota2/images/dota_react/items/bfury.png?t=1593393829403',
        dname: 'Battle Fury',
        qual: 'epic',
      },
      {
        id: 149,
        img: '/apps/dota2/images/dota_react/items/lesser_crit.png?t=1593393829403',
        dname: 'Crystalys',
        qual: 'epic',
      },
      {
        id: 151,
        img: '/apps/dota2/images/dota_react/items/armlet.png?t=1593393829403',
        dname: 'Armlet of Mordiggian',
        qual: 'epic',
      },
      {
        id: 152,
        img: '/apps/dota2/images/dota_react/items/invis_sword.png?t=1593393829403',
        dname: 'Shadow Blade',
        qual: 'epic',
      },
      {
        id: 249,
        img: '/apps/dota2/images/dota_react/items/silver_edge.png?t=1593393829403',
        dname: 'Silver Edge',
        qual: 'epic',
      },
      {
        id: 168,
        img: '/apps/dota2/images/dota_react/items/desolator.png?t=1593393829403',
        dname: 'Desolator',
        qual: 'artifact',
      },
      {
        id: 1097,
        img: '/apps/dota2/images/dota_react/items/disperser.png?t=1593393829403',
        dname: 'Disperser',
        qual: 'artifact',
      },
      {
        id: 176,
        img: '/apps/dota2/images/dota_react/items/ethereal_blade.png?t=1593393829403',
        dname: 'Ethereal Blade',
        qual: 'epic',
      },
      {
        id: 911,
        img: '/apps/dota2/images/dota_react/items/revenants_brooch.png?t=1593393829403',
        dname: "Revenant's Brooch",
        qual: 'rare',
      },
      {
        id: 208,
        img: '/apps/dota2/images/dota_react/items/abyssal_blade.png?t=1593393829403',
        dname: 'Abyssal Blade',
        qual: 'epic',
      },
    ],
    [EUpgradeDota2ItemTypes.Artifacts]: [
      {
        id: 600,
        img: '/apps/dota2/images/dota_react/items/overwhelming_blink.png?t=1593393829403',
        dname: 'Overwhelming Blink',
        qual: 'component',
      },
      {
        id: 603,
        img: '/apps/dota2/images/dota_react/items/swift_blink.png?t=1593393829403',
        dname: 'Swift Blink',
        qual: 'component',
      },
      {
        id: 604,
        img: '/apps/dota2/images/dota_react/items/arcane_blink.png?t=1593393829403',
        dname: 'Arcane Blink',
        qual: 'component',
      },
      {
        id: 1107,
        img: '/apps/dota2/images/dota_react/items/phylactery.png?t=1593393829403',
        dname: 'Phylactery',
        qual: 'common',
      },
      {
        id: 252,
        img: '/apps/dota2/images/dota_react/items/echo_sabre.png?t=1593393829403',
        dname: 'Echo Sabre',
        qual: 'artifact',
      },
      {
        id: 259,
        img: '/apps/dota2/images/dota_react/items/kaya.png?t=1593393829403',
        dname: 'Kaya',
        qual: 'epic',
      },
      {
        id: 236,
        img: '/apps/dota2/images/dota_react/items/dragon_lance.png?t=1593393829403',
        dname: 'Dragon Lance',
        qual: 'artifact',
      },
      {
        id: 154,
        img: '/apps/dota2/images/dota_react/items/sange_and_yasha.png?t=1593393829403',
        dname: 'Sange and Yasha',
        qual: 'artifact',
      },
      {
        id: 273,
        img: '/apps/dota2/images/dota_react/items/kaya_and_sange.png?t=1593393829403',
        dname: 'Kaya and Sange',
        qual: 'artifact',
      },
      {
        id: 277,
        img: '/apps/dota2/images/dota_react/items/yasha_and_kaya.png?t=1593393829403',
        dname: 'Yasha and Kaya',
        qual: 'artifact',
      },
      {
        id: 156,
        img: '/apps/dota2/images/dota_react/items/satanic.png?t=1593393829403',
        dname: 'Satanic',
        qual: 'artifact',
      },
      {
        id: 158,
        img: '/apps/dota2/images/dota_react/items/mjollnir.png?t=1593393829403',
        dname: 'Mjollnir',
        qual: 'artifact',
      },
      {
        id: 160,
        img: '/apps/dota2/images/dota_react/items/skadi.png?t=1593393829403',
        dname: 'Eye of Skadi',
        qual: 'artifact',
      },
      {
        id: 162,
        img: '/apps/dota2/images/dota_react/items/sange.png?t=1593393829403',
        dname: 'Sange',
        qual: 'artifact',
      },
      {
        id: 166,
        img: '/apps/dota2/images/dota_react/items/maelstrom.png?t=1593393829403',
        dname: 'Maelstrom',
        qual: 'artifact',
      },
      {
        id: 170,
        img: '/apps/dota2/images/dota_react/items/yasha.png?t=1593393829403',
        dname: 'Yasha',
        qual: 'artifact',
      },
      {
        id: 174,
        img: '/apps/dota2/images/dota_react/items/diffusal_blade.png?t=1593393829403',
        dname: 'Diffusal Blade',
        qual: 'artifact',
      },
      {
        id: 598,
        img: '/apps/dota2/images/dota_react/items/mage_slayer.png?t=1593393829403',
        dname: 'Mage Slayer',
        qual: 'rare',
      },
      {
        id: 210,
        img: '/apps/dota2/images/dota_react/items/heavens_halberd.png?t=1593393829403',
        dname: "Heaven's Halberd",
        qual: 'artifact',
      },
      {
        id: 939,
        img: '/apps/dota2/images/dota_react/items/harpoon.png?t=1593393829403',
        dname: 'Harpoon',
      },
    ],
  },
};

export const dota2ItemsMap = {
  '1': {
    id: 1,
    img: '/apps/dota2/images/dota_react/items/blink.png?t=1593393829403',
    dname: 'Blink Dagger',
    qual: 'component',
  },
  '2': {
    id: 2,
    img: '/apps/dota2/images/dota_react/items/blades_of_attack.png?t=1593393829403',
    dname: 'Blades of Attack',
    qual: 'component',
  },
  '3': {
    id: 3,
    img: '/apps/dota2/images/dota_react/items/broadsword.png?t=1593393829403',
    dname: 'Broadsword',
    qual: 'component',
  },
  '4': {
    id: 4,
    img: '/apps/dota2/images/dota_react/items/chainmail.png?t=1593393829403',
    dname: 'Chainmail',
    qual: 'component',
  },
  '5': {
    id: 5,
    img: '/apps/dota2/images/dota_react/items/claymore.png?t=1593393829403',
    dname: 'Claymore',
    qual: 'component',
  },
  '6': {
    id: 6,
    img: '/apps/dota2/images/dota_react/items/helm_of_iron_will.png?t=1593393829403',
    dname: 'Helm of Iron Will',
    qual: 'component',
  },
  '7': {
    id: 7,
    img: '/apps/dota2/images/dota_react/items/javelin.png?t=1593393829403',
    dname: 'Javelin',
    qual: 'component',
  },
  '8': {
    id: 8,
    img: '/apps/dota2/images/dota_react/items/mithril_hammer.png?t=1593393829403',
    dname: 'Mithril Hammer',
    qual: 'component',
  },
  '9': {
    id: 9,
    img: '/apps/dota2/images/dota_react/items/platemail.png?t=1593393829403',
    dname: 'Platemail',
    qual: 'secret_shop',
  },
  '10': {
    id: 10,
    img: '/apps/dota2/images/dota_react/items/quarterstaff.png?t=1593393829403',
    dname: 'Quarterstaff',
    qual: 'component',
  },
  '11': {
    id: 11,
    img: '/apps/dota2/images/dota_react/items/quelling_blade.png?t=1593393829403',
    dname: 'Quelling Blade',
    qual: 'component',
  },
  '12': {
    id: 12,
    img: '/apps/dota2/images/dota_react/items/ring_of_protection.png?t=1593393829403',
    dname: 'Ring of Protection',
    qual: 'component',
  },
  '13': {
    id: 13,
    img: '/apps/dota2/images/dota_react/items/gauntlets.png?t=1593393829403',
    dname: 'Gauntlets of Strength',
    qual: 'component',
  },
  '14': {
    id: 14,
    img: '/apps/dota2/images/dota_react/items/slippers.png?t=1593393829403',
    dname: 'Slippers of Agility',
    qual: 'component',
  },
  '15': {
    id: 15,
    img: '/apps/dota2/images/dota_react/items/mantle.png?t=1593393829403',
    dname: 'Mantle of Intelligence',
    qual: 'component',
  },
  '16': {
    id: 16,
    img: '/apps/dota2/images/dota_react/items/branches.png?t=1593393829403',
    dname: 'Iron Branch',
    qual: 'consumable',
  },
  '17': {
    id: 17,
    img: '/apps/dota2/images/dota_react/items/belt_of_strength.png?t=1593393829403',
    dname: 'Belt of Strength',
    qual: 'component',
  },
  '18': {
    id: 18,
    img: '/apps/dota2/images/dota_react/items/boots_of_elves.png?t=1593393829403',
    dname: 'Band of Elvenskin',
    qual: 'component',
  },
  '19': {
    id: 19,
    img: '/apps/dota2/images/dota_react/items/robe.png?t=1593393829403',
    dname: 'Robe of the Magi',
    qual: 'component',
  },
  '20': {
    id: 20,
    img: '/apps/dota2/images/dota_react/items/circlet.png?t=1593393829403',
    dname: 'Circlet',
    qual: 'component',
  },
  '21': {
    id: 21,
    img: '/apps/dota2/images/dota_react/items/ogre_axe.png?t=1593393829403',
    dname: 'Ogre Axe',
    qual: 'component',
  },
  '22': {
    id: 22,
    img: '/apps/dota2/images/dota_react/items/blade_of_alacrity.png?t=1593393829403',
    dname: 'Blade of Alacrity',
    qual: 'component',
  },
  '23': {
    id: 23,
    img: '/apps/dota2/images/dota_react/items/staff_of_wizardry.png?t=1593393829403',
    dname: 'Staff of Wizardry',
    qual: 'component',
  },
  '24': {
    id: 24,
    img: '/apps/dota2/images/dota_react/items/ultimate_orb.png?t=1593393829403',
    dname: 'Ultimate Orb',
    qual: 'secret_shop',
  },
  '25': {
    id: 25,
    img: '/apps/dota2/images/dota_react/items/gloves.png?t=1593393829403',
    dname: 'Gloves of Haste',
    qual: 'component',
  },
  '26': {
    id: 26,
    img: '/apps/dota2/images/dota_react/items/lifesteal.png?t=1593393829403',
    dname: 'Morbid Mask',
    qual: 'component',
  },
  '27': {
    id: 27,
    img: '/apps/dota2/images/dota_react/items/ring_of_regen.png?t=1593393829403',
    dname: 'Ring of Regen',
    qual: 'component',
  },
  '28': {
    id: 28,
    img: '/apps/dota2/images/dota_react/items/sobi_mask.png?t=1593393829403',
    dname: "Sage's Mask",
    qual: 'component',
  },
  '29': {
    id: 29,
    img: '/apps/dota2/images/dota_react/items/boots.png?t=1593393829403',
    dname: 'Boots of Speed',
    qual: 'component',
  },
  '30': {
    id: 30,
    img: '/apps/dota2/images/dota_react/items/gem.png?t=1593393829403',
    dname: 'Gem of True Sight',
    qual: 'component',
  },
  '31': {
    id: 31,
    img: '/apps/dota2/images/dota_react/items/cloak.png?t=1593393829403',
    dname: 'Cloak',
    qual: 'component',
  },
  '32': {
    id: 32,
    img: '/apps/dota2/images/dota_react/items/talisman_of_evasion.png?t=1593393829403',
    dname: 'Talisman of Evasion',
    qual: 'secret_shop',
  },
  '33': {
    id: 33,
    img: '/apps/dota2/images/dota_react/items/cheese.png?t=1593393829403',
    dname: 'Cheese',
    qual: 'consumable',
  },
  '34': {
    id: 34,
    img: '/apps/dota2/images/dota_react/items/magic_stick.png?t=1593393829403',
    dname: 'Magic Stick',
    qual: 'component',
  },
  '35': {
    id: 35,
    img: '/apps/dota2/images/dota_react/items/recipe.png?t=1593393829403',
    dname: 'Magic Wand Recipe',
  },
  '36': {
    id: 36,
    img: '/apps/dota2/images/dota_react/items/magic_wand.png?t=1593393829403',
    dname: 'Magic Wand',
    qual: 'common',
  },
  '37': {
    id: 37,
    img: '/apps/dota2/images/dota_react/items/ghost.png?t=1593393829403',
    dname: 'Ghost Scepter',
    qual: 'component',
  },
  '38': {
    id: 38,
    img: '/apps/dota2/images/dota_react/items/clarity.png?t=1593393829403',
    dname: 'Clarity',
    qual: 'consumable',
  },
  '39': {
    id: 39,
    img: '/apps/dota2/images/dota_react/items/flask.png?t=1593393829403',
    dname: 'Healing Salve',
    qual: 'consumable',
  },
  '40': {
    id: 40,
    img: '/apps/dota2/images/dota_react/items/dust.png?t=1593393829403',
    dname: 'Dust of Appearance',
    qual: 'consumable',
  },
  '41': {
    id: 41,
    img: '/apps/dota2/images/dota_react/items/bottle.png?t=1593393829403',
    dname: 'Bottle',
    qual: 'common',
  },
  '42': {
    id: 42,
    img: '/apps/dota2/images/dota_react/items/ward_observer.png?t=1593393829403',
    dname: 'Observer Ward',
    qual: 'consumable',
  },
  '43': {
    id: 43,
    img: '/apps/dota2/images/dota_react/items/ward_sentry.png?t=1593393829403',
    dname: 'Sentry Ward',
    qual: 'consumable',
  },
  '44': {
    id: 44,
    img: '/apps/dota2/images/dota_react/items/tango.png?t=1593393829403',
    dname: 'Tango',
    qual: 'consumable',
  },
  '45': {
    id: 45,
    img: '/apps/dota2/images/dota_react/items/courier.png?t=1593393829403',
    dname: 'Animal Courier',
    qual: 'consumable',
  },
  '46': {
    id: 46,
    img: '/apps/dota2/images/dota_react/items/tpscroll.png?t=1593393829403',
    dname: 'Town Portal Scroll',
    qual: 'consumable',
  },
  '47': {
    id: 47,
    img: '/apps/dota2/images/dota_react/items/recipe.png?t=1593393829403',
    dname: 'Boots of Travel Recipe',
  },
  '48': {
    id: 48,
    img: '/apps/dota2/images/dota_react/items/travel_boots.png?t=1593393829403',
    dname: 'Boots of Travel',
    qual: 'common',
  },
  '50': {
    id: 50,
    img: '/apps/dota2/images/dota_react/items/phase_boots.png?t=1593393829403',
    dname: 'Phase Boots',
    qual: 'common',
  },
  '51': {
    id: 51,
    img: '/apps/dota2/images/dota_react/items/demon_edge.png?t=1593393829403',
    dname: 'Demon Edge',
    qual: 'secret_shop',
  },
  '52': {
    id: 52,
    img: '/apps/dota2/images/dota_react/items/eagle.png?t=1593393829403',
    dname: 'Eaglesong',
    qual: 'secret_shop',
  },
  '53': {
    id: 53,
    img: '/apps/dota2/images/dota_react/items/reaver.png?t=1593393829403',
    dname: 'Reaver',
    qual: 'secret_shop',
  },
  '54': {
    id: 54,
    img: '/apps/dota2/images/dota_react/items/relic.png?t=1593393829403',
    dname: 'Sacred Relic',
    qual: 'secret_shop',
  },
  '55': {
    id: 55,
    img: '/apps/dota2/images/dota_react/items/hyperstone.png?t=1593393829403',
    dname: 'Hyperstone',
    qual: 'secret_shop',
  },
  '56': {
    id: 56,
    img: '/apps/dota2/images/dota_react/items/ring_of_health.png?t=1593393829403',
    dname: 'Ring of Health',
    qual: 'component',
  },
  '57': {
    id: 57,
    img: '/apps/dota2/images/dota_react/items/void_stone.png?t=1593393829403',
    dname: 'Void Stone',
    qual: 'component',
  },
  '58': {
    id: 58,
    img: '/apps/dota2/images/dota_react/items/mystic_staff.png?t=1593393829403',
    dname: 'Mystic Staff',
    qual: 'secret_shop',
  },
  '59': {
    id: 59,
    img: '/apps/dota2/images/dota_react/items/energy_booster.png?t=1593393829403',
    dname: 'Energy Booster',
    qual: 'secret_shop',
  },
  '60': {
    id: 60,
    img: '/apps/dota2/images/dota_react/items/point_booster.png?t=1593393829403',
    dname: 'Point Booster',
    qual: 'secret_shop',
  },
  '61': {
    id: 61,
    img: '/apps/dota2/images/dota_react/items/vitality_booster.png?t=1593393829403',
    dname: 'Vitality Booster',
    qual: 'secret_shop',
  },
  '63': {
    id: 63,
    img: '/apps/dota2/images/dota_react/items/power_treads.png?t=1593393829403',
    dname: 'Power Treads',
    qual: 'common',
  },
  '64': {
    id: 64,
    img: '/apps/dota2/images/dota_react/items/recipe.png?t=1593393829403',
    dname: 'Hand of Midas Recipe',
  },
  '65': {
    id: 65,
    img: '/apps/dota2/images/dota_react/items/hand_of_midas.png?t=1593393829403',
    dname: 'Hand of Midas',
    qual: 'common',
  },
  '67': {
    id: 67,
    img: '/apps/dota2/images/dota_react/items/oblivion_staff.png?t=1593393829403',
    dname: 'Oblivion Staff',
    qual: 'common',
  },
  '69': {
    id: 69,
    img: '/apps/dota2/images/dota_react/items/pers.png?t=1593393829403',
    dname: 'Perseverance',
    qual: 'common',
  },
  '71': {
    id: 71,
    img: '/apps/dota2/images/dota_react/items/poor_mans_shield.png?t=1593393829403',
    dname: "Poor Man's Shield",
    qual: 'common',
  },
  '72': {
    id: 72,
    img: '/apps/dota2/images/dota_react/items/recipe.png?t=1593393829403',
    dname: 'Bracer Recipe',
  },
  '73': {
    id: 73,
    img: '/apps/dota2/images/dota_react/items/bracer.png?t=1593393829403',
    dname: 'Bracer',
    qual: 'common',
  },
  '74': {
    id: 74,
    img: '/apps/dota2/images/dota_react/items/recipe.png?t=1593393829403',
    dname: 'Wraith Band Recipe',
  },
  '75': {
    id: 75,
    img: '/apps/dota2/images/dota_react/items/wraith_band.png?t=1593393829403',
    dname: 'Wraith Band',
    qual: 'common',
  },
  '76': {
    id: 76,
    img: '/apps/dota2/images/dota_react/items/recipe.png?t=1593393829403',
    dname: 'Null Talisman Recipe',
  },
  '77': {
    id: 77,
    img: '/apps/dota2/images/dota_react/items/null_talisman.png?t=1593393829403',
    dname: 'Null Talisman',
    qual: 'common',
  },
  '78': {
    id: 78,
    img: '/apps/dota2/images/dota_react/items/recipe.png?t=1593393829403',
    dname: 'Mekansm Recipe',
  },
  '79': {
    id: 79,
    img: '/apps/dota2/images/dota_react/items/mekansm.png?t=1593393829403',
    dname: 'Mekansm',
    qual: 'rare',
  },
  '81': {
    id: 81,
    img: '/apps/dota2/images/dota_react/items/vladmir.png?t=1593393829403',
    dname: "Vladmir's Offering",
    qual: 'rare',
  },
  '85': {
    id: 85,
    img: '/apps/dota2/images/dota_react/items/recipe.png?t=1593393829403',
    dname: 'Buckler Recipe',
  },
  '86': {
    id: 86,
    img: '/apps/dota2/images/dota_react/items/buckler.png?t=1593393829403',
    dname: 'Buckler',
    qual: 'rare',
  },
  '87': {
    id: 87,
    img: '/apps/dota2/images/dota_react/items/recipe.png?t=1593393829403',
    dname: 'Ring of Basilius Recipe',
  },
  '88': {
    id: 88,
    img: '/apps/dota2/images/dota_react/items/ring_of_basilius.png?t=1593393829403',
    dname: 'Ring of Basilius',
    qual: 'rare',
  },
  '89': {
    id: 89,
    img: '/apps/dota2/images/dota_react/items/recipe.png?t=1593393829403',
    dname: 'Pipe of Insight Recipe',
  },
  '90': {
    id: 90,
    img: '/apps/dota2/images/dota_react/items/pipe.png?t=1593393829403',
    dname: 'Pipe of Insight',
    qual: 'rare',
  },
  '91': {
    id: 91,
    img: '/apps/dota2/images/dota_react/items/recipe.png?t=1593393829403',
    dname: 'Urn of Shadows Recipe',
  },
  '92': {
    id: 92,
    img: '/apps/dota2/images/dota_react/items/urn_of_shadows.png?t=1593393829403',
    dname: 'Urn of Shadows',
    qual: 'rare',
  },
  '93': {
    id: 93,
    img: '/apps/dota2/images/dota_react/items/recipe.png?t=1593393829403',
    dname: 'Headdress Recipe',
  },
  '94': {
    id: 94,
    img: '/apps/dota2/images/dota_react/items/headdress.png?t=1593393829403',
    dname: 'Headdress',
    qual: 'rare',
  },
  '95': {
    id: 95,
    img: '/apps/dota2/images/dota_react/items/recipe.png?t=1593393829403',
    dname: 'Scythe of Vyse Recipe',
  },
  '96': {
    id: 96,
    img: '/apps/dota2/images/dota_react/items/sheepstick.png?t=1593393829403',
    dname: 'Scythe of Vyse',
    qual: 'rare',
  },
  '97': {
    id: 97,
    img: '/apps/dota2/images/dota_react/items/recipe.png?t=1593393829403',
    dname: 'Orchid Malevolence Recipe',
  },
  '98': {
    id: 98,
    img: '/apps/dota2/images/dota_react/items/orchid.png?t=1593393829403',
    dname: 'Orchid Malevolence',
    qual: 'rare',
  },
  '99': {
    id: 99,
    img: '/apps/dota2/images/dota_react/items/recipe.png?t=1593393829403',
    dname: "Eul's Scepter Recipe",
  },
  '100': {
    id: 100,
    img: '/apps/dota2/images/dota_react/items/cyclone.png?t=1593393829403',
    dname: "Eul's Scepter of Divinity",
    qual: 'rare',
  },
  '101': {
    id: 101,
    img: '/apps/dota2/images/dota_react/items/recipe.png?t=1593393829403',
    dname: 'Force Staff Recipe',
  },
  '102': {
    id: 102,
    img: '/apps/dota2/images/dota_react/items/force_staff.png?t=1593393829403',
    dname: 'Force Staff',
    qual: 'rare',
  },
  '103': {
    id: 103,
    img: '/apps/dota2/images/dota_react/items/recipe.png?t=1593393829403',
    dname: 'Dagon Recipe',
  },
  '104': {
    id: 104,
    img: '/apps/dota2/images/dota_react/items/dagon.png?t=1593393829403',
    dname: 'Dagon',
    qual: 'rare',
  },
  '105': {
    id: 105,
    img: '/apps/dota2/images/dota_react/items/recipe.png?t=1593393829403',
    dname: 'Necronomicon Recipe',
  },
  '106': {
    id: 106,
    img: '/apps/dota2/images/dota_react/items/necronomicon.png?t=1593393829403',
    dname: 'Necronomicon',
    qual: 'rare',
  },
  '108': {
    id: 108,
    img: '/apps/dota2/images/dota_react/items/ultimate_scepter.png?t=1593393829403',
    dname: "Aghanim's Scepter",
    qual: 'rare',
  },
  '109': {
    id: 109,
    img: '/apps/dota2/images/dota_react/items/recipe.png?t=1593393829403',
    dname: 'Refresher Orb Recipe',
  },
  '110': {
    id: 110,
    img: '/apps/dota2/images/dota_react/items/refresher.png?t=1593393829403',
    dname: 'Refresher Orb',
    qual: 'rare',
  },
  '111': {
    id: 111,
    img: '/apps/dota2/images/dota_react/items/recipe.png?t=1593393829403',
    dname: 'Assault Cuirass Recipe',
  },
  '112': {
    id: 112,
    img: '/apps/dota2/images/dota_react/items/assault.png?t=1593393829403',
    dname: 'Assault Cuirass',
    qual: 'epic',
  },
  '113': {
    id: 113,
    img: '/apps/dota2/images/dota_react/items/recipe.png?t=1593393829403',
    dname: 'Heart of Tarrasque Recipe',
  },
  '114': {
    id: 114,
    img: '/apps/dota2/images/dota_react/items/heart.png?t=1593393829403',
    dname: 'Heart of Tarrasque',
    qual: 'epic',
  },
  '115': {
    id: 115,
    img: '/apps/dota2/images/dota_react/items/recipe.png?t=1593393829403',
    dname: 'Black King Bar Recipe',
  },
  '116': {
    id: 116,
    img: '/apps/dota2/images/dota_react/items/black_king_bar.png?t=1593393829403',
    dname: 'Black King Bar',
    qual: 'epic',
  },
  '117': {
    id: 117,
    img: '/apps/dota2/images/dota_react/items/aegis.png?t=1593393829403',
    dname: 'Aegis of the Immortal',
    qual: 'artifact',
  },
  '118': {
    id: 118,
    img: '/apps/dota2/images/dota_react/items/recipe.png?t=1593393829403',
    dname: "Shiva's Guard Recipe",
  },
  '119': {
    id: 119,
    img: '/apps/dota2/images/dota_react/items/shivas_guard.png?t=1593393829403',
    dname: "Shiva's Guard",
    qual: 'epic',
  },
  '121': {
    id: 121,
    img: '/apps/dota2/images/dota_react/items/bloodstone.png?t=1593393829403',
    dname: 'Bloodstone',
    qual: 'epic',
  },
  '122': {
    id: 122,
    img: '/apps/dota2/images/dota_react/items/recipe.png?t=1593393829403',
    dname: "Linken's Sphere Recipe",
  },
  '123': {
    id: 123,
    img: '/apps/dota2/images/dota_react/items/sphere.png?t=1593393829403',
    dname: "Linken's Sphere",
    qual: 'epic',
  },
  '125': {
    id: 125,
    img: '/apps/dota2/images/dota_react/items/vanguard.png?t=1593393829403',
    dname: 'Vanguard',
    qual: 'epic',
  },
  '126': {
    id: 126,
    img: '/apps/dota2/images/dota_react/items/recipe.png?t=1593393829403',
    dname: 'Blade Mail Recipe',
  },
  '127': {
    id: 127,
    img: '/apps/dota2/images/dota_react/items/blade_mail.png?t=1593393829403',
    dname: 'Blade Mail',
    qual: 'epic',
  },
  '129': {
    id: 129,
    img: '/apps/dota2/images/dota_react/items/soul_booster.png?t=1593393829403',
    dname: 'Soul Booster',
    qual: 'epic',
  },
  '131': {
    id: 131,
    img: '/apps/dota2/images/dota_react/items/hood_of_defiance.png?t=1593393829403',
    dname: 'Hood of Defiance',
    qual: 'epic',
  },
  '133': {
    id: 133,
    img: '/apps/dota2/images/dota_react/items/rapier.png?t=1593393829403',
    dname: 'Divine Rapier',
    qual: 'epic',
  },
  '134': {
    id: 134,
    img: '/apps/dota2/images/dota_react/items/recipe.png?t=1593393829403',
    dname: 'Monkey King Bar Recipe',
  },
  '135': {
    id: 135,
    img: '/apps/dota2/images/dota_react/items/monkey_king_bar.png?t=1593393829403',
    dname: 'Monkey King Bar',
    qual: 'epic',
  },
  '137': {
    id: 137,
    img: '/apps/dota2/images/dota_react/items/radiance.png?t=1593393829403',
    dname: 'Radiance',
    qual: 'epic',
  },
  '139': {
    id: 139,
    img: '/apps/dota2/images/dota_react/items/butterfly.png?t=1593393829403',
    dname: 'Butterfly',
    qual: 'epic',
  },
  '140': {
    id: 140,
    img: '/apps/dota2/images/dota_react/items/recipe.png?t=1593393829403',
    dname: 'Daedalus Recipe',
  },
  '141': {
    id: 141,
    img: '/apps/dota2/images/dota_react/items/greater_crit.png?t=1593393829403',
    dname: 'Daedalus',
    qual: 'epic',
  },
  '142': {
    id: 142,
    img: '/apps/dota2/images/dota_react/items/recipe.png?t=1593393829403',
    dname: 'Skull Basher Recipe',
  },
  '143': {
    id: 143,
    img: '/apps/dota2/images/dota_react/items/basher.png?t=1593393829403',
    dname: 'Skull Basher',
    qual: 'epic',
  },
  '144': {
    id: 144,
    img: '/apps/dota2/images/dota_react/items/recipe.png?t=1593393829403',
    dname: 'Battle Fury Recipe',
  },
  '145': {
    id: 145,
    img: '/apps/dota2/images/dota_react/items/bfury.png?t=1593393829403',
    dname: 'Battle Fury',
    qual: 'epic',
  },
  '146': {
    id: 146,
    img: '/apps/dota2/images/dota_react/items/recipe.png?t=1593393829403',
    dname: 'Manta Style Recipe',
  },
  '147': {
    id: 147,
    img: '/apps/dota2/images/dota_react/items/manta.png?t=1593393829403',
    dname: 'Manta Style',
    qual: 'epic',
  },
  '148': {
    id: 148,
    img: '/apps/dota2/images/dota_react/items/recipe.png?t=1593393829403',
    dname: 'Crystalys Recipe',
  },
  '149': {
    id: 149,
    img: '/apps/dota2/images/dota_react/items/lesser_crit.png?t=1593393829403',
    dname: 'Crystalys',
    qual: 'epic',
  },
  '150': {
    id: 150,
    img: '/apps/dota2/images/dota_react/items/recipe.png?t=1593393829403',
    dname: 'Armlet of Mordiggian Recipe',
  },
  '151': {
    id: 151,
    img: '/apps/dota2/images/dota_react/items/armlet.png?t=1593393829403',
    dname: 'Armlet of Mordiggian',
    qual: 'epic',
  },
  '152': {
    id: 152,
    img: '/apps/dota2/images/dota_react/items/invis_sword.png?t=1593393829403',
    dname: 'Shadow Blade',
    qual: 'epic',
  },
  '154': {
    id: 154,
    img: '/apps/dota2/images/dota_react/items/sange_and_yasha.png?t=1593393829403',
    dname: 'Sange and Yasha',
    qual: 'artifact',
  },
  '156': {
    id: 156,
    img: '/apps/dota2/images/dota_react/items/satanic.png?t=1593393829403',
    dname: 'Satanic',
    qual: 'artifact',
  },
  '157': {
    id: 157,
    img: '/apps/dota2/images/dota_react/items/recipe.png?t=1593393829403',
    dname: 'Mjollnir Recipe',
  },
  '158': {
    id: 158,
    img: '/apps/dota2/images/dota_react/items/mjollnir.png?t=1593393829403',
    dname: 'Mjollnir',
    qual: 'artifact',
  },
  '159': {
    id: 159,
    img: '/apps/dota2/images/dota_react/items/recipe.png?t=1593393829403',
    dname: 'Eye of Skadi Recipe',
  },
  '160': {
    id: 160,
    img: '/apps/dota2/images/dota_react/items/skadi.png?t=1593393829403',
    dname: 'Eye of Skadi',
    qual: 'artifact',
  },
  '161': {
    id: 161,
    img: '/apps/dota2/images/dota_react/items/recipe.png?t=1593393829403',
    dname: 'Sange Recipe',
  },
  '162': {
    id: 162,
    img: '/apps/dota2/images/dota_react/items/sange.png?t=1593393829403',
    dname: 'Sange',
    qual: 'artifact',
  },
  '163': {
    id: 163,
    img: '/apps/dota2/images/dota_react/items/recipe.png?t=1593393829403',
    dname: 'Helm of the Dominator Recipe',
  },
  '164': {
    id: 164,
    img: '/apps/dota2/images/dota_react/items/helm_of_the_dominator.png?t=1593393829403',
    dname: 'Helm of the Dominator',
    qual: 'artifact',
  },
  '166': {
    id: 166,
    img: '/apps/dota2/images/dota_react/items/maelstrom.png?t=1593393829403',
    dname: 'Maelstrom',
    qual: 'artifact',
  },
  '168': {
    id: 168,
    img: '/apps/dota2/images/dota_react/items/desolator.png?t=1593393829403',
    dname: 'Desolator',
    qual: 'artifact',
  },
  '169': {
    id: 169,
    img: '/apps/dota2/images/dota_react/items/recipe.png?t=1593393829403',
    dname: 'Yasha Recipe',
  },
  '170': {
    id: 170,
    img: '/apps/dota2/images/dota_react/items/yasha.png?t=1593393829403',
    dname: 'Yasha',
    qual: 'artifact',
  },
  '172': {
    id: 172,
    img: '/apps/dota2/images/dota_react/items/mask_of_madness.png?t=1593393829403',
    dname: 'Mask of Madness',
    qual: 'artifact',
  },
  '173': {
    id: 173,
    img: '/apps/dota2/images/dota_react/items/recipe.png?t=1593393829403',
    dname: 'Diffusal Blade Recipe',
  },
  '174': {
    id: 174,
    img: '/apps/dota2/images/dota_react/items/diffusal_blade.png?t=1593393829403',
    dname: 'Diffusal Blade',
    qual: 'artifact',
  },
  '175': {
    id: 175,
    img: '/apps/dota2/images/dota_react/items/recipe.png?t=1593393829403',
    dname: 'Ethereal Blade Recipe',
  },
  '176': {
    id: 176,
    img: '/apps/dota2/images/dota_react/items/ethereal_blade.png?t=1593393829403',
    dname: 'Ethereal Blade',
    qual: 'epic',
  },
  '177': {
    id: 177,
    img: '/apps/dota2/images/dota_react/items/recipe.png?t=1593393829403',
    dname: 'Soul Ring Recipe',
  },
  '178': {
    id: 178,
    img: '/apps/dota2/images/dota_react/items/soul_ring.png?t=1593393829403',
    dname: 'Soul Ring',
    qual: 'common',
  },
  '179': {
    id: 179,
    img: '/apps/dota2/images/dota_react/items/recipe.png?t=1593393829403',
    dname: 'Arcane Boots Recipe',
  },
  '180': {
    id: 180,
    img: '/apps/dota2/images/dota_react/items/arcane_boots.png?t=1593393829403',
    dname: 'Arcane Boots',
    qual: 'rare',
  },
  '181': {
    id: 181,
    img: '/apps/dota2/images/dota_react/items/orb_of_venom.png?t=1593393829403',
    dname: 'Orb of Venom',
    qual: 'component',
  },
  '182': {
    id: 182,
    img: '/apps/dota2/images/dota_react/items/stout_shield.png?t=1593393829403',
    dname: 'Stout Shield',
    qual: 'component',
  },
  '184': {
    id: 184,
    img: '/apps/dota2/images/dota_react/items/recipe.png?t=1593393829403',
    dname: 'Drum of Endurance Recipe',
  },
  '185': {
    id: 185,
    img: '/apps/dota2/images/dota_react/items/ancient_janggo.png?t=1593393829403',
    dname: 'Drum of Endurance',
    qual: 'rare',
  },
  '187': {
    id: 187,
    img: '/apps/dota2/images/dota_react/items/medallion_of_courage.png?t=1593393829403',
    dname: 'Medallion of Courage',
    qual: 'rare',
  },
  '188': {
    id: 188,
    img: '/apps/dota2/images/dota_react/items/smoke_of_deceit.png?t=1593393829403',
    dname: 'Smoke of Deceit',
    qual: 'consumable',
  },
  '189': {
    id: 189,
    img: '/apps/dota2/images/dota_react/items/recipe.png?t=1593393829403',
    dname: 'Veil of Discord Recipe',
  },
  '190': {
    id: 190,
    img: '/apps/dota2/images/dota_react/items/veil_of_discord.png?t=1593393829403',
    dname: 'Veil of Discord',
    qual: 'rare',
  },
  '193': {
    id: 193,
    img: '/apps/dota2/images/dota_react/items/necronomicon_2.png?t=1593393829403',
    dname: 'Necronomicon',
    qual: 'rare',
  },
  '194': {
    id: 194,
    img: '/apps/dota2/images/dota_react/items/necronomicon_3.png?t=1593393829403',
    dname: 'Necronomicon',
    qual: 'rare',
  },
  '196': {
    id: 196,
    img: '/apps/dota2/images/dota_react/items/diffusal_blade_2.png?3',
    dname: 'Diffusal Blade',
    qual: 'artifact',
  },
  '201': {
    id: 201,
    img: '/apps/dota2/images/dota_react/items/dagon_2.png?t=1593393829403',
    dname: 'Dagon',
    qual: 'rare',
  },
  '202': {
    id: 202,
    img: '/apps/dota2/images/dota_react/items/dagon_3.png?t=1593393829403',
    dname: 'Dagon',
    qual: 'rare',
  },
  '203': {
    id: 203,
    img: '/apps/dota2/images/dota_react/items/dagon_4.png?t=1593393829403',
    dname: 'Dagon',
    qual: 'rare',
  },
  '204': {
    id: 204,
    img: '/apps/dota2/images/dota_react/items/dagon_5.png?t=1593393829403',
    dname: 'Dagon',
    qual: 'rare',
  },
  '205': {
    id: 205,
    img: '/apps/dota2/images/dota_react/items/recipe.png?t=1593393829403',
    dname: 'Rod of Atos Recipe',
  },
  '206': {
    id: 206,
    img: '/apps/dota2/images/dota_react/items/rod_of_atos.png?t=1593393829403',
    dname: 'Rod of Atos',
    qual: 'rare',
  },
  '207': {
    id: 207,
    img: '/apps/dota2/images/dota_react/items/recipe.png?t=1593393829403',
    dname: 'Abyssal Blade Recipe',
  },
  '208': {
    id: 208,
    img: '/apps/dota2/images/dota_react/items/abyssal_blade.png?t=1593393829403',
    dname: 'Abyssal Blade',
    qual: 'epic',
  },
  '209': {
    id: 209,
    img: '/apps/dota2/images/dota_react/items/recipe.png?t=1593393829403',
    dname: "Heaven's Halberd Recipe",
  },
  '210': {
    id: 210,
    img: '/apps/dota2/images/dota_react/items/heavens_halberd.png?t=1593393829403',
    dname: "Heaven's Halberd",
    qual: 'artifact',
  },
  '212': {
    id: 212,
    img: '/apps/dota2/images/dota_react/items/ring_of_aquila.png?t=1593393829403',
    dname: 'Ring of Aquila',
  },
  '214': {
    id: 214,
    img: '/apps/dota2/images/dota_react/items/tranquil_boots.png?t=1593393829403',
    dname: 'Tranquil Boots',
    qual: 'rare',
  },
  '215': {
    id: 215,
    img: '/apps/dota2/images/dota_react/items/shadow_amulet.png?t=1593393829403',
    dname: 'Shadow Amulet',
  },
  '216': {
    id: 216,
    img: '/apps/dota2/images/dota_react/items/enchanted_mango.png?t=1593393829403',
    dname: 'Enchanted Mango',
    qual: 'consumable',
  },
  '218': {
    id: 218,
    img: '/apps/dota2/images/dota_react/items/ward_dispenser.png?t=1593393829403',
    dname: 'Observer and Sentry Wards',
    qual: 'common',
  },
  '220': {
    id: 220,
    img: '/apps/dota2/images/dota_react/items/travel_boots_2.png?t=1593393829403',
    dname: 'Boots of Travel 2',
    qual: 'common',
  },
  '221': {
    id: 221,
    img: '/apps/dota2/images/dota_react/items/recipe.png?t=1593393829403',
    dname: 'Lotus Orb Recipe',
  },
  '222': {
    id: 222,
    img: '/apps/dota2/images/dota_react/items/recipe.png?t=1593393829403',
    dname: 'Meteor Hammer Recipe',
  },
  '223': {
    id: 223,
    img: '/apps/dota2/images/dota_react/items/meteor_hammer.png?t=1593393829403',
    dname: 'Meteor Hammer',
    qual: 'epic',
  },
  '225': {
    id: 225,
    img: '/apps/dota2/images/dota_react/items/nullifier.png?t=1593393829403',
    dname: 'Nullifier',
    qual: 'epic',
  },
  '226': {
    id: 226,
    img: '/apps/dota2/images/dota_react/items/lotus_orb.png?t=1593393829403',
    dname: 'Lotus Orb',
    qual: 'epic',
  },
  '227': {
    id: 227,
    img: '/apps/dota2/images/dota_react/items/recipe.png?t=1593393829403',
    dname: 'Solar Crest Recipe',
  },
  '229': {
    id: 229,
    img: '/apps/dota2/images/dota_react/items/solar_crest.png?t=1593393829403',
    dname: 'Solar Crest',
    qual: 'rare',
  },
  '230': {
    id: 230,
    img: '/apps/dota2/images/dota_react/items/recipe.png?t=1593393829403',
    dname: 'Guardian Greaves Recipe',
  },
  '231': {
    id: 231,
    img: '/apps/dota2/images/dota_react/items/guardian_greaves.png?t=1593393829403',
    dname: 'Guardian Greaves',
    qual: 'rare',
  },
  '232': {
    id: 232,
    img: '/apps/dota2/images/dota_react/items/aether_lens.png?t=1593393829403',
    dname: 'Aether Lens',
    qual: 'rare',
  },
  '233': {
    id: 233,
    img: '/apps/dota2/images/dota_react/items/recipe.png?t=1593393829403',
    dname: 'Aether Lens Recipe',
  },
  '234': {
    id: 234,
    img: '/apps/dota2/images/dota_react/items/recipe.png?t=1593393829403',
    dname: 'Dragon Lance Recipe',
  },
  '235': {
    id: 235,
    img: '/apps/dota2/images/dota_react/items/octarine_core.png?t=1593393829403',
    dname: 'Octarine Core',
    qual: 'rare',
  },
  '236': {
    id: 236,
    img: '/apps/dota2/images/dota_react/items/dragon_lance.png?t=1593393829403',
    dname: 'Dragon Lance',
    qual: 'artifact',
  },
  '237': {
    id: 237,
    img: '/apps/dota2/images/dota_react/items/faerie_fire.png?t=1593393829403',
    dname: 'Faerie Fire',
    qual: 'consumable',
  },
  '238': {
    id: 238,
    img: '/apps/dota2/images/dota_react/items/recipe.png?3',
    dname: 'Iron Talon Recipe',
  },
  '239': {
    id: 239,
    img: '/apps/dota2/images/dota_react/items/iron_talon.png?t=1593393829403',
    dname: 'Iron Talon',
    qual: 'common',
  },
  '240': {
    id: 240,
    img: '/apps/dota2/images/dota_react/items/blight_stone.png?t=1593393829403',
    dname: 'Blight Stone',
    qual: 'component',
  },
  '241': {
    id: 241,
    img: '/apps/dota2/images/dota_react/items/tango_single.png?t=1593393829403',
    dname: 'Tango (Shared)',
    qual: 'consumable',
  },
  '242': {
    id: 242,
    img: '/apps/dota2/images/dota_react/items/crimson_guard.png?t=1593393829403',
    dname: 'Crimson Guard',
    qual: 'epic',
  },
  '243': {
    id: 243,
    img: '/apps/dota2/images/dota_react/items/recipe.png?t=1593393829403',
    dname: 'Crimson Guard Recipe',
  },
  '244': {
    id: 244,
    img: '/apps/dota2/images/dota_react/items/wind_lace.png?t=1593393829403',
    dname: 'Wind Lace',
    qual: 'component',
  },
  '245': {
    id: 245,
    img: '/apps/dota2/images/dota_react/items/recipe.png?t=1593393829403',
    dname: 'Bloodthorn Recipe',
  },
  '247': {
    id: 247,
    img: '/apps/dota2/images/dota_react/items/moon_shard.png?t=1593393829403',
    dname: 'Moon Shard',
    qual: 'consumable',
  },
  '248': {
    id: 248,
    img: '/apps/dota2/images/dota_react/items/recipe.png?t=1593393829403',
    dname: 'Silver Edge Recipe',
  },
  '249': {
    id: 249,
    img: '/apps/dota2/images/dota_react/items/silver_edge.png?t=1593393829403',
    dname: 'Silver Edge',
    qual: 'epic',
  },
  '250': {
    id: 250,
    img: '/apps/dota2/images/dota_react/items/bloodthorn.png?t=1593393829403',
    dname: 'Bloodthorn',
    qual: 'epic',
  },
  '252': {
    id: 252,
    img: '/apps/dota2/images/dota_react/items/echo_sabre.png?t=1593393829403',
    dname: 'Echo Sabre',
    qual: 'artifact',
  },
  '253': {
    id: 253,
    img: '/apps/dota2/images/dota_react/items/recipe.png?t=1593393829403',
    dname: 'Glimmer Cape Recipe',
  },
  '254': {
    id: 254,
    img: '/apps/dota2/images/dota_react/items/glimmer_cape.png?t=1593393829403',
    dname: 'Glimmer Cape',
    qual: 'rare',
  },
  '255': {
    id: 255,
    img: '/apps/dota2/images/dota_react/items/recipe.png?t=1593393829403',
    dname: 'Aeon Disk Recipe',
  },
  '256': {
    id: 256,
    img: '/apps/dota2/images/dota_react/items/aeon_disk.png?t=1593393829403',
    dname: 'Aeon Disk',
    qual: 'epic',
  },
  '257': {
    id: 257,
    img: '/apps/dota2/images/dota_react/items/tome_of_knowledge.png?t=1593393829403',
    dname: 'Tome of Knowledge',
    qual: 'consumable',
  },
  '258': {
    id: 258,
    img: '/apps/dota2/images/dota_react/items/recipe.png?t=1593393829403',
    dname: 'Kaya Recipe',
  },
  '259': {
    id: 259,
    img: '/apps/dota2/images/dota_react/items/kaya.png?t=1593393829403',
    dname: 'Kaya',
    qual: 'epic',
  },
  '260': {
    id: 260,
    img: '/apps/dota2/images/dota_react/items/refresher_shard.png?t=1593393829403',
    dname: 'Refresher Shard',
    qual: 'consumable',
  },
  '261': {
    id: 261,
    img: '/apps/dota2/images/dota_react/items/crown.png?t=1593393829403',
    dname: 'Crown',
    qual: 'component',
  },
  '262': {
    id: 262,
    img: '/apps/dota2/images/dota_react/items/recipe.png?t=1593393829403',
    dname: 'Hurricane Pike Recipe',
  },
  '263': {
    id: 263,
    img: '/apps/dota2/images/dota_react/items/hurricane_pike.png?t=1593393829403',
    dname: 'Hurricane Pike',
    qual: 'epic',
  },
  '265': {
    id: 265,
    img: '/apps/dota2/images/dota_react/items/infused_raindrop.png?t=1593393829403',
    dname: 'Infused Raindrops',
    qual: 'component',
  },
  '266': {
    id: 266,
    img: '/apps/dota2/images/dota_react/items/recipe.png?t=1593393829403',
    dname: 'Spirit Vessel Recipe',
  },
  '267': {
    id: 267,
    img: '/apps/dota2/images/dota_react/items/spirit_vessel.png?t=1593393829403',
    dname: 'Spirit Vessel',
    qual: 'rare',
  },
  '268': {
    id: 268,
    img: '/apps/dota2/images/dota_react/items/recipe.png?t=1593393829403',
    dname: 'Holy Locket Recipe',
  },
  '269': {
    id: 269,
    img: '/apps/dota2/images/dota_react/items/holy_locket.png?t=1593393829403',
    dname: 'Holy Locket',
    qual: 'rare',
  },
  '270': {
    id: 270,
    img: '/apps/dota2/images/dota_react/items/recipe.png?t=1593393829403',
    dname: "Aghanim's Blessing Recipe",
  },
  '271': {
    id: 271,
    img: '/apps/dota2/images/dota_react/items/ultimate_scepter_2.png?t=1593393829403',
    dname: "Aghanim's Blessing",
    qual: 'rare',
  },
  '273': {
    id: 273,
    img: '/apps/dota2/images/dota_react/items/kaya_and_sange.png?t=1593393829403',
    dname: 'Kaya and Sange',
    qual: 'artifact',
  },
  '275': {
    id: 275,
    img: '/apps/dota2/images/dota_react/items/recipe.png?t=1593393829403',
    dname: 'Trident Recipe',
  },
  '276': {
    id: 276,
    img: '/apps/dota2/images/dota_react/items/combo_breaker.png?t=1593393829403',
  },
  '277': {
    id: 277,
    img: '/apps/dota2/images/dota_react/items/yasha_and_kaya.png?t=1593393829403',
    dname: 'Yasha and Kaya',
    qual: 'artifact',
  },
  '279': {
    id: 279,
    img: '/apps/dota2/images/dota_react/items/ring_of_tarrasque.png?t=1593393829403',
    dname: 'Ring of Tarrasque',
    qual: 'component',
  },
  '286': {
    id: 286,
    img: '/apps/dota2/images/dota_react/items/flying_courier.png?t=1593393829403',
    dname: 'Flying Courier',
    qual: 'consumable',
  },
  '287': {
    id: 287,
    img: '/apps/dota2/images/dota_react/items/keen_optic.png?t=1593393829403',
    dname: 'Keen Optic',
  },
  '288': {
    id: 288,
    img: '/apps/dota2/images/dota_react/items/grove_bow.png?t=1593393829403',
    dname: 'Grove Bow',
  },
  '289': {
    id: 289,
    img: '/apps/dota2/images/dota_react/items/quickening_charm.png?t=1593393829403',
    dname: 'Quickening Charm',
  },
  '290': {
    id: 290,
    img: '/apps/dota2/images/dota_react/items/philosophers_stone.png?t=1593393829403',
    dname: "Philosopher's Stone",
  },
  '291': {
    id: 291,
    img: '/apps/dota2/images/dota_react/items/force_boots.png?t=1593393829403',
    dname: 'Force Boots',
  },
  '292': {
    id: 292,
    img: '/apps/dota2/images/dota_react/items/desolator_2.png?t=1593393829403',
    dname: 'Stygian Desolator',
  },
  '293': {
    id: 293,
    img: '/apps/dota2/images/dota_react/items/phoenix_ash.png?t=1593393829403',
    dname: 'Phoenix Ash',
  },
  '294': {
    id: 294,
    img: '/apps/dota2/images/dota_react/items/seer_stone.png?t=1593393829403',
    dname: 'Seer Stone',
  },
  '295': {
    id: 295,
    img: '/apps/dota2/images/dota_react/items/greater_mango.png?t=1593393829403',
    dname: 'Greater Mango',
  },
  '297': {
    id: 297,
    img: '/apps/dota2/images/dota_react/items/vampire_fangs.png?t=1593393829403',
    dname: 'Vampire Fangs',
  },
  '298': {
    id: 298,
    img: '/apps/dota2/images/dota_react/items/craggy_coat.png?t=1593393829403',
    dname: 'Craggy Coat',
  },
  '299': {
    id: 299,
    img: '/apps/dota2/images/dota_react/items/greater_faerie_fire.png?t=1593393829403',
    dname: 'Greater Faerie Fire',
    qual: 'consumable',
  },
  '300': {
    id: 300,
    img: '/apps/dota2/images/dota_react/items/timeless_relic.png?t=1593393829403',
    dname: 'Timeless Relic',
  },
  '301': {
    id: 301,
    img: '/apps/dota2/images/dota_react/items/mirror_shield.png?t=1593393829403',
    dname: 'Mirror Shield',
  },
  '302': {
    id: 302,
    img: '/apps/dota2/images/dota_react/items/elixer.png?t=1593393829403',
    dname: 'Elixir',
  },
  '303': {
    id: 303,
    img: '/apps/dota2/images/dota_react/items/recipe.png?t=1593393829403',
    dname: 'Ironwood Tree Recipe',
  },
  '304': {
    id: 304,
    img: '/apps/dota2/images/dota_react/items/ironwood_tree.png?t=1593393829403',
    dname: 'Ironwood Tree',
  },
  '305': {
    id: 305,
    img: '/apps/dota2/images/dota_react/items/royal_jelly.png?t=1593393829403',
    dname: 'Royal Jelly',
    qual: 'consumable',
  },
  '306': {
    id: 306,
    img: '/apps/dota2/images/dota_react/items/pupils_gift.png?t=1593393829403',
    dname: "Pupil's Gift",
  },
  '307': {
    id: 307,
    img: '/apps/dota2/images/dota_react/items/tome_of_aghanim.png?t=1593393829403',
    dname: 'Tome of Aghanim',
  },
  '308': {
    id: 308,
    img: '/apps/dota2/images/dota_react/items/repair_kit.png?t=1593393829403',
    dname: 'Repair Kit',
    qual: 'consumable',
  },
  '309': {
    id: 309,
    img: '/apps/dota2/images/dota_react/items/mind_breaker.png?t=1593393829403',
    dname: 'Mind Breaker',
  },
  '310': {
    id: 310,
    img: '/apps/dota2/images/dota_react/items/third_eye.png?t=1593393829403',
    dname: 'Third Eye',
  },
  '311': {
    id: 311,
    img: '/apps/dota2/images/dota_react/items/spell_prism.png?t=1593393829403',
    dname: 'Spell Prism',
  },
  '312': {
    id: 312,
    img: '/apps/dota2/images/dota_react/items/horizon.png?t=1593393829403',
    dname: 'Horizon',
  },
  '313': {
    id: 313,
    img: '/apps/dota2/images/dota_react/items/fusion_rune.png?t=1593393829403',
    dname: 'Fusion Rune',
  },
  '325': {
    id: 325,
    img: '/apps/dota2/images/dota_react/items/princes_knife.png?t=1593393829403',
    dname: "Prince's Knife",
  },
  '326': {
    id: 326,
    img: '/apps/dota2/images/dota_react/items/spider_legs.png?t=1593393829403',
    dname: 'Spider Legs',
  },
  '327': {
    id: 327,
    img: '/apps/dota2/images/dota_react/items/helm_of_the_undying.png?t=1593393829403',
    dname: 'Helm of the Undying',
  },
  '328': {
    id: 328,
    img: '/apps/dota2/images/dota_react/items/mango_tree.png?t=1593393829403',
    dname: 'Mango Tree',
    qual: 'consumable',
  },
  '330': {
    id: 330,
    img: '/apps/dota2/images/dota_react/items/witless_shako.png?t=1593393829403',
    dname: 'Witless Shako',
  },
  '331': {
    id: 331,
    img: '/apps/dota2/images/dota_react/items/vambrace.png?t=1593393829403',
    dname: 'Vambrace',
    qual: 'common',
  },
  '334': {
    id: 334,
    img: '/apps/dota2/images/dota_react/items/imp_claw.png?t=1593393829403',
    dname: 'Imp Claw',
  },
  '335': {
    id: 335,
    img: '/apps/dota2/images/dota_react/items/flicker.png?t=1593393829403',
    dname: 'Flicker',
  },
  '336': {
    id: 336,
    img: '/apps/dota2/images/dota_react/items/spy_gadget.png?t=1593393829403',
    dname: 'Telescope',
  },
  '349': {
    id: 349,
    img: '/apps/dota2/images/dota_react/items/arcane_ring.png?t=1593393829403',
    dname: 'Arcane Ring',
  },
  '354': {
    id: 354,
    img: '/apps/dota2/images/dota_react/items/ocean_heart.png?t=1593393829403',
    dname: 'Ocean Heart',
  },
  '355': {
    id: 355,
    img: '/apps/dota2/images/dota_react/items/broom_handle.png?t=1593393829403',
    dname: 'Broom Handle',
  },
  '356': {
    id: 356,
    img: '/apps/dota2/images/dota_react/items/trusty_shovel.png?t=1593393829403',
    dname: 'Trusty Shovel',
  },
  '357': {
    id: 357,
    img: '/apps/dota2/images/dota_react/items/nether_shawl.png?t=1593393829403',
    dname: 'Nether Shawl',
  },
  '358': {
    id: 358,
    img: '/apps/dota2/images/dota_react/items/dragon_scale.png?t=1593393829403',
    dname: 'Dragon Scale',
  },
  '359': {
    id: 359,
    img: '/apps/dota2/images/dota_react/items/essence_ring.png?t=1593393829403',
    dname: 'Essence Ring',
  },
  '360': {
    id: 360,
    img: '/apps/dota2/images/dota_react/items/clumsy_net.png?t=1593393829403',
    dname: 'Clumsy Net',
  },
  '361': {
    id: 361,
    img: '/apps/dota2/images/dota_react/items/enchanted_quiver.png?t=1593393829403',
    dname: 'Enchanted Quiver',
  },
  '362': {
    id: 362,
    img: '/apps/dota2/images/dota_react/items/ninja_gear.png?t=1593393829403',
    dname: 'Ninja Gear',
  },
  '363': {
    id: 363,
    img: '/apps/dota2/images/dota_react/items/illusionsts_cape.png?t=1593393829403',
    dname: "Illusionist's Cape",
  },
  '364': {
    id: 364,
    img: '/apps/dota2/images/dota_react/items/havoc_hammer.png?t=1593393829403',
    dname: 'Havoc Hammer',
  },
  '365': {
    id: 365,
    img: '/apps/dota2/images/dota_react/items/panic_button.png?t=1593393829403',
    dname: 'Magic Lamp',
  },
  '366': {
    id: 366,
    img: '/apps/dota2/images/dota_react/items/apex.png?t=1593393829403',
    dname: 'Apex',
  },
  '367': {
    id: 367,
    img: '/apps/dota2/images/dota_react/items/ballista.png?t=1593393829403',
    dname: 'Ballista',
  },
  '368': {
    id: 368,
    img: '/apps/dota2/images/dota_react/items/woodland_striders.png?t=1593393829403',
    dname: 'Woodland Striders',
  },
  '369': {
    id: 369,
    img: '/apps/dota2/images/dota_react/items/trident.png?t=1593393829403',
    dname: 'Trident',
  },
  '370': {
    id: 370,
    img: '/apps/dota2/images/dota_react/items/demonicon.png?t=1593393829403',
    dname: 'Book of the Dead',
  },
  '371': {
    id: 371,
    img: '/apps/dota2/images/dota_react/items/fallen_sky.png?t=1593393829403',
    dname: 'Fallen Sky',
  },
  '372': {
    id: 372,
    img: '/apps/dota2/images/dota_react/items/pirate_hat.png?t=1593393829403',
    dname: 'Pirate Hat',
  },
  '373': {
    id: 373,
    img: '/apps/dota2/images/dota_react/items/dimensional_doorway.png?t=1593393829403',
    dname: 'Dimensional Doorway',
  },
  '374': {
    id: 374,
    img: '/apps/dota2/images/dota_react/items/ex_machina.png?t=1593393829403',
    dname: 'Ex Machina',
  },
  '375': {
    id: 375,
    img: '/apps/dota2/images/dota_react/items/faded_broach.png?t=1593393829403',
    dname: 'Faded Broach',
  },
  '376': {
    id: 376,
    img: '/apps/dota2/images/dota_react/items/paladin_sword.png?t=1593393829403',
    dname: 'Paladin Sword',
  },
  '377': {
    id: 377,
    img: '/apps/dota2/images/dota_react/items/minotaur_horn.png?t=1593393829403',
    dname: 'Minotaur Horn',
  },
  '378': {
    id: 378,
    img: '/apps/dota2/images/dota_react/items/orb_of_destruction.png?t=1593393829403',
    dname: 'Orb of Destruction',
  },
  '379': {
    id: 379,
    img: '/apps/dota2/images/dota_react/items/the_leveller.png?t=1593393829403',
    dname: 'The Leveller',
  },
  '381': {
    id: 381,
    img: '/apps/dota2/images/dota_react/items/titan_sliver.png?t=1593393829403',
    dname: 'Titan Sliver',
  },
  '473': {
    id: 473,
    img: '/apps/dota2/images/dota_react/items/voodoo_mask.png?t=1593393829403',
    dname: 'Voodoo Mask',
    qual: 'component',
  },
  '485': {
    id: 485,
    img: '/apps/dota2/images/dota_react/items/blitz_knuckles.png?t=1593393829403',
    dname: 'Blitz Knuckles',
    qual: 'component',
  },
  '533': {
    id: 533,
    img: '/apps/dota2/images/dota_react/items/recipe.png?t=1593393829403',
    dname: 'Witch Blade Recipe',
  },
  '534': {
    id: 534,
    img: '/apps/dota2/images/dota_react/items/witch_blade.png?t=1593393829403',
    dname: 'Witch Blade',
    qual: 'common',
  },
  '565': {
    id: 565,
    img: '/apps/dota2/images/dota_react/items/chipped_vest.png?t=1593393829403',
    dname: 'Chipped Vest',
  },
  '566': {
    id: 566,
    img: '/apps/dota2/images/dota_react/items/wizard_glass.png?t=1593393829403',
    dname: 'Wizard Glass',
  },
  '569': {
    id: 569,
    img: '/apps/dota2/images/dota_react/items/orb_of_corrosion.png?t=1593393829403',
    dname: 'Orb of Corrosion',
    qual: 'rare',
  },
  '570': {
    id: 570,
    img: '/apps/dota2/images/dota_react/items/gloves_of_travel.png?t=1593393829403',
    dname: 'Gloves of Travel',
  },
  '571': {
    id: 571,
    img: '/apps/dota2/images/dota_react/items/trickster_cloak.png?t=1593393829403',
    dname: 'Trickster Cloak',
  },
  '573': {
    id: 573,
    img: '/apps/dota2/images/dota_react/items/elven_tunic.png?t=1593393829403',
    dname: 'Elven Tunic',
  },
  '574': {
    id: 574,
    img: '/apps/dota2/images/dota_react/items/cloak_of_flames.png?t=1593393829403',
    dname: 'Cloak of Flames',
  },
  '575': {
    id: 575,
    img: '/apps/dota2/images/dota_react/items/venom_gland.png?t=1593393829403',
    dname: 'Venom Gland',
  },
  '576': {
    id: 576,
    img: '/apps/dota2/images/dota_react/items/gladiator_helm.png?t=1593393829403',
    dname: 'Helm of the Gladiator',
  },
  '577': {
    id: 577,
    img: '/apps/dota2/images/dota_react/items/possessed_mask.png?t=1593393829403',
    dname: 'Possessed Mask',
  },
  '578': {
    id: 578,
    img: '/apps/dota2/images/dota_react/items/ancient_perseverance.png?t=1593393829403',
    dname: 'Ancient Perseverance',
  },
  '582': {
    id: 582,
    img: '/apps/dota2/images/dota_react/items/oakheart.png?t=1593393829403',
    dname: 'Oakheart',
  },
  '585': {
    id: 585,
    img: '/apps/dota2/images/dota_react/items/stormcrafter.png?t=1593393829403',
    dname: 'Stormcrafter',
  },
  '588': {
    id: 588,
    img: '/apps/dota2/images/dota_react/items/overflowing_elixir.png?t=1593393829403',
    dname: 'Overflowing Elixir',
  },
  '589': {
    id: 589,
    img: '/apps/dota2/images/dota_react/items/mysterious_hat.png?t=1593393829403',
    dname: "Fairy's Trinket",
  },
  '593': {
    id: 593,
    img: '/apps/dota2/images/dota_react/items/fluffy_hat.png?t=1593393829403',
    dname: 'Fluffy Hat',
    qual: 'secret_shop',
  },
  '596': {
    id: 596,
    img: '/apps/dota2/images/dota_react/items/falcon_blade.png?t=1593393829403',
    dname: 'Falcon Blade',
    qual: 'rare',
  },
  '597': {
    id: 597,
    img: '/apps/dota2/images/dota_react/items/recipe.png?t=1593393829403',
    dname: 'Mage Slayer Recipe',
  },
  '598': {
    id: 598,
    img: '/apps/dota2/images/dota_react/items/mage_slayer.png?t=1593393829403',
    dname: 'Mage Slayer',
    qual: 'rare',
  },
  '599': {
    id: 599,
    img: '/apps/dota2/images/dota_react/items/recipe.png?t=1593393829403',
    dname: 'Falcon Blade Recipe',
  },
  '600': {
    id: 600,
    img: '/apps/dota2/images/dota_react/items/overwhelming_blink.png?t=1593393829403',
    dname: 'Overwhelming Blink',
    qual: 'component',
  },
  '603': {
    id: 603,
    img: '/apps/dota2/images/dota_react/items/swift_blink.png?t=1593393829403',
    dname: 'Swift Blink',
    qual: 'component',
  },
  '604': {
    id: 604,
    img: '/apps/dota2/images/dota_react/items/arcane_blink.png?t=1593393829403',
    dname: 'Arcane Blink',
    qual: 'component',
  },
  '606': {
    id: 606,
    img: '/apps/dota2/images/dota_react/items/recipe.png?t=1593393829403',
    dname: 'Arcane Blink Recipe',
  },
  '607': {
    id: 607,
    img: '/apps/dota2/images/dota_react/items/recipe.png?t=1593393829403',
    dname: 'Swift Blink Recipe',
  },
  '608': {
    id: 608,
    img: '/apps/dota2/images/dota_react/items/recipe.png?t=1593393829403',
    dname: 'Overwhelming Blink Recipe',
  },
  '609': {
    id: 609,
    img: '/apps/dota2/images/dota_react/items/aghanims_shard.png?t=1593393829403',
    dname: "Aghanim's Shard",
    qual: 'rare',
  },
  '610': {
    id: 610,
    img: '/apps/dota2/images/dota_react/items/wind_waker.png?t=1593393829403',
    dname: 'Wind Waker',
    qual: 'rare',
  },
  '612': {
    id: 612,
    img: '/apps/dota2/images/dota_react/items/recipe.png?t=1593393829403',
    dname: 'Wind Waker Recipe',
  },
  '633': {
    id: 633,
    img: '/apps/dota2/images/dota_react/items/recipe.png?t=1593393829403',
    dname: 'Helm of the Overlord Recipe',
  },
  '635': {
    id: 635,
    img: '/apps/dota2/images/dota_react/items/helm_of_the_overlord.png?t=1593393829403',
    dname: 'Helm of the Overlord',
    qual: 'artifact',
  },
  '637': {
    id: 637,
    img: '/apps/dota2/images/dota_react/items/star_mace.png?t=1593393829403',
    dname: 'Star Mace',
  },
  '638': {
    id: 638,
    img: '/apps/dota2/images/dota_react/items/penta_edged_sword.png?t=1593393829403',
    dname: 'Penta-Edged Sword',
  },
  '655': {
    id: 655,
    img: '/apps/dota2/images/dota_react/items/grandmasters_glaive.png?t=1593393829403',
    dname: "Grandmaster's Glaive",
    qual: 'common',
  },
  '674': {
    id: 674,
    img: '/apps/dota2/images/dota_react/items/warhammer.png?t=1593393829403',
    dname: 'Warhammer',
  },
  '675': {
    id: 675,
    img: '/apps/dota2/images/dota_react/items/psychic_headband.png?t=1593393829403',
    dname: 'Psychic Headband',
  },
  '676': {
    id: 676,
    img: '/apps/dota2/images/dota_react/items/ceremonial_robe.png?t=1593393829403',
    dname: 'Ceremonial Robe',
  },
  '677': {
    id: 677,
    img: '/apps/dota2/images/dota_react/items/book_of_shadows.png?t=1593393829403',
    dname: 'Book of Shadows',
  },
  '678': {
    id: 678,
    img: '/apps/dota2/images/dota_react/items/giants_ring.png?t=1593393829403',
    dname: "Giant's Ring",
  },
  '679': {
    id: 679,
    img: '/apps/dota2/images/dota_react/items/vengeances_shadow.png?t=1593393829403',
    dname: 'Shadow of Vengeance',
  },
  '680': {
    id: 680,
    img: '/apps/dota2/images/dota_react/items/bullwhip.png?t=1593393829403',
    dname: 'Bullwhip',
  },
  '686': {
    id: 686,
    img: '/apps/dota2/images/dota_react/items/quicksilver_amulet.png?t=1593393829403',
    dname: 'Quicksilver Amulet',
  },
  '691': {
    id: 691,
    img: '/apps/dota2/images/dota_react/items/recipe.png?t=1593393829403',
    dname: 'Eternal Shroud Recipe',
  },
  '692': {
    id: 692,
    img: '/apps/dota2/images/dota_react/items/eternal_shroud.png?t=1593393829403',
    dname: 'Eternal Shroud',
    qual: 'epic',
  },
  '725': {
    id: 725,
    img: '/apps/dota2/images/dota_react/items/aghanims_shard_roshan.png?t=1593393829403',
    dname: "Aghanim's Shard - Consumable",
    qual: 'rare',
  },
  '727': {
    id: 727,
    img: '/apps/dota2/images/dota_react/items/ultimate_scepter_roshan.png?t=1593393829403',
    dname: "Aghanim's Blessing - Roshan",
    qual: 'rare',
  },
  '731': {
    id: 731,
    img: '/apps/dota2/images/dota_react/items/satchel.png?t=1593393829403',
    dname: 'Satchel',
    qual: 'common',
  },
  '824': {
    id: 824,
    img: '/apps/dota2/images/dota_react/items/assassins_dagger.png?t=1593393829403',
    dname: "Assassin's Dagger",
  },
  '825': {
    id: 825,
    img: '/apps/dota2/images/dota_react/items/ascetic_cap.png?t=1593393829403',
    dname: "Ascetic's Cap",
  },
  '826': {
    id: 826,
    img: '/apps/dota2/images/dota_react/items/sample_picker.png?t=1593393829403',
    dname: "Assassin's Contract",
  },
  '827': {
    id: 827,
    img: '/apps/dota2/images/dota_react/items/icarus_wings.png?t=1593393829403',
    dname: 'Icarus Wings',
  },
  '828': {
    id: 828,
    img: '/apps/dota2/images/dota_react/items/misericorde.png?t=1593393829403',
    dname: "Brigand's Blade",
  },
  '829': {
    id: 829,
    img: '/apps/dota2/images/dota_react/items/force_field.png?t=1593393829403',
    dname: "Arcanist's Armor",
  },
  '834': {
    id: 834,
    img: '/apps/dota2/images/dota_react/items/black_powder_bag.png?t=1593393829403',
    dname: 'Blast Rig',
  },
  '835': {
    id: 835,
    img: '/apps/dota2/images/dota_react/items/paintball.png?t=1593393829403',
    dname: 'Fae Grenade',
  },
  '836': {
    id: 836,
    img: '/apps/dota2/images/dota_react/items/light_robes.png?t=1593393829403',
    dname: 'Light Robes',
  },
  '837': {
    id: 837,
    img: '/apps/dota2/images/dota_react/items/heavy_blade.png?t=1593393829403',
    dname: 'Witchbane',
  },
  '838': {
    id: 838,
    img: '/apps/dota2/images/dota_react/items/unstable_wand.png?t=1593393829403',
    dname: 'Pig Pole',
  },
  '839': {
    id: 839,
    img: '/apps/dota2/images/dota_react/items/fortitude_ring.png?t=1593393829403',
    dname: 'Ring of Fortitude',
  },
  '840': {
    id: 840,
    img: '/apps/dota2/images/dota_react/items/pogo_stick.png?t=1593393829403',
    dname: "Tumbler's Toy",
  },
  '849': {
    id: 849,
    img: '/apps/dota2/images/dota_react/items/mechanical_arm.png?t=1593393829403',
    dname: 'Mechanical Arm',
  },
  '907': {
    id: 907,
    img: '/apps/dota2/images/dota_react/items/recipe.png?t=1593393829403',
    dname: 'Wraith Pact Recipe',
  },
  '908': {
    id: 908,
    img: '/apps/dota2/images/dota_react/items/wraith_pact.png?t=1593393829403',
    dname: 'Wraith Pact',
    qual: 'rare',
  },
  '910': {
    id: 910,
    img: '/apps/dota2/images/dota_react/items/recipe.png?t=1593393829403',
    dname: "Revenant's Brooch Recipe",
  },
  '911': {
    id: 911,
    img: '/apps/dota2/images/dota_react/items/revenants_brooch.png?t=1593393829403',
    dname: "Revenant's Brooch",
    qual: 'rare',
  },
  '930': {
    id: 930,
    img: '/apps/dota2/images/dota_react/items/recipe.png?t=1593393829403',
    dname: 'Boots of Bearing Recipe',
  },
  '931': {
    id: 931,
    img: '/apps/dota2/images/dota_react/items/boots_of_bearing.png?t=1593393829403',
    dname: 'Boots of Bearing',
    qual: 'rare',
  },
  '938': {
    id: 938,
    img: '/apps/dota2/images/dota_react/items/slime_vial.png?t=1593393829403',
  },
  '939': {
    id: 939,
    img: '/apps/dota2/images/dota_react/items/harpoon.png?t=1593393829403',
    dname: 'Harpoon',
  },
  '940': {
    id: 940,
    img: '/apps/dota2/images/dota_react/items/wand_of_the_brine.png?t=1593393829403',
  },
  '945': {
    id: 945,
    img: '/apps/dota2/images/dota_react/items/seeds_of_serenity.png?t=1593393829403',
    dname: 'Seeds of Serenity',
  },
  '946': {
    id: 946,
    img: '/apps/dota2/images/dota_react/items/lance_of_pursuit.png?t=1593393829403',
    dname: 'Lance of Pursuit',
  },
  '947': {
    id: 947,
    img: '/apps/dota2/images/dota_react/items/occult_bracelet.png?t=1593393829403',
    dname: 'Occult Bracelet',
  },
  '948': {
    id: 948,
    img: '/apps/dota2/images/dota_react/items/tome_of_omniscience.png?t=1593393829403',
  },
  '949': {
    id: 949,
    img: '/apps/dota2/images/dota_react/items/ogre_seal_totem.png?t=1593393829403',
    dname: 'Ogre Seal Totem',
  },
  '950': {
    id: 950,
    img: '/apps/dota2/images/dota_react/items/defiant_shell.png?t=1593393829403',
    dname: 'Defiant Shell',
  },
  '968': {
    id: 968,
    img: '/apps/dota2/images/dota_react/items/arcane_scout.png?t=1593393829403',
  },
  '969': {
    id: 969,
    img: '/apps/dota2/images/dota_react/items/barricade.png?t=1593393829403',
  },
  '990': {
    id: 990,
    img: '/apps/dota2/images/dota_react/items/eye_of_the_vizier.png?t=1593393829403',
    dname: 'Eye of the Vizier',
  },
  '998': {
    id: 998,
    img: '/apps/dota2/images/dota_react/items/manacles_of_power.png?t=1593393829403',
  },
  '1000': {
    id: 1000,
    img: '/apps/dota2/images/dota_react/items/bottomless_chalice.png?t=1593393829403',
  },
  '1017': {
    id: 1017,
    img: '/apps/dota2/images/dota_react/items/wand_of_sanctitude.png?t=1593393829403',
  },
  '1021': {
    id: 1021,
    img: '/apps/dota2/images/dota_react/items/river_painter.png?t=1593393829403',
    dname: 'River Vial: Chrome',
    qual: 'component',
  },
  '1022': {
    id: 1022,
    img: '/apps/dota2/images/dota_react/items/river_painter2.png?t=1593393829403',
    dname: 'River Vial: Dry',
    qual: 'component',
  },
  '1023': {
    id: 1023,
    img: '/apps/dota2/images/dota_react/items/river_painter3.png?t=1593393829403',
    dname: 'River Vial: Slime',
    qual: 'component',
  },
  '1024': {
    id: 1024,
    img: '/apps/dota2/images/dota_react/items/river_painter4.png?t=1593393829403',
    dname: 'River Vial: Oil',
    qual: 'component',
  },
  '1025': {
    id: 1025,
    img: '/apps/dota2/images/dota_react/items/river_painter5.png?t=1593393829403',
    dname: 'River Vial: Electrified',
    qual: 'component',
  },
  '1026': {
    id: 1026,
    img: '/apps/dota2/images/dota_react/items/river_painter6.png?t=1593393829403',
    dname: 'River Vial: Potion',
    qual: 'component',
  },
  '1027': {
    id: 1027,
    img: '/apps/dota2/images/dota_react/items/river_painter7.png?t=1593393829403',
    dname: 'River Vial: Blood',
    qual: 'component',
  },
  '1028': {
    id: 1028,
    img: '/apps/dota2/images/dota_react/items/mutation_tombstone.png?t=1593393829403',
    dname: 'Tombstone',
    qual: 'consumable',
  },
  '1029': {
    id: 1029,
    img: '/apps/dota2/images/dota_react/items/super_blink.png?t=1593393829403',
    dname: 'Super Blink Dagger',
  },
  '1030': {
    id: 1030,
    img: '/apps/dota2/images/dota_react/items/pocket_tower.png?t=1593393829403',
    dname: 'Pocket Tower',
  },
  '1032': {
    id: 1032,
    img: '/apps/dota2/images/dota_react/items/pocket_roshan.png?t=1593393829403',
    dname: 'Pocket Roshan',
    qual: 'rare',
  },
  '1076': {
    id: 1076,
    img: '/apps/dota2/images/dota_react/items/specialists_array.png?t=1593393829403',
    dname: "Specialist's Array",
  },
  '1077': {
    id: 1077,
    img: '/apps/dota2/images/dota_react/items/dagger_of_ristul.png?t=1593393829403',
    dname: 'Dagger of Ristul',
  },
  '1090': {
    id: 1090,
    img: '/apps/dota2/images/dota_react/items/muertas_gun.png?t=1593393829403',
    dname: 'Mercy & Grace',
  },
  '1091': {
    id: 1091,
    img: '/apps/dota2/images/dota_react/items/samurai_tabi.png?t=1593393829403',
    dname: 'Samurai Tabi',
    qual: 'common',
  },
  '1092': {
    id: 1092,
    img: '/apps/dota2/images/dota_react/items/recipe.png?t=1593393829403',
    dname: 'Hermes Sandals Recipe',
  },
  '1093': {
    id: 1093,
    img: '/apps/dota2/images/dota_react/items/hermes_sandals.png?t=1593393829403',
    dname: 'Hermes Sandals',
    qual: 'common',
  },
  '1094': {
    id: 1094,
    img: '/apps/dota2/images/dota_react/items/recipe.png?t=1593393829403',
    dname: 'Lunar Crest Recipe',
  },
  '1095': {
    id: 1095,
    img: '/apps/dota2/images/dota_react/items/lunar_crest.png?t=1593393829403',
    dname: 'Lunar Crest',
    qual: 'common',
  },
  '1096': {
    id: 1096,
    img: '/apps/dota2/images/dota_react/items/recipe.png?t=1593393829403',
    dname: 'Disperser Recipe',
  },
  '1097': {
    id: 1097,
    img: '/apps/dota2/images/dota_react/items/disperser.png?t=1593393829403',
    dname: 'Disperser',
    qual: 'artifact',
  },
  '1098': {
    id: 1098,
    img: '/apps/dota2/images/dota_react/items/recipe.png?t=1593393829403',
    dname: 'Samurai Tabi Recipe',
  },
  '1099': {
    id: 1099,
    img: '/apps/dota2/images/dota_react/items/recipe.png?t=1593393829403',
    dname: 'Witches Switch Recipe',
  },
  '1100': {
    id: 1100,
    img: '/apps/dota2/images/dota_react/items/witches_switch.png?t=1593393829403',
    dname: 'Witches Switch',
    qual: 'common',
  },
  '1101': {
    id: 1101,
    img: '/apps/dota2/images/dota_react/items/recipe.png?t=1593393829403',
    dname: 'Harpoon Recipe',
  },
  '1106': {
    id: 1106,
    img: '/apps/dota2/images/dota_react/items/recipe.png?t=1593393829403',
    dname: 'Phylactery Recipe',
  },
  '1107': {
    id: 1107,
    img: '/apps/dota2/images/dota_react/items/phylactery.png?t=1593393829403',
    dname: 'Phylactery',
    qual: 'common',
  },
  '1122': {
    id: 1122,
    img: '/apps/dota2/images/dota_react/items/diadem.png?t=1593393829403',
    dname: 'Diadem',
    qual: 'component',
  },
  '1123': {
    id: 1123,
    img: '/apps/dota2/images/dota_react/items/blood_grenade.png?t=1593393829403',
    dname: 'Blood Grenade',
    qual: 'consumable',
  },
  '1124': {
    id: 1124,
    img: '/apps/dota2/images/dota_react/items/spark_of_courage.png?t=1593393829403',
    dname: 'Spark of Courage',
  },
  '1125': {
    id: 1125,
    img: '/apps/dota2/images/dota_react/items/cornucopia.png?t=1593393829403',
    dname: 'Cornucopia',
    qual: 'common',
  },
  '1127': {
    id: 1127,
    img: '/apps/dota2/images/dota_react/items/recipe.png?t=1593393829403',
    dname: 'Pavise Recipe',
  },
  '1128': {
    id: 1128,
    img: '/apps/dota2/images/dota_react/items/pavise.png?t=1593393829403',
    dname: 'Pavise',
    qual: 'rare',
  },
  '1154': {
    id: 1154,
    img: '/apps/dota2/images/dota_react/items/royale_with_cheese.png?t=1593393829403',
    dname: 'Block of Cheese',
    qual: 'consumable',
  },
  '1156': {
    id: 1156,
    img: '/apps/dota2/images/dota_react/items/ancient_guardian.png?t=1593393829403',
    dname: 'Ancient Guardian',
  },
  '1157': {
    id: 1157,
    img: '/apps/dota2/images/dota_react/items/safety_bubble.png?t=1593393829403',
    dname: 'Safety Bubble',
  },
  '1158': {
    id: 1158,
    img: '/apps/dota2/images/dota_react/items/whisper_of_the_dread.png?t=1593393829403',
    dname: 'Whisper of the Dread',
  },
  '1159': {
    id: 1159,
    img: '/apps/dota2/images/dota_react/items/nemesis_curse.png?t=1593393829403',
    dname: 'Nemesis Curse',
  },
  '1160': {
    id: 1160,
    img: '/apps/dota2/images/dota_react/items/avianas_feather.png?t=1593393829403',
    dname: "Aviana's Feather",
  },
  '1161': {
    id: 1161,
    img: '/apps/dota2/images/dota_react/items/unwavering_condition.png?t=1593393829403',
    dname: 'Unwavering Condition',
  },
  '1164': {
    id: 1164,
    img: '/apps/dota2/images/dota_react/items/aetherial_halo.png?t=1593393829403',
    dname: 'Aetherial Hammer',
    qual: 'common',
  },
  '1167': {
    id: 1167,
    img: '/apps/dota2/images/dota_react/items/light_collector.png?t=1593393829403',
    dname: 'Light Collector',
  },
  '1168': {
    id: 1168,
    img: '/apps/dota2/images/dota_react/items/rattlecage.png?t=1593393829403',
    dname: 'Rattlecage',
  },
  '1466': {
    id: 1466,
    img: '/apps/dota2/images/dota_react/items/gungir.png?t=1593393829403',
    dname: 'Gleipnir',
    qual: 'artifact',
  },
  '1565': {
    id: 1565,
    img: '/apps/dota2/images/dota_react/items/recipe.png?t=1593393829403',
    dname: 'Gleipnir Recipe',
  },
  '1801': {
    id: 1801,
    img: '/apps/dota2/images/dota_react/items/caster_rapier.png?t=1593393829403',
    dname: 'Caster Rapier',
    qual: 'rare',
  },
  '1802': {
    id: 1802,
    img: '/apps/dota2/images/dota_react/items/tiara_of_selemene.png?t=1593393829403',
    dname: 'Tiara of Selemene',
    qual: 'component',
  },
  '1803': {
    id: 1803,
    img: '/apps/dota2/images/dota_react/items/doubloon.png?t=1593393829403',
    dname: 'Doubloon',
  },
  '1804': {
    id: 1804,
    img: '/apps/dota2/images/dota_react/items/roshans_banner.png?t=1593393829403',
    dname: "Roshan's Banner",
    qual: 'artifact',
  },
  '1805': {
    id: 1805,
    img: '/apps/dota2/images/dota_react/items/recipe.png?t=1593393829403',
    dname: 'Parasma Recipe',
  },
  '1806': {
    id: 1806,
    img: '/apps/dota2/images/dota_react/items/devastator.png?t=1593393829403',
    dname: 'Parasma',
    qual: 'rare',
  },
  '1807': {
    id: 1807,
    img: '/apps/dota2/images/dota_react/items/recipe.png?t=1593393829403',
    dname: 'Khanda Recipe',
  },
  '1808': {
    id: 1808,
    img: '/apps/dota2/images/dota_react/items/angels_demise.png?t=1593393829403',
    dname: 'Khanda',
    qual: 'common',
  },
  '2091': {
    id: 2091,
    img: '/apps/dota2/images/dota_react/items/tier1_token.png?t=1593393829403',
    dname: 'Tier 1 Token',
  },
  '2092': {
    id: 2092,
    img: '/apps/dota2/images/dota_react/items/tier2_token.png?t=1593393829403',
    dname: 'Tier 2 Token',
  },
  '2093': {
    id: 2093,
    img: '/apps/dota2/images/dota_react/items/tier3_token.png?t=1593393829403',
    dname: 'Tier 3 Token',
  },
  '2094': {
    id: 2094,
    img: '/apps/dota2/images/dota_react/items/tier4_token.png?t=1593393829403',
    dname: 'Tier 4 Token',
  },
  '2095': {
    id: 2095,
    img: '/apps/dota2/images/dota_react/items/tier5_token.png?t=1593393829403',
    dname: 'Tier 5 Token',
  },
  '2096': {
    id: 2096,
    img: '/apps/dota2/images/dota_react/items/vindicators_axe.png?t=1593393829403',
    dname: "Vindicator's Axe",
  },
  '2097': {
    id: 2097,
    img: '/apps/dota2/images/dota_react/items/duelist_gloves.png?t=1593393829403',
    dname: 'Duelist Gloves',
  },
  '2098': {
    id: 2098,
    img: '/apps/dota2/images/dota_react/items/horizons_equilibrium.png?t=1593393829403',
    dname: "Horizon's Equilibrium",
  },
  '2099': {
    id: 2099,
    img: '/apps/dota2/images/dota_react/items/blighted_spirit.png?t=1593393829403',
    dname: 'Blighted Spirit',
  },
  '2190': {
    id: 2190,
    img: '/apps/dota2/images/dota_react/items/dandelion_amulet.png?t=1593393829403',
    dname: 'Dandelion Amulet',
  },
  '2191': {
    id: 2191,
    img: '/apps/dota2/images/dota_react/items/turtle_shell.png?t=1593393829403',
    dname: 'Turtle Shell',
  },
  '2192': {
    id: 2192,
    img: '/apps/dota2/images/dota_react/items/martyrs_plate.png?t=1593393829403',
    dname: "Martyr's Plate",
  },
  '2193': {
    id: 2193,
    img: '/apps/dota2/images/dota_react/items/gossamer_cape.png?t=1593393829403',
    dname: 'Gossamer Cape',
  },
  '4204': {
    id: 4204,
    img: '/apps/dota2/images/dota_react/items/famango.png?t=1593393829403',
    dname: 'Healing Lotus',
    qual: 'consumable',
  },
  '4205': {
    id: 4205,
    img: '/apps/dota2/images/dota_react/items/great_famango.png?t=1593393829403',
    dname: 'Great Healing Lotus',
    qual: 'consumable',
  },
  '4206': {
    id: 4206,
    img: '/apps/dota2/images/dota_react/items/greater_famango.png?t=1593393829403',
    dname: 'Greater Healing Lotus',
    qual: 'consumable',
  },
  '4300': {
    id: 4300,
    img: '/apps/dota2/images/dota_react/items/ofrenda.png?t=1593393829403',
    dname: 'Beloved Memory',
  },
  '4301': {
    id: 4301,
    img: '/apps/dota2/images/dota_react/items/ofrenda_shovel.png?t=1593393829403',
    dname: 'Scrying Shovel',
  },
  '4302': {
    id: 4302,
    img: '/apps/dota2/images/dota_react/items/ofrenda_pledge.png?t=1593393829403',
    dname: "Forebearer's Fortune",
  },
};

const tier1 = [
  {
    id: 1124,
    img: '/apps/dota2/images/dota_react/items/spark_of_courage.png?t=1593393829403',
    dname: 'Spark of Courage',
  },
  {
    id: 1157,
    img: '/apps/dota2/images/dota_react/items/safety_bubble.png?t=1593393829403',
    dname: 'Safety Bubble',
  },
  {
    id: 838,
    img: '/apps/dota2/images/dota_react/items/unstable_wand.png?t=1593393829403',
    dname: 'Pig Pole',
  },
  {
    id: 946,
    img: '/apps/dota2/images/dota_react/items/lance_of_pursuit.png?t=1593393829403',
    dname: 'Lance of Pursuit',
  },
  {
    id: 375,
    img: '/apps/dota2/images/dota_react/items/faded_broach.png?t=1593393829403',
    dname: 'Faded Broach',
  },
  {
    id: 355,
    img: '/apps/dota2/images/dota_react/items/broom_handle.png?t=1593393829403',
    dname: 'Broom Handle',
  },
  {
    id: 356,
    img: '/apps/dota2/images/dota_react/items/trusty_shovel.png?t=1593393829403',
    dname: 'Trusty Shovel',
  },
  {
    id: 945,
    img: '/apps/dota2/images/dota_react/items/seeds_of_serenity.png?t=1593393829403',
    dname: 'Seeds of Serenity',
  },
  {
    id: 305,
    img: '/apps/dota2/images/dota_react/items/royal_jelly.png?t=1593393829403',
    dname: 'Royal Jelly',
    qual: 'consumable',
  },
  {
    id: 947,
    img: '/apps/dota2/images/dota_react/items/occult_bracelet.png?t=1593393829403',
    dname: 'Occult Bracelet',
  },
  {
    id: 589,
    img: '/apps/dota2/images/dota_react/items/mysterious_hat.png?t=1593393829403',
    dname: "Fairy's Trinket",
  },
  {
    id: 2097,
    img: '/apps/dota2/images/dota_react/items/duelist_gloves.png?t=1593393829403',
    dname: 'Duelist Gloves',
  },
  {
    id: 349,
    img: '/apps/dota2/images/dota_react/items/arcane_ring.png?t=1593393829403',
    dname: 'Arcane Ring',
  },
];

const tier2 = [
  {
    id: 297,
    img: '/apps/dota2/images/dota_react/items/vampire_fangs.png?t=1593393829403',
    dname: 'Vampire Fangs',
  },
  {
    id: 1076,
    img: '/apps/dota2/images/dota_react/items/specialists_array.png?t=1593393829403',
    dname: "Specialist's Array",
  },
  {
    id: 290,
    img: '/apps/dota2/images/dota_react/items/philosophers_stone.png?t=1593393829403',
    dname: "Philosopher's Stone",
  },
  {
    id: 1167,
    img: '/apps/dota2/images/dota_react/items/light_collector.png?t=1593393829403',
    dname: 'Light Collector',
  },
  {
    id: 2193,
    img: '/apps/dota2/images/dota_react/items/gossamer_cape.png?t=1593393829403',
    dname: 'Gossamer Cape',
  },
  {
    id: 358,
    img: '/apps/dota2/images/dota_react/items/dragon_scale.png?t=1593393829403',
    dname: 'Dragon Scale',
  },
  {
    id: 1158,
    img: '/apps/dota2/images/dota_react/items/whisper_of_the_dread.png?t=1593393829403',
    dname: 'Whisper of the Dread',
  },
  {
    id: 331,
    img: '/apps/dota2/images/dota_react/items/vambrace.png?t=1593393829403',
    dname: 'Vambrace',
    qual: 'common',
  },
  {
    id: 306,
    img: '/apps/dota2/images/dota_react/items/pupils_gift.png?t=1593393829403',
    dname: "Pupil's Gift",
  },
  {
    id: 378,
    img: '/apps/dota2/images/dota_react/items/orb_of_destruction.png?t=1593393829403',
    dname: 'Orb of Destruction',
  },
  {
    id: 288,
    img: '/apps/dota2/images/dota_react/items/grove_bow.png?t=1593393829403',
    dname: 'Grove Bow',
  },
  {
    id: 990,
    img: '/apps/dota2/images/dota_react/items/eye_of_the_vizier.png?t=1593393829403',
    dname: 'Eye of the Vizier',
  },
  {
    id: 680,
    img: '/apps/dota2/images/dota_react/items/bullwhip.png?t=1593393829403',
    dname: 'Bullwhip',
  },
];

const tier3 = [
  {
    id: 675,
    img: '/apps/dota2/images/dota_react/items/psychic_headband.png?t=1593393829403',
    dname: 'Psychic Headband',
  },
  {
    id: 949,
    img: '/apps/dota2/images/dota_react/items/ogre_seal_totem.png?t=1593393829403',
    dname: 'Ogre Seal Totem',
  },
  {
    id: 361,
    img: '/apps/dota2/images/dota_react/items/enchanted_quiver.png?t=1593393829403',
    dname: 'Enchanted Quiver',
  },
  {
    id: 1803,
    img: '/apps/dota2/images/dota_react/items/doubloon.png?t=1593393829403',
    dname: 'Doubloon',
  },
  {
    id: 2190,
    img: '/apps/dota2/images/dota_react/items/dandelion_amulet.png?t=1593393829403',
    dname: 'Dandelion Amulet',
  },
  {
    id: 574,
    img: '/apps/dota2/images/dota_react/items/cloak_of_flames.png?t=1593393829403',
    dname: 'Cloak of Flames',
  },
  {
    id: 2096,
    img: '/apps/dota2/images/dota_react/items/vindicators_axe.png?t=1593393829403',
    dname: "Vindicator's Axe",
  },
  {
    id: 376,
    img: '/apps/dota2/images/dota_react/items/paladin_sword.png?t=1593393829403',
    dname: 'Paladin Sword',
  },
  {
    id: 1159,
    img: '/apps/dota2/images/dota_react/items/nemesis_curse.png?t=1593393829403',
    dname: 'Nemesis Curse',
  },
  {
    id: 573,
    img: '/apps/dota2/images/dota_react/items/elven_tunic.png?t=1593393829403',
    dname: 'Elven Tunic',
  },
  {
    id: 950,
    img: '/apps/dota2/images/dota_react/items/defiant_shell.png?t=1593393829403',
    dname: 'Defiant Shell',
  },
  {
    id: 298,
    img: '/apps/dota2/images/dota_react/items/craggy_coat.png?t=1593393829403',
    dname: 'Craggy Coat',
  },
  {
    id: 676,
    img: '/apps/dota2/images/dota_react/items/ceremonial_robe.png?t=1593393829403',
    dname: 'Ceremonial Robe',
  },
];

const tier4 = [
  {
    id: 300,
    img: '/apps/dota2/images/dota_react/items/timeless_relic.png?t=1593393829403',
    dname: 'Timeless Relic',
  },
  {
    id: 309,
    img: '/apps/dota2/images/dota_react/items/mind_breaker.png?t=1593393829403',
    dname: 'Mind Breaker',
  },
  {
    id: 336,
    img: '/apps/dota2/images/dota_react/items/spy_gadget.png?t=1593393829403',
    dname: 'Telescope',
  },
  {
    id: 362,
    img: '/apps/dota2/images/dota_react/items/ninja_gear.png?t=1593393829403',
    dname: 'Ninja Gear',
  },
  {
    id: 364,
    img: '/apps/dota2/images/dota_react/items/havoc_hammer.png?t=1593393829403',
    dname: 'Havoc Hammer',
  },
  {
    id: 571,
    img: '/apps/dota2/images/dota_react/items/trickster_cloak.png?t=1593393829403',
    dname: 'Trickster Cloak',
  },
  {
    id: 585,
    img: '/apps/dota2/images/dota_react/items/stormcrafter.png?t=1593393829403',
    dname: 'Stormcrafter',
  },
  {
    id: 825,
    img: '/apps/dota2/images/dota_react/items/ascetic_cap.png?t=1593393829403',
    dname: "Ascetic's Cap",
  },
  {
    id: 1156,
    img: '/apps/dota2/images/dota_react/items/ancient_guardian.png?t=1593393829403',
    dname: 'Ancient Guardian',
  },
  {
    id: 1160,
    img: '/apps/dota2/images/dota_react/items/avianas_feather.png?t=1593393829403',
    dname: "Aviana's Feather",
  },
  {
    id: 2192,
    img: '/apps/dota2/images/dota_react/items/martyrs_plate.png?t=1593393829403',
    dname: "Martyr's Plate",
  },
  {
    id: 1168,
    img: '/apps/dota2/images/dota_react/items/rattlecage.png?t=1593393829403',
    dname: 'Rattlecage',
  },
];

const tier5 = [
  [
    {
      id: 291,
      img: '/apps/dota2/images/dota_react/items/force_boots.png?t=1593393829403',
      dname: 'Force Boots',
    },
    {
      id: 292,
      img: '/apps/dota2/images/dota_react/items/desolator_2.png?t=1593393829403',
      dname: 'Stygian Desolator',
    },
    {
      id: 294,
      img: '/apps/dota2/images/dota_react/items/seer_stone.png?t=1593393829403',
      dname: 'Seer Stone',
    },
    {
      id: 829,
      img: '/apps/dota2/images/dota_react/items/force_field.png?t=1593393829403',
      dname: "Arcanist's Armor",
    },
    {
      id: 301,
      img: '/apps/dota2/images/dota_react/items/mirror_shield.png?t=1593393829403',
      dname: 'Mirror Shield',
    },
    {
      id: 365,
      img: '/apps/dota2/images/dota_react/items/panic_button.png?t=1593393829403',
      dname: 'Magic Lamp',
    },
    {
      id: 366,
      img: '/apps/dota2/images/dota_react/items/apex.png?t=1593393829403',
      dname: 'Apex',
    },
    {
      id: 370,
      img: '/apps/dota2/images/dota_react/items/demonicon.png?t=1593393829403',
      dname: 'Book of the Dead',
    },
    {
      id: 372,
      img: '/apps/dota2/images/dota_react/items/pirate_hat.png?t=1593393829403',
      dname: 'Pirate Hat',
    },
    {
      id: 677,
      img: '/apps/dota2/images/dota_react/items/book_of_shadows.png?t=1593393829403',
      dname: 'Book of Shadows',
    },
    {
      id: 678,
      img: '/apps/dota2/images/dota_react/items/giants_ring.png?t=1593393829403',
      dname: "Giant's Ring",
    },
    {
      id: 1161,
      img: '/apps/dota2/images/dota_react/items/unwavering_condition.png?t=1593393829403',
      dname: 'Unwavering Condition',
    },
  ],
];
