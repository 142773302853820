import { makeStyles } from 'theme';

export const useStyle = makeStyles()((theme) => ({
  adCards: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '24px',
    paddingRight: '8px',
  },
  banner: {
    width: '100%',
    marginBottom: 40,
  },
  leaderBoard: {
    background:
      'linear-gradient(38.92deg, rgba(22, 28, 40, 0.4) 0%, rgba(22, 28, 40, 0.1) 134.39%)',
    backdropFilter: 'blur(6px)',
  },
  leaderboardContainer: {
    display: 'flex',
    marginBottom: 40,
    marginTop: 40,
    gap: 24,
    '@media (max-width: 1400px)': {
      paddingRight: 8,
    },
  },
  footerContainer: {
    width: '100%',
    marginBottom: 48,
    '@media (max-width: 1400px)': {
      paddingRight: 8,
    },
  },
  imgContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 48,
    maxWidth: 1232,
    minWidth: 840,
    height: 140,
    borderRadius: 6,
    backgroundImage: 'url("/images/main-page/footer/footer-bg.jpg")',
    backgroundSize: 'cover',
    padding: '16px 32px',
  },
}));
