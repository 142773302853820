import { makeStyles } from 'theme';

export const useStyle = makeStyles()((theme) => ({
  container: {
    ...theme.layouts.flexAICenter,
    color: theme.palette.typo.primary,
  },
  prevButton: {
    ...theme.layouts.flexAICenter,
    marginRight: '0.75rem',
  },
  prevWithoutError: {
    marginRight: '1.25rem',
  },
  nextButton: {
    ...theme.layouts.flexAICenter,
    marginLeft: '0.75rem',
  },
  nextWithoutArrow: {
    marginLeft: '1.25rem',
  },
  prevArrow: {
    transform: 'rotate(90deg)',
    color: theme.palette.typo.primary,
    marginRight: '0.5rem',
  },
  nextArrow: {
    transform: 'rotate(-90deg)',
    color: theme.palette.typo.primary,
    marginLeft: '0.5rem',
  },
  disabled: {
    opacity: 0.5,
  },
  select: {
    marginLeft: '1.75rem',
    width: 80,
  },
}));
