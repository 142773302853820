import { FC } from 'react';

import { Typography } from '../Typography';

interface IErrorMessageProps {
  message: string;
  className?: string;
}

export const ErrorMessage: FC<IErrorMessageProps> = ({
  className,
  message,
}) => {
  const { Text } = Typography;
  return (
    <Text className={className} color="alert" variant="b6">
      {message}
    </Text>
  );
};
