export const challengeConfig = {
  title: 'challenges-about.player-journey.title',
  steps: {
    stepFirst: {
      titleKey: 'challenges-about.player-journey.step-1.title',
      descriptionKey: 'challenges-about.player-journey.step-1.description',
    },
    stepSecond: {
      titleKey: 'challenges-about.player-journey.step-2.title',
      descriptionKey: 'challenges-about.player-journey.step-2.description',
    },
    stepThird: {
      titleKey: 'challenges-about.player-journey.step-3.title',
      descriptionKey: 'challenges-about.player-journey.step-3.description',
    },
    stepFourth: {
      titleKey: 'challenges-about.player-journey.step-4.title',
      descriptionKey: 'challenges-about.player-journey.step-4.description',
    },
  },
  heroes: {
    heroFirstUrl: '/images/challenges/dota2-hero-1.png',
    heroSecondUrl: '/images/challenges/dota2-hero-2.png',
  },
};

export const challengeOwnerConfig = {
  title: 'challenges-about.owner-journey.title',
  steps: {
    stepFirst: {
      titleKey: 'challenges-about.owner-journey.step-1.title',
      descriptionKey: 'challenges-about.owner-journey.step-1.description',
    },
    stepSecond: {
      titleKey: 'challenges-about.owner-journey.step-2.title',
      descriptionKey: 'challenges-about.owner-journey.step-2.description',
    },
    stepThird: {
      titleKey: 'challenges-about.owner-journey.step-3.title',
      descriptionKey: 'challenges-about.owner-journey.step-3.description',
    },
    stepFourth: {
      titleKey: 'challenges-about.owner-journey.step-4.title',
      descriptionKey: 'challenges-about.owner-journey.step-4.description',
    },
  },
  heroes: {
    heroFirstUrl: '/images/challenges/dota2-hero-3.png',
    heroSecondUrl: '/images/challenges/dota2-hero-4.png',
  },
};
