import { FC, useRef } from 'react';

import { useTranslation } from 'react-i18next';

import { useClickOutside } from 'hooks';
import { Button, CircleIcon, Typography } from 'ui-kit';

import { Modal } from '../Modal';
import { useStyle } from './WalletSuccess.styles';

interface IWalletSuccessProps {
  isOpen: boolean;
  onClose: () => void;
}

export const WalletSuccess: FC<IWalletSuccessProps> = ({ isOpen, onClose }) => {
  const { classes } = useStyle();
  const { Text, Headline } = Typography;
  const { t: translation } = useTranslation();
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className={classes.modal}>
        <CircleIcon type="success" className={classes.icon} />
        <Headline variant="h3">{translation('profile.success.title')}</Headline>
        <Text>{translation('profile.success.description')}</Text>
        <Button onClick={onClose} className={classes.button} buttonSize="l">
          {translation('profile.success.btn')}
        </Button>
        <Text variant="b5" className={classes.help}>
          {translation('profile.success.help')}{' '}
          <Text variant="b5" color="link">
            <a
              href="https://discord.gg/7fKEWhnh4x"
              target="_blank"
              rel="noreferrer"
            >
              Discord
            </a>
          </Text>{' '}
        </Text>
      </div>
    </Modal>
  );
};
