import { FC, useRef } from 'react';

import { useTranslation } from 'react-i18next';

import { useClickOutside } from 'hooks';
import { Button, Typography } from 'ui-kit';

import { Modal } from '../Modal';
import { useStyle } from './CreateChallengePermission.styles';

interface ICreateChallengePermissionProps {
  onClose: () => void;
  isOpen: boolean;
}

export const CreateChallengePermission: FC<ICreateChallengePermissionProps> = ({
  onClose,
  isOpen,
}) => {
  const { classes } = useStyle();
  const ref = useRef();
  useClickOutside(ref, onClose);
  const { Text, Headline } = Typography;
  const { t: translation } = useTranslation();
  return (
    <Modal onClose={onClose} isOpen={isOpen}>
      <div ref={ref} className={classes.container}>
        <Headline variant="h3">{translation('createPerm.title')}</Headline>
        <Text variant="b2">{translation('createPerm.description')}</Text>
        <div className={classes.btnContainer}>
          <Button color="secondary" onClick={onClose}>
            {translation('createPerm.cancelBtn')}
          </Button>
          <a
            target="_blank"
            href="https://discord.gg/7fKEWhnh4x"
            rel="noreferrer"
          >
            <Button>{translation('createPerm.discord')}</Button>
          </a>
        </div>
      </div>
    </Modal>
  );
};
