import { FC } from 'react';

import clsx from 'clsx';

import { EWinnerPlace, removeZeroesAfterComma } from 'helpers';
import { ChallengeRewardIcon } from 'icons';
import { Card, Typography } from 'ui-kit';

import { useStyle } from '../../ChallengeView.styles';
import { colorShadowMap } from './utils';

export const WinnerEqual: FC<{
  reward: number;
  winnerCount: number;
  winnersActual: number;
}> = ({ reward, winnerCount, winnersActual }) => {
  const { classes } = useStyle();
  const { Headline } = Typography;
  return (
    <Card
      className={clsx(
        classes.winnersEqualContainer,
        !!winnersActual && classes.winnersEqualOpenList
      )}
      variant="p16"
      color="bgSecondary"
    >
      <div className={classes.winnerPlaceHolderContainer}>
        <div
          className={clsx(
            classes.winnersLeftBar,
            classes['gradient-text-gold'],
            classes[colorShadowMap[EWinnerPlace.FIRST]]
          )}
        />
        <div className={classes.trophyEqualContainer}>
          <Headline variant="h2">
            <ChallengeRewardIcon type="gold" className={classes.equalTrophy} />
            <span className={classes.winnersAmount}>
              <span className={classes.winnersNumber}>
                {`${winnersActual} / ${winnerCount} `}
              </span>
            </span>
          </Headline>
        </div>
        <Headline variant="h4">
          <span className={classes.winnersReward}>
            {`${removeZeroesAfterComma((+reward)?.toFixed(2))} GC`}
          </span>
        </Headline>
      </div>
    </Card>
  );
};
