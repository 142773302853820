import { makeStyles } from 'theme';

export const useStyle = makeStyles()((theme) => ({
  modalContainer: {
    width: 1303,
    height: 532,
    backgroundColor: theme.palette.bg.secondary,
    display: 'flex',
    justifyContent: 'space-between',
    padding: 32,
    borderRadius: 8,
  },
  leftBlock: {
    width: 'calc((100% - 12px) / 2)',
  },
  rightBlock: {
    width: 'calc((100% - 12px) / 2)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  selectButton: {
    marginLeft: '1rem',
  },
  imageTable: {
    width: 580,
    height: 420,
    display: 'flex',
    marginTop: 8,
    padding: 8,
    flexWrap: 'wrap',
    alignContent: 'flex-start',
    justifyItems: 'flex-start',
    rowGap: '8px',
    borderRadius: 8,
    overflowY: 'scroll',
    backgroundColor: `${theme.palette.bg.tertiary} !important`,
    '::-webkit-scrollbar': {
      width: 4,
    },
    '::-webkit-scrollbar-thumb': {
      background: theme.palette.control.secondary.bgBorder,
      borderRadius: 11,
      boxShadow: '0px 4px 9px rgba(0, 0, 0, 0.25)',
    },
  },
  image: {
    width: 180,
    height: 85,
    borderRadius: 4,
    marginRight: 8,
    border: '1px solid transparent',
    transitionDuration: '300ms',
    ':hover': {
      border: `1px solid ${theme.palette.typo.link}`,
    },
    ':nth-child(3n)': {
      marginRight: 0,
    },
  },
  activeImage: {
    border: `1px solid ${theme.palette.typo.link}`,
  },
  imagePreview: {
    width: 655,
    height: 317,
    borderRadius: 8,
    marginTop: 43,
  },
  btnContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
  },
}));
