import type { FC } from 'react';

import type { ICommonIconProps } from './types';

export const LinkIcon: FC<ICommonIconProps> = ({
  width = 20,
  height = 20,
  viewBox = '0 0 20 20',
  className,
  style,
}) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M3.51907 16.4814C3.90564 16.8685 4.36488 17.1755 4.87042 17.3845C5.37596 17.5936 5.91784 17.7007 6.46491 17.6998C7.0121 17.7007 7.55409 17.5936 8.05976 17.3845C8.56544 17.1754 9.02483 16.8685 9.41158 16.4814L11.7682 14.1239L10.5899 12.9456L8.23324 15.3031C7.76363 15.7706 7.12797 16.0331 6.46532 16.0331C5.80268 16.0331 5.16702 15.7706 4.69741 15.3031C4.22949 14.8337 3.96675 14.198 3.96675 13.5352C3.96675 12.8724 4.22949 12.2367 4.69741 11.7673L7.05491 9.4106L5.87657 8.23226L3.51907 10.5889C2.73891 11.371 2.30078 12.4305 2.30078 13.5352C2.30078 14.6398 2.73891 15.6994 3.51907 16.4814V16.4814ZM16.4824 9.4106C17.2622 8.62834 17.7 7.56886 17.7 6.46435C17.7 5.35983 17.2622 4.30036 16.4824 3.5181C15.7004 2.73794 14.6408 2.2998 13.5362 2.2998C12.4315 2.2998 11.372 2.73794 10.5899 3.5181L8.23324 5.8756L9.41158 7.05393L11.7682 4.69643C12.2378 4.22892 12.8735 3.96646 13.5362 3.96646C14.1988 3.96646 14.8345 4.22892 15.3041 4.69643C15.772 5.16582 16.0347 5.80157 16.0347 6.46435C16.0347 7.12713 15.772 7.76287 15.3041 8.23226L12.9466 10.5889L14.1249 11.7673L16.4824 9.4106Z"
      fill="currentColor"
    />
    <path
      d="M7.05417 14.125L5.875 12.9467L12.9475 5.875L14.1258 7.05417L7.05417 14.125Z"
      fill="currentColor"
    />
  </svg>
);
