import { FC } from 'react';

import { ICommonIconProps } from './types';

export const UiCheckIcon: FC<ICommonIconProps> = ({
  color = 'currentColor',
  width = 32,
  height = 32,
  viewBox = '0 0 32 32',
  className = '',
  style,
}) => (
  <svg
    fill="none"
    width={width}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.7998 8.77835C27.3246 9.30314 27.3246 10.154 26.7998 10.6788L14.2578 23.2208C13.733 23.7456 12.8821 23.7456 12.3574 23.2208L5.19046 16.0539C4.66568 15.5292 4.66568 14.6783 5.19046 14.1535C5.71525 13.6287 6.56609 13.6287 7.09087 14.1535L13.3076 20.3702L24.8994 8.77835C25.4242 8.25357 26.2751 8.25357 26.7998 8.77835Z"
      fill={color}
    />
  </svg>
);
