import { makeStyles } from 'theme';

export const useStyle = makeStyles()((theme) => ({
  divider: {
    width: 1,
    height: '100%',
    borderRadius: 2,
    backgroundColor: theme.palette.bg.border,
  },
}));
