import { FC, useContext, useEffect, useState } from 'react';

import { motion } from 'framer-motion';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import { v4 as uuid } from 'uuid';

import { IUserResponse } from 'api';
import { EViewChallengeMode, CHALLENGE_FORM_DATA_KEY } from 'consts';
import { getPersistedForm, usePersistForm } from 'hooks';
import { BackgroundContext } from 'Layout';

import {
  CostAndReward,
  Footer,
  GameOptions,
  General,
  HeroAndItemBuild,
  StepNavigator,
} from './components';
import { EStepStatus } from './components/StepNavigator/Step/Step';
import { StepNavigatorOptions } from './components/StepNavigator/StepNavigator';
import {
  ChallengeFormValueKey,
  ChallengeFormValueType,
  defaultValues,
  isHeroDefault,
  isItemsDefault,
  isOptionsDefault,
  preparePreviewChallengeData,
  validationFields,
} from './CreateChallenge.model';
import { useStyle } from './CreateChallenge.styles';

export const CreateChallenge: FC = () => {
  const navigate = useNavigate();
  const { data: user } = useSWR<IUserResponse>('api/user');
  const { classes } = useStyle();

  const { setBackgroundState, backgroundState } = useContext(BackgroundContext);
  const [stepStatuses, setStepStatuses] = useState<EStepStatus[]>([
    EStepStatus.Inactive,
    EStepStatus.Inactive,
    EStepStatus.Inactive,
    EStepStatus.Inactive,
  ]);

  const persistedValues =
    getPersistedForm<ChallengeFormValueType>(CHALLENGE_FORM_DATA_KEY) ??
    defaultValues;

  const methods = useForm<ChallengeFormValueType>({
    defaultValues: {
      ...defaultValues,
      freeForAll: +persistedValues.entryCost <= 0,
      ...persistedValues,
    },
  });
  const randomId = uuid();

  const {
    formState: { errors },
    getValues,
    trigger,
    setFocus,
    control,
    reset,
  } = methods;

  const options = useWatch({ name: 'options', control });
  const winInsensitivity = useWatch({ name: 'winInsensitivity', control });
  const hero = useWatch({ name: 'hero', control });
  const items = useWatch({ name: 'items', control });

  const calcStepStatus = (stepNumber: number): EStepStatus[] => {
    return [null, null, null, null].map((_, index) => {
      if (index === stepNumber) {
        setBackgroundState(index);
        return EStepStatus.Active;
      }
      if (index > stepNumber) {
        return EStepStatus.Inactive;
      }
      if (
        index < stepNumber &&
        index === 1 &&
        isHeroDefault(hero) &&
        isItemsDefault(items)
      ) {
        return EStepStatus.Skipped;
      }
      if (
        index < stepNumber &&
        index === 2 &&
        isOptionsDefault(options, winInsensitivity)
      ) {
        return EStepStatus.Skipped;
      }
      return EStepStatus.Complete;
    });
  };

  const onPreview = async (): Promise<void> => {
    const data = getValues();
    await preparePreviewChallengeData(data, user?.username, randomId);
    reset(defaultValues);
    navigate(`/challenges/${randomId}?mode=${EViewChallengeMode.Preview}`);
  };

  useEffect(() => {
    const errorNameFiled = Object.keys(errors)[0] as ChallengeFormValueKey;
    if (errorNameFiled) {
      setFocus(errorNameFiled);
    }
  }, [errors, setFocus]);

  usePersistForm(getValues(), CHALLENGE_FORM_DATA_KEY);

  const isStepValid = async (stepIndex: number): Promise<boolean> => {
    if (stepIndex === 0) {
      return await trigger(validationFields.firsStep);
    }
    if (stepIndex === 3) {
      return await trigger(validationFields.fourthStep);
    }
    return true;
  };

  const onStepClick = async (stepNumber: number): Promise<void> => {
    const isValid = await isStepValid(backgroundState);
    if (backgroundState === 3 && stepNumber !== 4) {
      setBackgroundState(stepNumber);
    }
    if (!isValid) {
      return;
    }
    if (stepNumber < 4) {
      setBackgroundState(stepNumber);
    } else {
      onPreview();
    }
  };

  const handleNextStepClick = (): Promise<void> =>
    onStepClick(backgroundState + 1);
  const handlePrevStepClick = (): Promise<void> =>
    onStepClick(backgroundState - 1);

  useEffect(() => {
    setStepStatuses(calcStepStatus(backgroundState));
  }, [backgroundState]);

  const { t: translation } = useTranslation();

  const stepNavigatorOptions: StepNavigatorOptions[] = [
    { label: translation('challenge.form.step.first') },
    { label: translation('challenge.form.step.second') },
    { label: translation('challenge.form.step.third') },
    { label: translation('challenge.form.step.fourth') },
  ];

  return (
    <div className={classes.formContainer}>
      <FormProvider {...methods}>
        <StepNavigator
          setSelectedStep={onStepClick}
          stepStatuses={stepStatuses}
          stepsOptions={stepNavigatorOptions}
        />
        <form autoComplete="false">
          {backgroundState === 0 && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5 }}
            >
              <General />
            </motion.div>
          )}
          {backgroundState === 1 && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5 }}
            >
              <HeroAndItemBuild />
            </motion.div>
          )}
          {backgroundState === 2 && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5 }}
            >
              <GameOptions />{' '}
            </motion.div>
          )}
          {backgroundState === 3 && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5 }}
            >
              <CostAndReward />
            </motion.div>
          )}
          <Footer
            currentStep={backgroundState}
            onNextClick={handleNextStepClick}
            onPrevClick={handlePrevStepClick}
          />
        </form>
      </FormProvider>
    </div>
  );
};
