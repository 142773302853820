import { makeStyles } from 'theme';

export const useStyle = makeStyles()(() => ({
  root: {
    background: 'url("images/not-found/not-found-bg.jpg") no-repeat',
    backgroundSize: 'cover',
    width: '100vw',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    fontSize: '32px',
    color: 'white',
  },
  img: {
    width: '50%',
  },
  content: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 24,
  },
  footer: {
    marginLeft: 0,
    width: '100%',
  },
}));
