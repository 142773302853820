import { makeStyles } from 'theme';

export const useStyle = makeStyles<{
  isOpen: boolean;
  withSideIcon: boolean;
}>()((theme, { isOpen, withSideIcon }) => ({
  dropdown: {
    ...theme.layouts.flexAICenter,
    cursor: 'pointer',
    padding: '8px 8px 8px 12px',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.bg.secondary,
    position: 'relative',
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    minWidth: 120,
    borderLeft: `1px solid ${theme.palette.control.default.bgBorder}`,
    color: `${
      isOpen
        ? theme.palette.control.default.typo
        : theme.palette.control.default.typoLabel
    } !important`,
    span: {
      transitionDuration: '300ms',
      color: `${
        isOpen
          ? theme.palette.control.default.typo
          : theme.palette.control.default.typoLabel
      } !important`,
    },
    ':hover': {
      '> svg': {
        color: `${theme.palette.control.default.bgBorderHover} !important`,
      },
      '> span': {
        transitionDuration: '300ms',
        color: `${theme.palette.control.default.bgBorderHover} !important`,
      },
    },
  },
  arrowIcon: {
    transform: `${isOpen ? 'rotate(180deg)' : 'rotate(0deg)'}`,
    transitionDuration: '300ms',
  },
  options: {
    position: 'absolute',
    top: '100%',
    left: 0,
    width: '100%',
    backgroundColor: theme.palette.control.default.bg,
    border: `2px solid ${theme.palette.control.default.bgBorder}`,
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    padding: 16,
    gap: 16,
  },
  option: {
    display: 'inline-block',
    transitionDuration: '300ms',
    color: theme.palette.control.default.typo,
    ':hover': {
      transitionDuration: '300ms',
      color: `${theme.palette.control.default.bgBorderHover} !important`,
    },
    cursor: 'pointer',
  },
  subcontainer: {
    position: 'relative',
    width: 'fit-content',
    display: 'flex',
    boxShadow: isOpen && withSideIcon && '0px 0px 0px 2.8px #94C5FF',
    transitionDuration: '300ms',
    border:
      isOpen && withSideIcon
        ? `2px solid ${theme.palette.typo.link}`
        : '2px solid transparent',
    boxSizing: 'border-box',
    borderRadius: 4,
  },
  iconContainer: {
    cursor: 'pointer',
    height: 36,
    width: 36,
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    backgroundColor: theme.palette.bg.secondary,
    color: theme.palette.typo.system,
    transitionDuration: '300ms',
    ':hover': {
      transitionDuration: '300ms',
      color: theme.palette.control.default.bgBorderHover,
    },
    ...theme.layouts.flexCenter,
  },
}));
