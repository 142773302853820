import { FC, useState } from 'react';

import { AnimatePresence, motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useSWR, { mutate } from 'swr';

import { IListedParry, isErrorResponse, startParry } from 'api';
import { GameCard } from 'components';
import { EParryStatus } from 'consts';
import { InfinityIcon, LinkIcon } from 'icons';
import { Card, Dividers, Timer, Typography } from 'ui-kit';

import { EParryModals, getIsWinner } from '../../ParryView.model';
import { AvatarList } from '../AvatarList';
import { OwnerHeader } from './OwnerHeader';
import { useStyle } from './ParryHeader.styles';

interface IParryHeaderProps {
  parry: IListedParry;
  onOpenModal: (mode: EParryModals) => void;
  onStopCycleClick: () => void;
}

export const ParryHeader: FC<IParryHeaderProps> = ({
  parry,
  onOpenModal,
  onStopCycleClick,
}) => {
  const [isCopied, setIsCopied] = useState<boolean>(false);
  const { t: translation } = useTranslation();
  const [searchParams] = useSearchParams();
  const isPreview = searchParams.get('mode') === 'preview';

  const {
    participants,
    owner,
    title,
    status,
    isOwner,
    isParticipant,
    avatars,
    activeParticipants,
    toWin,
    toLose,
  } = parry;
  const { Headline, Text } = Typography;
  const { VerticalDivider } = Dividers;

  const navigate = useNavigate();
  const { data: user } = useSWR('api/user');
  const isDone = status === EParryStatus.Done;
  const isActive = status === EParryStatus.Active;
  const hasControl = isOwner;
  const onCopyLinkClick = (): void => {
    if (!isPreview && !isCopied) {
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 1000);
      navigator.clipboard.writeText(window.location.href);
    }
  };
  const onStopRegistrationClick = (): void => onOpenModal(EParryModals.Stop);
  const onStartParryClick = async (): Promise<void> => {
    try {
      const parryResponse = await startParry(parry.id);
      if (!isErrorResponse(parryResponse)) {
        mutate(`api/parry/${parry.id}`, parryResponse);
        navigate(`/parries/${parry.id}`);
      }
    } catch (err) {
      console.error('Failed to create draft', err);
    }
  };

  const { classes } = useStyle({});

  return (
    <Card
      variant="parryHeader"
      className={classes.headerContainer}
      color="bgSecondary"
    >
      <GameCard
        className={classes.game}
        isParticipant={isParticipant && isActive}
      />
      <div className={classes.titleContainer}>
        <Headline variant="h2">{title}</Headline>
        {!isPreview && (
          <span onClick={onCopyLinkClick}>
            <Text color={isCopied ? 'success' : 'primary'}>
              <LinkIcon className={classes.copyIcon} />
            </Text>
            <AnimatePresence>
              {isCopied && (
                <motion.span
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.3 }}
                >
                  <Text variant="b5" color="success">
                    {translation('parryView.copy')}
                  </Text>
                </motion.span>
              )}
            </AnimatePresence>
          </span>
        )}
      </div>
      <div className={classes.infoContainer}>
        <VerticalDivider />
        <div className={classes.participantsContainer}>
          <div className={classes.dataContainer}>
            <Text variant="b6" color="system">
              {translation('parryView.participants')}
            </Text>
            <Text
              variant="b3"
              color={activeParticipants > 0 ? 'link' : 'primary'}
            >
              {activeParticipants || 0}
              {' / '}
              {participants > 0 ? (
                participants
              ) : (
                <InfinityIcon
                  width={16}
                  height={16}
                  className={classes.infIcon}
                />
              )}
            </Text>
          </div>
          <AvatarList avatars={avatars} usersLength={activeParticipants} />
        </div>
        <VerticalDivider />
        <div className={classes.dataContainer}>
          <Text variant="b6" color="system">
            {translation('parryView.ratio')}
          </Text>
          <Text variant="b3">
            {toWin}
            {' / '}
            {toLose}
          </Text>
        </div>
        <VerticalDivider />
        <div className={classes.dataContainer}>
          <Text variant="b6" color="system">
            {translation('parryView.owner')}
          </Text>
          <div className={classes.ownerContainer}>
            <img
              src={isPreview ? user?.avatarUrl : owner?.avatarUrl}
              className={classes.ownerAvatar}
              alt="Owner avatar"
            />
            <Text variant="b3">
              {isPreview ? user?.username : owner?.username}
            </Text>
          </div>
        </div>
      </div>
      {isDone && isOwner && (
        <img
          src="/images/parry/doneShadow.png"
          alt="done shadow"
          className={classes.resultShadow}
        />
      )}
      {(isPreview || hasControl) && (
        <OwnerHeader
          parry={parry as any}
          onStopCycleClick={onStopCycleClick}
          onStartParryClick={onStartParryClick}
          onStopRegistrationClick={onStopRegistrationClick}
        />
      )}
    </Card>
  );
};
