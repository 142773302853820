import { FC } from 'react';

import clsx from 'clsx';
import { ButtonProps } from 'primereact/button';

import { Typography } from 'ui-kit';

import { useStyle } from './PageControls.styles';

interface IPageButton extends ButtonProps {
  isActive: boolean;
  isMarkedActive?: boolean;
  children: number;
  onClick: () => void;
}

export const PageButton: FC<IPageButton> = ({
  isActive,
  isMarkedActive,
  children,
  onClick,
}) => {
  const { classes } = useStyle();
  const { Text } = Typography;
  return (
    <button
      onClick={onClick}
      className={clsx(
        classes.pageButton,
        !isActive && classes.halfOpacity,
        isMarkedActive ? classes.bigButton : classes.smallButton,
        isMarkedActive && isActive && classes.active
      )}
    >
      <Text variant="b4">{children}</Text>
    </button>
  );
};
