import { FC } from 'react';

import { EPredictStatus, IPredictResponse } from 'api';
import { PlayIcon } from 'icons';

import { useStyle } from './GameCard.styles';
interface IGameCardProps {
  activePredict: IPredictResponse;
}

export const GameCard: FC<IGameCardProps> = ({ activePredict }) => {
  const { classes } = useStyle();
  return (
    <div className={classes.gameCard}>
      <div className={classes.gameCardInner}>
        {activePredict?.status === EPredictStatus.PROGRESS && (
          <div className={classes.activePredictCard}>
            <a
              className={classes.activePredictIcon}
              href="steam://rungameid/570"
            >
              <PlayIcon className={classes.playIcon} />
            </a>
          </div>
        )}
      </div>
    </div>
  );
};
