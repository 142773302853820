import { makeStyles } from 'theme';

export const useStyle = makeStyles()(() => ({
  discordCard: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 288,
    width: 604,
    padding: '40px 40px 30px 40px',
    borderRadius: '6px',
    backgroundSize: 'cover',
    backgroundImage: 'url("/images/home/banners/discord.jpg")',
  },
  discordAd: {
    position: 'relative',
  },
  btnLink: {
    marginTop: 'auto',
    marginBottom: 0,
  },
  discordText: {
    maxWidth: 320,
    position: 'relative',
    marginTop: 16,
    marginBottom: 30,
  },
}));
