import { makeStyles } from 'theme';

export const useStyle = makeStyles()((theme) => ({
  generalContainer: {
    marginTop: 30,
    width: 870,
    display: 'flex',
    flexWrap: 'wrap',
    gap: 20,
  },
  mainTermCard: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: 19,
    width: 24,
    height: 24,
    color: theme.palette.typo.system,
  },
  subTermCard: {
    width: 'calc(50% - 10px)',
  },
  mainTermContainer: {
    display: 'flex',
    width: '100%',
  },
  mainTermContent: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 16,
    gap: 4,
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: 8,
  },
  heroContent: {
    gap: 16,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    position: 'relative',
  },
  heroImg: {
    width: 320,
    height: 200,
    position: 'absolute',
    bottom: -19,
    right: -50,
  },
  ownerAvatar: {
    height: 32,
    width: 32,
    borderRadius: '50%',
    marginRight: 8,
  },
  controlIcon: {
    height: 20,
    width: 20,
  },
  performer: {
    marginLeft: 16,
  },
}));
