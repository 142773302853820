import { FC, HTMLProps } from 'react';

import { useTranslation } from 'react-i18next';

import { Typography } from '../../ui-kit';
import { ReactComponent as PathRoute } from './Assets/road.svg';
import { useStyle } from './RoadHowTo.styles';
import { RoadStep } from './RoadStep';

interface IStep {
  titleKey: string;
  descriptionKey: string;
}

interface IRoadProps extends HTMLProps<unknown> {
  text: {
    title: string;
    steps: {
      stepFirst: IStep;
      stepSecond: IStep;
      stepThird: IStep;
      stepFourth: IStep;
    };
    heroes: {
      heroFirstUrl: string;
      heroSecondUrl: string;
      heroThirdUrl?: string;
    };
  };
}

export const RoadHowTo: FC<IRoadProps> = ({ text, className }) => {
  const { classes } = useStyle();
  const {
    title,
    steps: { stepFirst, stepSecond, stepThird, stepFourth },
    heroes: { heroFirstUrl, heroSecondUrl, heroThirdUrl },
  } = text;
  const { t: translation } = useTranslation();
  const { Headline } = Typography;
  return (
    <div className={className}>
      <div className={classes.howToTitle}>
        <Headline>{translation(title)}</Headline>
      </div>
      <div className={classes.howTo}>
        <img src={heroFirstUrl} className={classes.heroOne} alt="Dota 2 hero" />
        <img
          src={heroSecondUrl}
          className={classes.heroTwo}
          alt="Dota 2 hero"
        />

        {heroThirdUrl && (
          <img
            src={heroThirdUrl}
            className={classes.heroThree}
            alt="Dota 2 hero"
          />
        )}

        <RoadStep {...stepFirst} className={classes.firstStep} />
        <RoadStep {...stepSecond} className={classes.secondStep} />
        <RoadStep {...stepThird} className={classes.thirdStep} />
        <RoadStep {...stepFourth} className={classes.fourthStep} />
        <PathRoute className={classes.svgRoute} />
      </div>
    </div>
  );
};
