import { FC, useEffect } from 'react';

import TagManager from 'react-gtm-module';
import useSWRImmutable from 'swr/immutable';

import { isLoginUser, isErrorResponse } from 'api';
import { SkeletonLoader } from 'ui-kit';

import { Tabs } from './components';
import { useStyle } from './components/HeaderComponents.styles';
import { UserMenu } from './UserMenu';

export const HeaderSkeleton: FC = () => {
  const { classes } = useStyle();
  return (
    <>
      <SkeletonLoader className={classes.balancePlaceholder} />
      <SkeletonLoader className={classes.userPlaceholder} />
    </>
  );
};

export function Header(): JSX.Element {
  const { data: user, isValidating } = useSWRImmutable('api/user', isLoginUser);

  useEffect(() => {
    if (!isErrorResponse(user) && user?.id && !isValidating) {
      TagManager.dataLayer({
        dataLayer: { event: 'user_id', userId: user.id },
      });
    }
  }, [isValidating, user]);

  const isUserExist = !isErrorResponse(user) && user?.id;
  const { classes } = useStyle();
  return (
    <header className={classes.header}>
      <Tabs isUserExist={!!isUserExist} />
      <div className={classes.userMenuContainer}>
        {isValidating ? <HeaderSkeleton /> : <UserMenu user={user} />}
      </div>
    </header>
  );
}
