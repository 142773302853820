import { FC } from 'react';

import { ICommonIconProps } from './types';

export const FlagPasswordIcon: FC<ICommonIconProps> = ({
  color = 'currentColor',
  width = 56,
  height = 56,
  viewBox = '0 0 56 56',
  className = '',
  style,
}) => (
  <svg
    fill="none"
    width={width}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path d="M0 0H56V56L28 45L0 56V0Z" fill="url(#paint0_linear_5629_96450)" />
    <path
      d="M35.2747 23.9999C35.2747 22.9969 34.459 22.1813 33.456 22.1813H32.5467V19.4533C32.5467 16.9463 30.5071 14.9067 28.0001 14.9067C25.4931 14.9067 23.4535 16.9463 23.4535 19.4533V22.1813H22.5442C21.5412 22.1813 20.7256 22.9969 20.7256 23.9999V31.2744C20.7256 32.2774 21.5412 33.0931 22.5442 33.0931H33.456C34.459 33.0931 35.2747 32.2774 35.2747 31.2744V23.9999ZM25.2722 19.4533C25.2722 17.9493 26.4961 16.7254 28.0001 16.7254C29.5041 16.7254 30.7281 17.9493 30.7281 19.4533V22.1813H25.2722V19.4533Z"
      fill="#ECF6FF"
    />
    <defs>
      <linearGradient
        id="paint0_linear_5629_96450"
        x1="4.59611e-08"
        y1="34.5883"
        x2="99.7069"
        y2="32.8925"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#702BC8" />
        <stop offset="1" stopColor="#3452BE" />
      </linearGradient>
    </defs>
  </svg>
);
