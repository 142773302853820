import { useTranslation } from 'react-i18next';

import {
  LOCALE_LOCAL_STORAGE_KEY,
  PRELOGIN_LOCAL_STORAGE_KEY,
} from '../consts';

interface IUseLanguageUpdate {
  updateLanguage: (id?: number) => void;
}

export const useLanguageUpdate = (): IUseLanguageUpdate => {
  const {
    i18n: { changeLanguage },
  } = useTranslation();
  const updateLanguage = (id: number): void => {
    const saveLanguage = localStorage.getItem(
      `${id}-${LOCALE_LOCAL_STORAGE_KEY}`
    );
    const preloginLanguage = localStorage.getItem(PRELOGIN_LOCAL_STORAGE_KEY);
    const userLocale = window.navigator.language;
    if (id) {
      if (saveLanguage) {
        changeLanguage(saveLanguage);
      } else {
        if (preloginLanguage) {
          changeLanguage(preloginLanguage);
        } else {
          changeLanguage(userLocale.includes('ru') ? 'ru' : 'en');
        }
      }
    } else {
      if (preloginLanguage) {
        changeLanguage(preloginLanguage);
      } else {
        changeLanguage(userLocale.includes('ru') ? 'ru' : 'en');
      }
    }
  };
  return {
    updateLanguage,
  };
};
