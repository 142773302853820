import { FC } from 'react';

import clsx from 'clsx';

import { challengeCovers } from 'consts';

import { useStyle } from './GalleryModal.styles';

interface IImageTableProps {
  tabIndex?: number;
  srcSetter: (newSrc: string) => void;
  activeImage: string;
}

export const ImagesTable: FC<IImageTableProps> = ({
  tabIndex,
  srcSetter,
  activeImage,
}) => {
  const { classes } = useStyle();
  return (
    <div className={classes.imageTable}>
      {challengeCovers[tabIndex].map((item) => (
        <img
          alt="cover"
          src={item.src}
          key={item.id}
          className={clsx(
            classes.image,
            activeImage === item.src && classes.activeImage
          )}
          onClick={() => {
            srcSetter(item.src);
          }}
        />
      ))}
    </div>
  );
};
