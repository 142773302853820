import { useEffect } from 'react';

export const usePersistForm = (
  value: unknown,
  localStorageKey: string
): void => {
  useEffect(() => {
    if (value) {
      localStorage.setItem(localStorageKey, JSON.stringify(value));
    }
  }, [value, localStorageKey]);

  return;
};

export const getPersistedForm = <T>(localStorageKey: string): T | null => {
  const localStorageItem = localStorage.getItem(localStorageKey);
  let data: T;
  if (localStorageItem) {
    try {
      data = JSON.parse(localStorageItem);
    } catch (err) {
      console.log(err);
    }
    return data;
  }
  return null;
};
