import { ParryView } from './ParryView';

export const PARRY_VIEW = {
  name: 'parries',
  routes: [
    {
      title: 'View',
      path: '/parries/:id',
      element: <ParryView />,
    },
  ],
};
