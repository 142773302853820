import type { FC } from 'react';

import type { ICommonIconProps } from './types';

export const MinusIcon: FC<ICommonIconProps> = ({
  color = '#181E33',
  width = 14,
  height = 2,
  viewBox = '0 0 14 2',
  className,
  style,
}) => (
  <svg
    color={color}
    width={width}
    height={height}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.166992 1.00011C0.166992 0.539875 0.540088 0.166779 1.00033 0.166779H15.0003C15.4606 0.166779 15.8337 0.539875 15.8337 1.00011C15.8337 1.46035 15.4606 1.83345 15.0003 1.83345H1.00033C0.540088 1.83345 0.166992 1.46035 0.166992 1.00011Z"
      fill={color}
    />
  </svg>
);
