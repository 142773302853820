import { FC } from 'react';

import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import { ICreatedChallenge, IUserResponse } from 'api';
import { ExitChallengeIcon } from 'icons';
import { Button, Typography } from 'ui-kit';

import { EChallengeModals, getIsWinner } from '../../ChallengeView.model';
import { useStyle } from './Buttons.styles';

interface IExitChallengeButton {
  openModal: (EChallengeModals) => void;
  challenge: ICreatedChallenge;
}

export const ExitChallengeButton: FC<IExitChallengeButton> = ({
  openModal,
  challenge,
}) => {
  const { t: translation } = useTranslation();
  const { classes } = useStyle();
  const { data: user } = useSWR<IUserResponse>('api/user');
  const { Headline } = Typography;
  const isWinner = getIsWinner(challenge, user);
  return (
    <Button
      className={classes.exitBtn}
      color="secondary"
      disabled={isWinner}
      onClick={() => {
        openModal(EChallengeModals.Exit);
      }}
    >
      <div className={classes.exitButtonContainer}>
        <Headline variant="h3" color="button">
          <ExitChallengeIcon className={classes.exitIcon} />
        </Headline>
        <Headline variant="h3" color="button">
          {translation('challengeView.actions.exitChallenge')}{' '}
        </Headline>
      </div>
    </Button>
  );
};
