import { FC } from 'react';

import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import type { IUserResponse } from 'api';
import { EmailIcon, SteamIcon } from 'icons';
import { Typography } from 'ui-kit';

import { useStyle } from '../../Profile.styles';

interface IProps {
  user: IUserResponse | null;
}

interface IIconProps {
  children: React.ReactNode;
  className: string;
}

const Discord: FC<IIconProps> = ({
  children,
  className,
}): React.ReactElement => <div className={className}>{children}</div>;

const Steam: FC<IIconProps> = ({ children, className }): React.ReactElement => (
  <div className={className}>{children}</div>
);

export function Person({ user }: IProps): JSX.Element {
  if (!user) {
    return null;
  }

  const { avatarUrl, email, username, steamId } = user;
  const { classes } = useStyle();
  const { Headline, Text } = Typography;
  const { t: translation } = useTranslation();

  return (
    <div className={classes.flexColumn}>
      <div className={classes.personContainer}>
        <img
          alt="avatar"
          className={!avatarUrl && classes.avatar}
          src={avatarUrl ?? '/images/header/default-avatar.png'}
        />
      </div>
      <div className={classes.userName}>
        <Headline variant="h2" color="primary">
          {username}
        </Headline>
      </div>
      <div className={classes.userMail}>
        <EmailIcon className={clsx(!email && classes.mailIcon)} />
        {email ? (
          <span className={classes.emailText}>{email}</span>
        ) : (
          <span className={classes.noEmailText}>
            <Text variant="b4" color="system">
              {translation('profile.user.emailNotLinked')}
            </Text>
          </span>
        )}
      </div>
      <div className={classes.iconsContainer}>
        <Steam className={steamId ? classes.linkIcon : classes.noLinkIcon}>
          <SteamIcon className={!steamId && classes.noLinkIcon} />
        </Steam>
      </div>
    </div>
  );
}
