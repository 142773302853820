import { makeStyles } from 'theme';

export const useStyle = makeStyles()((theme) => ({
  card: {
    fontFamily: 'Ubuntu',
    background:
      'linear-gradient(38.92deg, rgba(22, 28, 40, 0.4) 0%, rgba(22, 28, 40, 0.1) 134.39%)',
    backdropFilter: 'blur(8px)',
    borderRadius: 6,
    padding: 32,
    border: '2px solid rgba(43, 44, 46, 0.64)',
    width: 'calc(50% - 16px)',
    minWidth: 400,
  },
  title: {
    marginBottom: 48,
  },
  itemLeaderBoardDots: {
    padding: '0 24px',
  },
  itemDot: {
    display: 'inline-block',
    content: "''",
    minWidth: 10,
    height: 10,
    width: 10,
    minHeight: 10,
    borderRadius: 10,
    backgroundColor: theme.palette.typo.system,
    marginRight: 20,
  },
  button: {
    width: '100%',
    marginTop: 32,
  },
  leaderboardItem: {
    lineHeight: '24px',
    backgroundColor: theme.palette.bg.system,
    color: theme.palette.typo.primary,
    width: '100%',
    marginBottom: 8,
    borderRadius: 6,
    padding: '4px 24px',
    display: 'flex',
    alignItems: 'center',
    '& div': {
      '&:first-of-type': {
        marginRight: 40,
      },
      '&:last-child': {
        marginRight: 0,
        marginLeft: 'auto',
      },
    },
  },
  tableHeader: {
    display: 'flex',
    marginBottom: 8,
    paddingLeft: 14,
    paddingRight: 14,
  },
  tableColumn: {
    color: theme.palette.typo.primary,
    fontSize: 12,
    '&:first-of-type': {
      marginRight: 44,
    },
    '&:last-child': {
      marginRight: 0,
      marginLeft: 'auto',
    },
  },
  avatar: {
    width: '32px',
    borderRadius: 4,
    marginRight: 16,
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));
