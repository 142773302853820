import type { FC } from 'react';

import type { ICommonIconProps } from './types';

export const SmallLockIcon: FC<ICommonIconProps> = ({
  color = 'ECF6FF',
  width = 12,
  height = 14,
  viewBox = '0 0 12 14',
  className = '',
  style,
}) => (
  <svg
    fill="none"
    width={width}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
    color={color}
  >
    <path
      d="M12 7C12 6.2279 11.3273 5.6 10.5 5.6H9.75V3.5C9.75 1.5701 8.06775 0 6 0C3.93225 0 2.25 1.5701 2.25 3.5V5.6H1.5C0.67275 5.6 0 6.2279 0 7V12.6C0 13.3721 0.67275 14 1.5 14H10.5C11.3273 14 12 13.3721 12 12.6V7ZM3.75 3.5C3.75 2.3422 4.7595 1.4 6 1.4C7.2405 1.4 8.25 2.3422 8.25 3.5V5.6H3.75V3.5Z"
      fill="currentColor"
    />
  </svg>
);
