import { useTranslation } from 'react-i18next';
import { PieChart } from 'react-minimal-pie-chart';

import { Typography } from '../Typography';
import { useStyle } from './DonutChart.styles';

interface IProps {
  values: number[];
}

export function DonutChart({ values }: IProps): JSX.Element {
  const { t: translation } = useTranslation();
  const [win, lose] = values;
  const { classes } = useStyle();
  const { Text } = Typography;
  return (
    <div className={classes.container}>
      <PieChart
        data={[
          { value: win, color: '#31D368' },
          { value: lose, color: '#D33131' },
        ]}
        startAngle={270}
        lineWidth={15}
        paddingAngle={!win || !lose ? 0 : 5}
      />
      <div className={classes.dataContainer}>
        <span className={classes.matches}>
          <Text variant="b7">{translation('predictions.winRate.matches')}</Text>
        </span>
        <Text variant="b2">{win + lose}</Text>
      </div>
    </div>
  );
}
