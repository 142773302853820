import { FC, useMemo, useState } from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import { getSteamUsers } from 'api';
import { Dropdown } from 'ui-kit';

import { text } from '../../../CreateChallenge.model';

export const Performer: FC = () => {
  const [param, setParam] = useState('');
  const { data: steamUsers } = useSWR(['api/steam-users', param], () =>
    getSteamUsers(param)
  );
  const { control } = useFormContext();

  const { t: translation } = useTranslation();

  const anyHero = {
    value: '',
    label: translation('challenge.form.general.performer'),
  };

  const selectUsers = useMemo(() => {
    return steamUsers?.map((steamUser) => ({
      imgUrl: steamUser.avatarUrl,
      label: steamUser?.username,
      value: steamUser?.steamId,
    }));
  }, [steamUsers]);

  return (
    <Controller
      name="targetSteamId"
      control={control}
      render={({ field: { onChange, value } }) => {
        const steamUserOption = selectUsers?.filter(
          (steamUser) => steamUser.value === value
        )[0];
        return (
          <Dropdown
            title={translation('challenge.form.general.performer')}
            options={selectUsers}
            filter
            cross={!!steamUserOption?.value}
            onChange={(value) => onChange(value)}
            onInputChange={setParam}
            tooltip={translation(text.performer)}
            defaultValue={anyHero}
            value={steamUserOption}
          />
        );
      }}
    />
  );
};
