import { makeStyles } from 'theme';

export const useStyle = makeStyles<{ imageUrl: string; isDraft: boolean }>()(
  (theme, { imageUrl, isDraft }) => ({
    ...theme.layouts,
    ...theme.fontStyles,

    menuItem2: {
      color: theme.palette.typo.system,
    },

    blue: {
      color: theme.palette.typo.link,
    },

    borderYellow: {
      borderColor: `${theme.palette.typo.warning} !important`,
    },

    borderBlue: {
      borderColor: `${theme.palette.typo.link} !important`,
    },

    challengeCard: {
      cursor: !isDraft ? 'pointer' : '',
      width: 370,
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #15191E 100%), url('${imageUrl}')`,
      backgroundSize: '370px 174px',
      backgroundRepeat: 'no-repeat',
    },
    challengeCardContent: {
      marginTop: 135,
      width: '100%',
    },

    challengeTitle: {
      marginTop: '12px',
    },
    dataAndTagContainer: {
      marginTop: 16,
      display: 'flex',
      width: '100%',
      height: 120,
    },
    flagIcon: {
      position: 'absolute',
      [isDraft ? 'left' : 'right']: 20,
      top: 0,
    },
    finishingBadge: {
      padding: '3px 7px',
      borderRadius: 24,
      border: `1px solid ${theme.palette.typo.primary}`,
      boxShadow: 'border-box',
      ...theme.layouts.flexCenter,
    },
    finishingShift: {
      left: 73,
    },
    dailyBadge: {
      backgroundImage: theme.palette.control.brand.bgPressed,
      padding: '4px 8px',
      borderRadius: 24,
      ...theme.layouts.flexCenter,
    },
    draftButtons: {
      gap: 12,
      position: 'absolute',
      top: 20,
      right: 20,
      display: 'flex',
      flexDirection: 'column',
      svg: {
        cursor: 'pointer',
      },
    },
    badgesContainer: {
      position: 'absolute',
      top: 20,
      left: 20,
      display: 'flex',
      gap: 10,
    },
  })
);
