import { makeStyles } from 'theme';

export const useStyle = makeStyles<{
  step: number;
  challengeBackground: string;
}>()((theme, { step, challengeBackground }) => ({
  smartBackground: {
    background: "url('/images/challenges/create/background.jpg')",
    transition: 'background-position 500ms',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: `top 0px left -${step * 1000}px `,
    backgroundSize: 'auto 1200px',
  },
  page: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    minWidth: '100%',
    minHeight: '100vh',
    marginTop: '-4px',
    '@media(max-width: 1536px)': {
      paddingLeft: 72,
      paddingRight: 0,
    },
  },
  container: {
    ...theme.layouts.marginXauto,
    width: '100%',
    maxWidth: '1240px',
    flex: '1 1 0%',
    position: 'relative',
    paddingTop: 120,
    paddingLeft: 0,
    '@media(max-width: 1400px)': {
      paddingLeft: 20,
    },
  },
  home: {
    backgroundImage: 'url("/images/main-page/bg-new.jpg")',
    backgroundSize: 'cover',
    backgroundPosition: 'center top -7rem',
  },
  predictions: {
    backgroundImage: 'url("/images/predictions/predictions-background.jpg")',
    backgroundSize: 'cover',
    backgroundPosition: 'center -24px',
  },
  challenges: {
    backgroundImage: 'url("/images/challenges/challenges-background.jpg")',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  product: {
    backgroundImage: 'url("/images/product-page/bg-product-page.png")',
    backgroundSize: 'cover',
    backgroundPosition: 'center top',
  },
  referral: {
    backgroundImage: 'url("/images/profile/referral-bg.png")',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  other: {
    backgroundImage: 'url("/images/page-bg.png")',
    backgroundSize: 'cover',
  },
  challengeBackground: {
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #0A0D10 40%), url("${challengeBackground}")`,
    backgroundColor: theme.palette.bg.default,
  },
}));
