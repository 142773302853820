import { useContext, useEffect } from 'react';

import { ProgressSpinner } from 'primereact/progressspinner';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import useSWR from 'swr';

import {
  getChallenge,
  getChallengeHistory,
  ICreatedChallenge,
  IUserResponse,
} from 'api';
import { ModalContext } from 'components';
import {
  EChallengeModals,
  EViewChallengeMode,
  CHALLENGE_FORM_DATA_KEY,
} from 'consts';
import { useCheckHistory } from 'hooks';
import { BackgroundContext } from 'Layout';
import { BottomNotification, Button } from 'ui-kit';

import { getIsWinner } from './ChallengeView.model';
import { useStyle } from './ChallengeView.styles';
import {
  ActionButton,
  BottomActionButtons,
  WinnersList,
  AdditionalTerms,
  ChallengeHeader,
  GeneralTerms,
} from './components';

export function ChallengeView(): JSX.Element {
  const { t: translation } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const { classes } = useStyle();

  const viewMode = searchParams.get('mode');
  const isPreviewMode = viewMode === EViewChallengeMode.Preview;
  const isHistoryMode = viewMode === EViewChallengeMode.History;

  const { isHistoryFailed } = useCheckHistory();
  const localChallenge: ICreatedChallenge = JSON.parse(
    localStorage.getItem(CHALLENGE_FORM_DATA_KEY)
  );
  const { data: challenge } = useSWR(
    isHistoryMode ? `api/challenge/history/${id}` : `api/challenge/${id}`,
    isPreviewMode
      ? () =>
          new Promise<ICreatedChallenge>((resolve) => resolve(localChallenge))
      : () => {
          if (isHistoryMode) {
            return getChallengeHistory(+id);
          }
          return getChallenge(+id);
        }
  );

  const { data: user } = useSWR<IUserResponse>('api/user');

  const isWinner = getIsWinner(challenge, user);

  const {
    steamModal: { openSteam },
    historyModal: { openHistory },
    cycleModal: { openCycle, setCycleId },
  } = useContext(ModalContext);

  const onStopCycleClick = (): void => {
    setCycleId(challenge?.id);
    openCycle();
  };

  const {
    challengeConfirmation: {
      setChallengeModalMode,
      setChallengeId,
      setChallengeConfirmationValue,
    },
  } = useContext(ModalContext);

  const onOpenModalClick = (mode: EChallengeModals): void => {
    setChallengeModalMode(mode);
    setChallengeId(challenge?.id);
    setChallengeConfirmationValue(+challenge.entryCost);
  };

  const { setChallengeBackground } = useContext(BackgroundContext);

  useEffect(() => {
    if (challenge) {
      setChallengeBackground(challenge?.image);
    }
  }, [challenge]);

  useEffect(() => {
    return () => setChallengeBackground('');
  }, []);

  const onBackClick = (): void => navigate(-1);

  if (!challenge) {
    return <ProgressSpinner className={classes.progressSpinner} />;
  }

  if ((challenge as any)?.code === 400) {
    navigate('/challenges');
  }

  return (
    <>
      <Button onClick={onBackClick} color="secondary">
        {translation('challenge.back')}
      </Button>
      <ChallengeHeader
        challenge={challenge}
        onOpenModal={onOpenModalClick}
        onStopCycleClick={onStopCycleClick}
      />
      <div className={classes.container}>
        <div className={classes.mainInfoContainer}>
          <GeneralTerms challenge={challenge} />
          <AdditionalTerms challenge={challenge} />
        </div>
        <div className={classes.sideBarContainer}>
          {!isWinner && (
            <ActionButton
              challenge={challenge}
              openModal={onOpenModalClick}
              openSteamModal={openSteam}
              openHistoryModal={openHistory}
              isHistoryMode={isHistoryMode}
              isHistoryClosed={isHistoryFailed}
              isPreviewMode={isPreviewMode}
            />
          )}
          <WinnersList challenge={challenge} />
        </div>
      </div>
      {isPreviewMode && <BottomActionButtons challenge={challenge} />}
      {!isPreviewMode && <BottomNotification />}
    </>
  );
}
