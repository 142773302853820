import { FC } from 'react';

import { ICommonIconProps } from './types';

export const GradientEntryCostIcon: FC<ICommonIconProps> = ({
  color = 'currentColor',
  width = 16,
  height = 16,
  viewBox = '0 0 16 16',
  className = '',
  style,
}) => (
  <svg
    fill="none"
    width={width}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M13.8624 10.3008V11.1579C13.8624 11.672 13.6142 12.1115 13.2697 12.457C12.929 12.7993 12.4666 13.0785 11.9462 13.2995C10.9028 13.7417 9.50766 14 8.00051 14C6.49337 14 5.09825 13.7423 4.05484 13.2995C3.53444 13.0785 3.07201 12.7993 2.73137 12.457C2.41548 12.1412 2.18101 11.7446 2.14388 11.2848L2.13867 11.1579V10.3008C2.44153 10.4675 2.76524 10.6153 3.11174 10.7398C4.43391 11.2141 6.16315 11.4787 8.00051 11.4787C9.83788 11.4787 11.5671 11.2141 12.8893 10.7398C13.1492 10.6463 13.396 10.5402 13.6311 10.4221L13.8624 10.3008ZM2.13867 6.82713C2.44153 6.99386 2.76524 7.14165 3.11174 7.26608C4.43391 7.74039 6.16315 8.00502 8.00051 8.00502C9.83788 8.00502 11.5671 7.74039 12.8893 7.26608C13.2255 7.14575 13.5509 6.99895 13.8624 6.82713V8.78818C13.4299 9.1119 12.9494 9.37039 12.4373 9.55492C11.2942 9.96544 9.7252 10.2162 8.00051 10.2162C6.27648 10.2162 4.70746 9.96544 3.56375 9.55492C3.05161 9.37039 2.57117 9.1119 2.13867 8.78818V6.82713V6.82713ZM8.00051 1.99997C9.50766 1.99997 10.9028 2.25765 11.9462 2.70039C12.4666 2.92144 12.929 3.2006 13.2697 3.54292C13.5855 3.85871 13.82 4.25534 13.8571 4.71513L13.8624 4.84207V5.3145C13.4299 5.63823 12.9494 5.89673 12.4373 6.08123C11.2942 6.49176 9.7252 6.7425 8.00051 6.7425C6.27648 6.7425 4.70746 6.49176 3.56375 6.08123C3.12336 5.92283 2.70623 5.70923 2.32299 5.44586L2.13867 5.3145V4.84207C2.13867 4.32797 2.38682 3.88839 2.73137 3.54292C3.07201 3.2006 3.53444 2.92144 4.05484 2.70039C5.09825 2.25829 6.49337 1.99997 8.00051 1.99997Z"
      fill="url(#paint0_linear_5831_88015)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_5831_88015"
        x1="2.13867"
        y1="9.41174"
        x2="23.0127"
        y2="9.0649"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00DAA5" />
        <stop offset="1" stopColor="#08C1D1" />
      </linearGradient>
    </defs>
  </svg>
);
