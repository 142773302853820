import { makeStyles } from 'theme';

export const useStyle = makeStyles()(() => ({
  bannerContainer: {
    width: 870,
    height: 200,
    backgroundImage: "url('/images/wallet/firstBanner.jpg')",
    borderRadius: 8,
    display: 'flex',
    gap: 40,
  },
  money: {
    marginTop: -35,
  },
  textContainer: {
    maxWidth: 500,
    display: 'flex',
    gap: 12,
    flexDirection: 'column',
    padding: '40px 0',
  },
}));
