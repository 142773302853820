import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { Button, Typography } from 'ui-kit';

import { useStyle } from './AboutHeader.styles';

export const Header: FC<{
  onMakePredictionClick: () => void;
}> = ({ onMakePredictionClick }) => {
  const { classes } = useStyle();
  const { t: translation } = useTranslation();
  const { Headline, Text } = Typography;
  return (
    <div className={classes.intro}>
      <div className={classes.contentContainer}>
        <Headline
          variant="h0"
          color="brand"
          className={classes.predictionsTitle}
        >
          {translation('predictionsAbout.header.title')}
        </Headline>
        <Headline className={classes.title}>
          {translation('predictionsAbout.header.subtitle')}
        </Headline>
        <Text variant="b2" className={classes.description}>
          {translation('predictionsAbout.header.description')}
        </Text>
        <Button buttonSize="l" onClick={onMakePredictionClick}>
          {translation('predictionsAbout.header.button')}
        </Button>
      </div>
      <img
        className={classes.img}
        src="/images/predictions/image-intro.png"
        alt="Prediction intro"
      />
    </div>
  );
};
