import { makeStyles } from 'theme';

export const useStyle = makeStyles()((theme) => ({
  footerContainer: {
    position: 'fixed',
    bottom: 0,
    left: 72,
    height: 72,
    width: 'calc(100% - 72px)',
    justifyContent: 'space-between',
    zIndex: 10,
    backdropFilter: 'blur(8px)',
    ...theme.layouts.flexAICenter,
  },
  nextButton: {
    marginLeft: 'auto',
    marginRight: 116,
  },
}));
