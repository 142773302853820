import { FC } from 'react';

import { ICommonIconProps } from './types';

export const EditIcon: FC<ICommonIconProps> = ({
  color = 'currentColor',
  width = 20,
  height = 20,
  viewBox = '0 0 20 20',
  className = '',
  style,
}) => (
  <svg
    fill="none"
    width={width}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.04102 16.6124C3.04102 16.228 3.35262 15.9164 3.73701 15.9164H16.2649C16.6493 15.9164 16.9609 16.228 16.9609 16.6124C16.9609 16.9967 16.6493 17.3084 16.2649 17.3084H3.73701C3.35262 17.3084 3.04102 16.9967 3.04102 16.6124Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.2143 2.90878C14.0837 2.76999 13.9066 2.69204 13.7219 2.69208C13.5372 2.69212 13.3601 2.77015 13.2296 2.90899L6.02777 10.5701C5.89743 10.7088 5.82422 10.8968 5.82422 11.0927V13.7845C5.82422 14.1929 6.13583 14.524 6.52021 14.524H9.06651C9.25115 14.524 9.42823 14.446 9.55876 14.3073L16.7564 6.65647C17.0281 6.3677 17.028 5.89965 16.7564 5.61089L14.2143 2.90878Z"
      fill={color}
    />
  </svg>
);
