import { FC, useContext, useEffect, useRef, useState } from 'react';

import { Controller, FormProvider, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useSWR, { mutate } from 'swr';

import { getBalance, withdrawFiat } from 'api';
import { useClickOutside } from 'hooks';
import { Button, Input, InputNumber, Typography } from 'ui-kit';

import { ModalContext } from '../../ModalProvider';
import { Modal } from '../Modal';
import { useStyle } from './WithdrawFiat.styles';

interface IWithdrawProps {
  isOpen: boolean;
  onClose: () => void;
}

const defaultValues = {
  pan: '',
  holder: '',
  month: null,
  year: null,
  amount: null,
};

type WithdrawTypes = typeof defaultValues;

export const WithdrawFiat: FC<IWithdrawProps> = ({ isOpen, onClose }) => {
  const ref = useRef(null);
  const { classes } = useStyle();
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);

  useClickOutside(ref, onClose);
  const { Text } = Typography;
  const { data: balanceData } = useSWR('api/balance');
  const methods = useForm<WithdrawTypes>({ defaultValues });
  const {
    formState: { errors },
    control,
    register,
    getValues,
    trigger,
    setError,
    clearErrors,
    reset,
  } = methods;
  const pan = useWatch<WithdrawTypes>({ name: 'pan', control });
  const amount = useWatch<WithdrawTypes>({ name: 'amount', control });

  const {
    walletSuccess: { openWalletSuccess },
    walletError: { openWalletError },
  } = useContext(ModalContext);

  const onWithdraw = async (): Promise<void> => {
    if (errors['amount']) {
      return;
    }
    const isValid = await trigger(['pan', 'amount', 'year', 'month', 'holder']);
    if (!isValid) {
      return;
    }
    const { amount, year, month, ...rest } = getValues();
    setIsButtonDisabled(true);
    const response = await withdrawFiat({
      month: Number(month),
      year: Number(year),
      amount: Number(amount),
      ...rest,
    });
    if (response.status === 200) {
      onClose();
      openWalletSuccess();
      reset(defaultValues);
      setIsButtonDisabled(false);
      mutate('api/balance', getBalance);
    } else {
      onClose();
      reset(defaultValues);
      setIsButtonDisabled(false);
      openWalletError();
    }
  };

  useEffect(() => {
    const balance = balanceData?.balance || 0;
    if (amount > balance) {
      setError('amount', {
        type: 'custom',
        message: translation('profile.withdraw.amountInsuffError'),
      });
    } else {
      clearErrors('amount');
    }
  }, [amount]);

  const { t: translation } = useTranslation();

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        reset(defaultValues);
        onClose();
      }}
    >
      <div ref={ref} className={classes.modal}>
        <FormProvider {...methods}>
          <InputNumber
            placeholder={translation('profile.withdraw.pan')}
            title={translation('profile.withdraw.cardTitle')}
            className={classes.addressInput}
            error={errors['pan']?.message}
            onFocus={() => clearErrors('pan')}
            {...register('pan', {
              required: {
                value: true,
                message: translation('profile.withdraw.cardError'),
              },
              minLength: {
                value: 16,
                message: translation('profile.withdraw.cardError'),
              },
              maxLength: {
                value: 16,
                message: translation('profile.withdraw.cardError'),
              },
            })}
          />
          <div className={classes.cardInfo}>
            <div className={classes.cardDate}>
              <InputNumber
                placeholder={translation('profile.withdraw.month')}
                title={translation('profile.withdraw.monthTitle')}
                className={classes.addressInput}
                error={errors['month']?.message as string}
                onFocus={() => clearErrors('month')}
                {...register('month', {
                  required: {
                    value: true,
                    message: translation('profile.withdraw.monthError'),
                  },
                  maxLength: {
                    value: 2,
                    message: translation('profile.withdraw.monthSizeError'),
                  },
                })}
              />
              <InputNumber
                placeholder={translation('profile.withdraw.year')}
                title={translation('profile.withdraw.yearTitle')}
                className={classes.addressInput}
                error={errors['year']?.message as string}
                onFocus={() => clearErrors('year')}
                {...register('year', {
                  required: {
                    value: true,
                    message: translation('profile.withdraw.yearError'),
                  },
                  minLength: {
                    value: 4,
                    message: translation('profile.withdraw.yearError'),
                  },
                  maxLength: {
                    value: 4,
                    message: translation('profile.withdraw.yearError'),
                  },
                })}
              />
            </div>
            <Input
              placeholder={translation('profile.withdraw.holder')}
              title={translation('profile.withdraw.holderTitle')}
              className={classes.addressInput}
              error={errors['holder']?.message}
              onFocus={() => clearErrors('holder')}
              {...register('holder', {
                required: {
                  value: true,
                  message: translation('profile.withdraw.holderError'),
                },
              })}
            />
          </div>
          <div className={classes.amountContainer}>
            <Controller
              name="amount"
              rules={{
                required: {
                  value: true,
                  message: translation('profile.withdraw.minError'),
                },
                min: {
                  value: 10000,
                  message: translation('profile.withdraw.minError'),
                },
              }}
              render={({ field: { onChange, ...rest } }) => (
                <InputNumber
                  error={errors['amount']?.message as string}
                  placeholder="10 000 GC"
                  title={translation('profile.withdraw.amountTitle')}
                  className={classes.amountInput}
                  onFocus={() => clearErrors('amount')}
                  suffix={' GC'}
                  disabled={!pan}
                  onValueChange={({ value }) => onChange(value)}
                  {...rest}
                />
              )}
            />
          </div>
          <div className={classes.footer}>
            <div className={classes.currencyItem}>
              <Text className={classes.text} color="typoLabel" variant="b6">
                {translation('profile.withdraw.total')}
              </Text>
              <Text className={classes.text} variant="b5">
                {amount >= 10000
                  ? ((amount * 0.97) / 1000 - 0.8).toFixed(2)
                  : 0}{' '}
                USD
              </Text>
            </div>
            <Button
              color="secondary"
              className={classes.cancelBtn}
              onClick={() => {
                reset(defaultValues);
                onClose();
              }}
            >
              {translation('profile.withdraw.cancelBtn')}
            </Button>
            <Button onClick={onWithdraw} disabled={isButtonDisabled}>
              {translation('profile.withdraw.withdrawBtn')}
            </Button>
          </div>
        </FormProvider>
      </div>
    </Modal>
  );
};
