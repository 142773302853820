import { FC } from 'react';

import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { ArrowIcon } from 'icons';
import { Dropdown, Typography } from 'ui-kit';

import { PageControls } from './PageControls';
import { useStyle } from './Paginator.styles';
import { getPagesCount } from './utils';

export interface IPaginatorProps {
  className?: string;
  currentPage: number;
  setCurrentPage: (page: number) => void;
  itemsPerPage: number;
  itemsTotal: number;
  hideArrow?: boolean;
  isMarkedActive?: boolean;
  showSizeChanger?: boolean;
  limitOptions?: number[];
  setItemsPerPage?: (limit: number) => void;
}

export const Paginator: FC<IPaginatorProps> = ({
  className,
  itemsPerPage,
  itemsTotal,
  setCurrentPage,
  currentPage,
  hideArrow,
  isMarkedActive,
  showSizeChanger = false,
  limitOptions = [],
  setItemsPerPage,
}: IPaginatorProps) => {
  const { classes } = useStyle();
  const { Text } = Typography;
  const pagesCount = getPagesCount(itemsTotal, itemsPerPage);
  const { t: translation } = useTranslation();

  const dropdownOptions = limitOptions.map((opt) => ({
    value: opt,
    label: `${opt}`,
  }));

  return (
    <div className={clsx(classes.container, className)}>
      <button
        onClick={() => setCurrentPage(currentPage - 1)}
        className={clsx(
          classes.prevButton,
          currentPage === 1 && classes.disabled,
          hideArrow && classes.prevWithoutError
        )}
        disabled={currentPage === 1}
      >
        {!hideArrow && <ArrowIcon className={classes.prevArrow} />}
        <Text variant="b4">
          {hideArrow
            ? translation('paginator.previous')
            : translation('paginator.prev')}
        </Text>
      </button>
      <PageControls
        totalPages={pagesCount}
        setCurrentPage={setCurrentPage}
        isMarkedActive={isMarkedActive}
        currentPage={currentPage}
      />
      <button
        onClick={() => setCurrentPage(currentPage + 1)}
        className={clsx(
          classes.nextButton,
          currentPage === pagesCount && classes.disabled,
          hideArrow && classes.nextWithoutArrow
        )}
        disabled={currentPage === pagesCount}
      >
        <Text variant="b4">{translation('paginator.next')}</Text>
        {!hideArrow && <ArrowIcon className={classes.nextArrow} />}
      </button>
      {showSizeChanger && (
        <Dropdown
          options={dropdownOptions}
          onChange={(value) => {
            setItemsPerPage(value);
            setCurrentPage(1);
          }}
          className={classes.select}
          defaultValue={dropdownOptions[0]}
          position="top"
        />
      )}
    </div>
  );
};
