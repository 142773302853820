import { makeStyles } from 'theme';

export const useStyle = makeStyles()((theme) => ({
  dividerActive: {
    backgroundColor: theme.palette.typo.link,
    transitionDuration: '500ms',
  },
  dividerInactive: {
    backgroundColor: theme.palette.typo.primary,
    opacity: 0.3,
    transitionDuration: '500ms',
  },

  divider: {
    height: 1,
    width: 60,
    content: "''",
    margin: '0 12px',
  },
}));
