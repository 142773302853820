import { makeStyles } from 'theme';

export const useStyle = makeStyles()((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  listContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '34px 30px',
    justifyContent: 'flex-start',
    marginTop: '2.75rem',
  },
  spinner: {
    ...theme.layouts.flexCenter,
    width: '100%',
    marginTop: 170,
    marginBottom: 1000,
  },
  paginator: {
    margin: '72px 0',
  },
}));
