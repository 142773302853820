import { FC } from 'react';

import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { EItemState } from 'consts';
import { BucketIcon, LockIcon, LockOpenIcon } from 'icons';
import { Typography } from 'ui-kit';

import {
  ChallengeFormValueType,
  defaultValues,
} from '../../../CreateChallenge.model';
import { useStyle } from './ItemList.styles';

export const ItemList: FC = () => {
  const { classes } = useStyle();

  const { control, setValue } = useFormContext();
  const items = useWatch({ name: 'items', control });

  const onItemClick = (item): void => {
    const { type, place } = item;
    const itemsCopy: ChallengeFormValueType['items'] = [...items];
    if (type === EItemState.Free) {
      itemsCopy[place] = {
        place,
        type: EItemState.Locked,
        url: '',
        item: null,
        dname: '',
      };
      setValue('items', itemsCopy);
    }
    if (type === EItemState.Locked || type === EItemState.Filled) {
      itemsCopy[place] = {
        place,
        type: EItemState.Free,
        url: '',
        item: null,
        dname: '',
      };
      setValue('items', itemsCopy);
    }
  };
  const onClearClick = (): void => {
    setValue('items', defaultValues.items);
  };
  const isAnyoneTouched = items.some(
    (item) => item.type === EItemState.Filled || item.type === EItemState.Locked
  );
  const { t: translation } = useTranslation();
  const { Text } = Typography;

  return (
    <div>
      <Text variant="b6" color="typoLabel" className={classes.title}>
        {translation('challenge.form.heroItemBuild.items.title')}
      </Text>
      <div className={classes.itemContainer}>
        {items?.map((item) => (
          <div
            className={classes.item}
            key={item.place}
            onClick={() => onItemClick(item)}
          >
            {item.type !== EItemState.Filled && (
              <LockOpenIcon className={classes.openLock} />
            )}
            {item.type === EItemState.Locked && <LockIcon />}
            {item.type === EItemState.Filled && (
              <img
                src={item.url}
                alt="selected item"
                title={item.dname}
                className={classes.img}
              />
            )}
          </div>
        ))}
        {isAnyoneTouched && (
          <div className={classes.bucketIcon} onClick={onClearClick}>
            <BucketIcon color="#8289A3" className={classes.bucketIcon} />
          </div>
        )}
      </div>
    </div>
  );
};
