import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { Button, Typography } from 'ui-kit';

import { useStyle } from './DiscordBanner.styles';

export const DiscordBanner: FC = () => {
  const { classes } = useStyle();
  const { Headline } = Typography;
  const { t: translation } = useTranslation();
  return (
    <div className={classes.bannerContainer}>
      <Headline variant="h2" block>
        {translation('profile.discordBanner.title')}
      </Headline>
      <Button className={classes.btn} buttonSize="xl">
        <a
          href="https://discord.gg/7fKEWhnh4x"
          target="_blank"
          rel="noreferrer"
        >
          {translation('profile.discordBanner.btn')}
        </a>
      </Button>
    </div>
  );
};
