import { useRef } from 'react';

import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import useSWR from 'swr';

import { getSteamUrl } from 'api';
import { useClickOutside } from 'hooks';
import { SteamIcon } from 'icons';
import { Button, Typography } from 'ui-kit';

import { Modal } from '../Modal';
import { useStyle } from './ModalSteam.styles';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

export function ModalSteam({ isOpen, onClose }: IProps): JSX.Element {
  const { data: steamUrl } = useSWR(isOpen ? 'api/steam' : null, getSteamUrl);
  const ref = useRef(null);
  const { t: translation } = useTranslation();
  const { classes } = useStyle();
  const { search } = useLocation();

  useClickOutside(ref, onClose);
  const { Text, Headline } = Typography;
  return (
    <Modal isOpen={isOpen}>
      <div className={classes.steamModal} ref={ref}>
        <div className={classes.steamModalBg} />
        <div className={classes.steamModalText}>
          <Headline className={classes.steamModalTitle}>
            {translation('modal.steam.title')}
          </Headline>
          <Text variant="b4" className={classes.steamModalDescr}>
            {translation('modal.steam.description')}
          </Text>
          <Button
            color="primary"
            className={classes.steamBtn}
            buttonSize="xl"
            onClick={() => {
              if (search.length > 0) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                window.gtagReport(steamUrl.url);
              } else {
                window.location.href = steamUrl.url;
              }
            }}
          >
            <SteamIcon className={classes.marginRight10} />
            {translation('modal.steam.signupButton')}
          </Button>
          <Button
            className={classes.closeBtn}
            onClick={onClose}
            color="secondary"
            buttonSize="xl"
          >
            {translation('modal.steam.back')}
          </Button>
        </div>
      </div>
    </Modal>
  );
}
