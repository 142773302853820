import { FC } from 'react';

import { Divider } from './Divider';
import { Step } from './Step';
import { EStepStatus } from './Step/Step';
import { useStyle } from './StepNavigator.styles';

export type StepNavigatorOptions = {
  label: string;
};

interface IStepNavigator {
  setSelectedStep: (previousStep: number) => void;
  stepStatuses: EStepStatus[];
  stepsOptions: StepNavigatorOptions[];
}

export const StepNavigator: FC<IStepNavigator> = ({
  setSelectedStep,
  stepStatuses,
  stepsOptions,
}) => {
  const { classes } = useStyle();
  return (
    <div className={classes.navigatorContainer}>
      {stepStatuses.map((status, index) => (
        <>
          {index !== 0 && (
            <Divider isActive={stepStatuses[index] !== EStepStatus.Inactive} />
          )}
          <Step
            stepIndex={index}
            onClick={setSelectedStep}
            label={stepsOptions[index]?.label}
            status={status}
            key={status}
          />
        </>
      ))}
    </div>
  );
};
