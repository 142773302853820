import type { FC } from 'react';

import type { ICommonIconProps } from './types';

export const EmailIcon: FC<ICommonIconProps> = ({
  color = '#ECF6FF',
  width = 20,
  height = 20,
  viewBox = '0 0 20 20',
  className = '',
  style,
}) => (
  <svg
    fill="none"
    color={color}
    width={width}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M11.1763 0.297718C10.8142 0.102246 10.4103 0 10 0C9.58973 0 9.18577 0.102246 8.82375 0.297718L1.32375 4.3483C0.923851 4.56422 0.589434 4.88617 0.356268 5.27969C0.123101 5.67322 -4.53083e-05 6.12352 1.25049e-08 6.58245V6.97865L8.38625 11.9571L10 11.0482L11.6138 11.9558L20 6.98118V6.58245C20 6.12352 19.8769 5.67322 19.6437 5.27969C19.4106 4.88617 19.0761 4.56422 18.6763 4.3483L11.1763 0.297718ZM20 8.44698L12.8837 12.671L20 16.6735V8.44572V8.44698ZM19.9263 18.081L10 12.4963L0.07375 18.081C0.208755 18.6292 0.520927 19.1158 0.960587 19.4636C1.40025 19.8113 1.94213 20.0001 2.5 20H17.5C18.0579 20.0001 18.5998 19.8113 19.0394 19.4636C19.4791 19.1158 19.7912 18.6292 19.9263 18.081ZM1.25049e-08 16.6747L7.11625 12.671L1.25049e-08 8.44698V16.6747Z"
      fill={'currentColor'}
    />
  </svg>
);
