import TagManager from 'react-gtm-module';
import { TFunction } from 'react-i18next';
import useSWR from 'swr';

import {
  activateChallenge,
  createChallenge,
  getChallengesCount,
  IBalanceResponse,
  IChallenge,
  ICreatedChallenge,
  IErrorResponse,
  IUserResponse,
} from 'api';
import {
  EChallengeStatus,
  EDistribution,
  CHALLENGE_FORM_DATA_KEY,
} from 'consts';

export enum EChallengeModals {
  Password = 'password',
  EntryCost = 'entryCost',
  Exit = 'exit',
  Stop = 'stop',
}

const isButtonNotExist = (
  challenge: ICreatedChallenge,
  isFinished: boolean,
  isHistoryMode: boolean,
  isPreviewMode: boolean
): boolean => {
  const { isOwner, status } = challenge;
  return (
    status === EChallengeStatus.Draft ||
    (isFinished && !isOwner) ||
    isHistoryMode ||
    isOwner ||
    isPreviewMode
  );
};

interface IUseChallengeStatus {
  isAcceptDisabled: boolean;
  isNotEnoughBalance: boolean;
  isMaxParticipation: boolean;
  isFullParticipantChallenge: boolean;
}

export const getIsWinner = (
  challenge: ICreatedChallenge,
  user: IUserResponse
): boolean =>
  !!challenge?.winners?.find((winner) => winner.id === user?.id)?.id;

export const useChallengeStatus = (
  challenge: ICreatedChallenge
): IUseChallengeStatus | null => {
  if (!challenge) {
    return {
      isAcceptDisabled: false,
      isNotEnoughBalance: false,
      isMaxParticipation: false,
      isFullParticipantChallenge: false,
    };
  }
  const { data: balance } = useSWR<IBalanceResponse>('api/balance');
  const { data: maxChallenges } = useSWR(
    '/api/challenges-count',
    getChallengesCount
  );
  const { participants, activeParticipants, entryCost } = challenge;
  const isFullParticipantChallenge =
    participants > 0 && activeParticipants === participants;
  const isNotEnoughBalance = +balance?.balance < +entryCost;
  const isMaxParticipation = maxChallenges?.asParticipant >= 3;
  const isAcceptDisabled =
    isFullParticipantChallenge || isNotEnoughBalance || isMaxParticipation;

  return {
    isAcceptDisabled,
    isMaxParticipation,
    isNotEnoughBalance,
    isFullParticipantChallenge,
  };
};

export enum EButtonType {
  None,
  StopRegistration,
  ExitChallenge,
  AcceptChallenge,
}

export const getButtonType = (
  challenge: ICreatedChallenge,
  isHistoryMode: boolean,
  isFinished: boolean,
  isPreviewMode: boolean
): EButtonType => {
  const { isParticipant } = challenge;
  const canExit = isParticipant && !isHistoryMode;
  const isEmpty = isButtonNotExist(
    challenge,
    isFinished,
    isHistoryMode,
    isPreviewMode
  );

  if (isEmpty) {
    return EButtonType.None;
  }
  if (canExit) {
    return EButtonType.ExitChallenge;
  }
  return EButtonType.AcceptChallenge;
};

export const prepareChallangeData = (
  challenge: ICreatedChallenge
): IChallenge => {
  const {
    title,
    duration,
    password,
    entryCost,
    reward,
    distribution,
    participants,
    image,
    hero,
    options,
    winnerCount,
    iterations,
    items,
    winInsensitivity,
    targetSteamId,
  } = challenge;
  TagManager.dataLayer({
    dataLayer: { event: 'challenge_create', type: 'draft' },
  });

  return {
    title,
    duration: +duration,
    password,
    entryCost: +entryCost,
    reward: +reward,
    distribution: +distribution,
    participants: +participants,
    image,
    hero: hero ? +hero : null,
    options,
    winnerCount,
    iterations,
    items,
    winInsensitivity,
    targetSteamId,
  };
};

export const createAndPublishChallenge = (
  challenge: ICreatedChallenge
): Promise<ICreatedChallenge | IErrorResponse> => {
  const {
    title,
    duration,
    password,
    entryCost,
    reward,
    distribution,
    participants,
    image,
    hero,
    options,
    winnerCount,
    iterations,
    items,
    winInsensitivity,
    targetSteamId,
  } = challenge;
  return createChallenge({
    title,
    duration,
    password,
    entryCost: +entryCost,
    reward: +reward,
    distribution: +distribution,
    participants: +participants,
    image,
    hero: hero ? +hero : null,
    options,
    winnerCount,
    iterations,
    items,
    winInsensitivity,
    targetSteamId,
  });
};

export const finishActivateChallenge = async (
  id: number
): Promise<IChallenge | IErrorResponse> => {
  const response = await activateChallenge(id);
  localStorage.removeItem(CHALLENGE_FORM_DATA_KEY);
  TagManager.dataLayer({
    dataLayer: { event: 'challenge_create', type: 'published' },
  });
  return response;
};

interface IUseProfit {
  totalEntryFees: number;
  totalProfit: string;
  establishedReward: number;
}

export const calcProfit = (challenge: ICreatedChallenge): IUseProfit => {
  const totalEntryFees = challenge.entryCost * challenge.activeParticipants;
  let totalProfit = '';
  if (!challenge.winners) {
    return {
      establishedReward: 0,
      totalEntryFees: 0,
      totalProfit: '0',
    };
  }

  if (challenge.distribution === EDistribution.FirstToWin) {
    if (challenge.winners.length === 1) {
      totalProfit = (totalEntryFees - +challenge.reward).toFixed(2);
    } else totalProfit = totalEntryFees.toFixed(2);
  }
  if (challenge.distribution === EDistribution.ThreeParticipants) {
    if (challenge.winners.length === 1) {
      totalProfit = (totalEntryFees - +challenge.reward * 0.5).toFixed(2);
    } else if (challenge.winners.length === 2) {
      totalProfit = (totalEntryFees - +challenge.reward * 0.8).toFixed(2);
    } else if (challenge.winners.length === 3) {
      totalProfit = (totalEntryFees - +challenge.reward).toFixed(2);
    } else totalProfit = totalEntryFees.toFixed(2);
  }
  if (challenge.distribution === EDistribution.EqualReward) {
    const onePersonReward = +(
      +challenge.reward / challenge.winnerCount
    ).toFixed(2);
    totalProfit = (
      totalEntryFees -
      challenge.winners.length * onePersonReward
    ).toFixed(2);
  }
  const establishedReward = totalEntryFees - +totalProfit;
  return {
    totalProfit,
    totalEntryFees,
    establishedReward,
  };
};
export const PUBLISH_ERRORS_TEXT_TRANSLATION = (
  translation: TFunction<'translation', undefined>
): Record<string, string> => ({
  network: translation('challengeView.errors.network'),
  money: translation('challengeView.errors.money'),
  maxLimit: translation('challengeView.errors.maxLimit'),
});
