import { FC } from 'react';

import { ICommonIconProps } from './types';

export const MailIcon: FC<ICommonIconProps> = ({
  color = 'currentColor',
  width = 20,
  height = 20,
  viewBox = '0 0 20 20',
  className = '',
  style,
}) => (
  <svg
    fill="none"
    width={width}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 4.70703V3.92578C19.7768 3.14954 19.269 2.67428 18.4766 2.5C12.8255 2.47396 7.17449 2.47396 1.52344 2.5C0.730563 2.67449 0.222748 3.14975 0 3.92578V4.70703C0.112784 5.13992 0.347159 5.49801 0.703125 5.78125L8.90625 11.25C9.63543 11.6927 10.3646 11.6927 11.0938 11.25L19.2969 5.78125C19.6529 5.49801 19.8872 5.13992 20 4.70703ZM8.47656 12.3047C5.65566 10.4145 2.83014 8.53301 0 6.66016V16.0352C0.222748 16.8112 0.730563 17.2864 1.52344 17.4609C7.17449 17.487 12.8255 17.487 18.4766 17.4609C19.269 17.2866 19.7768 16.8114 20 16.0352V6.66016C17.1698 8.53301 14.3443 10.4145 11.5234 12.3047C10.5078 12.8516 9.49219 12.8516 8.47656 12.3047Z"
      fill="#8188A2"
    />
  </svg>
);
