import { FC, useState } from 'react';

import { ParryCard } from 'components';
import { BottomNotification, Paginator } from 'ui-kit';

import { Filters, ParriesNotFound } from '../components';
import { EParryViewMode, LIMIT_OPTIONS } from '../Parries.model';
import { useStyle } from './ParriesList.styles';
import { ParryCardSkeleton } from './ParryCardSkeleton';
import { useParryListFilter } from './ParryList.model';

const ParryListSkeleton: FC = () => (
  <>
    <ParryCardSkeleton />
    <ParryCardSkeleton />
    <ParryCardSkeleton />
    <ParryCardSkeleton />
    <ParryCardSkeleton />
    <ParryCardSkeleton />
  </>
);

export const ParryList: FC = () => {
  const [itemPerPage, setItemPerPage] = useState(LIMIT_OPTIONS[0]);
  const [currentPage, setCurrentPage] = useState(1);

  const { isLoading, filters, changeFilter, activeParries } =
    useParryListFilter(currentPage, itemPerPage);
  const { classes } = useStyle();
  return (
    <div className={classes.container}>
      <Filters
        changeFilter={changeFilter}
        filters={filters}
        mode={EParryViewMode.AllActive}
      />
      <div className={classes.listContainer}>
        {isLoading && <ParryListSkeleton />}
        {!isLoading && activeParries?.total > 0 ? (
          <>
            {activeParries?.result.map((item) => (
              <ParryCard parry={item} key={item.id} />
            ))}
          </>
        ) : (
          <ParriesNotFound title={filters.title} />
        )}
      </div>
      {activeParries?.total > 0 && (
        <Paginator
          className={classes.paginator}
          currentPage={currentPage}
          itemsPerPage={itemPerPage}
          itemsTotal={activeParries.total}
          setCurrentPage={setCurrentPage}
          setItemsPerPage={setItemPerPage}
          limitOptions={LIMIT_OPTIONS}
          isMarkedActive
          showSizeChanger
          hideArrow
        />
      )}
      <BottomNotification />
    </div>
  );
};
