import { makeStyles } from 'theme';

export const useStyle = makeStyles()((theme) => ({
  description: {
    maxWidth: 600,
    marginBottom: 40,
    display: 'inline-block',
  },
  distributionContainer: {
    marginTop: 60,
    ...theme.layouts.flex,
    justifyContent: 'space-between',
  },
  distributionItem: {
    ...theme.layouts.flex,
  },
  distributionTitle: {
    marginBottom: 12,
    width: '100%',
    display: 'inline-block',
  },
  distributionDescription: {
    opacity: 0.6,
  },
  distributionImage: {
    marginRight: -80,
    marginTop: -99,
    marginLeft: -99,
  },
  distributionText: {
    width: 200,
  },
  title: {
    display: 'inline-block',
    width: '100%',
    marginBottom: '1.75rem',
  },
  costContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  entryPlayersContainer: {
    ...theme.layouts.flexJCSpaceBetween,
    width: '100%',
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: 480,
  },
  inputTitle: {
    marginBottom: '1.5rem',
    position: 'relative',
  },
  numberInput: {
    marginBottom: 16,
  },
  checkBox: {
    marginBottom: '1.5rem',
  },
  rewardSection: {
    paddingBottom: 100,
  },
  rewardContainer: {
    ...theme.layouts.flexJCSpaceBetween,
    width: '100%',
    flexWrap: 'wrap',
    gap: 32,
  },
  inputTitleWithoutCheckbox: {
    position: 'relative',
    marginBottom: 70,
  },
  lastInput: {
    marginBottom: '2rem',
    position: 'relative',
  },
  warning: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: 600,
    gap: 12,
    marginBottom: 40,
  },
}));
