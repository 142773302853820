import { makeStyles } from 'theme';

export const useStyle = makeStyles()((theme) => ({
  ...theme.fontStyles,
  ...theme.layouts,

  modal: {
    ...theme.layouts.flexAICenter,
    flexDirection: 'column',
    width: '592px',
    padding: '16px 24px',
  },

  modalTitle: {
    ...theme.fontStyles.textSecondaryTitle,
    ...theme.fontStyles.fontFamilyUbuntu,
    fontWeight: '500',
    color: theme.palette.typo.primary,
    textAlegn: 'center',
    marginTop: '28px',
    marginBottom: '28px',
  },

  modalLoginBtn: {
    ...theme.layouts.flexJCcenter,
    width: '400px !important',
  },

  modalRegisterBtn: {
    ...theme.layouts.flexJCcenter,
    width: '400px !important',
    border: 'none !important',
    marginTop: '20px',
    marginBottom: '20px',
  },
}));
