import { BACKEND_URL } from './env';
interface ILeaderboardItem {
  place: number;
  userId: number;
  score: number;
  username: string;
  avatarUrl: string;
}

interface IUserPlace {
  place: number;
  userId: number;
  score: number;
  username: string;
  avatarUrl: string;
}

interface IRewardList {
  step: number;
  complete: boolean;
}

export interface ILeaderboard {
  table: ILeaderboardItem[];
  userPlace: IUserPlace;
}

export interface IBetaReward {
  message: string;
  code: number;
}

export function getPredictionsLeaderboard(): Promise<ILeaderboard> {
  return fetch(`${BACKEND_URL}/api/leaderboard-service/v1/prediction`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  }).then((data) => data.json());
}

export function getChallengeLeaderboard(): Promise<ILeaderboard> {
  return fetch(`${BACKEND_URL}/api/leaderboard-service/v1/challenge`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  }).then((data) => data.json());
}

export function requestBetaTestReward(
  step: number,
  tradeOfferURL = ''
): Promise<Response> {
  return fetch(`${BACKEND_URL}/api/leaderboard-service/v1/rewards`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      step,
      tradeOfferURL,
    }),
    credentials: 'include',
  });
}

export function getRewardList(): Promise<IRewardList[]> {
  return fetch(`${BACKEND_URL}/api/leaderboard-service/v1/rewards`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  }).then((data) => data.json());
}
