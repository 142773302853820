import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { ICreatedParry, IListedParry } from 'api';
import { Typography } from 'ui-kit';

import { InfinityIcon } from '../../../../icons';
import { useStyle } from './ParryInfo.styles';

interface IParryInfoProps {
  parry: IListedParry;
  isShowDuration: boolean;
}

export const ParryInfo: FC<IParryInfoProps> = ({ parry }) => {
  const { t: translation } = useTranslation();
  const { classes } = useStyle();

  const { Text } = Typography;
  const VerticalDivider: FC = () => <div className={classes.divider} />;
  const HorizontalDivider: FC = () => (
    <div className={classes.horizontalDivider} />
  );

  return (
    <>
      <div className={classes.infoContainer}>
        <div className={classes.itemContainer}>
          <Text variant="b7" color="system">
            {translation('parries-list.parryCard.owner')}
          </Text>
          <Text variant="b6">{parry.owner.username}</Text>
        </div>
        <VerticalDivider />
        <div className={classes.itemContainer}>
          <Text variant="b7" color="system">
            {translation('parries-list.parryCard.signed')}
          </Text>
          <Text variant="b6">
            {parry.activeParticipants}
            <Text variant="b6" color="system">
              {' / '}
              {parry.participants || (
                <InfinityIcon
                  width={16}
                  height={16}
                  className={classes.infIcon}
                />
              )}
            </Text>
          </Text>
        </div>
      </div>
      <HorizontalDivider />
    </>
  );
};
