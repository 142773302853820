import React, { FC } from 'react';

import clsx from 'clsx';

import { useStyle } from './Card.styles';

export type CardPaddingType =
  | 'p2x4'
  | 'p4x8'
  | 'p20'
  | 'challengeHeader'
  | 'parryHeader'
  | 'p8'
  | 'p16'
  | 'p12x16'
  | 'p24'
  | 'p16x32'
  | 'p32'
  | 'p32x16x16x16';

export type CardBorderRadiusType = 'br2' | 'br4' | 'br8' | 'br12' | 'br16';

export type CardBackgroundType = 'bgGhost' | 'bgSecondary';

export interface ICardProps {
  variant?: CardPaddingType;
  children: React.ReactNode;
  className?: string;
  color?: CardBackgroundType;
  borderRadius?: CardBorderRadiusType;
  title?: string;
  onClick?: () => void;
}

export const Card: FC<ICardProps> = ({
  children,
  className = '',
  variant = 'p20',
  onClick = () => null,
  color = 'bgSecondary',
  borderRadius = 'br8',
  title,
}) => {
  const { classes } = useStyle({ variant, color, border: borderRadius });
  return (
    <div
      onClick={onClick}
      className={clsx(classes.card, className)}
      title={title}
    >
      {children}
    </div>
  );
};
