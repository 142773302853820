import { makeStyles } from 'theme';

export const useStyle = makeStyles()((theme) => ({
  ...theme.layouts,
  // Challeng Title

  title: {
    ...theme.fontStyles.fontFamilyUbuntu,
    ...theme.fontStyles.textSecondaryTitle,
    width: '380px',
    color: theme.palette.typo.primary,
    fontWeight: 700,
    textAlign: 'center',
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    top: '2.5rem',
  },
  headerOwner: {
    ...theme.fontStyles.fontFamilyUbuntu,
    display: 'flex',
    position: 'absolute',
    top: '2.5rem',
    left: '50%',
    transform: 'translateX(-50%)',
  },
  headerColumn: {
    ...theme.layouts.flexAICenter,
    flexDirection: 'column',
  },
  headerSeparator: {
    height: '66px',
    width: '1px',
    backgroundColor: theme.palette.bg.border,
    margin: '0 1.5rem',
  },
  headerTitle: {
    ...theme.fontStyles.text18,
    color: theme.palette.typo.primary,
  },
  headerAmount: {
    ...theme.fontStyles.text24,
    color: theme.palette.typo.warning,
    marginTop: '0.75rem',
  },

  // Challenge SideBar

  sidebarContainer: {
    display: 'flex',
    marginBottom: '2rem',
  },
  linkCopied: {
    color: theme.palette.typo.success,
  },
  link: {
    ...theme.fontStyles.text14,
    marginLeft: '0.5rem',
    fontWeight: 400,
    textDecoration: 'underline',
  },
  participantsContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '2rem',
  },
  participantsTitle: {
    marginBottom: '0.75rem',
  },
  participantsAmount: {
    ...theme.layouts.flexJCSpaceBetween,
    marginBottom: '0.5rem',
  },
  participantsAmountText: {
    ...theme.fontStyles.text10,
    fontWeight: 400,
    color: theme.palette.typo.primary,
    opacity: '0.7',
  },
  progressBar: {
    height: '0.5rem',
    marginBottom: '0.5rem',
  },
  winnersContainer: {
    marginBottom: '1rem',
  },

  // Challenge Params

  paramsImage: {
    height: 86,
    width: 86,
  },
  listParams: {
    marginLeft: '1.5rem',
  },
  challengeTitle: {
    ...theme.fontStyles.fontFamilyUbuntu,
    ...theme.fontStyles.text24,
    marginBottom: '1rem',
    fontWeight: 700,
    color: theme.palette.typo.primary,
  },
  paramsBlockFirst: {
    display: 'flex',
    flexDirection: 'column',
  },
  paramsBlock: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '1.5rem',
  },
  paramsTopValueText: {
    color: theme.palette.typo.primary,
    marginLeft: '0.375rem',
  },
  paramsTopName: {
    ...theme.fontStyles.text10,
    color: theme.palette.typo.system,
    marginBottom: '0.25rem',
  },
  paramsIcon: {
    color: theme.palette.typo.system,
    width: 15,
  },
  challengeFinishingSoon: {
    ...theme.fontStyles.text10,
    background: 'linear-gradient(89.03deg, #d34e31 0.64%, #fb18a0 175.76%)',
    marginLeft: '1.5rem',
    padding: '4px 8px',
    borderRadius: '24px',
    color: theme.palette.typo.primary,
  },

  // AvatarsList
  avatar: {
    borderRadius: '50%',
    width: '44px',
    height: '44px',
    border: `4px solid ${theme.palette.bg.secondary}`,
  },
  avatarNotFirst: {
    marginLeft: '-16px',
  },
  avatarsMore: {
    ...theme.layouts.flexCenter,
    backgroundColor: theme.palette.typo.link,
    borderRadius: '50%',
    width: 40,
    height: 40,
    marginLeft: '-16px',
  },
}));
