import { FC, useEffect } from 'react';

import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import { EUserPermissions } from 'api';
import { EDistribution, OPTIONS_DISTRIBUTION } from 'consts';
import { hasPermissions } from 'helpers';
import { Checkbox, Dropdown, InputNumber, Typography } from 'ui-kit';

import { AlertIcon } from '../../../../../icons';
import {
  ChallengeFormValueType,
  ERROR_MESSAGES_TRANSLATIONS,
  text,
} from '../../CreateChallenge.model';
import { useStyle } from './CostAndReward.styles';

export const CostAndReward: FC = () => {
  const { setValue } = useFormContext();
  const { t: translation } = useTranslation();
  const {
    control,
    setError,
    clearErrors,
    formState: { errors },
  } = useFormContext<ChallengeFormValueType>();
  const ERROR_MESSAGES = ERROR_MESSAGES_TRANSLATIONS(translation);
  const rewardMode = useWatch({ name: 'distribution', control });
  const reward = useWatch({ name: 'reward', control });
  const { data: balance } = useSWR('api/balance');
  const { data: user } = useSWR('api/user');

  const hasPermission = hasPermissions(user, 'create_cycle_challenge');

  useEffect(() => {
    if (rewardMode === EDistribution.ThreeParticipants) {
      setValue('winnerCount', 3);
    }
    if (rewardMode === EDistribution.FirstToWin) {
      setValue('winnerCount', 1);
    }
  }, [rewardMode]);

  useEffect(() => {
    if (Number(balance?.balance) < Number(reward)) {
      setError('reward', {
        type: 'custom',
        message: ERROR_MESSAGES.rewardGreaterThanBalance,
      });
    } else {
      clearErrors('reward');
    }
  }, [reward]);

  const limitedParticipants = useWatch({
    name: 'limitedParticipants',
    control,
  });
  const freeForAll = useWatch({
    name: 'freeForAll',
    control,
  });

  useEffect(() => {
    if (freeForAll) {
      setValue('entryCost', 0);
    }
  }, [freeForAll]);
  const { classes } = useStyle();
  const { Headline, Text } = Typography;
  const isAdmin = hasPermissions(user, EUserPermissions.createCycle);
  return (
    <>
      <section>
        <Headline variant="h2" className={classes.title}>
          {translation('challenge.form.costReward.title')}
        </Headline>
        <Text className={classes.description}>
          {translation('challenge.form.costReward.description')}
        </Text>
        <div className={classes.warning}>
          <Text color="warning">
            <AlertIcon />
          </Text>
          <Text variant="b5" color="warning">
            {translation('challenge.form.warning')}
          </Text>
        </div>
        <div className={classes.costContainer}>
          <div className={classes.entryPlayersContainer}>
            <div className={classes.inputContainer}>
              <Controller
                name={`entryCost`}
                control={control}
                rules={{
                  required: {
                    value: !freeForAll,
                    message: ERROR_MESSAGES.requiredFiled,
                  },
                  min: {
                    value: freeForAll ? 0 : 100,
                    message: ERROR_MESSAGES.entry,
                  },
                }}
                render={({ field: { onChange, ...rest } }) => (
                  <InputNumber
                    placeholder="100.00 GC"
                    suffix=" GC"
                    isAllowed={({ floatValue }) => floatValue !== undefined}
                    min={0}
                    tooltip={translation(text.entryFee)}
                    title={translation('challenge.form.costReward.entryFee')}
                    disabled={freeForAll && hasPermission}
                    allowNegative={false}
                    className={classes.numberInput}
                    decimalScale={2}
                    decimalSeparator={'.'}
                    onValueChange={({ value }) => {
                      onChange(value);
                    }}
                    error={errors['entryCost']?.message}
                    {...rest}
                  />
                )}
              />
              {isAdmin && (
                <Controller
                  name={`freeForAll`}
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      className={classes.checkBox}
                      label={translation(
                        'challenge.form.costReward.freeForAll'
                      )}
                      checked={!!field.value}
                      onChange={field.onChange}
                    />
                  )}
                />
              )}
            </div>
            <div className={classes.inputContainer}>
              <Controller
                name={`participants`}
                control={control}
                rules={{
                  required: {
                    value: limitedParticipants,
                    message: ERROR_MESSAGES.requiredFiled,
                  },
                  min: {
                    value: limitedParticipants ? 1 : 0,
                    message: ERROR_MESSAGES.requiredFiled,
                  },
                }}
                render={({ field: { onChange, ...rest } }) => (
                  <InputNumber
                    tooltip={translation(text.playersLimit)}
                    title={translation(
                      'challenge.form.costReward.playersLimit'
                    )}
                    isAllowed={({ floatValue }) =>
                      floatValue < 1000 || floatValue === undefined
                    }
                    placeholder="0"
                    min={0}
                    allowNegative={false}
                    decimalScale={0}
                    disabled={!limitedParticipants}
                    onValueChange={({ value }) => {
                      onChange(value);
                    }}
                    className={classes.numberInput}
                    error={errors['participants']?.message}
                    {...rest}
                  />
                )}
              />
              <Controller
                name={`limitedParticipants`}
                control={control}
                render={({ field }) => (
                  <Checkbox
                    className={classes.checkBox}
                    label={translation('challenge.form.costReward.limited')}
                    checked={!!field.value}
                    onChange={field.onChange}
                  />
                )}
              />
            </div>
          </div>
        </div>
      </section>
      <section className={classes.rewardSection}>
        <Headline variant="h2" className={classes.title}>
          {translation('challenge.form.costReward.reward')}
        </Headline>
        <Text className={classes.description}>
          {translation('challenge.form.costReward.rewardDescription')}
        </Text>
        <div className={classes.warning}>
          <Text color="warning">
            <AlertIcon />
          </Text>
          <Text variant="b5" color="warning">
            {translation('challenge.form.warning')}
          </Text>
        </div>
        <div className={classes.rewardContainer}>
          <div className={classes.inputContainer}>
            <Controller
              name="reward"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: ERROR_MESSAGES.requiredFiled,
                },
                min: {
                  value: 1000,
                  message: ERROR_MESSAGES.rewardMin,
                },
              }}
              render={({ field: { onChange, ...rest } }) => (
                <InputNumber
                  placeholder="0.00 GC"
                  suffix=" GC"
                  min={0}
                  tooltip={translation(text.reward)}
                  isAllowed={({ floatValue }) => floatValue !== undefined}
                  className={classes.numberInput}
                  allowNegative={false}
                  title={translation('challenge.form.costReward.reward')}
                  decimalScale={2}
                  decimalSeparator={'.'}
                  onValueChange={({ value }) => {
                    onChange(value);
                  }}
                  error={errors['reward']?.message}
                  {...rest}
                />
              )}
            />
          </div>
          <div className={classes.inputContainer}>
            <Controller
              name={`winnerCount`}
              control={control}
              rules={{
                min: {
                  value: 1,
                  message: ERROR_MESSAGES['winnerCountRequired'],
                },
                required: {
                  value: rewardMode === EDistribution.EqualReward,
                  message: ERROR_MESSAGES['winnerCountRequired'],
                },
                max: {
                  value:
                    (rewardMode === EDistribution.EqualReward &&
                      +reward * 100) ||
                    1000,
                  message: ERROR_MESSAGES['winnerCountBig'],
                },
              }}
              render={({ field: { onChange, ...rest } }) => (
                <InputNumber
                  isAllowed={({ floatValue }) =>
                    floatValue < 1000 || floatValue === undefined
                  }
                  tooltip={translation(text.winners)}
                  placeholder="0"
                  className={classes.numberInput}
                  title={translation('challenge.form.costReward.winners')}
                  min={1}
                  decimalScale={0}
                  allowNegative={false}
                  disabled={rewardMode !== EDistribution.EqualReward}
                  onValueChange={({ value }) => {
                    onChange(value);
                  }}
                  max={999}
                  error={errors['winnerCount']?.message}
                  {...rest}
                />
              )}
            />
          </div>
          <div className={classes.inputContainer}>
            <Controller
              name={`distribution`}
              control={control}
              render={({ field: { onChange } }) => (
                <Dropdown
                  options={OPTIONS_DISTRIBUTION(translation)}
                  defaultValue={OPTIONS_DISTRIBUTION(translation)[0]}
                  value={OPTIONS_DISTRIBUTION(translation)[rewardMode]}
                  onChange={onChange}
                  title={translation(
                    'challenge.form.costReward.rewardDistribution'
                  )}
                />
              )}
            />
          </div>
        </div>
        <div className={classes.distributionContainer}>
          <div className={classes.distributionItem}>
            <img
              className={classes.distributionImage}
              src="/images/challenges/create/hero-one.png"
              alt="dota2 hero"
            />
            <div className={classes.distributionText}>
              <Text className={classes.distributionTitle}>
                {translation(
                  'challenge.form.costReward.distribution.first.title'
                )}
              </Text>
              <Text variant="b4" className={classes.distributionDescription}>
                {translation(
                  'challenge.form.costReward.distribution.first.description'
                )}
              </Text>
            </div>
          </div>
          <div className={classes.distributionItem}>
            <img
              className={classes.distributionImage}
              src="/images/challenges/create/hero-two.png"
              alt="dota2 hero"
            />
            <div className={classes.distributionText}>
              <Text className={classes.distributionTitle}>
                {translation(
                  'challenge.form.costReward.distribution.equal.title'
                )}
              </Text>
              <Text variant="b4" className={classes.distributionDescription}>
                {translation(
                  'challenge.form.costReward.distribution.equal.description'
                )}
              </Text>
            </div>
          </div>
          <div className={classes.distributionItem}>
            <img
              className={classes.distributionImage}
              src="/images/challenges/create/hero-three.png"
              alt="dota2 hero"
            />
            <div className={classes.distributionText}>
              <Text className={classes.distributionTitle}>
                {translation(
                  'challenge.form.costReward.distribution.amongThree.title'
                )}
              </Text>
              <Text variant="b4" className={classes.distributionDescription}>
                {translation(
                  'challenge.form.costReward.distribution.amongThree.description'
                )}
              </Text>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
