import { makeStyles } from 'theme';

export const useStyle = makeStyles()((theme) => ({
  container: {
    backgroundColor: theme.palette.bg.secondary,
    position: 'fixed',
    bottom: 0,
    right: 0,
    width: 'calc(100% - 72px)',
    padding: '12px 64px',
    display: 'flex',
    gap: 12,
    alignItems: 'center',
  },
  visibility: {
    display: 'none',
  },
}));
